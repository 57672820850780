import React, { useEffect } from "react";
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Pagination } from "react-bootstrap";
import { Switch } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { connect } from 'react-redux';
import moment from 'moment';
import FuzzySearch from 'fuzzy-search';

import ItemDropdown from './ItemDropdown';
import { toAbsoluteUrl } from "../../_helpers";
import * as subheaderAction from '../../../app/modules/Subheader';
import * as accommodationActions from '../../../app/modules/Accommodation';

const AccommodationManagement = (props) => {
    let allAccommodations = [];
    const history = useHistory();
    const pageNumbers = [];
    let totalPages;
    let result = [];
    let skipInit = props.skip;

    // State
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });
    
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    // // Show delete modal
    // const showDeleteModal = (e, user, multiple) => {
    //     // Show delete modal
    //     setDeleteModal(true);

    //     if (multiple) {
    //         // Set user state
    //         setUser({
    //             values: user,
    //             multiple
    //         });

    //     } else {
    //         // Set user state
    //         setUser({
    //             _id: user._id,
    //             status: user.status,
    //             emailId: user.emailId,
    //             lastName: user.lastName,
    //             firstName: user.firstName,
    //             createdAt: user.createdAt,
    //             contactNumber: user.contactNumber
    //         });
    //     }
    // };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    // On view button click
    const onViewButtonHandler = (e, accommodation) => {
        e.preventDefault();

        if (accommodation._id) {

            // Go to property detail page
            history.push(`/accommodation-details/${accommodation._id}`);
        }
    };

    useEffect(() => {
        let skip = (props.skip - 1) * props.limit;

        if (props.search) {
            skip = 0;
        }

        // Fetch all accommodation of admin
        props.getAllAccommodations(
            props.user._id,
            props.column,
            props.dir,
            skip,
            props.limit,
            props.type,
            props.search
        );

    }, [props.skip, props.type, props.column, props.dir, props.search]);

    useEffect(() => {

        // Remove data from store
        return () => {
            // Clean accommodation data
            props.accommodationInit();

            // Clean accommodation data
            props.accommodationSkipInit();

            // Clean subheader search, sort and type value
            props.initSearchSorting();
        };
    }, []);

    if (props.allAccommodations && props.allAccommodations.records && props.allAccommodations.records.length > 0) {        
        // // Integrate fuzzy search here
        // if (props.search && props.search.length > 0) {
        //     skipInit = 1;
        //     const searcher = new FuzzySearch(props.allAccommodations.records, [
        //         'name.en', 'address.flat', 'address.streetAddress', 'address.city', 'address.country', 'address.postCode'
        //     ], {
        //         sort: true
        //     });

        //     result = searcher.search(props.search);

        //     totalPages = Math.ceil(result.length / props.limit);
        //     for (let i = 1; i <= totalPages; i++) {
        //         pageNumbers.push(i);
        //     };

        // } else {
        //     result = props.allAccommodations.records;

        //     totalPages = Math.ceil(props.allAccommodations.recordsTotal / props.limit);
        //     for (let i = 1; i <= totalPages; i++) {
        //         pageNumbers.push(i);
        //     };
        // }

        result = props.allAccommodations.records;

        totalPages = Math.ceil(props.allAccommodations.recordsTotal / props.limit);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        };
        
        // totalPages = Math.ceil(result.length / props.limit);
        // for (let i = 1; i <= totalPages; i++) {
        //     pageNumbers.push(i);
        // };

        allAccommodations = result.map((accommodation, index) => {
            let amount = 0;
        
            if (accommodation.price && accommodation.price.length > 0) {
                accommodation.price.forEach(el => {
                    amount += el.rate;
                });
            }
    
            return (
                <Col xl={6} key={ index }>
                    <div className="pho_bx1 border-0 bg-white card-custom">
                        <div className="left">
                            <img
                                className="phoimgSize120"
                                src={ accommodation.media[0] }
                                alt="..."
                            />
                            <div className="leftInRight">
                                <div className="pho_16_400 pho_ellips">
                                    { accommodation.name.en }
                                    {/* <NavLink to={ `/accommodation-details/${accommodation._id}` }>
                                        { accommodation.name.en }   
                                    </NavLink> */}
                                </div>
                                <div className="pho_14_400">
                                    Added on: { accommodation.createdAt ? moment().format('L') : '' }
                                </div>
                                <div className="pho_14_400 mb-2">
                                    Location: { accommodation.address.flat } { accommodation.address.streetAddress }, { accommodation.address.city }, { accommodation.address.country }, { accommodation.address.postcode }
                                </div>
                                <div className="pho_14_600">Price: $ { amount.toFixed(2) }</div>
                                <div className="pho_14_400">By { (accommodation.landlordDetails && accommodation.landlordDetails.firstName)?accommodation.landlordDetails.firstName: '' } { (accommodation.landlordDetails && accommodation.landlordDetails.lastName)?accommodation.landlordDetails.lastName:'' }</div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="pho_detailMark pho_14_400">{ accommodation.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' }</div>
                            <div>
                                <Switch
                                    // value="active"
                                    color="primary"
                                    checked={ accommodation.status === 'ACTIVE' }
                                    onChange={ (e) => {
                                        const skip = (props.skip - 1) * props.limit;

                                        // Accommodation status change function
                                        props.accommodationStatusChange(
                                            props.user._id, 
                                            accommodation._id,
                                            props.column,
                                            props.dir,
                                            skip,
                                            props.limit,
                                            props.type                                            
                                        );
                                    } }
                                />
                                <ItemDropdown 
                                    item="" 
                                    doc={ accommodation }
                                    view={ onViewButtonHandler }
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            );
        });
    }

    if (props.search) {
        skipInit = 1;
    }
      
    return (
        <>
            {
                !props.loading ?
                    allAccommodations.length > 0 ?
                        <Row className="phoGap14">
                            { allAccommodations }
                        </Row>
                    :
                        <div>No Data Available</div>
                : 
                    <div id="compnent-splash-screen" className="compnent-splash-screen">
                        <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                            <circle 
                                className="path"
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                            ></circle>
                        </svg>
                    </div>
            }
            
            {/* Pagination */}
            { 
                !props.loading && props.allAccommodations && props.allAccommodations.recordsTotal > 0 && 
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                        <div className="pho_14_400 pho_opacity_5">
                            {/* Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.allAccommodations.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.allAccommodations.recordsTotal } of { props.allAccommodations.recordsTotal } records */}
                            {
                                props.search ? 
                                    `Displaying ${ skipInit > 1 ? ((skipInit - 1) * props.limit) + 1 : 1 } - ${ props.allAccommodations.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.allAccommodations.recordsTotal } of ${ props.allAccommodations.recordsTotal } records`
                                :
                                    `Displaying ${ props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - ${ props.allAccommodations.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.allAccommodations.recordsTotal } of ${ props.allAccommodations.recordsTotal } records`
                            }
                        </div>
                        {/* {
                            props.search && !result.length > 0 ?
                                null
                            : */}
                                <Pagination>
                                    {/* Go to first page */}
                                    <Pagination.First
                                        disabled={ props.skip === 1 || skipInit === 1 } 
                                        onClick={ goToFirstPage }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                                    </Pagination.First>

                                    {/* Go to previous page */}
                                    <Pagination.Prev
                                        disabled={ props.skip === 1 || skipInit === 1 } 
                                        onClick={ prevPageHandler }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                                    </Pagination.Prev>

                                    {/* All pages */}
                                    {
                                        pageNumbers.map((number, i) => {
                                            console.log(number);
                                            if (
                                                !props.search &&
                                                number >= parseInt(props.skip) - 3 &&
                                                number <= parseInt(props.skip) + 3 
                                            ) {
                                                return (
                                                    <Pagination.Item
                                                        key={ i }
                                                        id={ number }
                                                        disabled={ number === props.skip }
                                                        active={ number === props.skip }
                                                        onClick={ pageChangeHandler }
                                                    >
                                                        { number }
                                                    </Pagination.Item>
                                                );
                                            } else if (
                                                props.search &&
                                                number >= parseInt(skipInit) - 3 &&
                                                number <= parseInt(skipInit) + 3 
                                            ) {
                                                return (
                                                    <Pagination.Item
                                                        key={ i }
                                                        id={ number }
                                                        disabled={ number === skipInit }
                                                        active={ number === skipInit }
                                                        onClick={ pageChangeHandler }
                                                    >
                                                        { number }
                                                    </Pagination.Item>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }

                                    {/* Go to next page */}
                                    <Pagination.Next
                                        disabled={ props.skip === totalPages || skipInit === totalPages }  
                                        onClick={ nextPageHandler }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                                    </Pagination.Next>

                                    {/* Go to last page */}
                                    <Pagination.Last
                                        disabled={ props.skip === totalPages || skipInit === totalPages }  
                                        onClick={ goToLastPage }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                                    </Pagination.Last>
                                </Pagination>
                        {/* // } */}
                    </div>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        skip: state.accommodation.skip,
        limit: state.accommodation.limit,
        column: state.subHeader.column,
        dir: state.subHeader.dir,
        type: state.subHeader.type,
        search: state.subHeader.search,  
        loading: state.accommodation.loading,      
        allAccommodations: state.accommodation.allAccommodations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // addUser: (adminId, values) => dispatch(userActions.addUser(adminId, values)),
        initSearchSorting: () => dispatch(subheaderAction.initState()),
        updateSkip: (skip) => dispatch(accommodationActions.updateSkip(skip)),
        accommodationInit: () => dispatch(accommodationActions.accommodationInit()),
        accommodationSkipInit: () => dispatch(accommodationActions.accommodationSkipInit()),
        accommodationStatusChange: (
            adminId,
            accommodationId,
            column,
            dir,
            skip,
            limit,
            type
        ) => dispatch(accommodationActions.accommodationStatusChange(adminId, accommodationId, column, dir, skip, limit, type)),
        getAllAccommodations: (
            adminId,
            column,
            dir,
            skip,
            limit,
            type,
            search
        ) => dispatch(accommodationActions.fetchAllAccommodation(adminId, column, dir, skip, limit, type, search)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationManagement);

{/* <Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Home Office</div>
            <div>
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange('checkedB')}
                    value="active"
                    color="primary"
                />
                <ItemDropdown item="" />
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Home Office</div>
            <div>
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange('checkedB')}
                    value="active"
                    color="primary"
                />
                <ItemDropdown item="" />
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange('checkedB')}
                    value="active"
                    color="primary"
                />
                <ItemDropdown item="" />
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange('checkedB')}
                    value="active"
                    color="primary"
                />
                <ItemDropdown item="" />
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Home Office</div>
            <div>
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange('checkedB')}
                    value="active"
                    color="primary"
                />
                <ItemDropdown item="" />
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange('checkedB')}
                    value="active"
                    color="primary"
                />
                <ItemDropdown item="" />
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Switch
                    checked={state.checkedB}
                    onChange={handleChange('checkedB')}
                    value="active"
                    color="primary"
                />
                <ItemDropdown item="" />
            </div>
        </div>
    </div>
</Col> */}