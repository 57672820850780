import React, { useEffect, useState } from 'react';
import { Table, Modal, Pagination } from "react-bootstrap";
import { connect } from 'react-redux';
import SVG from "react-inlinesvg";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Formik, FieldArray, Form } from 'formik';

import ItemDropdown from './ItemDropdown';
import { useSubheader } from '../../layout';
import { toAbsoluteUrl } from "../../_helpers";
import { initialTabValues } from './NotificationSchema';
import * as notificationActions from '../../../app/modules/Notification';

import 'react-toastify/dist/ReactToastify.min.css';

const Notifications = (props) => {
    let totalPages;
    const pageNumbers = [];
    const notificationIds = [];
    const history = useHistory();
    const subHeader = useSubheader();

    const modifiedInitialTabObj = Object.assign({}, initialTabValues);

    // On notification button click handler
    const onNotificationButtonClick = (notificationId) => {

        // Push history
        history.push(`/accommodation-details/${notificationId}`);
    };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    useEffect(() => {
        const title = 'Notifications';

        // Set sub header title
        subHeader.setTitle(title);

        const skip = (props.skip - 1) * props.limit;

        // Get all notification
        props.getAllNotifications(props.user._id, skip, props.limit);

        // if (skip === 0) {
        //     setTimeout(() => {
        //         const skip = (props.skip - 1) * props.limit;
    
        //         // Call update notification viewed action function
        //         props.updateNotifications(
        //             props.user._id, 
        //             notificationIds,
        //             skip,
        //             props.limit
        //         );
        //     }, 7000);
        // }
    }, [props.page, props.skip]);

    useEffect(() => {
        // Reset skip when component destroy
        return () => {

            // Call skip init function
            props.skipInit();
        };
    }, []);

    if (props.allNotifications && props.allNotifications.records && props.allNotifications.records.length > 0) {

        totalPages = Math.ceil(props.allNotifications.recordsTotal / props.limit);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        };

        const array = [];

        props.allNotifications.records.forEach(el => {
            const data = { ...el };

            data.checkbox = false;
            array.push(data);

            // Push notification id into notification ids array
            notificationIds.push(el._id);
        });

        modifiedInitialTabObj.notifications = array;
    }

    return (
        <>
            <div className="pho_notification_bx">
                <Formik
                    enableReinitialize
                    initialValues={ modifiedInitialTabObj }
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        // try {
                        //     setSubmitting(true);

                        //     // Show delete modal
                        //     showDeleteModal(undefined, values, true);

                        //     if (deleteModal) {
                        //         setTimeout(() => {                                
                        //             // Reset form to initial value
                        //             resetForm(modifiedInitialTabObj);
    
                        //             // Set submitting form to false
                        //             setSubmitting(false);  
                        //         }, 500);
                        //     }
                            
                        // } catch (err) {
                        //     console.log(err);

                        //     // Notification
                        //     notify('error', 'Something went wrong');
                        // }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <Form onSubmit={ handleSubmit }>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="pho_checkbox iskl_border">
                                                <input
                                                    type="checkbox"
                                                    name="checkbox"
                                                    className={`mr-2 checkbox`}
                                                    checked={ values.checkbox }
                                                    onChange={ (e) => {   
                                                        let total = 0;
                                                        // Set current check box value
                                                        setFieldValue('checkbox', !values.checkbox);

                                                        values.notifications.forEach((el, index) => {  
                                                            total += 1;
                                                            
                                                            // Set all checkbox value
                                                            setFieldValue(`notifications.${index}.checkbox`, !values.checkbox);
                                                        });                                                                            

                                                        // if (values.checkbox || (!values.checkbox && total === 0)) {
                                                        //     // Set common delete state
                                                        //     setCommonDelete({
                                                        //         count: 0,
                                                        //         value: false
                                                        //     });
                                                        // } else if (!values.checkbox && total > 0) {
                                                        //     // Set common delete state
                                                        //     setCommonDelete({
                                                        //         count: total,
                                                        //         value: true
                                                        //     });
                                                        // }
                                                    }}
                                                />
                                                <label></label>
                                            </div>
                                        </th>
                                        <th>Summary</th>
                                        <th>Date</th>
                                        {/* <th>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <FieldArray name="notifications">
                                        {({ insert, remove, push }) => (
                                            <>
                                                {
                                                    !props.loading && values.notifications && values.notifications.length > 0 ?
                                                        values.notifications.map((notification, index) => (
                                                            <tr key={ index }>
                                                                <td>
                                                                    <div className="pho_checkbox iskl_border">
                                                                        <input                                                                                    
                                                                            type="checkbox"
                                                                            name={ `notifications.${index}.checkbox` }
                                                                            className={`mr-2 checkbox`}
                                                                            checked={ notification.checkbox }
                                                                            onChange={ (e) => {
                                                                                // if (notification.checkbox) {
                                                                                //     // Set common delete state
                                                                                //     setCommonDelete({
                                                                                //         count: commonDelete.count - 1,
                                                                                //         value: false
                                                                                //     });
                                                                                // } else {
                                                                                //     // Set common delete state
                                                                                //     setCommonDelete({
                                                                                //         count: commonDelete.count + 1,
                                                                                //         value: true
                                                                                //     });
                                                                                // }

                                                                                // Set field values
                                                                                setFieldValue(`notifications.${index}.checkbox`, !notification.checkbox);
                                                                            } }
                                                                        />
                                                                        <label></label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="pho_new max-width-400 d-flex align-items-center">
                                                                        {
                                                                            !notification.isRead &&
                                                                                <span className="pho_new_notification"></span>
                                                                            } 
                                                                        {/* <div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
                                                                            <span className="symbol-label">
                                                                            <img src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
                                                                            </span>
                                                                        </div> */}
                                                                        <div className="pho_notification_text d-flex flex-column">
                                                                            <a
                                                                                onClick={ (e) => onNotificationButtonClick(notification.refId) }
                                                                                className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
                                                                            >
                                                                                { notification.message.en }
                                                                            </a>
                                                                            {/* <span className="pho_ellips text-muted">
                                                                                Duis non eros consequat elementum.
                                                                            </span> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="pho_14_400">
                                                                        { moment(notification.date ? notification.date : notification.createdAt).format('L') }  |  { moment(notification.date ? notification.date :notification.createdAt).format('LT') }
                                                                    </div>
                                                                </td>
                                                                {/* <td><ItemDropdown item="" /></td> */}
                                                            </tr>
                                                        ))
                                                    :
                                                        props.loading ?
                                                            <tr>
                                                                <td colSpan="3" className="text-center">Loading...</td>
                                                            </tr>
                                                        : 
                                                            <tr>
                                                                <td colSpan="3" className="text-center">No Data Available</td>
                                                            </tr>
                                                }
                                            </>
                                        )}
                                    </FieldArray> 
                                </tbody>
                            </Table>
                        </Form>
                    )}
                </Formik>  

                {/* Pagination */}
                { 
                    props.allNotifications && props.allNotifications.recordsTotal > 0 && 
                        <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                            <div className="pho_14_400 pho_opacity_5">                                
                                Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.allNotifications.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.allNotifications.recordsTotal } of { props.allNotifications.recordsTotal } records
                            </div>
                            <Pagination>
                                {/* Go to first page */}
                                <Pagination.First
                                    disabled={ props.skip === 1 } 
                                    onClick={ goToFirstPage }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                                </Pagination.First>

                                {/* Go to previous page */}
                                <Pagination.Prev
                                    disabled={ props.skip === 1 } 
                                    onClick={ prevPageHandler }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                                </Pagination.Prev>

                                {/* All pages */}
                                {
                                    pageNumbers.map((number, i) => {
                                        if (
                                            number >= parseInt(props.skip) - 3 &&
                                            number <= parseInt(props.skip) + 3 
                                        ) {
                                            return (
                                                <Pagination.Item
                                                    key={ i }
                                                    id={ number }
                                                    active={ number === props.skip }
                                                    onClick={ pageChangeHandler }
                                                >
                                                    { number }
                                                </Pagination.Item>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }

                                {/* Go to next page */}
                                <Pagination.Next
                                    disabled={ props.skip === totalPages } 
                                    onClick={ nextPageHandler }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                                </Pagination.Next>

                                {/* Go to last page */}
                                <Pagination.Last
                                    disabled={ props.skip === totalPages } 
                                    onClick={ goToLastPage }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                                </Pagination.Last>
                            </Pagination>
                        </div>
                }                    
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
      user: state.auth.user,
      skip: state.notification.skip,
      limit: state.notification.limit,
      loading: state.notification.loading,
      allNotifications: state.notification.allNotifications
    };
};
  
const mapDispatchToProps = dispatch => {
    return {
        skipInit: () => dispatch(notificationActions.notificationSkipInit()),
        notificationInit: () => dispatch(notificationActions.notificationInit()),
        updateSkip: (skip) => dispatch(notificationActions.notificationSkipUpdate(skip)),
        getAllNotifications: (adminId, skip, limit) => dispatch(notificationActions.fetchAllNotification(
            adminId, 
            skip, 
            limit
        )),
        updateNotifications: (
            adminId, 
            notificationIds,
            skip, 
            limit
          ) => dispatch(notificationActions.notificationUpdate(adminId, notificationIds, skip, limit)),
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

{/* <tr>
<td>
<div className="pho_checkbox iskl_border">
    <input
        type="checkbox"
        className={`mr-2 checkbox`}
        name="checkbox"
    />
    <label></label>
</div>
</td>
<td>
<div className="pho_new max-width-400 d-flex align-items-center">
    <span className="pho_new_notification"></span>
<div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
    <img src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
</div>
<div className="pl-5 pho_notification_text d-flex flex-column">
    <a
    href="#"
    className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
    Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
    Duis non eros consequat elementum.
    </span>
</div>
</div>
</td>
<td>
    <div className="pho_14_400">06/03/2020  |  05:00 PM</div>
</td>
<td><ItemDropdown item="" /></td>
</tr>
<tr>
<td>
<div className="pho_checkbox iskl_border">
    <input
        type="checkbox"
        className={`mr-2 checkbox`}
        name="checkbox"
    />
    <label></label>
</div>
</td>
<td>
<div className="pho_new max-width-400 d-flex align-items-center">
    <span className="pho_new_notification"></span>
<div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
    <img src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
</div>
<div className="pl-5 pho_notification_text d-flex flex-column">
    <a
    href="#"
    className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
    Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
    Duis non eros consequat elementum.
    </span>
</div>
</div>
</td>
<td>
    <div className="pho_14_400">06/03/2020  |  05:00 PM</div>
</td>
<td><ItemDropdown item="" /></td>
</tr>
<tr>
<td>
<div className="pho_checkbox iskl_border">
    <input
        type="checkbox"
        className={`mr-2 checkbox`}
        name="checkbox"
    />
    <label></label>
</div>
</td>
<td>
<div className="pho_new max-width-400 d-flex align-items-center">
    <span className="pho_new_notification"></span>
<div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
    <img src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
</div>
<div className="pl-5 pho_notification_text d-flex flex-column">
    <a
        href="#"
        className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
        Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
    Duis non eros consequat elementum.
    </span>
</div>
</div>
</td>
<td>
    <div className="pho_14_400">06/03/2020  |  05:00 PM</div>
</td>
<td><ItemDropdown item="" /></td>
</tr> */}