import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import * as bookingActions from '../../Booking/actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    getAllAccomodations,
    getHostedAccomodations,
    getPendingAccomodations,
    getAccommodationDetail,
    accommodationStatusChange,
    accommodationRequestVerification
} from '../accommodationCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all accommodations function
export function* fetchingAllAccommodation(action) {  
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all accommodations
        const result = yield getAllAccomodations(
            action.userId,
            action.column,
            action.dir,
            action.skip,
            action.limit,
            action.typeValue,
            action.search
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allAccommodationFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get hosted accommodation function
export function* fetchingHostedAccommodation(action) { 
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch hosted accommodations
        const result = yield getHostedAccomodations(
            action.adminId, 
            action.id,
            action.column,
            action.dir,
            action.skip,
            action.limit
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.hostedAccommodationFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get pending accommodation function
export function* fetchingPendingAccommodation(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch pending accommodations
        const result = yield getPendingAccomodations(
            action.userId,
            action.column,
            action.dir,
            action.skip,
            action.limit,
            action.typeValue,
            action.search
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.pendingAccommodationFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get accommodation detail function
export function* fetchingAccommodationDetail(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch accommodation detail
        const result = yield getAccommodationDetail(action.adminId, action.accommodationId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.accommodationDetailFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Change accommodation status function
export function* changingAccommodationStatus(action) {    
    console.log('skip', action.skip);
    console.log('limit', action.limit);
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change accommodation status
        const result = yield accommodationStatusChange(action.adminId, action.accommodationId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        if (action.condition) {
            if (action.condition.hostedAccommodations) {
                yield put(actions.fetchHostedAccommodation(
                    action.adminId,
                    action.id,
                    action.column,
                    action.dir,
                    action.skip,
                    action.limit
                ));

            } else {
                yield put(bookingActions.fetchUserBooking(
                    action.adminId, 
                    action.id, 
                    action.skip, 
                    action.limit
                ));
            }

        } else {
            // Store dashboard data into store
            yield put(actions.fetchAllAccommodation(
                action.adminId,
                action.column,
                action.dir,
                action.skip,
                action.limit,
                action.typeValue
            ));
        }        

        // Notification
        notify('success', 'Accommodation status updated successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Accommodation request verfication function
export function* verifingAccommodationReq(action) {    
    try {
        console.log('action', action);
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change accommodation status
        const result = yield accommodationRequestVerification(
            action.adminId, 
            action.accommodationId,
            action.values
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Fetch all accommodation request
        yield put(actions.fetchPendingAccommodation(
            action.adminId, 
            action.column, 
            action.dir, 
            action.skip, 
            action.limit, 
            action.type
        ));

        // Notification
        notify('success', 'Accommodation updated successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};