import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";
import { Formik, Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import { Switch } from '@material-ui/core';
import moment from 'moment';
import SVG from "react-inlinesvg";
import {
  Card,
  Col,
  Row,
  Tabs,
  Tab,
  Button,
  Modal,
  Form,
  Table,
  useAccordionToggle,
  Accordion
} from "react-bootstrap";
// eslint-disable-next-line no-restricted-imports
import AccordionContext from "react-bootstrap/AccordionContext";

import { notify } from '../toaster';
import ItemDropdown from "./ItemDropdown";
import {toAbsoluteUrl} from "../../_helpers";
import * as masterAction from '../../../app/modules/MasterManagement';
import { 
  categoryInitValues, 
  categorySchema, 
  amenityInitValues, 
  amenitySchema,
  categoryAndAmenityInitValue,
  categoryAndAmenitySchema
} from './MasterSchema';
import { 
  addAmenity, 
  addCategory, 
  updateAmenity, 
  createAmenity,
  removeAmenity,
  updateCategory,
  deleteAmenity
} from '../../../app/modules/MasterManagement/masterCrud';

const statusOptions = [
  { value: "WORKPLACE", label: "Workplace" },
  { value: "HOME_OFFICE", label: "Home Office" },
];

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      className={`${isCurrentEventKey ? 'active btn btn-link' : 'btn btn-link'}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const MasterManagement = (props) => {
  const modifiCategoryObj = Object.assign({}, categoryInitValues);
  const modifiAmenityObj = Object.assign({}, amenityInitValues);
  const modifiCategoryAndAmenityObj = Object.assign({}, categoryAndAmenityInitValue);

  // State
  const [statusOption, setStatusOption] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('Categories');
  const [amenityEditMode, setAmenityEditMode] = useState(false);
  const [categoryAndAmenityEditMode, setCategoryAndAmenityEditMode] = useState(false);
  const [categoryEditMode, setCategoryEditMode] = useState(false);
  const [showCategoryAndAmenityDeleteModal, setShowCategoryAndAmenityDeleteModal] = useState({
    show: false,
    id: null,
    title: null
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    amenity: null,
    docId: ''
  });
  const [showCategoryAndAmenityModal, setShowCategoryAndAmenityModal] = useState({
    show: false,
    id: '',
    title: '',
    amenities: [{ name: '' }]
  });
  const [showAmenityModal, setAmenityModalShow] = useState({
    show: false,
    name: '',
    id: '',
    amenities: null
  });
  const [showCategoryModal, setCategoryModalShow] = useState({
    show: false,
    name: '',
    type: '',
    id: ''
  });

  // On category and amenity click
  const onCategoryAndAmenityClick = (title, amenities, id) => {

    // Set show category and amenity state
    setShowCategoryAndAmenityModal({
      show: true,
      id,
      title,
      amenities
    });
  };

  // Hide category and amenity modal
  const hideCategoryAndAmenityModal = () => {

    // Set show category and amenity state
    setShowCategoryAndAmenityModal({
      show: false,
      id: '',
      title: '',
      amenities: null
    });

    if (categoryAndAmenityEditMode) {
      // Set category and amenity edit mode to false
      setCategoryAndAmenityEditMode(false);
    }
  };

  // On category and amenity view button handler
  const onCategoryAndAmenityViewButtonHandler = (e, value) => {

    // Set category and amenity edit mode to true
    setCategoryAndAmenityEditMode(true);

    // Show category and amenity modal
    onCategoryAndAmenityClick(value.title, value.amenities, value._id);
  };

  //category modal
  const handleCategoryModalClose = () => {
    // Set category modal to false
    setCategoryModalShow({
      show: false,
      name: '',
      type: '',
      id: ''
    });

    // Set category edit mode to false
    setCategoryEditMode(false);
  };

  const handleCategoryModalShow = (name, type, id) => {
    // Set category modal show to true
    setCategoryModalShow({
      show: true,
      name,
      type,
      id
    });
  };

  //Amenity modal
  const handleAmenityModalClose = () => {
    // Set amenity modal to false
    setAmenityModalShow({
      show: false,
      name: '',
      id: '',
      amenities: null
    });

    // Set amenity edit mode to false
    setAmenityEditMode(false);
  };

  const handleAmenityModalShow = (name, id, amenities) => {
    // Set category modal show to true
    setAmenityModalShow({
      show: true,
      name,
      id,
      amenities
    });
  };

  // On status change handler
  const onStatusChangeHandler = (e) => {

    // Set state
    setStatusOption(e);
  };

  // On category view button handler
  const onCategoryViewButtonHandler = (e, category) => {

    // Set category edit mode to true
    setCategoryEditMode(true);

    // Show category modal
    handleCategoryModalShow(category.name, category.type, category._id);
  };

  // On amenity view button handler
  const onAmenityViewHandler = (amenity, docId, amenities) => {

    // Set amenity edit modal to true
    setAmenityEditMode(true);

    // Show amenity modal
    handleAmenityModalShow(amenity, docId, amenities);
  };

  // On amenity delete button handler
  const onAmenityDeleteHandler = (amenity, docId) => {

    // Set delete modal state to true
    setShowDeleteModal({
      show: true,
      amenity,
      docId
    });
  };

  // On Detele button click
  const onDeleteButtonClick = async () => {
    try {
      const amenityId = showDeleteModal.amenity._id;
      const docId = showDeleteModal.docId

      // Add template function
      const { data } = await removeAmenity(props.user._id, docId, amenityId);
  
      // If response error found throw error
      if (data.responseCode !== 200) {
        throw new Error(data.responseMessage);
      }

      // Init master store
      props.masterInit();

      // Call get categories function
      props.getAllAmenities(props.user._id);

      // Notification
      notify('success', 'Amenity removed successfully');

      // Hide delete modal
      hideDeleteModal();

    } catch (err) {
      console.log(err);

      // Notification
      notify('error', err.message);
    }
  };

  // Hide delete modal
  const hideDeleteModal = () => {

    // Set delete modal state to false
    setShowDeleteModal({
      show: false,
      amenity: null,
      docId: ''
    });
  };

  // On category and amenity delete button handler
  const onCategoryAndAmenityDeleteButtonHandler = (e, category) => {

    // Set category and amenity delete modal state to true
    setShowCategoryAndAmenityDeleteModal({
      show: true,
      id: category._id,
      title: category.title
    });
  };

  // On Detele button click
  const onCategoryAndAmenityDeleteButtonClick = async () => {
    try {
      const id = showCategoryAndAmenityDeleteModal.id;

      // Add template function
      const { data } = await deleteAmenity(props.user._id, id);
  
      // If response error found throw error
      if (data.responseCode !== 200) {
        throw new Error(data.responseMessage);
      }

      // Init master store
      props.masterInit();

      // Call get categories function
      props.getAllAmenities(props.user._id);

      // Notification
      notify('success', 'Category and Amenity deleted successfully');

      // Hide delete modal
      hideCategoryAndAmenityDeleteModal();

    } catch (err) {
      console.log(err);

      // Notification
      notify('error', err.message);
    }
  };

  // Hide delete modal
  const hideCategoryAndAmenityDeleteModal = () => {

    // Set category and amenity delete modal state to true
    setShowCategoryAndAmenityDeleteModal({
      show: false,
      id: null,
      title: null
    });
  };

  // On category submit handler
  const onCategorySubmitHandler = async (values, resetForm, setSubmitting) => {
    try {
      if (categoryEditMode) {
        // Add template function
        const { data } = await updateCategory(props.user._id, showCategoryModal.id, values);
  
        // If response error found throw error
        if (data.responseCode !== 200) {
          throw new Error(data.responseMessage);
        }

        // Notification
        notify('success', 'Category updated successfully');

      } else {
        // // Submit add category data
        // props.addCategory(props.user._id, values);

        // Add template function
        const { data } = await addCategory(props.user._id, values);
  
        // If response error found throw error
        if (data.responseCode !== 200) {
          throw new Error(data.responseMessage);
        }

        // Notification
        notify('success', 'Category added successfully');
      }

      // Call get categories function
      props.getAllCategories(props.user._id);

      setTimeout(() => {  
        // Call category modal close function
        handleCategoryModalClose();
        
        // Reset form to initial value
        resetForm(modifiCategoryObj);

        // Set submitting form to false
        setSubmitting(false);  
      }, 1500);

    } catch (err) {
      console.log(err);

      // Notification
      notify('error', err.message);
    }
  };

  // On amenity submit handler
  const onAmenitySubmitHandler = async (values, resetForm, setSubmitting) => {
    try {
      if (amenityEditMode) {
        const amenities = [];

        showAmenityModal.amenities.forEach(el => {
          if (el._id === values.id) {
            amenities.push({ _id: values.id, name: values.name });

          } else {
            amenities.push(el);
          }
        });

        // Add template function
        const { data } = await updateAmenity(props.user._id, showAmenityModal.id, { amenities });
  
        // If response error found throw error
        if (data.responseCode !== 200) {
          throw new Error(data.responseMessage);
        }

        // Notification
        notify('success', 'Amenity updated successfully');

      } else {
        const amenities = [{ name: values.name }];

        // Add template function
        const { data } = await addAmenity(props.user._id, showAmenityModal.id, amenities);
  
        // If response error found throw error
        if (data.responseCode !== 200) {
          throw new Error(data.responseMessage);
        }

        // Notification
        notify('success', 'Amenity added successfully');
      }

      // Call get categories function
      props.getAllAmenities(props.user._id);

      setTimeout(() => {  
        // Call amenity modal close function
        handleAmenityModalClose();
        
        // Reset form to initial value
        resetForm(modifiAmenityObj);

        // Set submitting form to false
        setSubmitting(false);  
      }, 1500);

    } catch (err) {
      console.log(err);

      // Notification
      notify('error', err.message);
    }
  };

  // On category and amenity submit handler
  const onCategoryAndAmenitySubmitHandler = async (values, resetForm, setSubmitting) => {
    try {
      if (categoryAndAmenityEditMode) {
        // Add category and amenity function
        const { data } = await updateAmenity(props.user._id, showCategoryAndAmenityModal.id, values);
  
        // If response error found throw error
        if (data.responseCode !== 200) {
          throw new Error(data.responseMessage);
        }

        // Notification
        notify('success', 'Category and Amenity updated successfully');

      } else {
        // Add category and amenities function
        const { data } = await createAmenity(props.user._id, values);
  
        // If response error found throw error
        if (data.responseCode !== 200) {
          throw new Error(data.responseMessage);
        }

        // Notification
        notify('success', 'Category and Amenity added successfully');
      }

      // Call get categories function
      props.getAllAmenities(props.user._id);

      setTimeout(() => {  
        // Call category and amenity modal close function
        hideCategoryAndAmenityModal();
        
        // Reset form to initial value
        resetForm(modifiCategoryAndAmenityObj);

        // Set submitting form to false
        setSubmitting(false);  
      }, 1500);

    } catch (err) {
      console.log(err);

      // Notification
      notify('error', err.message);
    }
  };

  useEffect(() => {
    if (activeTabKey === 'Categories') {
      // Call get categories function
      props.getAllCategories(props.user._id);

    } else {
      // Call get amenities function
      props.getAllAmenities(props.user._id);
    }
  }, [activeTabKey]);

  useEffect(() => {
    // Remove data from store when component destroy
    return () => {

      // Call master init function
      props.masterInit();
    };
  }, []);

  if (showCategoryModal.show && showCategoryModal.name) {
    modifiCategoryObj.type = showCategoryModal.type;
    modifiCategoryObj.name = showCategoryModal.name;
  }

  if (showAmenityModal.show && showAmenityModal.name) {
    modifiAmenityObj.name = showAmenityModal.name.name;
    modifiAmenityObj.id = showAmenityModal.name._id;
  }

  if (showCategoryAndAmenityModal.show && showCategoryAndAmenityModal.title) {
    modifiCategoryAndAmenityObj.title = showCategoryAndAmenityModal.title;
    modifiCategoryAndAmenityObj.amenities = showCategoryAndAmenityModal.amenities;
  }

  return (
    <>
      {/* Category and amenity delete Modal */}
      <Modal
        show={ showCategoryAndAmenityDeleteModal.show }
        onHide={ hideCategoryAndAmenityDeleteModal }
        size="md"
        className="pho_delete_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 pho_18_600 text-danger text-center">
            Delete Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="pho_18_500 text-center">
            Are you sure want to delete ({ showCategoryAndAmenityDeleteModal.title ? showCategoryAndAmenityDeleteModal.title : '' })?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button type="button" variant="outline-success" onClick={ hideCategoryAndAmenityDeleteModal }>
            Cancel
          </Button>
          {/* <button className="btn btn-outline-primary btn-sm mr-5" onClick={ hideDeleteModal }>Cancel</button> */}
          <button className="btn btn-outline-danger btn-sm" onClick={ onCategoryAndAmenityDeleteButtonClick }>Delete</button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={ showDeleteModal.show }
        onHide={ hideDeleteModal }
        size="md"
        className="pho_delete_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 pho_18_600 text-danger text-center">
            Delete Alert
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="pho_18_500 text-center">
            Are you sure want to delete ({ showDeleteModal.amenity ? showDeleteModal.amenity.name : '' })?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button type="button" variant="outline-success" onClick={ hideDeleteModal }>
            Cancel
          </Button>
          {/* <button className="btn btn-outline-primary btn-sm mr-5" onClick={ hideDeleteModal }>Cancel</button> */}
          <button className="btn btn-outline-danger btn-sm" onClick={ onDeleteButtonClick }>Delete</button>
        </Modal.Footer>
      </Modal>

      {/* Category modal */}
      <Modal
        show={ showCategoryModal.show }
        onHide={ handleCategoryModalClose }
        centered
      >
        <Modal.Header className="border-0 pb-0" closeButton>
          <Modal.Title className="pho_18_600">
            { categoryEditMode ? 'Update Category' : 'New Category' }
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={ modifiCategoryObj }
          validationSchema={ categorySchema }
          onSubmit={ (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            // Call category submit handler function
            onCategorySubmitHandler(values, resetForm, setSubmitting);
          } }
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleSubmit,
            setFieldValue
          }) => (
            <Form onSubmit={ handleSubmit }>
              <Modal.Body className="pb-0">
                <div className="form-group">
                  <label className="pho_14_500">Title</label>
                  <Field 
                    type="text"
                    name="name"
                    className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 " 
                  />
                  {touched.name && errors.name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.name}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="pho_14_500 text-left">
                    Select Type
                  </label>
                  <Select
                    name="type"
                    value={ { value: values.type, label: values.type === 'HOME_OFFICE' ? 'HOME OFFICE' : values.type } }
                    placeholder="Status"
                    className="form-control p-0 border-0 bg-gray-light h-auto pho_16_500"
                    classNamePrefix="phoSelect"
                    options={ statusOptions }
                    onChange={ (e) => {
                      // Set field value
                      setFieldValue('type', e.value);
                    } }
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#DCF4E4",
                        primary75: "#DCF4E4",
                        primary50: "#DCF4E4",
                        primary: "#50C878",
                      },
                    })}
                  />
                  {touched.type && errors.type ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.type}</div>
                    </div>
                  ) : null}
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0">
                <Button
                  type="submit"
                  variant="success"
                  className="px-5 py-3"
                  disabled={ isSubmitting }
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Amenity modal */}
      <Modal 
        centered
        size="sm"
        show={ showAmenityModal.show } 
        onHide={ handleAmenityModalClose } 
      >
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">
            { amenityEditMode ? 'Update Amenity' : 'Add Amenity' }
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={ modifiAmenityObj }
          validationSchema={ amenitySchema }
          onSubmit={ (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            // Call category submit handler function
            onAmenitySubmitHandler(values, resetForm, setSubmitting);
          } }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            handleSubmit
          }) => (
            <Form onSubmit={ handleSubmit }>
              <Modal.Body>
                <Field
                  name="name"
                  className="form-control"
                  onChange={ handleChange }
                  value={ values.name }                  
                />
                {errors.name && touched.name ? (
                  <div className="pho_14_regular text-danger">
                    { errors.name }
                  </div>
                ) : null}
              </Modal.Body>
              <Modal.Footer className="border-0 px-4">
                <Button type="button" variant="outline-success" onClick={ handleAmenityModalClose }>
                  Close
                </Button>
                <Button
                  type="submit"
                  disabled={ isSubmitting }
                  variant="success"
                >
                  { amenityEditMode ? 'Update' : 'Add' }
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>        
      </Modal>

      {/* Amenity add modal */}
      <Modal 
        centered
        onHide={ hideCategoryAndAmenityModal } 
        show={ showCategoryAndAmenityModal.show } 
      >
        <Modal.Header className="border-0 pb-0" closeButton>
          <Modal.Title className="pho_18_bold">
            { categoryAndAmenityEditMode ? 'Update Category and Amenities' : 'Add Category and Amenities' }
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={ modifiCategoryAndAmenityObj }
          validationSchema={ categoryAndAmenitySchema }
          onSubmit={ (values, { setSubmitting, resetForm }) => {
            console.log('values', values);
            // setSubmitting(true);

            // Call category submit handler function
            onCategoryAndAmenitySubmitHandler(values, resetForm, setSubmitting);
          } }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            handleSubmit
          }) => (
            <Form onSubmit={ handleSubmit }>
              <Modal.Body>
                <Form.Label className="pho_14_500">Category Title</Form.Label>
                <Field 
                  type="text"
                  name="title"
                  placeholder="Category"
                  onChange={ handleChange }
                  className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 "
                />
                {errors.title && touched.title ? (
                  <div className="pho_14_regular text-danger">
                    { errors.title }
                  </div>
                ) : null}
              </Modal.Body>
              <Modal.Body className="border-top">
                <Form.Label className="pho_14_500 text-left">Add Amenities</Form.Label>
                <FieldArray name="amenities">
                  {({ insert, remove, push }) => (
                    <>
                      {
                        values.amenities.length > 0 &&
                          values.amenities.map((amenity, index) => (
                            <div key={ index } className="pb-3">
                              <div className="d-flex">
                              <Field
                                type="text"
                                placeholder="Amenity"
                                // value={ `amenities.${index}.name` }
                                name={ `amenities.${index}.name`}
                                className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 bg-dark"
                              />
                                <Button 
                                  className="p-0 ml-2"
                                  variant="outline-danger" 
                                  onClick={ (e) => {
                                    if (values.amenities.length > 1) {
                                      // Remove line from amenities array
                                      remove(index);
                                    }
                                  } }
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g id="Group_282" data-name="Group 282" transform="translate(-1697 -1131)"><g id="Group_281" data-name="Group 281" transform="translate(1 1)"><rect id="Rectangle_1002" data-name="Rectangle 1002" width="2" height="16" rx="1" transform="translate(1708 1151) rotate(-90)" fill="#F64E60"></rect></g></g></svg>
                                </Button>
                              </div>
                              { 
                                errors.amenities && errors.amenities.length > 0 && 
                                touched.amenities && touched.amenities.length > 0 && 
                                errors.amenities[index] && errors.amenities[index].name && 
                                touched.amenities[index] && touched.amenities[index].name ? (
                                  <div className="pho_14_regular text-danger">
                                    { errors.amenities[index].name }
                                  </div>
                                ) : null
                              }
                            </div>
                          ))
                      }
                      <Button 
                        variant="outline-success" 
                        className="px-4 py-2 mt-4"
                        onClick={ () => {
                          let count = 0;

                          values.amenities.forEach((amenity, index) => {
                            if (amenity.name === '') {
                              count++;
                            }                            
                          });

                          if (count > 0) {
                            return;
                          }

                          // Push new amenity 
                          push({ name: "" });
                        } }
                      >
                        ADD MORE
                      </Button>
                    </>
                  )}  
                </FieldArray>
              </Modal.Body>
              <Modal.Footer className="border-0">
                <Button 
                  type="submit"
                  disabled={ isSubmitting }
                  variant="success" 
                  className="px-5 py-3"
                  // onClick={ handleCategoryModalClose } 
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Row className="justify-content-center">
        <Col md={12} className="userDetailsPage phomasterManagements">
          <Tabs           
            id="uncontrolled-tab-example"
            defaultActiveKey={ activeTabKey } 
            onSelect={ (key) => {

              // Set active tab key to state
              setActiveTabKey(key);
            } }
          >
            <Tab eventKey="Categories" title="Categories">
              <Table hover responsive className="phoTable">
                <thead>
                  <tr>
                    <th width="120px">Date</th>
                    <th width="150px">Category Name</th>
                    <th>Type</th>
                    <th>Active/Deactive</th>
                    <th width="130px" className="text-right">
                      <Button
                        variant="link"
                        onClick={ (e) => handleCategoryModalShow() }
                        className="text-success pho_16_400"
                      >
                        Add New
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !props.loading && props.categories && props.categories.length > 0 ?
                      props.categories.map((category, index) => (
                        <tr key={ index }>
                          <td>{ moment(category.createdAt).format('L') }</td>
                          <td>{ category.name }</td>
                          <td>{ category.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' }</td>
                          <td className="text-center" width="135px">
                            <Switch
                              color="primary"
                              value={ category.status === 'ACTIVE' ? true : false }
                              checked={ category.status === 'ACTIVE' ? true : false }
                              onChange={ (e) => {

                                // Delete category function
                                props.deleteCategory(props.user._id, category._id);
                              } }
                            />
                          </td>
                          <td className="text-right">
                            <ItemDropdown 
                              item="" 
                              doc={ category }
                              view={ onCategoryViewButtonHandler }
                            />
                          </td>
                        </tr>
                      ))
                    : 
                      props.loading ?
                        <tr>
                          <td colSpan="4" className="text-center">Loading...</td>
                        </tr>
                      : 
                        <tr>
                          <td colSpan="4" className="text-center">No Data Available</td>
                        </tr>
                  }              
                </tbody>
              </Table>
            </Tab>

            <Tab eventKey="Amenities" title="Amenities">              
              {/* <Table hover responsive className="phoTable">
                <thead>
                  <tr>
                    <th width="120px">Date</th>
                    <th width="140px">Name</th>
                    <th>Assigned Category</th>
                    <th width="110px" className="text-right">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>06/03/2020</td>
                    <td>Video Conference</td>
                    <td>General, Open Station, Private Office</td>
                    <td className="text-right">
                      <ItemDropdown />
                    </td>
                  </tr>
                  <tr>
                    <td>06/03/2020</td>
                    <td>Coffe/Tea</td>
                    <td>General, Meeting Room, Team Office</td>
                    <td className="text-right">
                      <ItemDropdown />
                    </td>
                  </tr>
                </tbody>
              </Table> */}
              <Button 
                variant="link" 
                onClick={ (e) => onCategoryAndAmenityClick() }
                className="phoTopRight text-success pho_16_400"
              >
                Add New
              </Button>
                
              <Accordion>
                {/* <Button
                  variant="link"
                  className="text-success pho_16_400"
                  onClick={ (e) => onCategoryAndAmenityClick() }
                >
                  Add New
                </Button> */}

                {
                  !props.loading && props.amenities && props.amenities.length > 0 ?
                    props.amenities.map((amenity, index) => (
                      <Card key={ index }>
                        <Card.Header>
                          <ContextAwareToggle as={ Button } variant="link" eventKey={ index }>
                              { amenity.title }
                          </ContextAwareToggle>
                          <div className="d-flex align-items-center">
                            <Switch
                              color="primary"
                              className="mr-2"
                              value={ amenity.status === 'ACTIVE' ? true : false }
                              checked={ amenity.status === 'ACTIVE' ? true : false }
                              onChange={ (e) => {

                                // Delete category function
                                props.deleteAmenity(props.user._id, amenity._id);
                              } }
                            />
                            <ItemDropdown 
                              item="" 
                              doc={ amenity }
                              view={ onCategoryAndAmenityViewButtonHandler }
                              // delete={ onCategoryAndAmenityDeleteButtonHandler }
                            />
                          </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey={ index }>
                          <ul className="list-unstyled m-0">
                            {
                              amenity.amenities && amenity.amenities.length > 0 &&
                                amenity.amenities.map((el, i) => (
                                  <li key={ i }>
                                    { el.name } 
                                    <ItemDropdown 
                                      item=""
                                      doc={ el }
                                      view={ (e, amenityEl) => onAmenityViewHandler(amenityEl, amenity._id, amenity.amenities) } 
                                      delete={ (e, amenityEl) =>  onAmenityDeleteHandler(amenityEl, amenity._id) }
                                    />
                                  </li>
                                ))
                            }

                            <li className="phobtnLi">
                              <Button 
                                variant="link" 
                                className="p-0"
                                onClick={(e) => handleAmenityModalShow(undefined, amenity._id)} 
                              >
                                <SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_plus.svg")}/>
                              </Button>
                            </li>
                          </ul>
                        </Accordion.Collapse>
                    </Card>
                    ))
                  : 
                    props.loading ?
                      <div className="text-center py-3">Loading...</div>
                    : 
                      <div className="text-center py-3">No Data Available</div>
                }
              </Accordion>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    error: state.master.error,
    loading: state.master.loading,
    amenities: state.master.amenities,
    categories: state.master.categories    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    masterInit: () => dispatch(masterAction.masterInit()),
    getAllAmenities: (adminId) => dispatch(masterAction.fetchAllAmenities(adminId)),
    getAllCategories: (adminId) => dispatch(masterAction.fetchAllCategories(adminId)),
    addAmenity: (adminId, values) => dispatch(masterAction.addAmenity(adminId, values)),
    addCategory: (adminId, values) => dispatch(masterAction.addCategory(adminId, values)),
    deleteCategory: (adminId, categoryId) => dispatch(masterAction.deleteCategory(adminId, categoryId)),
    deleteAmenity: (adminId, id) => dispatch(masterAction.deleteAmenity(adminId, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterManagement);
                  
{/* <tr>
<td>06/03/2020</td>
<td>Private Office</td>
<td>Home Office</td>
<td className="text-right">
  <ItemDropdown />
</td>
</tr>    */}

// {/* <Card>
//   <Card.Header>
//   <ContextAwareToggle as={Button} variant="link" eventKey="2">
//   Open Station
//   </ContextAwareToggle>
//   <ItemDropdown item="" />
//   </Card.Header>
//   <Accordion.Collapse eventKey="2">
//   <ul className="list-unstyled m-0">
//       <li>Projector <ItemDropdown item="" /></li>
//       <li>TV/Monitor <ItemDropdown item="" /></li>
//       <li>Phone <ItemDropdown item="" /></li>
//       <li>Whiteboard <ItemDropdown item="" /></li>
//       <li>Video Conference <ItemDropdown item="" /></li>
//       <li className="phobtnLi"><Button onClick={(e) => handleAmenityModalShow(e,1)} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_plus.svg")}/></Button></li>
//   </ul>
//   </Accordion.Collapse>
// </Card>
// <Card>
//   <Card.Header>
//   <ContextAwareToggle as={Button} variant="link" eventKey="3">
//   Dedicated Desk
//   </ContextAwareToggle>
//   <ItemDropdown item="" />
//   </Card.Header>
//   <Accordion.Collapse eventKey="3">
//   <ul className="list-unstyled m-0">
//       <li>Projector <ItemDropdown item="" /></li>
//       <li>TV/Monitor <ItemDropdown item="" /></li>
//       <li>Phone <ItemDropdown item="" /></li>
//       <li>Whiteboard <ItemDropdown item="" /></li>
//       <li>Video Conference <ItemDropdown item="" /></li>
//       <li className="phobtnLi"><Button onClick={(e) => handleAmenityModalShow(e,1)} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_plus.svg")}/></Button></li>
//   </ul>
//   </Accordion.Collapse>
// </Card>
// <Card>
//   <Card.Header>
//   <ContextAwareToggle as={Button} variant="link" eventKey="1">
//   Private Office
//   </ContextAwareToggle>
//   <ItemDropdown item="" />
//   </Card.Header>
//   <Accordion.Collapse eventKey="1">
//   <ul className="list-unstyled m-0">
//       <li>Projector <ItemDropdown item="" /></li>
//       <li>TV/Monitor <ItemDropdown item="" /></li>
//       <li>Phone <ItemDropdown item="" /></li>
//       <li>Whiteboard <ItemDropdown item="" /></li>
//       <li>Video Conference <ItemDropdown item="" /></li>
//       <li className="phobtnLi"><Button onClick={(e) => handleAmenityModalShow(e,1)} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_plus.svg")}/></Button></li>
//   </ul>
//   </Accordion.Collapse>
// </Card>
// <Card>
//   <Card.Header>
//   <ContextAwareToggle as={Button} variant="link" eventKey="1">
//   Team Office
//   </ContextAwareToggle>
//   <ItemDropdown item="" />
//   </Card.Header>
//   <Accordion.Collapse eventKey="1">
//   <ul className="list-unstyled m-0">
//       <li>Projector <ItemDropdown item="" /></li>
//       <li>TV/Monitor <ItemDropdown item="" /></li>
//       <li>Phone <ItemDropdown item="" /></li>
//       <li>Whiteboard <ItemDropdown item="" /></li>
//       <li>Video Conference <ItemDropdown item="" /></li>
//       <li className="phobtnLi"><Button onClick={(e) => handleAmenityModalShow(e,1)} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_plus.svg")}/></Button></li>
//   </ul>
//   </Accordion.Collapse>
// </Card>
// <Card>
//   <Card.Header>
//   <ContextAwareToggle as={Button} variant="link" eventKey="1">
//   Meeting Room
//   </ContextAwareToggle>
//   <ItemDropdown item="" />
//   </Card.Header>
//   <Accordion.Collapse eventKey="1">
//   <ul className="list-unstyled m-0">
//       <li>Projector <ItemDropdown item="" /></li>
//       <li>TV/Monitor <ItemDropdown item="" /></li>
//       <li>Phone <ItemDropdown item="" /></li>
//       <li>Whiteboard <ItemDropdown item="" /></li>
//       <li>Video Conference <ItemDropdown item="" /></li>
//       <li className="phobtnLi"><Button onClick={(e) => handleAmenityModalShow(e,1)} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_plus.svg")}/></Button></li>
//   </ul>
//   </Accordion.Collapse>
// </Card>
// <Card>
//   <Card.Header>
//   <ContextAwareToggle as={Button} variant="link" eventKey="1">
//   Event Space
//   </ContextAwareToggle>
//   <ItemDropdown item="" />
//   </Card.Header>
//   <Accordion.Collapse eventKey="1">
//   <ul className="list-unstyled m-0">
//       <li>Projector <ItemDropdown item="" /></li>
//       <li>TV/Monitor <ItemDropdown item="" /></li>
//       <li>Phone <ItemDropdown item="" /></li>
//       <li>Whiteboard <ItemDropdown item="" /></li>
//       <li>Video Conference <ItemDropdown item="" /></li>
//       <li className="phobtnLi"><Button onClick={(e) => handleAmenityModalShow(e,1)} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_plus.svg")}/></Button></li>
//   </ul>
//   </Accordion.Collapse>
// </Card> */}


// {/* Amenity modal */}
// <Modal show={showAmenityModal} onHide={handleAmenityModalClose} centered>
//   <Modal.Header className="border-0 pb-0" closeButton>
//     <Modal.Title className="pho_18_600">New Amenity</Modal.Title>
//   </Modal.Header>
//   <Form>
//     <Modal.Body className="pb-0">
//       <Form.Group>
//         <Form.Label className="pho_14_500 text-left">
//           Enter Amenities
//         </Form.Label>
//         <Form.Control className="border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 " />
//       </Form.Group>
//       <div className="pho_16_600 mb-3">Assign Categories</div>
//       <Row>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">General</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Open Station</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Dedicated Desk</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Private Office</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Team Office</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Meeting Room</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Event Space</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Entire Place</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Private Room</span>
//           </div>
//         </Col>
//         <Col md={6}>
//           <div className="pho_checkbox w-auto iskl_border d-flex align-items-center mx-0 mb-3">
//             <input type="checkbox" className={`mr-2 checkbox`} />
//             <label></label>
//             <span className="pho_14_500">Shared Room</span>
//           </div>
//         </Col>
//       </Row>
//     </Modal.Body>
//     <Modal.Footer className="border-0">
//       <Button
//         variant="success"
//         onClick={handleAmenityModalClose}
//         className="px-5 py-3"
//       >
//         Submit
//       </Button>
//     </Modal.Footer>
//   </Form>
// </Modal>
