import * as Yup from "yup";

export const initialLinkSchema = Yup.object().shape({
  links: [],
});

export const linkAddSchema = {
  type: "",
  URL: "",
};

export const linkAddValidationSchema = Yup.object().shape({
  type: Yup.string().required("Please enter Name/Type"),
  URL: Yup.string().required("Please enter Link"),
});
