import * as actionTypes from "../actions/actionTypes";

// Initial state
const initialState = {
  error: null,
  links: null,
  loading: null,
};

// Reducer
export const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LINK_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.LINK_FAILD:
      return {
        ...state,
        error: action.error,
        loading: null,
      };
    case actionTypes.LINK_SUCCESS:
      return {
        ...state,
        error: null,
        loading: null,
      };
    case actionTypes.LINK_ALL_FETCHED:
      return {
        ...state,
        links: action.links,
      };
    case actionTypes.LINK_INIT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
