import axios from "axios";

// Accommodation related URL
export const ACCOMMODATION_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllAccomodations`;
export const ACCOMMODATION_HOSTED_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getHostedAccomodations`;
export const ACCOMMODATION_PENDING_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getPendingAccomodations`;
export const ACCOMMODATION_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAccomodationDetails`;
export const ACCOMMODATION_DELETED_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteAccomodation`;
export const ACCOMMODATION_VERIFICATION_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateAccomodationVerification`;

// All accommodation get function
export async function getAllAccomodations(userId, column, dir, skip, limit, type, search) {
  return await axios.get(`${ACCOMMODATION_ALL_URL}/${userId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${type ? `&type=${type}` : '' }${search ? `&search=${search}` : '' }`);
};

// Hosted accommodation get function
export async function getHostedAccomodations(userId, landlordId, column, dir, skip, limit) {
  return await axios.get(`${ACCOMMODATION_HOSTED_URL}/${userId}/${landlordId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }`);
};

// Pending accommodation get function
export async function getPendingAccomodations(userId, column, dir, skip, limit, type, search) {
  return await axios.get(`${ACCOMMODATION_PENDING_URL}/${userId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${type ? `&type=${type}` : '' }${search ? `&search=${search}` : '' }`);
};

// Accommodation get detail function
export async function getAccommodationDetail(adminId, accommodationId) {
  return await axios.get(`${ACCOMMODATION_DETAIL_URL}/${adminId}/${accommodationId}`);
};

// Accommodation status change function
export async function accommodationStatusChange(adminId, accommodationId) {
  return await axios.delete(`${ACCOMMODATION_DELETED_URL}/${adminId}/${accommodationId}`);
};

// Accommodation verification function
export async function accommodationRequestVerification(adminId, accommodationId, values) {
  return await axios.put(`${ACCOMMODATION_VERIFICATION_URL}/${adminId}/${accommodationId}`, { ...values });
};