import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.USER_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.USER_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.USER_SUCCESS
    };
};

// All users fetch function
export const fetchAllUsers = (userId, column, dir, skip, limit, search) => {
    return {
        type: actionTypes.USERS_ALL_FETCH,
        userId,
        column,
        dir, 
        skip, 
        limit,
        search
    };
};

// All users fetched function
export const allUsersFetched = (users) => {
    return {
        type: actionTypes.USERS_ALL_FETCHED,
        users
    };
};

// Remove all user data function
export const removeAllUsersData = () => {
    return {
        type: actionTypes.USERS_ALL_DATA_REMOVE
    };
};

// Get user fetch function
export const fetchUser = (adminId, userId) => {
    return {
        type: actionTypes.USER_FETCH,
        adminId,
        userId
    };
};

// Get user fetched function
export const userFetched = (userDetail) => {
    return {
        type: actionTypes.USER_FETCHED,
        userDetail
    };
};

// Add user id function
export const addUserId = (userId) => {
    return {
        type: actionTypes.USER_ID_ADD,
        userId
    };
};

// Remove user id function
export const removeUserId = () => {
    return {
        type: actionTypes.USER_ID_REMOVE        
    };
};

// Remove user data function
export const removeUserData = () => {
    return {
        type: actionTypes.USER_DATA_REMOVE
    };
};

// Update user data function
export const updateUserData = (adminId, userId, updateData, isDashboard) => {
    return {
        type: actionTypes.USER_DATA_UPDATE,
        adminId, 
        userId,
        updateData,
        isDashboard
    };
};

// Delete user data function
export const deleteUserData = (adminId, userId) => {
    return {
        type: actionTypes.USER_DATA_DELETE,
        adminId, 
        userId
    };
};

// User status change function
export const userStatusChange = (adminId, userId, column, dir, skip, limit) => {
    return {
        type: actionTypes.USER_STATUS_CHANGE,
        adminId, 
        userId,
        column,
        dir,
        skip,
        limit
    };
};

// Add user function
export const addUser = (adminId, values) => {
    return {
        type: actionTypes.USER_ADD,
        adminId,
        values
    };
};

// Get user list function
export const getUserList = (adminId) => {
    return {
        type: actionTypes.USER_LIST_FETCH,
        adminId    
    };
};

// Get user list fetched function
export const userListFetched = (userList) => {
    return {
        type: actionTypes.USER_LIST_FETCHED,
        userList    
    };
};

// Skip update function
export const skipUpdate = (skip) => {
    return {
        type: actionTypes.USER_SKIP_UPDATE,
        skip
    };
};

// Skip init function
export const skipInit = () => {
    return {
        type: actionTypes.USER_SKIP_INIT
    };
};