// Action types
export const NOTIFICATION_START = 'NOTIFICATION_START';
export const NOTIFICATION_FAILD = 'NOTIFICATION_FAILD';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE';
export const NOTIFICATION_ALL_FETCH = 'NOTIFICATION_ALL_FETCH';
export const NOTIFICATION_ALL_FETCHED = 'NOTIFICATION_ALL_FETCHED';
export const NOTIFICATION_COUNT_FETCH = 'NOTIFICATION_COUNT_FETCH';
export const NOTIFICATION_COUNT_FETCHED = 'NOTIFICATION_COUNT_FETCHED';
export const NOTIFICATION_INIT = 'NOTIFICATION_INIT';
export const NOTIFICATION_SKIP_INIT = 'NOTIFICATION_SKIP_INIT';
export const NOTIFICATION_SKIP_UPDATE = 'NOTIFICATION_SKIP_UPDATE';

// export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
// export const NOTIFICATION_DETAIL_FETCH = 'NOTIFICATION_DETAIL_FETCH';
// export const NOTIFICATION_DETAIL_FETCHED = 'NOTIFICATION_DETAIL_FETCHED';
// export const NOTIFICATION_STATUS_CHANGE = 'NOTIFICATION_STATUS_CHANGE';
// export const NOTIFICATION_ID_ADD = 'NOTIFICATION_ID_ADD';