import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import { notify } from '../toaster';
import { useSubheader } from '../../layout';
import PhoneCode from '../../_assets/json/phone_code.json';
import { initialValues, adminSchema } from './ProfileUtils';
import { actions } from '../../../app/modules/Auth/_redux/authRedux';
import { uploadImage } from '../../../app/modules/ImageUpload/imageUpload';

import 'react-toastify/dist/ReactToastify.min.css';

const phoneCodeOption = PhoneCode;

// let imageUrl;
const Profile = (props) => {
    const inputFile = useRef(null);
    const subHeader = useSubheader();
    const history = useHistory();
    
    const modifiedInitialObj = Object.create(initialValues);
    
    // State
    const [profileImage, setProfileImage] = useState(null);
    const [serviceModal, setServiceModal] = useState(false);
    const [code, setCode] = useState({
        value: "+91",
        label: "(+91) India",
    });

    // // Image change handler
    // const handleImageChange = (e) => {
    //     if (e.target.files.length) {
    //         setProfileImage(URL.createObjectURL(e.target.files[0]));
    //     }
    // };

    // Image change handler
    const handleImageChange = async (e, setFieldValue) => {
        const file = e.target.files[0];  

        if (!file) {          
            return;
        }

        const type = file.type.split('/');

        if (
            type[1] === 'png' ||
            type[1] === 'jpg' ||
            type[1] === 'jpeg' 
        ) {

            // Convert file into form data
            const formData = new FormData();
            formData.append('img', file);
    
            // Upload file to storage service
            return uploadImage(formData)
                .then(async result => {    

                    if (result.data.responseCode === 200) {
                        // Set field values
                        setFieldValue('profilePicture', result.data.responseData);
                    }

                    // if (oldImage && oldImage.length > 0) {
                    //     await deleteFile(oldImage);
                    // }
                })
                .catch(err => {                    
                    console.log(err);
                });            
        } else {
            document.getElementById('upload-button').value = '';

            // Notification
            notify('error', 'Please upload image');
        }
    };

    const onButtonClick = (e) => {
        // `current` points to the mounted file input element
       inputFile.current.click();
    };

    // Show service fee modal function
    const showServiceFeeModal = () => {

        // Set service modal state
        setServiceModal(true);
    };

    // Hide service fee modal function
    const hideServiceFeeModal = () => {

        // Set service modal state
        setServiceModal(false);
    };

    // On phone code change function
    const onPhoneCodeChange = (code) => {

        // Set code state
        setCode(code);
    };

    // Update code state function
    const updateCodeState = () => {
        // If user found then split contect number
        if (props.user) {
            const fields = props.user.contactNumber.split('-');
            for (var i = 0; i < phoneCodeOption.length; i++) {
                if (phoneCodeOption[i].value === fields[0]) {

                    // Set code state
                    setCode(phoneCodeOption[i]);
                }
            }
        }
    };

    useEffect(() => {
        const title = 'Profile';

        // Set sub header title
        subHeader.setTitle(title);

        // Get service fee
        props.getServiceFee(props.user._id);
    }, []);

    useEffect(() => {
        // If user found then split contect number
        if (props.user) {
            // Call update code state function
            updateCodeState();
        }
    }, [props.user.contactNumber]);

    if (props.user) {
        const data = {
            ...props.initialValues,
            ...props.user            
        };

        const contactNumber = data.contactNumber.split('-');        

        modifiedInitialObj.emailId = data.emailId;
        modifiedInitialObj.lastName = data.lastName;
        modifiedInitialObj.firstName = data.firstName;
        modifiedInitialObj.contactNumber = contactNumber[1];
        // modifiedInitialObj.key = data.profileImage.key ? data.profileImage.key : '';
        modifiedInitialObj.profilePicture = data.profilePicture;
    }

    return (
        <>
            <Modal 
                centered
                size="sm"
                show={ serviceModal } 
                onHide={ hideServiceFeeModal } 
                className="pho_delete_modal" 
            >
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="pho_18_600">Update Service Fee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        enableReinitialize
                        initialValues={ { serviceFee: '' } }
                        validationSchema={ Yup.object().shape({
                            serviceFee: Yup.number()
                                .required('Please enter Service Fee')
                        }) }
                        onSubmit={(values, { setSubmitting, resetForm }) => {  
                            // Set submitting to true
                            setSubmitting(true);
                            
                            // Update service fee
                            props.updateServiceFee(props.user._id, values);
    
                            setTimeout(() => {
                                // Set submitting form to false
                                setSubmitting(false);

                                // Reset form to initial values
                                resetForm({ serviceFee: '' });
    
                                // Call reject close function to hide comment modal
                                hideServiceFeeModal();
                            }, 2000);
                        }}
                    >
                        {({
                            values, 
                            errors, 
                            touched, 
                            isSubmitting,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <Form onSubmit={ handleSubmit }>
                                <label className="pho_14_500">Service Fee</label>
                                <Field 
                                    type="number"
                                    name="serviceFee"
                                    value={ values.serviceFee }
                                    className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 " 
                                />
                                {touched.serviceFee && errors.serviceFee ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{errors.serviceFee}</div>
                                    </div>
                                ) : null}
                                <div className="text-right pt-5">
                                    <Button 
                                        type="submit"
                                        variant="success" 
                                        className="px-5 py-3" 
                                        disabled={ isSubmitting }
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <div>  
                <Formik
                    initialValues={ modifiedInitialObj }
                    validationSchema={ adminSchema }
                    onSubmit={(values, { setSubmitting, resetForm }) => {                        
                        // Set submitting to true
                        setSubmitting(true);

                        // Update contact number
                        const updatedContact = `${code.value}-${values.contactNumber}`;

                        // values.role = props.user.role;

                        // if (props.user.subRole) {
                        //     values.subRole = props.user.subRole;
                        // }
                        
                        // Send data to backend
                        props.updateUser(props.user._id, {
                            ...values,
                            contactNumber: updatedContact
                        });

                        setTimeout(() => {
                            // Set submitting form to false
                            setSubmitting(false);

                            // Go back to property management page                            
                            history.push('/dashboard');
                        }, 3000);
                    }}
                >
                    {({
                        values, 
                        errors, 
                        touched, 
                        isSubmitting,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <Form onSubmit={ handleSubmit }>
                            <div className="row">
                                <div className="col-md-12 col-lg-4 text-center">
                                    <div className="pho_profile_pic_bx">
                                        <div className="pho_pic_bx">
                                            {
                                                values.profilePicture ? (
                                                    <img 
                                                        src={ values.profilePicture } 
                                                        alt="Admin Pic" 
                                                        className="w-100 h-100" 
                                                    />
                                                ) : (
                                                    <div>
                                                        <div className="pho_opacity_3 pho_plus pt-18 pb-10">+</div>
                                                        <label className="pho_opacity_3 pho_14_500">Upload Photo</label>
                                                    </div>
                                                )
                                            }
                                            <input
                                                type="file"
                                                ref={ inputFile }
                                                name="profilePicture"
                                                id="upload-button"
                                                accept=".png, .jpg, .jpeg"
                                                // onChange={ handleImageChange }
                                                onChange={ async (e) => {
                                                    
                                                    // Call handle image change function to upload image file
                                                    await handleImageChange(e, setFieldValue);
                                                } }
                                            />
                                        </div>                                        
                                        {touched.profilePicture && errors.profilePicture ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.profilePicture}</div>
                                            </div>
                                        ) : null}
                                        {
                                            values.profilePicture &&
                                                <div className="d-flex justify-content-center">   
                                                    <button
                                                        type="button"
                                                        className="text-center btn btn-sm btn-outline-success pho_90 mr-1"
                                                        onClick={ async (e) => {
                                                            onButtonClick(e);
                                                        } }
                                                    >
                                                        <span>EDIT</span>
                                                    </button>          
                                                    <button 
                                                        type="button" 
                                                        className="text-center btn btn-sm btn-outline-danger pho_90"
                                                        onClick={ (e) => {
                                                            // Remove image from state
                                                            // remove(e);

                                                            // Set field values
                                                            setFieldValue('profilePicture', '');
                                                        }} 
                                                    >
                                                        <span>REMOVE</span>
                                                    </button>
                                                </div>
                                        }
                                    </div>                                    
                                </div>
                                <div className="col-md-12 col-lg-8">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">First Name<sup className="text-danger">*</sup></label>
                                                <Field
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                />
                                                {touched.firstName && errors.firstName ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.firstName}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Last Name<sup className="text-danger">*</sup></label>
                                                <Field
                                                    type="text"
                                                    name="lastName"           
                                                    placeholder="Last Name"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6`}
                                                />
                                                {touched.lastName && errors.lastName ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.lastName}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Contact Number<sup className="text-danger">*</sup></label>
                                                <div className="d-flex">
                                                    <div className="pho_contrycode">
                                                        <Select
                                                            required
                                                            value={ code }
                                                            classNamePrefix="pho"
                                                            options={ phoneCodeOption }
                                                            className="form-control grayField h-auto px-0 border-0"
                                                            onChange={ (e) => {

                                                                // Call on phone code change
                                                                onPhoneCodeChange(e);
                                                            } }
                                                        />
                                                    </div>
                                                    <Field
                                                        type="text"
                                                        placeholder="Contact Number"
                                                        name="contactNumber" 
                                                        className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                    />
                                                    {touched.contactNumber && errors.contactNumber ? (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{errors.contactNumber}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Email</label>
                                                <Field
                                                    disabled
                                                    type="email"
                                                    name="emailId" 
                                                    placeholder="Email"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <div className="d-flex align-items-center">
                                                    <div className="pho_14_500">
                                                        Service Fee <span className="pho_16_500">
                                                            $ { props.fee && props.fee.serviceFee ? props.fee.serviceFee : '' }
                                                        </span>
                                                    </div>                                                
                                                    <Button 
                                                        variant="link" 
                                                        className="text-success"
                                                        onClick={ (e) => {
                                                            // Show service fee change modal
                                                            showServiceFeeModal();
                                                        } }
                                                    >
                                                        Change
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-5 d-flex flex-wrap align-items-center justify-content-between">
                                            <NavLink className="btn text-success btn-link my-3" to="/change-password">
                                                Change Password
                                            </NavLink>
                                            <button
                                                type="submit"
                                                className={`btn btn-success px-8 py-4 my-3`}
                                                disabled={ 
                                                    isSubmitting
                                                }                                              
                                            >
                                                <span>Update</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                        </Form> 
                    )}
                </Formik>   
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        fee: state.auth.fee,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getServiceFee: (adminId) => dispatch(actions.getServiceFee(adminId)),
        updateUser: (userId, updateData) => dispatch(actions.updateUser(userId, updateData)),
        updateServiceFee: (adminId, values) => dispatch(actions.updateServiceFee(adminId, values))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
