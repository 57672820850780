import axios from 'axios';

const FILE_UPLOAD_URL = `${process.env.REACT_APP_IMAGE_UPLOAD_API}/uploadImage`;
// const BUFFER_FILE_UPLOAD_URL = `${process.env.REACT_APP_IMAGE_UPLOAD_API}/image/buffer-image-upload`;
// const FILE_DELETE_URL = `${process.env.REACT_APP_IMAGE_UPLOAD_API}/image/image-delete`;
// const FILE_GET_URL = `${process.env.REACT_APP_IMAGE_UPLOAD_API}/image/get-file`;

// File upload url
export async function uploadImage(formData) {
    return await axios.post(`${FILE_UPLOAD_URL}`, formData);
};

// // Buffer file upload url
// export async function bufferImageUpload(data) {
//     return await axios.post(`${BUFFER_FILE_UPLOAD_URL}`, data);
// };

// // File delete url
// export async function deleteFile(file) {
//     return await axios.put(`${FILE_DELETE_URL}/?file=${file}`);
// };

// // File delete url
// export async function getFile(file) {
//     return await axios.get(`${FILE_GET_URL}/?file=${file}`);
// };