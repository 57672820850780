import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.LANDLORD_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.LANDLORD_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.LANDLORD_SUCCESS
    };
};

// All landlords fetch function
export const fetchAllLandlord = (adminId, column, dir, skip, limit, search) => {
    return {
        type: actionTypes.LANDLORDS_ALL_FETCH,
        adminId,
        column,
        dir,
        skip,
        limit,
        search
    };
};

// All landlords fetched function
export const allLandlordFetched = (landlords) => {
    return {
        type: actionTypes.LANDLORDS_ALL_FETCHED,
        landlords
    };
};

// All Pending landlords fetch function
export const fetchAllPendingLandlord = (adminId, column, dir, skip, limit) => {
    return {
        type: actionTypes.LANDLORDS_ALL_PENDING_FETCH,
        adminId,
        column,
        dir,
        skip,
        limit
    };
};

// All Pending landlords fetched function
export const allPendingLandlordFetched = (pendingLandlords) => {
    return {
        type: actionTypes.LANDLORDS_ALL_PENDING_FETCHED,
        pendingLandlords
    };
};

// Remove all landlords data function
export const removeAllLandlordsData = () => {
    return {
        type: actionTypes.LANDLORDS_ALL_DATA_REMOVE
    };
};

// Get landlord fetch function
export const fetchLandlord = (adminId, landlordId) => {
    return {
        type: actionTypes.LANDLORD_FETCH,
        adminId,
        landlordId
    };
};

// Get landlords fetched function
export const landlordFetched = (landlordDetail) => {
    return {
        type: actionTypes.LANDLORD_FETCHED,
        landlordDetail
    };
};

// Add landlords id function
export const addLandlordId = (landlordId) => {
    return {
        type: actionTypes.LANDLORD_ID_ADD,
        landlordId
    };
};

// Remove landlord id function
export const removeLandlordId = () => {
    return {
        type: actionTypes.LANDLORD_ID_REMOVE        
    };
};

// Remove landlord data function
export const removeLandlordData = () => {
    return {
        type: actionTypes.LANDLORD_DATA_REMOVE
    };
};

// Update landlord data function
export const updateLandlordData = (adminId, landlordId, updateData) => {
    return {
        type: actionTypes.LANDLORD_DATA_UPDATE,
        adminId, 
        landlordId,
        updateData
    };
};

// Delete landlord data function
export const deleteLandlordData = (adminId, landlordId) => {
    return {
        type: actionTypes.LANDLORD_DATA_DELETE,
        adminId, 
        landlordId
    };
};

// Landlord status change function
export const landlordStatusChange = (adminId, landlordId, column, dir, skip, limit) => {
    return {
        type: actionTypes.LANDLORD_STATUS_CHANGE,
        adminId, 
        landlordId,
        column,
        dir,
        skip,
        limit
    };
};

// Add landlord function
export const addLandlord = (adminId, values) => {
    return {
        type: actionTypes.LANDLORD_ADD,
        adminId,
        values
    };
};

// Get landlord list function
export const getLandlordList = (adminId) => {
    return {
        type: actionTypes.LANDLORD_LIST_FETCH,
        adminId    
    };
};

// Get landlord list fetched function
export const landlordListFetched = (landlordList) => {
    return {
        type: actionTypes.LANDLORD_LIST_FETCHED,
        landlordList    
    };
};

// Skip update function
export const skipUpdate = (skip) => {
    return {
        type: actionTypes.LANDLORD_SKIP_UPDATE,
        skip
    };
};

// Skip init function
export const skipInit = () => {
    return {
        type: actionTypes.LANDLORD_SKIP_INIT
    };
};