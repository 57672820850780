import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { notify } from '../toaster';
import { useSubheader } from '../../layout';
import * as cmsActions from '../../../app/modules/Cms';
import { pageInitSchema, pageInitValue } from './CMSSchme';
import { uploadImage } from '../../../app/modules/ImageUpload/imageUpload';

import 'react-toastify/dist/ReactToastify.min.css';

const HowWeWorks = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const subHeader = useSubheader();
    
    const modifiedInitialObj = Object.assign({}, pageInitValue);

    // State
    const [name , setName] = useState("Select");

    const imageUpload = (e) => {
        const fileName = e.target.value.split( '\\' ).pop();
        if ( fileName ) {
            setName(fileName);
        }
    };

    // Image change handler
    const handleImageChange = async (e, setFieldValue) => {
        const file = e.target.files[0];  

        if (!file) {          
            return;
        }

        const type = file.type.split('/');

        if (
            type[1] === 'png' ||
            type[1] === 'jpg' ||
            type[1] === 'jpeg' 
        ) {

            // Convert file into form data
            const formData = new FormData();
            formData.append('img', file);
    
            // Upload file to storage service
            return uploadImage(formData)
                .then(async result => {    

                    if (result.data.responseCode === 200) {
                        // Set field values
                        setFieldValue('image', result.data.responseData);
                    }

                    // if (oldImage && oldImage.length > 0) {
                    //     await deleteFile(oldImage);
                    // }
                })
                .catch(err => {                    
                    console.log(err);
                });            
        } else {
            document.getElementById('upload-button').value = '';

            // Notification
            notify('error', 'Please upload image');
        }
    };

    useEffect(() => {
        // Set sub header title
        const title = 'How We Works';

        subHeader.setTitle(title);

        // Add cms id into store
        props.addCMSId(id);

        // Call get cms detail function to get cms detail
        props.getCMSDetail(props.user._id, id);

        // Remove cms store data when component destroy
        return () => {

            // Cms init function
            props.cmsInit();
        };
    }, []);

    if (props.cmsDetail) {
        modifiedInitialObj.title = props.cmsDetail.title;
        modifiedInitialObj.description = props.cmsDetail.description;
        modifiedInitialObj.image = props.cmsDetail.image;
        modifiedInitialObj.status = props.cmsDetail.status;
        modifiedInitialObj.type = props.cmsDetail.type;
    }

    return (
        <>
            { 
                !props.loading ?
                    <Formik
                        enableReinitialize
                        initialValues={ modifiedInitialObj }
                        validationSchema={ pageInitSchema }
                        onSubmit={(values, { setSubmitting, resetForm }) => {  
                            // Set submitting to true
                            setSubmitting(true);
                            
                            // Send data to backend
                            props.updateDetail(props.user._id, id, values);

                            setTimeout(() => {
                                // Set submitting form to false
                                setSubmitting(false);

                                // Reset form to initial values
                                resetForm();

                                // Go back to property management page                            
                                history.push('/cms-pages');
                            }, 1000);
                        }}
                    >
                        {({
                            values, 
                            errors, 
                            touched, 
                            handleChange,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                        }) => (
                            <Form 
                                onSubmit={ handleSubmit }
                                className="justify-content-center pho_cmspage row" 
                            >
                                <Col xl={ 6 }>
                                    <div className="card card-custom">
                                        <Button 
                                            type="submit"
                                            variant="success" 
                                            disabled={ isSubmitting }
                                            className="desktopTopRight px-5 py-3"
                                        >
                                            Update
                                        </Button>
                                        <div className="pho_16_400 mb-3">Heading</div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={ values.title }
                                            onInit={ editor => {
                                                const data = editor.getData();

                                                // // Set field value
                                                // setFieldValue('title', data);
                                            }}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();

                                                // // Set field value
                                                // setFieldValue('title', data);

                                                // setInit(init => ({
                                                //     ...init,
                                                //     title: data
                                                // }));

                                                // // Call on input change function
                                                // onInputChange('title', setFieldValue, data);
                                            } }
                                            onBlur={ ( event, editor ) => {
                                                const data = editor.getData();

                                                // Set field touched value
                                                setFieldTouched('title', true);

                                                // Set field value
                                                setFieldValue('title', data);
                                            } }
                                        />
                                        {touched.title && errors.title ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.title}</div>
                                            </div>
                                        ) : null}
                                        <div className="pho_16_400 mt-5 mb-3">Description</div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={ values.description }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                
                                                // // Set field value
                                                // setFieldValue('description', data);
                                            } }
                                            onBlur={ ( event, editor ) => {
                                                const data = editor.getData();

                                                // Set field touched value
                                                setFieldTouched('description', true);

                                                // Set field value
                                                setFieldValue('description', data);
                                            } }
                                        />
                                        {touched.description && errors.description ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.description}</div>
                                            </div>
                                        ) : null}
                                        {/* <div className="pho_16_400 mt-5 mb-3">Image</div>
                                        <div className="phobox">
                                            <input 
                                                type="file" 
                                                name="image"
                                                id="upload-button"
                                                placeholder="Select Image"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={ async (e) => {          

                                                    // Upload file
                                                    await handleImageChange(e, setFieldValue);                                        
                                                } } 
                                            />
                                            <div className="displayLabel" >
                                                <span>{ values.image ? values.image : 'Select Image' }</span>
                                                <Button variant="success">
                                                    Upload
                                                </Button>
                                            </div>
                                        </div>
                                        {touched.image && errors.image ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.image}</div>
                                            </div>
                                        ) : null} */}
                                        <div className="pho_16_400 mt-5 mb-3">Type</div>
                                        <select 
                                            disabled
                                            name="type"
                                            className="form-control" 
                                            onBlur={() => setFieldTouched('type', true)}
                                            onChange={ handleChange }
                                            value={ values.type }
                                        
                                        >
                                            <option value="">Select Type</option>
                                            <option value="ABOUTUS">About Us</option>
                                            <option value="HOWWEWORK">How we work</option>
                                            <option value="PRIVACYPOLICY">Privacy Policy</option>
                                            <option value="TRUSTANDSAFETY">Trust and Safety</option>
                                            <option value="TERMSANDCONDITIONS">Terms & Conditions</option>
                                            <option value="CANCELLATIONPOLICY">Cancellation Policy</option>
                                        </select> 
                                        {touched.type && errors.type ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.type}</div>
                                            </div>
                                        ) : null}

                                        <div className="pho_16_400 mt-5 mb-3">Status</div>
                                        <select 
                                            name="status"
                                            className="form-control" 
                                            onBlur={() => setFieldTouched('status', true)}
                                            onChange={ handleChange }
                                            value={ values.status }
                                        
                                        >
                                            <option value="">Select Status</option>
                                            <option value="ACTIVE">Active</option>
                                            <option value="INACTIVE">Deactive</option>
                                        </select> 
                                        {touched.status && errors.status ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.status}</div>
                                            </div>
                                        ) : null}
                                        {/* <div className="pho_16_400 mt-5 mb-3">Title 1</div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={ values.title1 }
                                            // onChange={ ( event, editor ) => {
                                            //     const data = editor.getData();
                                                
                                            //     // Set field value
                                            //     setFieldValue('title1', data);
                                            // } }
                                            // onBlur={ ( event, editor ) => {
                                            //     // Set field touched value
                                            //     setFieldTouched('title1', true);
                                            // } }
                                            onBlur={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log('data', data);

                                                // Set field touched value
                                                setFieldTouched('title1', true);

                                                // Set field value
                                                setFieldValue('title1', data);
                                            } }
                                        />
                                        {touched.title1 && errors.title1 ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.title1}</div>
                                            </div>
                                        ) : null}
                                        <div className="pho_16_400 mt-5 mb-3">Description</div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={ values.description }
                                            // onChange={ ( event, editor ) => {
                                            //     const data = editor.getData();
                                                
                                            //     // Set field value
                                            //     setFieldValue('description', data);
                                            // } }
                                            // onBlur={ ( event, editor ) => {
                                            //     // Set field touched value
                                            //     setFieldTouched('description', true);
                                            // } }
                                            onBlur={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log('data', data);

                                                // Set field touched value
                                                setFieldTouched('description', true);

                                                // Set field value
                                                setFieldValue('description', data);
                                            } }
                                        />
                                        {touched.description && errors.description ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.description}</div>
                                            </div>
                                        ) : null}
                                        <div className="pho_16_400 mt-5 mb-3">Title 2</div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={ values.title2 }
                                            // onChange={ ( event, editor ) => {
                                            //     const data = editor.getData();
                                                
                                            //     // Set field value
                                            //     setFieldValue('title2', data);
                                            // } }
                                            // onBlur={ ( event, editor ) => {
                                            //     // Set field touched value
                                            //     setFieldTouched('title2', true);
                                            // } }
                                            onBlur={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log('data', data);

                                                // Set field touched value
                                                setFieldTouched('title2', true);

                                                // Set field value
                                                setFieldValue('title2', data);
                                            } }
                                        />
                                        {touched.title2 && errors.title2 ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.title2}</div>
                                            </div>
                                        ) : null}
                                        <div className="pho_16_400 mt-5 mb-3">Description</div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={ values.description }
                                            // onChange={ ( event, editor ) => {
                                            //     const data = editor.getData();
                                                
                                            //     // Set field value
                                            //     setFieldValue('description', data);
                                            // } }
                                            // onBlur={ ( event, editor ) => {
                                            //     // Set field touched value
                                            //     setFieldTouched('description', true);
                                            // } }     
                                            onBlur={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log('data', data);

                                                // Set field touched value
                                                setFieldTouched('description', true);

                                                // Set field value
                                                setFieldValue('description', data);
                                            } }                                       
                                        />
                                        {touched.description && errors.description ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.description}</div>
                                            </div>
                                        ) : null} */}
                                    </div>
                                </Col>
                            </Form>
                        )}
                    </Formik>
                    
                :
                    <div id="compnent-splash-screen" className="compnent-splash-screen">
                        <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                            <circle 
                                className="path"
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                            ></circle>
                        </svg>
                    </div>
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,   
        error: state.cms.error,
        allCMS: state.cms.allCMS,
        loading: state.cms.loading,    
        cmsDetail: state.cms.cmsDetail        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        cmsInit: () => dispatch(cmsActions.cmsInit()),
        addCMSId: (cmsId) => dispatch(cmsActions.addcmsId(cmsId)),
        getCMSDetail: (adminId, cmsId) => dispatch(cmsActions.fetchCMSDetail(adminId, cmsId)),
        updateDetail: (adminId, cmsId, values) => dispatch(cmsActions.updateCmsPage(adminId, cmsId, values))         
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HowWeWorks);
