import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    addingAmenity,
    addingcategory,
    deletingAmenity,
    updatingAmenity,
    deletingCategory,
    updatingCategory,
    fetchingAllAmenities,
    fetchingAllCategories,
} from './saga';

export function* watchMaster() {
    yield all([
        ///////////////////////////// AMENITY /////////////////////////////

        // takeEvery call every functions that matches
        takeEvery(actionTypes.AMENITY_CREATE, addingAmenity),
        takeEvery(actionTypes.AMENITY_UPDATE, updatingAmenity),
        takeEvery(actionTypes.AMENITY_DELETE, deletingAmenity),
        takeEvery(actionTypes.AMENITIES_ALL_FETCH, fetchingAllAmenities),

        ///////////////////////////// CATEGORY /////////////////////////////
        
        takeEvery(actionTypes.CATEGORY_CREATE, addingcategory),
        takeEvery(actionTypes.CATEGORY_UPDATE, updatingCategory),
        takeEvery(actionTypes.CATEGORY_DELETE, deletingCategory),
        takeEvery(actionTypes.CATEGORIES_ALL_FETCH, fetchingAllCategories),
    ]);
};