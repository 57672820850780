import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    fetchingAllBookings,
    fetchingUserBookings,
    fetchingBookingDetail
} from './saga';

export function* watchBooking() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.BOOKING_ALL_FETCH, fetchingAllBookings),
        takeEvery(actionTypes.BOOKING_DETAIL_FETCH, fetchingBookingDetail),
        takeEvery(actionTypes.BOOKING_USER_FETCH, fetchingUserBookings)
    ]);
};