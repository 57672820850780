import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { Switch } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Formik, FieldArray } from 'formik';
import { connect } from "react-redux";
import moment from 'moment';

import ItemDropdown from './ItemDropdown';
import { initialCMSTableValues } from './CMSSchme';
import * as cmsActions from '../../../app/modules/Cms';

const CMSPages = (props) => {
    const history = useHistory();

    const modifiedInitialTabObj = Object.assign({}, initialCMSTableValues);

    // State
    const [state, setState] = useState({
        checked: true,
    });
    const [commonDelete, setCommonDelete] = useState({
        count: 0,
        value: false
    });
    
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    // On view button click
    const onViewButtonHandler = (e, cms) => {
        e.preventDefault();

        if (cms._id) {

            switch(cms.type) {
                case 'ABOUTUS':

                    // Go to about us page
                    history.push(`/about-us/${cms._id}`);
                    break;
                case 'PRIVACYPOLICY':

                    // Go to about us page
                    history.push(`/privacy-policy/${cms._id}`);
                    break;
                case 'TERMSANDCONDITIONS':

                    // Go to about us page
                    history.push(`/terms-conditions/${cms._id}`);
                    break;
                case 'TRUSTANDSAFETY':

                    // Go to about us page
                    history.push(`/trust-safety/${cms._id}`);
                    break;
                case 'HOWWEWORK':

                    // Go to about us page
                    history.push(`/how-we-works/${cms._id}`);
                    break;
                case 'CANCELLATIONPOLICY':

                    // Go to about us page
                    history.push(`/cancellation-policy/${cms._id}`);
                    break;
                default:
                    return;
            }
        }
    };
    
    useEffect(() => {

        // Get cms all data from database
        props.getAllCms(props.user._id);

        // Remove cms store data when component destroy
        return () => {

            // Cms init function
            props.cmsInit();
        };
    }, []);

    if (props.allCMS && props.allCMS.length > 0) {
        const array = [];
    
        props.allCMS.forEach(cms => {
            const data = { ...cms };
            data.checkbox = false;
            array.push(data);
        });

        modifiedInitialTabObj.CMS = array; 
    }

    return (
        <>
            <div className="card card-custom">   
                <Formik
                    enableReinitialize
                    initialValues={ modifiedInitialTabObj }
                    // onSubmit={(values, { setSubmitting, resetForm }) => {
                    //     try {
                    //         setSubmitting(true);

                    //         // Show delete modal
                    //         showDeleteModal(undefined, values, true);

                    //         if (deleteModal) {
                    //             setTimeout(() => {                                
                    //                 // Reset form to initial value
                    //                 resetForm(modifiedInitialTabObj);
    
                    //                 // Set submitting form to false
                    //                 setSubmitting(false);  
                    //             }, 500);
                    //         }
                            
                    //     } catch (err) {
                    //         console.log(err);

                    //         // Notification
                    //         notify('error', 'Something went wrong');
                    //     }
                    // }}
                >
                    {({
                        values, 
                        setFieldValue,
                    }) => (
                        <Table hover responsive className="phoTable">
                            <thead>
                                <tr>
                                    {/* <th>
                                        <div className="pho_checkbox iskl_border">
                                            <input
                                                type="checkbox"
                                                name="checkbox"
                                                className={`mr-2 checkbox`}
                                                checked={ values.checkbox }
                                                onChange={ (e) => {   
                                                    let total = 0;
                                                    // Set current check box value
                                                    setFieldValue('checkbox', !values.checkbox);

                                                    values.CMS.forEach((el, index) => {  
                                                        total += 1;
                                                        
                                                        // Set all checkbox value
                                                        setFieldValue(`CMS.${index}.checkbox`, !values.checkbox);
                                                    });                                                                            

                                                    if (values.checkbox || (!values.checkbox && total === 0)) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: 0,
                                                            value: false
                                                        });
                                                    } else if (!values.checkbox && total > 0) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: total,
                                                            value: true
                                                        });
                                                    }
                                                }}
                                            />  
                                            <label></label>
                                        </div>
                                    </th> */}
                                    <th>Date</th>
                                    <th>Pages</th>
                                    <th>Active / Deactive</th>
                                    {
                                        commonDelete.count !== 0 ?
                                            // <th width="90px">
                                            //     <button
                                            //         style={{
                                            //             userSelect: 'none',
                                            //             cursor: 'pointer',
                                            //             color: 'red'
                                            //         }}
                                            //         className="btn btn-sm btn-danger text-white"
                                            //         type='submit'
                                            //     >
                                            //         Delete  
                                            //     </button>
                                            // </th>
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                        :   
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray name="CMS">
                                    {({ insert, remove, push }) => (
                                       <>
                                        {
                                            !props.loading && values.CMS && values.CMS.length > 0 ?
                                                values.CMS.map((el, index) => (
                                                    <tr key={ index }>
                                                        {/* <td width="80px" >
                                                            <div className="pho_checkbox iskl_border">
                                                                <input                                                                                    
                                                                    type="checkbox"
                                                                    name={ `CMS.${index}.checkbox` }
                                                                    className={`mr-2 checkbox`}
                                                                    checked={ el.checkbox }
                                                                    onChange={ (e) => {
                                                                        if (el.checkbox) {
                                                                            // Set common delete state
                                                                            setCommonDelete({
                                                                                count: commonDelete.count - 1,
                                                                                value: false
                                                                            });
                                                                        } else {
                                                                            // Set common delete state
                                                                            setCommonDelete({
                                                                                count: commonDelete.count + 1,
                                                                                value: true
                                                                            });
                                                                        }

                                                                        // Set field values
                                                                        setFieldValue(`CMS.${index}.checkbox`, !el.checkbox);
                                                                    } }
                                                                />
                                                                <label></label>
                                                            </div>
                                                        </td> */}
                                                        <td width="115px">
                                                            { moment(el.createdAt).format('L') }
                                                        </td>
                                                        <td>
                                                            <span>
                                                                { el.title }
                                                            </span>
                                                            {/* <Link to="/about-us" className="text-dark">
                                                                { el.title }
                                                            </Link> */}
                                                        </td>
                                                        <td className="text-center" width="135px">
                                                            <Switch
                                                                color="primary"
                                                                value={ el.status === 'ACTIVE' ? true : false }
                                                                checked={ el.status === 'ACTIVE' ? true : false }
                                                                onChange={ (e) => {

                                                                    // if (el.status === 'ACTIVE') {
                                                                    //     setFieldValue(`CMS.${index}.status`, 'DEACTIVE');
                                                                    // } else {
                                                                    //     setFieldValue(`CMS.${index}.status`, 'ACTIVE');
                                                                    // }
                                                                    
                                                                    // Update user status
                                                                    props.cmsStatusChange(props.user._id, el._id);
                                                                } }
                                                            />
                                                        </td>
                                                        <td width="90px">
                                                            <ItemDropdown 
                                                                item="" 
                                                                doc={ el }
                                                                view={ onViewButtonHandler }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            : 
                                                props.loading ?
                                                    (
                                                        // <div id="compnent-splash-screen" className="compnent-splash-screen">
                                                        //     <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                                                        //         <circle 
                                                        //             className="path"
                                                        //             cx="25"
                                                        //             cy="25"
                                                        //             r="20"
                                                        //             fill="none"
                                                        //             strokeWidth="5"
                                                        //         ></circle>
                                                        //     </svg>
                                                        // </div>
                                                        <tr>
                                                            <td colSpan="5" className="text-center">Loading...</td>
                                                        </tr>
                                                    )
                                                : 
                                                    <tr>
                                                        <td colSpan="5" className="text-center">No Data Available</td>
                                                    </tr>
                                        }
                                       </> 
                                    )}
                                </FieldArray>
                            </tbody>
                        </Table>
                    )}
                </Formik>        
                        
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,   
        error: state.cms.error,
        allCMS: state.cms.allCMS,
        loading: state.cms.loading,            
    };
};

const mapDispatchToProps = dispatch => {
    return {
        cmsInit: () => dispatch(cmsActions.cmsInit()),
        getAllCms: (adminId) => dispatch(cmsActions.fetchAllCMS(adminId)),  
        cmsStatusChange: (adminId, cmsId) => dispatch(cmsActions.cmsStatusChange(adminId, cmsId))      
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CMSPages);

{/* <tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td><Link to="/how-we-works" className="text-dark">How We Works</Link></td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checked}
        onChange={handleChange('checked')}
        value="active"
        color="primary"
    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td><Link to="/trust-safety" className="text-dark">Trust & Safety</Link></td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checked}
        onChange={handleChange('checked')}
        value="active"
        color="primary"
    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td><Link to="/cancellation-policy" className="text-dark">Cancellation Policy</Link></td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checked}
        onChange={handleChange('checked')}
        value="active"
        color="primary"
    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td><Link to="/privacy-policy" className="text-dark">Privacy Policy</Link></td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checked}
        onChange={handleChange('checked')}
        value="active"
        color="primary"
    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td><Link to="/terms-conditions" className="text-dark">Terms and Conditions</Link></td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checked}
        onChange={handleChange('checked')}
        value="active"
        color="primary"
    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr> */}