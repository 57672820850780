import React, { useEffect, useState, Fragment } from 'react';
import { Table, Pagination, Modal, Col, Row, Form, Button } from "react-bootstrap";
import { Formik, Field, FieldArray } from 'formik';
import Select from "react-select";
import { connect } from 'react-redux';
import { CountryDropdown } from "react-country-region-selector";
import moment from 'moment';

import { taxSchema, taxAddSchema } from './TaxSchema';
import * as subheaderAction from '../../../app/modules/Subheader';
import * as taxManagementAction from '../../../app/modules/TaxManagement';

const TaxManagement = props => {
    const { user, fetchAllTaxes, updateTax, addTax, deleteTax } = props;
    
    const initialValues = { taxes: [] };
    const addTaxInitValue = { country: '' };

    // State
    const [editMode, setEditMode] = useState({
        edit: false,
        _id: null
    });
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        tax: {}
    });

    // Get input class function
    const getInputClasses = (touched, errors, index) => {
        if (touched.taxes && errors.taxes && 
            touched.taxes[index] && touched.taxes[index].country && touched.taxes[index].country &&
            errors.taxes[index] && errors.taxes[index].country && errors.taxes[index].country
        ) {
            return "is-invalid";
        }

        // if (touched.taxes && errors.taxes && touched.taxes[index].country && !errors.taxes[index].country) {
        //     console.log('in-valid 2');
        //     return "is-valid";
        // }

        return "";
    };

    // Get state field class function
    const getStateFieldClass = (touched, errors, index, key) => {
        if (touched.taxes && errors.taxes && 
            touched.taxes[index] && touched.taxes[index].stateTaxes[key] && touched.taxes[index].stateTaxes[key].state &&
            errors.taxes[index] && errors.taxes[index].stateTaxes[key] && errors.taxes[index].stateTaxes[key].state
        ) {
            return "is-invalid";
        }

        // if (touched.taxes && errors.taxes && 
        //     touched.taxes[index] && errors.taxes[index] &&
        //     touched.taxes[index].stateTaxes[key] && touched.taxes[index].stateTaxes[key].state &&
        //     !errors.taxes[index].stateTaxes[key] && !errors.taxes[index].stateTaxes[key].state
        // ) {
        //     console.log('in-valid 2');
        //     return "is-valid";
        // }

        return "";
    };

    // On edit button click handler
    const onEditButtonClickHandler = (tax, resetForm) => {

        if (editMode.edit) {
            // Reset form
            resetForm(initialValues);
        }

        // Set edit mode state
        setEditMode({
            edit: true,
            _id: tax._id
        });
    };

    // Submit tax data function
    const submitTaxData = async (values, setSubmitting, resetForm) => {
        // Add init type and rate in values object
        const addTaxData = {
            ...values,
            rate: '0',
            type: 'COUNTRY'
        };

        try {
            // Call add tax function
            await addTax(user._id, addTaxData);

            setTimeout(() => {
                // Close modal
                onModalClose();

                // Set submitting to false
                setSubmitting(false);

                // Reset form
                resetForm(addTaxInitValue);
            }, 2000);
        } catch (err) {
            console.log('err', err);
        }
    };

    // On update button click handler
    const onUpdateButtonClickHandler = async (values, setSubmitting, resetForm) => {
        const { taxes } = values;

        const filteredTax = taxes.filter(tax => {
            if (tax._id === editMode._id) return tax;
        });

        if (filteredTax.length <= 0) return;

        try {
            // Call update tax function
            await updateTax(user._id, editMode._id, filteredTax[0]);

            setTimeout(() => {
                // Set edit mode state
                setEditMode({
                    edit: false,
                    _id: null
                });

                // Set submitting to false
                setSubmitting(false);

                // Reset form
                resetForm(initialValues);
            }, 3000);
        } catch (err) {
            console.log(err);
        }        
    };

    // On delete button click handler
    const onDeleteButtonClickHandler = async (tax) => {

        // Set delete state
        setDeleteModal({
            show: true,
            tax: { ...tax }
        });
    };
    
    // Delete tax data function
    const deleteTaxData = async () => {
        try {
            if (!deleteModal.show) return;

            const taxId = deleteModal.tax._id;

            // Call delete tax function
            await deleteTax(user._id, taxId);

            setTimeout(() => {

                // Call on delete modal close function
                onDeleteModalClose();
            }, 2000);
        } catch (err) {
            console.log(err);
        }
    };

    // On delete modal close
    const onDeleteModalClose = () => {

        // Set delete modal state
        setDeleteModal({
            show: false,
            tax: {}
        });
    };

    // On modal close function
    const onModalClose = () => {

        // Call modal open function
        props.modalOpen(false);
    };

    useEffect(() => {
        // Call get all taxes function
        fetchAllTaxes(user._id);
    }, [user, fetchAllTaxes]);

    if (props.taxes && props.taxes.length > 0) {
        initialValues.taxes = [...props.taxes];
    }

    return (
        <div className="card card-custom">
            <Formik
                enableReinitialize
                validationSchema={ taxSchema }
                initialValues={ initialValues }
                onSubmit={(values, { setSubmitting, resetForm }) => {  
                    // Set submitting to true
                    setSubmitting(true);

                    // Call update button click handler function
                    onUpdateButtonClickHandler(values, setSubmitting, resetForm);
                }}
            >
                {({
                    values, 
                    errors, 
                    touched, 
                    resetForm,
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched
                }) => (
                    <Form onSubmit={ handleSubmit }>
                        <Table hover responsive className="phoTable">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Country</th>
                                    <th>Set tax calculation as wise</th>
                                    <th>Tax value</th>
                                    <th></th>
                                    {
                                        editMode.edit && <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray name="taxes">
                                    {({ push, remove, insert }) => (
                                        <>
                                            {   !props.loading && values.taxes.length > 0 ?
                                                    values.taxes.map((tax, index) => (
                                                        <Fragment key={ index }>
                                                            <tr>
                                                                <td width="135px">{ moment(tax.createdAt).format('L') }</td>
                                                                {
                                                                    editMode.edit && editMode._id && editMode._id === tax._id ?
                                                                        <td width="225px">
                                                                            <input 
                                                                                type="text" 
                                                                                className={`form-control w-80 ml-0 ${getInputClasses(touched, errors, index)}`}
                                                                                value={ tax.country }  
                                                                                onBlur={ () => setFieldTouched(`taxes[${index}].country`, true) }
                                                                                disabled={ !(editMode.edit && editMode._id && editMode._id === tax._id ? true : false) }
                                                                                onChange={ (e) => {
                                                                                    // Set field value
                                                                                    setFieldValue(`taxes[${index}].country`, e.target.value);
                                                                                } }
                                                                            />
                                                                        </td> 
                                                                    :
                                                                        <td width="225px">{ tax.country }</td>    
                                                                }
                                                                <td width="270px">
                                                                    <div className="cstmckbx">
                                                                        <div className="rd_box">
                                                                            <input 
                                                                                type="radio" 
                                                                                name={ `type${index}` }
                                                                                checked={ tax.type === 'COUNTRY' ? true : false }
                                                                                disabled={ !(editMode.edit && editMode._id && editMode._id === tax._id ? true : false) }
                                                                                onChange={ (e) => { 
                                                                                    // Set field value
                                                                                    setFieldValue(`taxes[${index}].type`, 'COUNTRY');
                                                                                } }
                                                                            />
                                                                            <label>Country</label>
                                                                        </div>
                                                                        <div className="rd_box">
                                                                            <input 
                                                                                type="radio" 
                                                                                disabled={ !(editMode.edit && editMode._id && editMode._id === tax._id ? true : false) }
                                                                                name={ `type${index}` }
                                                                                checked={ tax.type === 'STATE' ? true : false }
                                                                                onChange={ (e) => { 
                                                                                    // Set field value
                                                                                    setFieldValue(`taxes[${index}].type`, 'STATE');

                                                                                    console.log('values', values.taxes);
                                                                                    console.log(!values.taxes[index].stateTaxes || values.taxes[index].stateTaxes.length <= 0);

                                                                                    if (!values.taxes[index].stateTaxes || values.taxes[index].stateTaxes.length <= 0) {
                                                                                        // Set field value
                                                                                        setFieldValue(`taxes.${index}.stateTaxes`, [{ rate: 0, state: '' }]);
                                                                                    }
                                                                                } }
                                                                            />
                                                                            <label>State</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {
                                                                    tax.type === 'COUNTRY' ?
                                                                        editMode.edit && editMode._id && editMode._id === tax._id ?
                                                                            <td className="text-left"> 
                                                                                <input 
                                                                                    min="0"
                                                                                    step=".01"
                                                                                    type="number" 
                                                                                    disabled={ !(editMode.edit && editMode._id && editMode._id === tax._id ? true : false) }
                                                                                    className="form-control w-80 ml-0" 
                                                                                    value={ tax.rate && tax.rate !== 'NaN' ? parseFloat(tax.rate) : 0 }  
                                                                                    onChange={ (e) => {
                                                                                        // Set field value
                                                                                        setFieldValue(`taxes[${index}].rate`, parseFloat(e.target.value).toFixed(2));
                                                                                    } }
                                                                                />
                                                                            </td>       
                                                                        :
                                                                            <td className="text-left">{ tax.rate && tax.rate !== 'NaN' ? `${parseFloat(tax.rate).toFixed(2)}%` : '0%' }</td>  
                                                                    :
                                                                        <td></td>
                                                                }
                                                                {
                                                                    (!editMode.edit || (editMode._id && editMode._id !== tax._id)) &&
                                                                        <td width="100px">
                                                                            <Button
                                                                                type="button"
                                                                                className="px-4 py-2"
                                                                                variant="outline-success"
                                                                                onClick={ () => onEditButtonClickHandler(tax, resetForm) }
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                        </td>                                                                    
                                                                }       
                                                                {
                                                                    editMode.edit && editMode._id && editMode._id === tax._id &&                                                                
                                                                        <>
                                                                            <td width="140px">
                                                                                <Button
                                                                                    type="submit"
                                                                                    className="px-5 py-2"
                                                                                    variant="outline-success"
                                                                                    // onClick={ () => onUpdateButtonClickHandler(tax) }
                                                                                >
                                                                                    Update
                                                                                </Button>
                                                                            </td>
                                                                            <td width="160px">
                                                                                <Button
                                                                                    type="button"
                                                                                    className="px-5 py-2"
                                                                                    variant="outline-danger"
                                                                                    onClick={ () => onDeleteButtonClickHandler(tax) }
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            </td>
                                                                        </>
                                                                }                                                     
                                                            </tr>
                                                            {
                                                                tax.type === 'STATE' && tax.stateTaxes && tax.stateTaxes.length > 0 &&
                                                                    <FieldArray name="stateTaxes">
                                                                        {({ push, remove, insert }) => (
                                                                            <>
                                                                                {
                                                                                    tax.stateTaxes.map((stateTax, key) => (
                                                                                        <tr key={ key }>
                                                                                            <td width="185px"></td>
                                                                                            {
                                                                                                editMode.edit && editMode._id && editMode._id === tax._id ?
                                                                                                    <td width="225px" className="pl-5">
                                                                                                        <input 
                                                                                                            type="text" 
                                                                                                            className={`form-control w-80 ml-0 ${getStateFieldClass(touched, errors, index, key)}`} 
                                                                                                            value={ stateTax.state }  
                                                                                                            onBlur={ () => setFieldTouched(`taxes.${index}.stateTaxes.${key}.state`, true) }
                                                                                                            disabled={ !(editMode.edit && editMode._id && editMode._id === tax._id ? true : false) }
                                                                                                            onChange={ (e) => {
                                                                                                                if (e.target.value.length <= 0) {
                                                                                                                    setFieldError(`taxes.${index}.stateTaxes.${key}.state`, 'Please enter valid State');
                                                                                                                }

                                                                                                                // Set field value
                                                                                                                setFieldValue(`taxes.${index}.stateTaxes.${key}.state`, e.target.value);
                                                                                                            } }
                                                                                                        />
                                                                                                    </td> 
                                                                                                :
                                                                                                    <td width="225px" className="pl-5">{ stateTax.state }</td>
                                                                                            }                                                                        
                                                                                            <td width="315px"></td>
                                                                                            {
                                                                                                editMode.edit && editMode._id && editMode._id === tax._id ?
                                                                                                    <td 
                                                                                                        width="180px" 
                                                                                                        className="text-left flex align-items-center"
                                                                                                    > 
                                                                                                        <input 
                                                                                                            min="0"
                                                                                                            step=".01" 
                                                                                                            type="number" 
                                                                                                            disabled={ !(editMode.edit && editMode._id && editMode._id === tax._id ? true : false) }
                                                                                                            className="form-control w-80 ml-0" 
                                                                                                            value={ stateTax.rate && stateTax.rate !== 'NaN' ? parseFloat(stateTax.rate) : 0 }
                                                                                                            onChange={ (e) => {

                                                                                                                // Set field value
                                                                                                                setFieldValue(`taxes.${index}.stateTaxes.${key}.rate`, parseFloat(e.target.value).toFixed(2));
                                                                                                            } }
                                                                                                        />
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="ph_add_state"
                                                                                                            onClick={(e) => {
                                                                                                                e.preventDefault();
            
                                                                                                                if ((key + 1) > 1) {
                                                                                                                    // // Remove input field
                                                                                                                    // remove(key);

                                                                                                                    const filteredStateTax = tax.stateTaxes.filter((stateTax, index) => {
                                                                                                                        if (key !== index) return stateTax;
                                                                                                                    });

                                                                                                                    // Set field value
                                                                                                                    setFieldValue(`taxes.${index}.stateTaxes`, filteredStateTax);

                                                                                                                    const newFieldIndex = filteredStateTax.length - 1;
                                                                                                                    // Set field error
                                                                                                                    setFieldError(`taxes.${index}.stateTaxes.${newFieldIndex}.state`, 'Please enter State');

            
                                                                                                                } else {
                                                                                                                    // // Add input field
                                                                                                                    // push({ 
                                                                                                                    //     rate: 0,
                                                                                                                    //     state: ''
                                                                                                                    // });

                                                                                                                    const allStatTaxes = [...tax.stateTaxes];

                                                                                                                    allStatTaxes.push({ rate: 0, state: '' });

                                                                                                                    // Set field value
                                                                                                                    setFieldValue(`taxes.${index}.stateTaxes`, allStatTaxes);
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                                                                <g id="Group_282" data-name="Group 282" transform="translate(-1697 -1131)">
                                                                                                                    <g id="Form_Fill" data-name="Form / Fill" transform="translate(1697 1131)" fill="none" stroke="#05af4e" strokeMiterlimit="10" strokeWidth="2">
                                                                                                                        <rect width="40" height="40" rx="6" stroke="none" />
                                                                                                                        <rect x="1" y="1" width="38" height="38" rx="5" fill="none" />
                                                                                                                    </g>
                                                                                                                    <g id="Group_281" data-name="Group 281" transform="translate(1 1)">
                                                                                                                        {key + 1 === 1 &&
                                                                                                                            <rect id="Rectangle_1001" data-name="Rectangle 1001" width="2" height="16" rx="1" transform="translate(1715 1142)" fill="#05af4e" />
                                                                                                                        }
                                                                                                                        <rect id="Rectangle_1002" data-name="Rectangle 1002" width="2" height="16" rx="1" transform="translate(1708 1151) rotate(-90)" fill="#05af4e" />
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                                                            <g id="Group_282" data-name="Group 282" transform="translate(-1697 -1131)">
                                                                                                                <g id="Form_Fill" data-name="Form / Fill" transform="translate(1697 1131)" fill="none" stroke="#143bff" stroke-miterlimit="10" stroke-width="2">
                                                                                                                    <rect width="40" height="40" rx="6" stroke="none"/>
                                                                                                                    <rect x="1" y="1" width="38" height="38" rx="5" fill="none"/>
                                                                                                                </g>
                                                                                                                <g id="Group_281" data-name="Group 281" transform="translate(1 1)">
                                                                                                                    <rect id="Rectangle_1002" data-name="Rectangle 1002" width="2" height="16" rx="1" transform="translate(1708 1151) rotate(-90)" fill="#143bff"/>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg> */}
                                                                                                        </button>
                                                                                                    </td>
                                                                                                :
                                                                                                    <td
                                                                                                        width="80px" 
                                                                                                        className="text-left"
                                                                                                    >
                                                                                                        { `${parseFloat(stateTax.rate).toFixed(2)}%` }
                                                                                                    </td>
                                                                                            }                                                                        
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </FieldArray>
                                                            }
                                                        </Fragment>
                                                    ))
                                                :
                                                    props.loading ?
                                                        <tr>
                                                            <td colSpan="4" className="text-center">Loading...</td>
                                                        </tr>
                                                    : 
                                                        <tr>
                                                            <td colSpan="4" className="text-center">No Data Available</td>
                                                        </tr>
                                            }
                                        </>
                                    )}
                                </FieldArray>
                                
                            </tbody>
                        </Table>
                    </Form>
                )}
            </Formik>

            {/* Add Tax Modal */}
            <Modal
                show={ props.openModal }
                onHide={ onModalClose }
                centered
            >
                <Modal.Header className="border-0 pb-0" closeButton>
                    <Modal.Title className="pho_18_600">
                        Add New Country
                    </Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={ addTaxInitValue }
                    validationSchema={ taxAddSchema }
                    onSubmit={(values, { setSubmitting, resetForm }) => {  
                        console.log('values', values);
                        // Set submitting to true
                        setSubmitting(true);

                        // Call submit tax data function
                        submitTaxData(values, setSubmitting, resetForm);
                    }}
                >
                    {({ 
                        values, 
                        errors,
                        touched,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={ handleSubmit }>
                            <Modal.Body className="pb-0">
                                <div className="form-group">
                                    <label className="pho_14_500 text-left">
                                        Select
                                    </label>
                                    {/* <Select
                                        name="type"
                                        placeholder="Select Country"
                                        className="form-control p-0 border-0 bg-gray-light h-auto pho_16_500"
                                        classNamePrefix="phoSelect"
                                        options={ statusOptions }                                
                                    /> */}
                                    <CountryDropdown
                                        value={values.country}
                                        onChange={(val) => setFieldValue('country', val)}
                                        labelType="full"
                                        valueType="full"
                                        className="pho_18_medium form-control"
                                        name="bankCountry"
                                        //value={formik.values.bankCountry}
                                    />
                                    {touched.country && errors.country ? (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">{errors.country}</div>
                                        </div>
                                    ) : null}                          
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="border-0 pt-0">
                                <Button
                                    type="submit"
                                    variant="success"
                                    className="px-5 py-3"
                                >
                                    Add Now
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>                
            </Modal>

            {/* Delete Tax Modal */}
            <Modal
                show={ deleteModal.show }
                onHide={ onDeleteModalClose }
                size="md"
                className="pho_delete_modal"
                centered
            >
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="w-100 pho_18_600 text-danger text-center">
                        Delete Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="pho_18_500 text-center">
                        Are you sure want to delete Tax { Object.keys(deleteModal.tax).length > 0 ? `(${deleteModal.tax.country})` : '' } ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center border-0">
                    <Button 
                        variant="outline-success" 
                        className="btn-sm mr-5" 
                        onClick={ onDeleteModalClose }
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="outline-danger" 
                        className="btn-sm" 
                        onClick={ deleteTaxData }
                    >
                        Delete                        
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      
    );    
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        taxes: state.tax.taxes,
        loading: state.tax.loading,
        openModal: state.subHeader.addNew
    };
};

const mapDispatchToProps = dispatch => {
    return {
        modalOpen: (value) => dispatch(subheaderAction.addNew(value)),
        fetchAllTaxes: (adminId) => dispatch(taxManagementAction.fetchAllTaxes(adminId)),
        addTax: (adminId, taxData) => dispatch(taxManagementAction.addTax(adminId, taxData)),
        deleteTax: (adminId, taxId) => dispatch(taxManagementAction.deleteTax(adminId, taxId)),
        updateTax: (
            adminId, 
            taxId, 
            updateData
        ) => dispatch(taxManagementAction.updateTax(adminId, taxId, updateData))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxManagement);

{/* <tr className="tr_even">
    <td width="185px">06/03/2020</td>
    <td width="225px">India</td>
    <td width="315px">
        <div className="cstmckbx">
            <div className="rd_box">
                <input type="radio" checked name="lctn_rd" />
                <label>Country</label>
            </div>
            <div className="rd_box">
                <input type="radio" name="lctn_rd" />
                <label>State</label>
            </div>
        </div>
    </td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="18%"  />
    </td>
</tr>
<tr>
    <td width="185px">06/03/2020</td>
    <td width="225px">United States</td>
    <td width="315px">
        <div className="cstmckbx">
            <div className="rd_box">
                <input type="radio" name="lctn_rd1" />
                <label>Country</label>
            </div>
            <div className="rd_box">
                <input type="radio" checked name="lctn_rd1" />
                <label>State</label>
            </div>
        </div>
    </td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="18%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">California</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="10%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">Texas</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="0.5%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">Florida</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="15%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">Alaska</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="10%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">Arizona</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="15%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">Wyoming</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="10%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">Georgiya</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="12%"  />
    </td>
</tr>
<tr className="tr_odd">
    <td width="185px"></td>
    <td width="225px" className="pl-5">Colorado</td>
    <td width="315px"></td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="17%"  />
    </td>
</tr>
<tr className="tr_even">
    <td width="185px">06/03/2020</td>
    <td width="225px">India</td>
    <td width="315px">
        <div className="cstmckbx">
            <div className="rd_box">
                <input type="radio" checked name="lctn_rd2" />
                <label>Country</label>
            </div>
            <div className="rd_box">
                <input type="radio" name="lctn_rd2" />
                <label>State</label>
            </div>
        </div>
    </td>
    <td className="text-left"> 
        <input type="text" className="form-control w-220 ml-0" value="15%"  />
    </td>
</tr> */}