import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    getAllPayOuts,
    releasePayment,
    refundPayment,
    getAllPaymentsReceived
} from '../paymentCrud';

// Get all payment received functions
export function* fetchingAllPaymentReceived(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all payment received
        const result = yield getAllPaymentsReceived(
            action.adminId,
            action.column, 
            action.dir, 
            action.status, 
            action.skip, 
            action.limit,
            action.userId
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store payment data into store
        yield put(actions.allpaymentReceivedFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get payouts detail functions
export function* fetchingPayout(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch payout detail
        const result = yield getAllPayOuts(action.adminId, action.landlordId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store payment data into store
        yield put(actions.payoutFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Refund payment functions
export function* refundingPayment(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to refund user payment
        const result = yield refundPayment(action.adminId, action.userId, action.bookingId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store payment data into store
        yield put(actions.refundPaymentDone(true));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));

        // Notification
        notify('error', err.message);
    }
};

// Release payment functions
export function* releasingPayment(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to release landlord payment
        const result = yield releasePayment(action.adminId, action.landlordId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store payment data into store
        yield put(actions.releasePaymentDone(true));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));

        // Notification
        notify('error', err.message);
    }
};