import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    addingCMS,
    updatingCMS,
    fetchingAllCms,
    fetchingCMSDetail,
    changingCMSStatus
} from './saga';

export function* watchCms() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.CMS_ALL_FETCH, fetchingAllCms),
        takeEvery(actionTypes.CMS_CREATE, addingCMS),
        takeEvery(actionTypes.CMS_STATUS_CHANGE, changingCMSStatus),
        takeEvery(actionTypes.CMS_DETAIL_FETCH, fetchingCMSDetail),
        takeEvery(actionTypes.CMS_UPDATE, updatingCMS),
    ]);
};