import axios from "axios";

// Invitaion link related URL
export const LINK_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/createLink`;
export const LINK_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllLinks`;
export const LINK_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateLink`;
export const LINK_DELETE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteLink`;

// Get all link function
export async function getAllLinks(adminId) {
  return await axios.get(`${LINK_ALL_URL}/${adminId}`);
}

// Add link function
export async function addLink(adminId, linkData) {
  return await axios.post(`${LINK_ADD_URL}/${adminId}`, linkData);
}

// Update link function
export async function updateLink(adminId, linkId, updateData) {
  return await axios.put(`${LINK_UPDATE_URL}/${adminId}/${linkId}`, updateData);
}

// Delete link function
export async function deleteLink(adminId, linkId) {
  return await axios.delete(`${LINK_DELETE_URL}/${adminId}/${linkId}`);
}
