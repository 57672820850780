import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    verifingAccommodationReq,
    fetchingAllAccommodation,
    changingAccommodationStatus,
    fetchingAccommodationDetail,
    fetchingHostedAccommodation,
    fetchingPendingAccommodation
} from './saga';

export function* watchAccommodation() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.ACCOMODATIONS_ALL_FETCH, fetchingAllAccommodation),
        takeEvery(actionTypes.ACCOMODATIONS_HOSTED_FETCH, fetchingHostedAccommodation),
        takeEvery(actionTypes.ACCOMODATIONS_PENDING_FETCH, fetchingPendingAccommodation),
        takeEvery(actionTypes.ACCOMODATION_STATUS_CHANGE, changingAccommodationStatus),
        takeEvery(actionTypes.ACCOMODATION_DETAIL_FETCH, fetchingAccommodationDetail),
        takeEvery(actionTypes.ACCOMODATION_REQ_VERIFICATION, verifingAccommodationReq),
    ]);
};