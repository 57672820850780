import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 10,
    count: null,
    error: null,
    loading: null,
    allNotifications: null
};

// Reducer
export const notificationReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.NOTIFICATION_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.NOTIFICATION_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.NOTIFICATION_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.NOTIFICATION_ALL_FETCHED:
            return {
                ...state,
                allNotifications: action.allNotifications
            };
        case actionTypes.NOTIFICATION_COUNT_FETCHED:
            return {
                ...state,
                count: action.count
            };
        case actionTypes.NOTIFICATION_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.NOTIFICATION_INIT:
            return {
                ...state,
                error: null,
                loading: null,
                allNotifications: null
            };
        case actionTypes.NOTIFICATION_SKIP_INIT:
            return {
                ...state,
                skip: initialState.skip
            };
        default:
            return state;
    };
};