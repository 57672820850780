import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 10,
    error: null,
    loading: null,
    payouts: null,
    refunded: false,
    released: false,
    paymentReceived: null
};

// Reducer
export const paymentReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.PAYMENT_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.PAYMENT_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.PAYMENT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.PAYMENT_RECEIVED_FETCHED:
            return {
                ...state,
                paymentReceived: action.paymentReceived
            };
        case actionTypes.PAYMENT_PAYOUT_FETCHED:
            return {
                ...state,
                payouts: action.payouts
            };
        case actionTypes.PAYMENT_REFUND_DONE:
            return {
                ...state,
                refunded: action.value
            };
        case actionTypes.PAYMENT_RELEASE_DONE:
            return {
                ...state,
                released: action.value
            };
        case actionTypes.PAYMENT_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.PAYMENT_INIT:
            return {
                ...state,
                error: null,
                loading: null,
                payouts: null,
                refunded: null,
                released: null,
                paymentReceived: null
            };
        case actionTypes.PAYMENT_SKIP_INIT:
            return {
                ...state,
                skip: initialState.skip
            };
        default:
            return state;
    };
};