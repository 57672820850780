import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    addingTemplate,
    updatingTemplate,
    fetchingAllTemplates,
    changingTemplateStatus,
    fetchingTemplateDetail,
    fetchingTemplateEntities,
} from './saga';

export function* watchTemplate() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.TEMPLATE_ALL_FETCH, fetchingAllTemplates),
        takeEvery(actionTypes.TEMPLATE_CREATE, addingTemplate),
        takeEvery(actionTypes.TEMPLATE_STATUS_CHANGE, changingTemplateStatus),
        takeEvery(actionTypes.TEMPLATE_DETAIL_FETCH, fetchingTemplateDetail),
        takeEvery(actionTypes.TEMPLATE_UPDATE, updatingTemplate),
        takeEvery(actionTypes.TEMPLATE_ENTITIES_FETCH, fetchingTemplateEntities),
    ]);
};