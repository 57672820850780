/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";

import Login from "./Login";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout"
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import SetNewPassword from "./SetNewPassword";

import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
      <>
        <div className="d-flex flex-column flex-root bg-white">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login">
          {/*begin::Aside*/}
          <div
            className="  login-aside d-flex flex-row-auto shadow p-10 p-lg-10 bg-white">
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <div className="px-md-5 pb-5 mb-5">                  
                  <h3 className="pho_30_600 mb-5 text-dark">
                  Manage Your Portal
                  </h3>
                  <p className="pho_16_300">
                    Phaselloremus semper malesuada anteullam sapien
                    libero tristique ultrices lacinia rutrum nisi vel con.
                  </p>
                </div>
                <img
                  alt="Logo"
                  className="mt-5 w-100 pho_1366_90"
                  src={toAbsoluteUrl("/media/custom/pho_loginImage.svg")}
                />
              </div>
              {/* end:: Aside content */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}            
          <Switch>             
            <ContentRoute path="/auth/login" component={ Login }/>
            <ContentRoute path="/auth/registration" component={ Registration }/>
            <ContentRoute path="/auth/reset-password" component={ SetNewPassword } />
            <ContentRoute path="/auth/forgot-password" component={ ForgotPassword }/>

            <Redirect from="/auth" exact={true} to="/auth/login"/>
            <Redirect from="/auth/login" exact={true} to="/auth/login"/>
            <Redirect from='/auth/reset-password' exact={true} to="/auth/login" />
            <Redirect from='/auth/forgot-password' exact={true} to="/auth/login" />
            <Redirect to="/auth/login"/>
          </Switch>
        </div>
        {/*end::Login*/}
      </div>
      </>
  );
}
