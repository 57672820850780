import axios from "axios";

// Accommodation related URL
export const TAX_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/addTax`;
export const TAX_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllTaxes`;
export const TAX_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateTax`;
export const TAX_DELETE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteTax`;

// Get all taxes function
export async function getAllTaxes(adminId) {
  return await axios.get(`${TAX_ALL_URL}/${adminId}`);
};

// Add tax function
export async function addTax(adminId, taxData) {
  return await axios.post(`${TAX_ADD_URL}/${adminId}`, taxData);
};

// Update tax function
export async function updateTax(adminId, taxId, updateData) {
  return await axios.put(`${TAX_UPDATE_URL}/${adminId}/${taxId}`, updateData);
};

// Delete tax function
export async function deleteTax(adminId, taxId) {
  return await axios.delete(`${TAX_DELETE_URL}/${adminId}/${taxId}`);
};