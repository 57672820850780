import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    fetchingAllLanguages,
    changingLanguageStatus
} from './saga';

export function* watchLanguage() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.LANGUAGE_ALL_FETCH, fetchingAllLanguages),
        // takeEvery(actionTypes.LANGUAGE_CREATE, addingTemplate),
        takeEvery(actionTypes.LANGUAGE_STATUS_CHANGE, changingLanguageStatus),
        // takeEvery(actionTypes.LANGUAGE_DETAIL_FETCH, fetchingTemplateDetail),
        // takeEvery(actionTypes.LANGUAGE_UPDATE, updatingTemplate),
        // takeEvery(actionTypes.LANGUAGE_ENTITIES_FETCH, fetchingTemplateEntities),
    ]);
};