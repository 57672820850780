import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 10,
    error: null,
    loading: null,
    bookings: null,
    userBookings: null,
    detailLoading: null,
    bookingDetail: null
};

// Reducer
export const bookingReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.BOOKING_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.BOOKING_DETAIL_START:
            return {
                ...state,
                error: null,
                detailLoading: true
            };
        case actionTypes.BOOKING_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null,
                detailLoading: null
            };
        case actionTypes.BOOKING_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null,
                detailLoading: null
            };
        case actionTypes.BOOKING_ALL_FETCHED:
            return {
                ...state,
                bookings: action.bookings
            };
        case actionTypes.BOOKING_DETAIL_FETCHED:
            return {
                ...state,
                bookingDetail: action.bookingDetail
            };
        case actionTypes.BOOKING_USER_FETCHED:
            return {
                ...state,
                userBookings: action.userBookings
            };
        case actionTypes.BOOKING_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.BOOKING_DETAIL_REMOVE:
            return {
                ...state,
                bookingDetail: null
            };
        case actionTypes.BOOKING_INIT:
            return {
                ...state,
                error: null,
                loading: null,
                bookings: null
            };
        case actionTypes.BOOKING_SKIP_INIT:
            return {
                ...state,
                skip: initialState.skip
            };
        default:
            return state;
    };
};