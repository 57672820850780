import * as Yup from 'yup';

// Initial tab value
export const initialCMSTableValues = {
    CMS: [],
    checkbox: false
};

export const pageInitValue = {
    title: '',
    description: '',
    image: '',
    type: '',
    status: ''
};

export const pageInitSchema = Yup.object().shape({
    title: Yup.string()
        .trim()
        .nullable('Please enter valid Title')
        .required('Please enter Title'),
    description: Yup.string()
        .trim()
        .nullable('Please enter valid Description')
        .required('Please enter Description'),
    // image: Yup.string()
    //     .required('Please select Image'),
    type: Yup.string()
        .required('Please select Type'),
    status: Yup.string()
        .required('Please select Status'),
    // title1: Yup.string()
    //     .trim()
    //     .nullable('Please enter valid Title1')
    //     .required('Please enter Title1'),
    // title2: Yup.string()
    //     .trim()
    //     .nullable('Please enter valid Title2')
    //     .required('Please enter Title2'),
});