import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 10,
    error: null,
    loading: null,
    entities: null,
    templateId: null,
    allTemplates: null,
    templateDetail: null,
};

// Reducer
export const templateReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.TEMPLATE_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.TEMPLATE_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.TEMPLATE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.TEMPLATE_ALL_FETCHED:
            return {
                ...state,
                allTemplates: action.allTemplates
            };
        case actionTypes.TEMPLATE_ENTITIES_FETCHED:
            return {
                ...state,
                entities: action.entities
            };
        case actionTypes.TEMPLATE_DETAIL_FETCHED:
            return {
                ...state,
                templateDetail: action.templateDetail
            };
        case actionTypes.TEMPLATE_ID_ADD:
            return {
                ...state,
                templateId: action.templateId
            };      
        case actionTypes.TEMPLATE_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.TEMPLATE_SKIP_INIT:
            return {
                ...state,
                skip: initialState.skip
            }; 
        case actionTypes.TEMPLATE_INIT:
            return {
                ...initialState
            };
        default:
            return state;
    };
};