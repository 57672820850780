import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    error: null,
    loading: null,
    amenities: null,
    categories: null,
    amenityDetail: null,
    categoryDetail: null
};

// Reducer
export const masterReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.MASTER_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.MASTER_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.MASTER_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.CATEGORIES_ALL_FETCHED:
            return {
                ...state,
                categories: action.categories
            };
        case actionTypes.AMENITIES_ALL_FETCHED:
            return {
                ...state,
                amenities: action.amenities
            };
        case actionTypes.CATEGORY_FETCHED:
            return {
                ...state,
                categoryDetail: action.categoryDetail
            };
        case actionTypes.AMENITY_FETCHED:
            return {
                ...state,
                amenityDetail: action.amenityDetail
            };
        case actionTypes.MASTER_INIT:
            return {
                ...initialState
            };
        default:
            return state;
    };
};