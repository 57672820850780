import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";

import { PayoutPage } from "./pages/PayoutPage";
import { ReportsPage } from "./pages/ReportsPage";
import { DashboardPage } from "./pages/DashboardPage";
import { UserPaymentPage } from "./pages/UserPaymentPage";
import { TaxManagementPage } from "./pages/TaxManagementPage";
import { NotificationsPage } from "./pages/NotificationsPage";
import { InvitationLinkPage } from "./pages/InvitationLinkPage";
import { LandlordPaymentPage } from "./pages/LandlordPaymentPage";
import { LanguageSettingsPage } from "./pages/LanguageSettingsPage";
import { MasterManagementPage } from "./pages/MasterManagementPage";
import { SupportPage, SupportDetailsPage } from "./pages/SupportPage";
import { BookingManagementPage } from "./pages/BookingManagementPage";
import { ProfilePage, ChangePasswordPage } from "./pages/ProfilePage";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { AccommodationRequestPage } from "./pages/AccommodationRequestPage";
import { UserPaymentDetailsPage } from "./pages/UserPaymentPage/UserPaymentDetailsPage";
import {
  UserManagementPage,
  AddUserPage,
  UserDetailsPage,
} from "./pages/UserManagementPage";
import { LandlordPaymentDetailsPage } from "./pages/LandlordPaymentPage/LandlordPaymentDetailsPage";
import {
  AddTemplatePage,
  TemplateManagementPage,
  EditTemplatePage,
} from "./pages/TemplateManagementPage";
import {
  AccommodationManagementPage,
  AccommodationDetailsPage,
} from "./pages/AccommodationManagementPage";
import {
  AddLandlordPage,
  LandlordDetailsPage,
  LandlordManagementPage,
} from "./pages/LandlordManagementPage";
import {
  AboutUsPage,
  CancellationPolicyPage,
  HowWeWorksPage,
  CMSPagesPage,
  PrivacyPolicyPage,
  TermsConditionsPage,
  TrustSafetyPage,
} from "./pages/CMSPagesPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />

        {/* Admin progile route */}
        <ContentRoute path="/profile" component={ProfilePage} />
        <ContentRoute path="/change-password" component={ChangePasswordPage} />

        {/* Notification Route */}
        <ContentRoute path="/notifications" component={NotificationsPage} />

        <ContentRoute
          exact
          path="/user-management"
          component={UserManagementPage}
        />
        <ContentRoute path="/add-user" component={AddUserPage} />
        <ContentRoute path="/user-details/:id" component={UserDetailsPage} />
        <ContentRoute
          path="/user-accommodations/:id"
          component={UserDetailsPage}
        />
        <ContentRoute
          exact
          path="/landlord-management"
          component={LandlordManagementPage}
        />
        <ContentRoute path="/add-landlord" component={AddLandlordPage} />
        <ContentRoute
          path="/landlord-details/:id"
          component={LandlordDetailsPage}
        />
        <ContentRoute
          path="/landlord-accommodations/:id"
          component={LandlordDetailsPage}
        />
        <ContentRoute
          exact
          path="/accommodation-management"
          component={AccommodationManagementPage}
        />
        <ContentRoute
          path="/accommodation-details/:id"
          component={AccommodationDetailsPage}
        />
        <ContentRoute
          path="/accommodation-request"
          component={AccommodationRequestPage}
        />
        <ContentRoute
          path="/master-management"
          component={MasterManagementPage}
        />
        <ContentRoute path="/support" component={SupportPage} />
        <ContentRoute
          path="/support-details/:id"
          component={SupportDetailsPage}
        />
        <ContentRoute path="/cms-pages" component={CMSPagesPage} />
        <ContentRoute path="/about-us/:id" component={AboutUsPage} />
        <ContentRoute
          path="/cancellation-policy/:id"
          component={CancellationPolicyPage}
        />
        <ContentRoute path="/how-we-works/:id" component={HowWeWorksPage} />
        <ContentRoute
          path="/privacy-policy/:id"
          component={PrivacyPolicyPage}
        />
        <ContentRoute
          path="/terms-conditions/:id"
          component={TermsConditionsPage}
        />
        <ContentRoute path="/trust-safety/:id" component={TrustSafetyPage} />
        <ContentRoute
          path="/language-settings"
          component={LanguageSettingsPage}
        />
        <ContentRoute path="/reports" component={ReportsPage} />
        <ContentRoute
          path="/template-management"
          component={TemplateManagementPage}
        />
        <ContentRoute path="/add-template" component={AddTemplatePage} />
        <ContentRoute path="/edit-template/:id" component={EditTemplatePage} />
        <ContentRoute
          path="/booking-management"
          component={BookingManagementPage}
        />
        <ContentRoute
          path="/payment-received-by-users"
          component={UserPaymentPage}
        />
        <ContentRoute
          path="/user-payment-details/:id"
          component={UserPaymentDetailsPage}
        />
        <ContentRoute
          path="/landlord-payment"
          component={LandlordPaymentPage}
        />
        <ContentRoute
          path="/landlord-payment-details/:id"
          component={LandlordPaymentDetailsPage}
        />
        <ContentRoute path="/payout" component={PayoutPage} />
        <ContentRoute path="/tax-management" component={TaxManagementPage} />
        <ContentRoute path="/invitation-link" component={InvitationLinkPage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
