import axios from "axios";

// Category related URL
export const CATEGORY_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllCategories`;
export const CATEGORY_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/createCategory`;
export const CATEGORY_DELETED_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteCategory`;
// export const CATEGORY_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getLanguageDetails`;
export const CATEGORY_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateCategory`;

// Amenity related URL
export const AMENITY_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllAmenities`;
export const AMENITY_CREATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/createAmenities`;
export const AMENITY_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/addAmenities`;
// export const AMENITY_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getLanguageDetails`;
export const AMENITY_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateAmenities`;
export const AMENITY_REMOVE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/removeAmenities`;
export const AMENITY_DELETE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteMainAmenities`;
// export const CATEGORY_AMENITY_REMOVE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/removeAmenities`;

///////////////////////////// CATEGORY /////////////////////////////

// All Categories get function
export async function getAllCategories(adminId) {
  return await axios.get(`${CATEGORY_ALL_URL}/${adminId}`);
};

// // Category get detail function
// export async function getCategoryDetail(adminId, languageId) {
//   return await axios.get(`${CATEGORY_DETAIL_URL}/${adminId}/${languageId}`);
// };

// Category add function
export async function addCategory(adminId, values) {
  return await axios.post(`${CATEGORY_ADD_URL}/${adminId}`, { ...values });
};

// Category delete function
export async function deleteCategory(adminId, categoryId) {
  return await axios.delete(`${CATEGORY_DELETED_URL}/${adminId}/${categoryId}`);
};

// Category add function
export async function updateCategory(adminId, categoryId, values) {
  return await axios.put(`${CATEGORY_UPDATE_URL}/${adminId}/${categoryId}`, { ...values });
};


///////////////////////////// AMENITY /////////////////////////////

// All Amenities get function
export async function getAllAmenities(adminId) {
  return await axios.get(`${AMENITY_ALL_URL}/${adminId}`);
};

// // Category get detail function
// export async function getCategoryDetail(adminId, languageId) {
//   return await axios.get(`${AMENITY_DETAIL_URL}/${adminId}/${languageId}`);
// };

// Amenity add function
export async function createAmenity(adminId, values) {
  return await axios.post(`${AMENITY_CREATE_URL}/${adminId}`, { ...values });
};

// Amenity add function
export async function addAmenity(adminId, amenityId, amenities) {
  return await axios.put(`${AMENITY_ADD_URL}/${adminId}/${amenityId}`, { amenities });
};

// Amenity main delete function
export async function deleteAmenity(adminId, amenityId) {
  return await axios.delete(`${AMENITY_DELETE_URL}/${adminId}/${amenityId}`);
};

// Amenity remove function
export async function removeAmenity(adminId, docId, amenityId) {
  return await axios.delete(`${AMENITY_REMOVE_URL}/${adminId}/${docId}/${amenityId}`);
};

// Amenity add function
export async function updateAmenity(adminId, amenityId, values) {
  return await axios.put(`${AMENITY_UPDATE_URL}/${adminId}/${amenityId}`, { ...values });
};