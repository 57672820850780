import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { IconButton, InputAdornment } from '@material-ui/core';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid Email")
      .min(3, "Please enter valid Email")
      .max(50, "Please enter valid Email")
      .required("Please enter Email"),
    password: Yup.string()
      .min(3, "Please enter valid Password")
      .max(50, "Please enter valid Password")
      .required("Please enter Password"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(res => {
            // Validate response
            if (res.data.responseCode !== 200) {
              throw new Error('Please enter valid email or password');
            }

            // Disable loading
            disableLoading();

            // Token
            const user = res.data.responseData;
            const accessToken = res.data.responseData.token;
            
            // Get user data by accessToken
            props.login(accessToken, user);

          })
          .catch(err => {
            console.log(err);
            // Disable loading
            disableLoading();

            // Set submitting to false
            setSubmitting(false);

            if (err.message) {
              // Set new error status to state
              setStatus(err.message);

            } else {
              // Set new error status to state
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            }
          });
      }, 1000);
    },
  });

   // Show confirm password function
   const [values, setValues] = useState({
    showPassword: false,
  });
   const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
      {/* begin::Content body */}
      <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="mb-10">
            <img
              alt="Logo"
              className="max-h-70px mb-30"
              src={toAbsoluteUrl("/media/logos/pho_logo.svg")}
            />

            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            {/* end: Alert */}

            <h3 className="pho_26_600">
              {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
              Welcome to ProHOff
            </h3>
            <p className="text-muted pho_18_500">
              Enter details to login.
            </p>
          </div>
          {/* end::Head */}

          {/*begin::Form*/}
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >            
            <div className="form-group fv-plugins-icon-container">
              <label className="pho_14_500">Email</label>
              <input
                placeholder="Email"
                type="email"
                className={`bg_light_gray form-control border-0 h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <label className="pho_14_500">Password</label>
              <input
                placeholder="Password"
                type={values.showPassword ? 'text' : 'password'}
                className={`bg_light_gray form-control border-0 h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {values.showPassword ? <img height="15px" alt="visibility off" src={toAbsoluteUrl("/media/custom/pho_visibilityoff.svg")}/> : <span className="pho-visibility"></span>}
                </IconButton>
              </InputAdornment>
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <label className="pho_14_500 pho_width_ align-items-center d-flex">
              <div className="pho_checkbox">
              <input
                type="checkbox"
                className={`mr-2 checkbox ${getInputClasses(
                  "checkbox"
                )}`}
                name="checkbox"
                {...formik.getFieldProps("checkbox")}
              />
                <label></label>
              </div>
              Remember Me</label>
              <Link
                to={ `/auth/forgot-password` }
                className="text-success pho_16_500 my-3 mr-2"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={formik.isSubmitting}
                className={`btn btn-success px-8 py-4`}
              >
                <span>Login Now</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
