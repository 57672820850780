import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import * as landlordActions from '../../Landlord/actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    addUser,
    getUser,
    getAllUsers,
    updateUser,
    deleteUser,
    getAllUserList
} from '../userCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all users function
export function* fetchingAllUsers(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield getAllUsers(
            action.userId,
            action.column, 
            action.dir, 
            action.skip, 
            action.limit,
            action.search
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allUsersFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get user function
export function* fetchingUser(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield getUser(action.adminId, action.userId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.userFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Update user data function
export function* updatingUser(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield updateUser(action.adminId, action.userId, action.updateData);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        if (action.isDashboard) {
            // Fetch pending landlords
            yield put(landlordActions.fetchAllPendingLandlord(
                action.adminId, 
                undefined,
                undefined,
                0,
                10,
            ));
        }
        
        // // Store dashboard data into store
        // yield put(actions.fetchAllUsers(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'User updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Delete user function
export function* deletingUser(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield deleteUser(action.adminId, action.userId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // // Store dashboard data into store
        // yield put(actions.fetchAllUsers(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'User updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Change user status function
export function* changingUserStatus(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield deleteUser(action.adminId, action.userId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.fetchAllUsers(
            action.adminId,
            action.column, 
            action.dir, 
            action.skip, 
            action.limit
        ));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'User updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Add user function
export function* addingUser(action) { 
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield addUser(action.adminId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // // Store dashboard data into store
        // yield put(actions.fetchAllUsers(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'User added successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get all user list function
export function* fetchingAllUserList(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch user lists
        const result = yield getAllUserList(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store user data into store
        yield put(actions.userListFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};