import axios from "axios";

// Accommodation related URL
export const USER_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/addUser`;
export const USER_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllUsers`;
export const USER_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getUserDetails`;
export const USER_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateUser`;
export const USER_DELETE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteUser`;
export const USER_LIST_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllUserList`;

// All USER get function
export async function getAllUsers(userId, column, dir, skip, limit, search) {
  return await axios.get(`${USER_ALL_URL}/${userId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${search ? `&search=${search}` : '' }`);
};

// User get function
export async function getUser(adminId, userId) {
  return await axios.get(`${USER_URL}/${adminId}/${userId}`);
};

// User update function
export async function updateUser(adminId, userId, updateData) {
  return await axios.put(`${USER_UPDATE_URL}/${adminId}/${userId}`, { ...updateData });
};

// User delete function
export async function deleteUser(adminId, userId) {
  return await axios.delete(`${USER_DELETE_URL}/${adminId}/${userId}`);
};

// User delete function
export async function addUser(adminId, values) {
  return await axios.post(`${USER_ADD_URL}/${adminId}`, { ...values });
};

// User list get function
export async function getAllUserList(adminId) {
  return await axios.get(`${USER_LIST_URL}/${adminId}`);
};