import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    error: null,
    taxes: null,
    loading: null
};

// Reducer
export const taxReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.TAX_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.TAX_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.TAX_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.TAX_ALL_FETCHED:
            return {
                ...state,
                taxes: action.taxes
            };    
        case actionTypes.TAX_INIT:
            return {
                ...initialState
            }; 
        default:
            return state;
    };
};