import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Modal, Tabs, Tab, Row, Col, Pagination, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Switch } from '@material-ui/core';
import { Formik, Field, FieldArray } from 'formik';
import SVG from "react-inlinesvg";
import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import Select from 'react-select';

import { notify } from '../toaster';
import ItemDropdown from './ItemDropdown';
import { toAbsoluteUrl } from "../../_helpers";
import * as userActions from '../../../app/modules/User';
import PhoneCode from '../../_assets/json/phone_code.json';
import * as landlordActions from '../../../app/modules/Landlord';
import { initialValues, updateLandlordSchema } from './landlordSchema';
import * as accommodationActions from '../../../app/modules/Accommodation';
import { uploadImage } from '../../../app/modules/ImageUpload/imageUpload';
// import ItemDropdown from '../../../_metronic/layout/components/extras/dropdowns/ItemDropdown';

import 'react-toastify/dist/ReactToastify.min.css';

const phoneCodeOption = PhoneCode;

// let imageUrl;
const LandlordDetails = (props) => {
    const pageNumbers = [];
    let totalPages;
    let result;

    const inputFile = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams();

    const modifiedInitialObj = Object.create(initialValues);
    
    // State
    const [show, setShow] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [defaultKey, setDefaultKey] = useState('landlordDetailss');
    const [imageOpen, setImageOpen] = useState({
        show: false,
        index: null
    });
    const [profileImage, setProfileImage] = useState(null);
    const [state, setState] = useState({
        checkedA: true,
        checkedB: true,
    });
    const [code, setCode] = useState({
        value: "+91",
        label: "(+91) India",
    });

    // // Image change handler
    // const handleImageChange = (e) => {
    //     if (e.target.files.length) {
    //         setProfileImage(URL.createObjectURL(e.target.files[0]));
    //     }
    // };

    // On phone code change function
    const onPhoneCodeChange = (code) => {

        // Set code state
        setCode(code);
    };

    // Hide delete modal
    const onDeleteModalClose = () => {

        // Delete modal state to false
        setDeleteModal(false);
    };

    // Show delete modal
    const onDeleteAccoutClick = () => {

        // Delete modal state to true
        setDeleteModal(true);
    };

    // On delete button click
    const onDeleteButtonClick = () => {
        try {
            // Call functin to delete user account
            props.deleteLandlordData(props.user._id, id);

            setTimeout(() => {
                // Push user-management into history
                history.push('/landlord-management');
            }, 1000);
        } catch (err) {
            console.log(err);
        }
    };

    // Handle change function
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    // Image change handler
    const handleImageChange = async (e, setFieldValue) => {
        const file = e.target.files[0];  

        if (!file) {          
            return;
        }

        const type = file.type.split('/');

        if (
            type[1] === 'png' ||
            type[1] === 'jpg' ||
            type[1] === 'jpeg' 
        ) {

            // Convert file into form data
            const formData = new FormData();
            formData.append('img', file);
    
            // Upload file to storage service
            return uploadImage(formData)
                .then(async result => {    

                    if (result.data.responseCode === 200) {
                        // Set field values
                        setFieldValue('profilePicture', result.data.responseData);
                    }

                    // if (oldImage && oldImage.length > 0) {
                    //     await deleteFile(oldImage);
                    // }
                })
                .catch(err => {                    
                    console.log(err);
                });            
        } else {
            document.getElementById('upload-button').value = '';

            // Notification
            notify('error', 'Please upload image');
        }
    };

    // On button click
    const onButtonClick = (e) => {
        // `current` points to the mounted file input element
       inputFile.current.click();
    };

    // On view button click
    const onViewButtonHandler = (e, accommodation) => {
        e.preventDefault();

        if (accommodation._id) {

            // Go to property detail page
            history.push(`/accommodation-details/${accommodation._id}`);
        }
    };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    // Update code state function
    const updateCodeState = () => {
        // If user found then split contect number
        if (props.landlordDetail) {
            const fields = props.landlordDetail.contactNumber.split('-');
            for (var i = 0; i < phoneCodeOption.length; i++) {
                if (phoneCodeOption[i].value === fields[0]) {

                    // Set code state
                    setCode(phoneCodeOption[i]);
                }
            }
        }
    };


    useEffect(() => {
        const skip = (props.skip - 1) * props.limit;
        // Add user id into store
        props.addLandlordId(id);

        // Fetch user detail
        props.getLandlord(props.user._id, id);

        // Fetch user's accommodsations
        props.getLandlordAccommodations(
            props.user._id, 
            id,
            undefined,
            undefined,
            skip,
            props.limit
        );

        if (defaultKey === 'landlordDetailss' && location.pathname === `/landlord-accommodations/${id}`) {
            
            // Push landlord detail path in history
            history.push(`/landlord-details/${id}`)
        }
    }, [id, props.skip, location.pathname]);

    useEffect(() => {

        // Component clean up function
        return () => {

            // Remove user id from store
            props.removeLandlordId();

            // Remove user data from store
            props.removeLandlordData();

            // Init accommodation data
            props.accommodationInit();
        }; 
    }, []);

    useEffect(() => {
        // If user found then split contect number
        if (props.landlordDetail) {
            // Call update code state function
            updateCodeState();
        }
    }, [props.landlordDetail]);

    if (props.landlordDetail) {
        const contactNumber = props.landlordDetail.contactNumber.split('-');

        modifiedInitialObj.firstName = props.landlordDetail.firstName;
        modifiedInitialObj.lastName = props.landlordDetail.lastName;
        modifiedInitialObj.emailId = props.landlordDetail.emailId;
        modifiedInitialObj.contactNumber = contactNumber[1] && contactNumber[1].length > 0 ? contactNumber[1] : contactNumber[0];
        modifiedInitialObj.profilePicture = props.landlordDetail.profilePicture;
        modifiedInitialObj.document = props.landlordDetail.document;    
    }

    if (props.hostedAccommodations && props.hostedAccommodations.recordsTotal > 0) {
        result = props.hostedAccommodations.records;

        totalPages = Math.ceil(props.hostedAccommodations.recordsTotal / props.limit);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        };
    }

    return (
        <div className="userDetailsPage">
            <Modal
                show={ deleteModal }
                onHide={ onDeleteModalClose }
                size="md"
                className="pho_delete_modal"
                centered
            >
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="w-100 pho_18_600 text-danger text-center">
                        {/* Delete Alert */}
                        Status Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="pho_18_500 text-center">
                        {/* Are you sure want to delete Account { props.userDetail ? `(${props.userDetail.firstName} ${props.userDetail.lastName})` : '' } ? */}
                        Are you sure want to { props.landlordDetail && props.landlordDetail.status === 'ACTIVE' ? 'deactive' : 'active' } account { props.userDetail ? `(${props.userDetail.firstName} ${props.userDetail.lastName})` : '' } ?
                    </p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center border-0">
                    <Button 
                        variant="outline-success" 
                        className="btn-sm mr-5" 
                        onClick={ onDeleteModalClose }
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="outline-danger" 
                        className="btn-sm" 
                        onClick={ onDeleteButtonClick }
                    >
                        { props.userDetail && props.userDetail.status === 'ACTIVE' ? 'Deactive' : 'Active' }                        
                    </Button>
                </Modal.Footer>
            </Modal>

            <Tabs 
                defaultActiveKey={ defaultKey } 
                id="uncontrolled-tab-example"
                onSelect={ (key) => {
                    if (key === 'HostedAccommodations') {

                        // Set default key state
                        setDefaultKey(key);

                        // Set history
                        history.push(`/landlord-accommodations/${id}`);

                    } else {
                        // Set default key state
                        setDefaultKey(key);

                        // Set history
                        history.push(`/landlord-details/${id}`);
                    }
                } }
            >
                <Tab eventKey="landlordDetailss" title="Landlord Details">
                    <div>    
                        {
                            !props.loading ?
                                <Formik
                                    enableReinitialize
                                    initialValues={ modifiedInitialObj }
                                    validationSchema={ updateLandlordSchema }
                                    onSubmit={(values, { setSubmitting, resetForm }) => {  
                                        // Set submitting to true
                                        setSubmitting(true);
                                        const documents = [];

                                        if (values.document && values.document.length > 0) {
                                            // Update documents isKYCVerified field
                                            values.document.forEach(el => {
                                                if (el.hasOwnProperty('KYCVerified')) {
                                                    const isKYCVerified = el.KYCVerified;
                                                    documents.push({
                                                        ...el,
                                                        isKYCVerified
                                                    });

                                                } else {
                                                    documents.push(el);
                                                }
                                            });

                                            values.document = documents;
                                        }

                                        // Update contact number
                                        const updatedContact = `${code.value}-${values.contactNumber}`;
                                        
                                        // Send data to backend
                                        props.updateLandlordData(props.user._id, id, {
                                            ...values,
                                            contactNumber: updatedContact
                                        });
                
                                        setTimeout(() => {
                                            // Set submitting form to false
                                            setSubmitting(false);

                                            // Reset form to initial values
                                            resetForm(initialValues);
                
                                            // Go back to property management page                            
                                            history.push('/landlord-management');
                                        }, 3000);
                                    }}
                                >
                                    {({
                                        values, 
                                        errors, 
                                        touched, 
                                        isSubmitting,
                                        handleSubmit,
                                        setFieldValue
                                    }) => (
                                        <form onSubmit={ handleSubmit }>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-4 text-center">
                                                    <div className="pho_profile_pic_bx">
                                                        <div className="pho_pic_bx bg-gray-light">
                                                            {
                                                                values.profilePicture ? (
                                                                    <img 
                                                                        src={ values.profilePicture } 
                                                                        alt="Admin Pic" 
                                                                        className="w-100 h-100" 
                                                                    />
                                                                ) : (
                                                                    <div>
                                                                        <div className="pho_opacity_3 pho_plus pt-18 pb-10">+</div>
                                                                        <label className="pho_opacity_3 pho_14_500">Upload Photo</label>
                                                                    </div>
                                                                )
                                                            }
                                                            <input
                                                                type="file"
                                                                ref={ inputFile }
                                                                name="profilePicture"
                                                                id="upload-button"
                                                                accept=".png, .jpg, .jpeg"
                                                                // onChange={ handleImageChange }
                                                                onChange={ async (e) => {
                                                                    
                                                                    // Call handle image change function to upload image file
                                                                    await handleImageChange(e, setFieldValue);
                                                                } }
                                                            />
                                                        </div>
                                                        {touched.profilePicture && errors.profilePicture ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{errors.profilePicture}</div>
                                                            </div>
                                                        ) : null}
                                                        {
                                                            values.profilePicture &&
                                                                <div className="d-flex justify-content-center">   
                                                                    <button
                                                                        type="button"
                                                                        className="text-center btn btn-sm btn-outline-success pho_90 mr-1"
                                                                        onClick={ async (e) => {
                                                                            onButtonClick(e);
                                                                        } }
                                                                    >
                                                                        <span>EDIT</span>
                                                                    </button>          
                                                                    <button 
                                                                        type="button" 
                                                                        className="text-center btn btn-sm btn-outline-danger pho_90"
                                                                        onClick={ (e) => {
                                                                            // Remove image from state
                                                                            // remove(e);

                                                                            // Set field values
                                                                            setFieldValue('profilePicture', '');
                                                                        }} 
                                                                    >
                                                                        <span>REMOVE</span>
                                                                    </button>
                                                                </div>
                                                        }       
                                                    </div>                                    
                                                </div>
                                                <div className="col-md-12 col-lg-8">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group fv-plugins-icon-container">
                                                                <label className="pho_14_500">First Name<sup className="text-danger">*</sup></label>
                                                                <Field
                                                                    type="text"
                                                                    name="firstName"
                                                                    placeholder="First Name"
                                                                    className={`form-control pho_16_500 bg-gray-light border-0 h-auto py-5 px-6 `}
                                                                />
                                                                {touched.firstName && errors.firstName ? (
                                                                    <div className="fv-plugins-message-container">
                                                                        <div className="fv-help-block">{errors.firstName}</div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group fv-plugins-icon-container">
                                                                <label className="pho_14_500">Last Name<sup className="text-danger">*</sup></label>
                                                                <Field
                                                                    type="text"
                                                                    name="lastName"           
                                                                    placeholder="Last Name"
                                                                    className={`form-control pho_16_500 bg-gray-light border-0 h-auto py-5 px-6`}
                                                                />
                                                                {touched.lastName && errors.lastName ? (
                                                                    <div className="fv-plugins-message-container">
                                                                        <div className="fv-help-block">{errors.lastName}</div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group fv-plugins-icon-container">
                                                                <label className="pho_14_500">Contact Number<sup className="text-danger">*</sup></label>
                                                                <div className="d-flex">
                                                                    <div className="pho_contrycode">
                                                                        <Select
                                                                            required
                                                                            value={ code }
                                                                            classNamePrefix="pho"
                                                                            options={ phoneCodeOption }
                                                                            className="form-control grayField bg-gray-light h-auto px-0 border-0"
                                                                            onChange={ (e) => {

                                                                                // Call on phone code change
                                                                                onPhoneCodeChange(e);
                                                                            } }
                                                                        />
                                                                    </div>
                                                                    <Field
                                                                        type="text"
                                                                        placeholder="Contact Number"
                                                                        name="contactNumber" 
                                                                        className={`form-control pho_16_500 bg-gray-light border-0 h-auto py-5 px-6 `}
                                                                    />
                                                                    {touched.contactNumber && errors.contactNumber ? (
                                                                        <div className="fv-plugins-message-container">
                                                                            <div className="fv-help-block">{errors.contactNumber}</div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>                                            
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group fv-plugins-icon-container">
                                                                <label className="pho_14_500">Email<sup className="text-danger">*</sup></label>
                                                                <Field
                                                                    disabled
                                                                    type="email"
                                                                    name="emailId" 
                                                                    placeholder="Email"
                                                                    className={`form-control pho_16_500 bg-gray-light border-0 h-auto py-5 px-6 `}
                                                                />
                                                                {touched.emailId && errors.emailId ? (
                                                                    <div className="fv-plugins-message-container">
                                                                        <div className="fv-help-block">{errors.emailId}</div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 d-xl-flex d-md-flex d-lg-table align-items-start justify-content-between">
                                                            <div className="pb-3 pb-xl-0 w-260">
                                                                <div className="pho_14_500 mb-2">Uploaded Document</div>
                                                                <FieldArray name="document">
                                                                    {({ insert, remove, push }) => (
                                                                        <>
                                                                            {
                                                                                values.document && values.document.length > 0 ?
                                                                                    values.document.map((doc, index) => (
                                                                                        <div key={ index }>
                                                                                            <img
                                                                                                alt="..."
                                                                                                className="pho_doc"
                                                                                                src={ doc.doc_url ? doc.doc_url : toAbsoluteUrl("/media/custom/doc.png") }
                                                                                                onClick={ (e) => {
                                                                                                    if (doc.doc_url) {
                                                                                                        // Set image open state to true
                                                                                                        setImageOpen({
                                                                                                            show: true,
                                                                                                            index
                                                                                                        });
                                                                                                    }
                                                                                                } }
                                                                                            />

                                                                                            { imageOpen.show && imageOpen.index === index && (
                                                                                                <Lightbox
                                                                                                    mainSrc={ doc.doc_url }
                                                                                                    // nextSrc={images[(photoIndex + 1) % images.length]}
                                                                                                    // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                                                                                    onCloseRequest={ () => {
                                                                                                        // Set image open state to false
                                                                                                        setImageOpen({
                                                                                                            show: false,
                                                                                                            index: null
                                                                                                        });
                                                                                                    }}
                                                                                                    // onMovePrevRequest={() =>
                                                                                                    //     this.setState({
                                                                                                    //         photoIndex: (photoIndex + images.length - 1) % images.length,
                                                                                                    //     })
                                                                                                    // }
                                                                                                    // onMoveNextRequest={() =>
                                                                                                    //     this.setState({
                                                                                                    //         photoIndex: (photoIndex + 1) % images.length,
                                                                                                    //     })
                                                                                                    // }
                                                                                                />
                                                                                            ) }
                                                                                            
                                                                                            {
                                                                                                !doc.hasOwnProperty('isKYCVerified') ?
                                                                                                    <div className="d-flex align-items-center justify-content-center pt-4">
                                                                                                        <Form.Check
                                                                                                            inline
                                                                                                            type="radio"
                                                                                                            // checked={ doc.isKYCVerified.toString() === 'true' && true }
                                                                                                            value="approve"
                                                                                                            name={`radioButton-${index}`}
                                                                                                            className="phoApproveBtn"
                                                                                                            label="Approve"
                                                                                                            onChange={ (e) => {
                                                                                                                if (e.target.value === 'rejected') {
                                                                                                                    setFieldValue(`document.${index}.KYCVerified`, false);
                                                                                                                    // doc.KYCVerified = true;
                                                                                                                } else {
                                                                                                                    setFieldValue(`document.${index}.KYCVerified`, true);
                                                                                                                    // doc.KYCVerified = false;
                                                                                                                }
                                                                                                            } }
                                                                                                        />
                                                                                                        <Form.Check
                                                                                                            inline
                                                                                                            // checked={ doc.isKYCVerified.toString() === 'false' && true }
                                                                                                            value="rejected"
                                                                                                            type="radio"
                                                                                                            name={`radioButton-${index}`}
                                                                                                            className="phoRejectBtn"
                                                                                                            label="Reject"
                                                                                                            onChange={ (e) => {
                                                                                                                if (e.target.value === 'rejected') {
                                                                                                                    setFieldValue(`document.${index}.KYCVerified`, false);
                                                                                                                    // doc.KYCVerified = true;
                                                                                                                } else {
                                                                                                                    setFieldValue(`document.${index}.KYCVerified`, true);
                                                                                                                    // doc.KYCVerified = false;
                                                                                                                }
                                                                                                            } }
                                                                                                        /> 
                                                                                                    </div>
                                                                                                :
                                                                                                    doc.isKYCVerified ?
                                                                                                        <div className="d-flex align-items-center justify-content-center pt-4">
                                                                                                            <SVG src={toAbsoluteUrl("/media/custom/pho_approved.svg")}/>
                                                                                                        </div>
                                                                                                    :   
                                                                                                        <div className="d-flex align-items-center justify-content-center pt-4">
                                                                                                            <SVG src={toAbsoluteUrl("/media/custom/pho_rejected.svg")}/>
                                                                                                        </div> 
                                                                                            }
                                                                                        </div>
                                                                                    ))
                                                                                : 
                                                                                    <div>
                                                                                        No Document Available
                                                                                    </div>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </FieldArray>                                                    
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-end">  
                                                                <button 
                                                                    type="button"
                                                                    onClick={ onDeleteAccoutClick }                                               
                                                                    className={`btn btn-link pho_16_600 px-8 py-4 mb-5
                                                                        ${ props.landlordDetail && props.landlordDetail.status === 'ACTIVE' ? 'text-danger' : 'text-success' }
                                                                    `}
                                                                >
                                                                    {
                                                                        props.landlordDetail && props.landlordDetail.status === 'ACTIVE' ?
                                                                            <span>Deactive Account</span>
                                                                        :
                                                                            <span>Active Account</span>                                                                        
                                                                    }                                                                    
                                                                </button>
                                                                <button 
                                                                    type="submit"                                                
                                                                    className={`btn btn-success px-8 py-4 mb-5`}
                                                                    disabled={ isSubmitting }
                                                                >
                                                                    <span>Update</span>
                                                                </button>
                                                            </div>
                                                        </div>                                
                                                    </div>
                                                </div>
                                            </div>      
                                        </form> 
                                    )}
                                </Formik> 
                            :
                                <div id="compnent-splash-screen" className="compnent-splash-screen h-200px">
                                    <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                                        <circle 
                                            className="path"
                                            cx="25"
                                            cy="25"
                                            r="20"
                                            fill="none"
                                            strokeWidth="5"
                                        ></circle>
                                    </svg>
                                </div>
                        }                          
                    </div>
                </Tab>
                <Tab eventKey="HostedAccommodations" title="Hosted Accommodations">
                    {/* <Dropdown alignRight className="phoTopRight">
                        <Dropdown.Toggle variant="link" className="text-success" id="dropdown-basic">
                            Download
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Excel</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">PDF</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                    <Row className="phoGap14">
                        {
                            !props.accommodationLoading && props.hostedAccommodations && props.hostedAccommodations.recordsTotal > 0 ?
                                props.hostedAccommodations.records.map((el, index) => {
                                    let amount = 0;
        
                                    if (el.price && el.price.length > 0) {
                                        el.price.forEach(el => {
                                            amount += el.rate;
                                        });
                                    }

                                    return (
                                        <Col xl={ 6 } key={ index }>
                                            <div className="pho_bx1">
                                                <div className="left">
                                                    <img
                                                        className="phoimgSize120"
                                                        src={toAbsoluteUrl("/media/custom/1.jpg")}
                                                        alt="..."
                                                    />
                                                    <div className="leftInRight">
                                                        <div className="pho_16_400 pho_ellips">
                                                            { el.name.en }
                                                        </div>
                                                        {/* <div className="pho_14_400">From Date: { moment(el.fromDate).format('L') } | { Math.ceil(el.days) } Days</div> */}
                                                        <div className="pho_14_400 mb-2">
                                                            {/* Location: { el.address.flat } { el.address.streetAddress }, { el.address.city }, { el.address.country }, { el.address.postcode } */}
                                                            Location: { el.address.city }, { el.address.country }
                                                        </div>
                                                        <div className="pho_14_600">Price: $ { amount.toFixed(2) }</div>
                                                        {/* <div className="pho_14_400">By { el.landlordDetails.firstName } { el.landlordDetails.lastName }</div> */}
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="pho_detailMark pho_14_400">{ el.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' }</div>
                                                    <div>
                                                        <Switch
                                                            // value="active"
                                                            color="primary"
                                                            checked={ el.status === 'ACTIVE' }
                                                            onChange={ (e) => {
                                                                const skip = (props.skip - 1) * props.limit;

                                                                // Accommodation status change function
                                                                props.accommodationStatusChange(
                                                                    props.user._id, 
                                                                    el._id,
                                                                    undefined,
                                                                    undefined,
                                                                    skip,
                                                                    props.limit,
                                                                    undefined   ,
                                                                    { hostedAccommodations: true },
                                                                    id
                                                                );
                                                            } }
                                                        />
                                                        <ItemDropdown 
                                                            item="" 
                                                            doc={ el }
                                                            view={ onViewButtonHandler }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })
                            :
                                props.accommodationLoading ?
                                    <div id="compnent-splash-screen" className="compnent-splash-screen h-200px">
                                        <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                                            <circle 
                                                className="path"
                                                cx="25"
                                                cy="25"
                                                r="20"
                                                fill="none"
                                                strokeWidth="5"
                                            ></circle>
                                        </svg>
                                    </div>
                                :
                                    <div>
                                        No Data Available
                                    </div>
                        }
                    </Row>
                </Tab>            
            </Tabs>            
            {
                !props.accommodationLoading && props.hostedAccommodations && props.hostedAccommodations.recordsTotal > 0 && location.pathname !== `/landlord-details/${id}` &&
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                        <div className="pho_14_400 pho_opacity_5">
                            Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.hostedAccommodations.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.hostedAccommodations.recordsTotal } of { props.hostedAccommodations.recordsTotal } records
                        </div>
                        <Pagination>
                            {/* Go to first page */}
                            <Pagination.First
                                disabled={ props.skip === 1 } 
                                onClick={ goToFirstPage }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                            </Pagination.First>

                            {/* Go to previous page */}
                            <Pagination.Prev
                                disabled={ props.skip === 1 } 
                                onClick={ prevPageHandler }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                            </Pagination.Prev>

                            {/* All pages */}
                            {
                                pageNumbers.map((number, i) => {
                                    if (
                                        number >= parseInt(props.skip) - 3 &&
                                        number <= parseInt(props.skip) + 3 
                                    ) {
                                        return (
                                            <Pagination.Item
                                                key={ i }
                                                id={ number }
                                                active={ number === props.skip }
                                                onClick={ pageChangeHandler }
                                            >
                                                { number }
                                            </Pagination.Item>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }

                            {/* Go to next page */}
                            <Pagination.Next
                                disabled={ props.skip === totalPages } 
                                onClick={ nextPageHandler }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                            </Pagination.Next>

                            {/* Go to last page */}
                            <Pagination.Last
                                disabled={ props.skip === totalPages } 
                                onClick={ goToLastPage }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                            </Pagination.Last>
                        </Pagination>
                    </div>
            }
            {/* <div className="d-flex flex-wrap justify-content-end align-items-center mt-5">
                <Pagination>
                <Pagination.First><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/></Pagination.First>
                <Pagination.Prev><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/></Pagination.Prev>
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Next><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/></Pagination.Next>
                <Pagination.Last><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/></Pagination.Last>
                </Pagination>
            </div> */}
        </div>            
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        skip: state.accommodation.skip,
        loading: state.landlord.loading,
        limit: state.accommodation.limit,
        landlordDetail: state.landlord.landlordDetail,
        accommodationLoading: state.accommodation.loading,
        hostedAccommodations: state.accommodation.hostedAccommodations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeLandlordId: () => dispatch(landlordActions.removeLandlordId()),
        updateSkip: (skip) => dispatch(accommodationActions.updateSkip(skip)),
        removeLandlordData: () => dispatch(landlordActions.removeLandlordData()),
        accommodationInit: () => dispatch(accommodationActions.accommodationInit()),
        addLandlordId: (landlordId) => dispatch(landlordActions.addLandlordId(landlordId)),
        getLandlord: (adminId, landlordId) => dispatch(landlordActions.fetchLandlord(adminId, landlordId)),
        deleteLandlordData: (adminId, landlordId) => dispatch(landlordActions.deleteLandlordData(adminId, landlordId)),
        getLandlordAccommodations: (
            adminId, 
            id,
            column,
            dir,
            skip,
            limit
        ) => dispatch(accommodationActions.fetchHostedAccommodation(adminId, id, column, dir, skip, limit)),        
        updateLandlordData: (
            adminId, 
            landlordId, 
            updateData
        ) => dispatch(landlordActions.updateLandlordData(adminId, landlordId, updateData)),
        accommodationStatusChange: (
            adminId,
            accommodationId,
            column,
            dir,
            skip,
            limit,
            type,
            condition,
            id
        ) => dispatch(accommodationActions.accommodationStatusChange(adminId, accommodationId, column, dir, skip, limit, type, condition, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandlordDetails);

// {/* <Col xl={6}>
//     <div className="pho_bx1">
//         <div className="left">
//             <img
//                 className="phoimgSize120"
//                 src={toAbsoluteUrl("/media/custom/1.jpg")}
//                 alt="..."
//             />
//             <div className="leftInRight">
//                 <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
//                 <div className="pho_14_400">From Date: 05/02/2020 | 20 Days | 50 Desks</div>
//                 <div className="pho_14_400 mb-2">Location: New York, NY</div>
//                 <div className="pho_14_600">Price: $ 2451.81</div>
//                 <div className="pho_14_400">By UserName</div>
//             </div>
//         </div>
//         <div className="right">
//             <div className="pho_detailMark pho_14_400">Workplace</div>
//             <div>
//                 <Switch
//                     checked={state.checkedB}
//                     onChange={handleChange('checkedB')}
//                     value="active"
//                     color="primary"
//                 />
//                 <ItemDropdown item="" />
//             </div>
//         </div>
//     </div>
// </Col>
// <Col xl={6}>
//     <div className="pho_bx1">
//         <div className="left">
//             <img
//                 className="phoimgSize120"
//                 src={toAbsoluteUrl("/media/custom/1.jpg")}
//                 alt="..."
//             />
//             <div className="leftInRight">
//                 <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
//                 <div className="pho_14_400">From Date: 05/02/2020 | 20 Days | 50 Desks</div>
//                 <div className="pho_14_400 mb-2">Location: New York, NY</div>
//                 <div className="pho_14_600">Price: $ 2451.81</div>
//                 <div className="pho_14_400">By UserName</div>
//             </div>
//         </div>
//         <div className="right">
//             <div className="pho_detailMark pho_14_400">Workplace</div>
//             <div>
//                 <Switch
//                     checked={state.checkedB}
//                     onChange={handleChange('checkedB')}
//                     value="active"
//                     color="primary"
//                 />
//                 <ItemDropdown item="" />
//             </div>
//         </div>
//     </div>
// </Col>
// <Col xl={6}>
//     <div className="pho_bx1">
//         <div className="left">
//             <img
//                 className="phoimgSize120"
//                 src={toAbsoluteUrl("/media/custom/1.jpg")}
//                 alt="..."
//             />
//             <div className="leftInRight">
//                 <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
//                 <div className="pho_14_400">From Date: 05/02/2020 | 20 Days | 50 Desks</div>
//                 <div className="pho_14_400 mb-2">Location: New York, NY</div>
//                 <div className="pho_14_600">Price: $ 2451.81</div>
//                 <div className="pho_14_400">By UserName</div>
//             </div>
//         </div>
//         <div className="right">
//             <div className="pho_detailMark pho_14_400">Workplace</div>
//             <div>
//                 <Switch
//                     checked={state.checkedB}
//                     onChange={handleChange('checkedB')}
//                     value="active"
//                     color="primary"
//                 />
//                 <ItemDropdown item="" />
//             </div>
//         </div>
//     </div>
// </Col> */}
