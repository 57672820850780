import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { 
    getDashboardCounts
} from '../dashboardCrud';

// Get all dashboard conts functions
export function* fetchingDashboardCounts(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield getDashboardCounts(action.userId, action.year);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.dashboardCountsFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};