/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import {toAbsoluteUrl} from "../../../_helpers";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function AdvanceTablesWidget4(props) {

  let pendingLandlords = [];

  if (props.pendingLandlords && props.pendingLandlords.length > 0) {
    props.pendingLandlords.forEach((pendingLandlord, index) => {
      if (!pendingLandlord.hasOwnProperty('isAccountVerified')) {

        pendingLandlords.push(
          <li key={ index }>
            <div className="left d-flex">
              <div className="symbol symbol-60 pho_rounded_12">
                <span className="symbol-label">
                  <img
                    src={ pendingLandlord.profilePicture }
                    className="h-100 align-self-center"
                    alt="..."
                  />
                </span>
              </div>
              <div className="content">
                {/* <div className="pho_14_400 text-success text-uppercase">{ accommodation.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' }</div>  */}
                <div className="pho_16_400 text-dark pho_ellips">
                  { pendingLandlord.firstName } { pendingLandlord.lastName }
                </div>
                <div className="pho_14_400 text-dark pho_ellips">
                { pendingLandlord.emailId }
                </div>
              </div>
            </div>
            {/* <div className="text-right right">
              <span className="text-nowrap text-dark pho_14_600 d-block">
                Price: $ { amount.toFixed(2) }
              </span>
              <span className="pho_14_400 pho_ellips">By { accommodation.landlordDetails.firstName } { accommodation.landlordDetails.lastName }</span>
            </div> */}
            <div className="last pl-2">
              <Button 
                variant="link" 
                className="p-0"
                onClick={ (e) => {
                  const isAccountVerified = { isAccountVerified: true };

                  // Accept user request
                  props.updateUser(
                    props.adminId,
                    pendingLandlord._id,
                    isAccountVerified,
                    true
                  );
                } }
              >
                <SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")} />
              </Button>
              <Button 
                variant="link" 
                className="p-0 ml-2"
                onClick={ (e) => {
                  const isAccountVerified = { isAccountVerified: false };

                  // Reject user request
                  props.updateUser(
                    props.adminId,
                    pendingLandlord._id,
                    isAccountVerified,
                    true
                  );
                } }
              >
                <SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")} />
              </Button>
            </div>
          </li>
        );
      }
    });
  }

  return (
    <div className={`card card-custom ${props.className}`}>
      {/* Head */}
      <div className="card-header border-0 align-items-center">
        <h3 className="card-title pho_18_600 text-dark m-0">
          Lanlord Request
        </h3>
        {/* <Link to="/accommodation-request" className="pho_16_400 text-success text-uppercase">More</Link> */}
      </div>
      {/* Body */}
      <div className="card-body p-0">
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: 340, maxWidth:"100%", position: "relative" }}
      >
        <ul className="list-unstyled pho_accomodation_dashboard_tbl pho_accomodationRequest_dashboard_tbl m-0">
          { 
            pendingLandlords && pendingLandlords.length > 0 ?
              pendingLandlords
            :
              <li>
                No Data Available
              </li>
          }
        </ul>
      </PerfectScrollbar>
      </div>
    </div>
  );
}


// {/* <li>
//   <div className="left d-flex">
//     <div className="symbol symbol-60 pho_rounded_12">
//       <span className="symbol-label">
//         <img
//           src={toAbsoluteUrl("/media/custom/1.jpg")}
//           className="h-100 align-self-center"
//           alt="..."
//         />
//       </span>
//     </div>
//     <div className="content">
//       <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//       <div className="pho_16_400 text-dark pho_ellips">
//         Neque porro quisquam est qui dolorem
//       </div>
//       <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//     </div>
//   </div>
//   <div className="text-right right">
//     <span className="text-nowrap text-dark pho_14_600 d-block">
//       Price: $ 2451.81
//     </span>
//     <span className="pho_14_400 pho_ellips">By UserName</span>
//   </div>
//   <div className="last pl-2">
//       <Button variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
//       <Button variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
//   </div>
// </li>
// <li>
//   <div className="left d-flex">
//     <div className="symbol symbol-60 pho_rounded_12">
//       <span className="symbol-label">
//         <img
//           src={toAbsoluteUrl("/media/custom/1.jpg")}
//           className="h-100 align-self-center"
//           alt="..."
//         />
//       </span>
//     </div>
//     <div className="content">
//       <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//       <div className="pho_16_400 text-dark pho_ellips">
//         Neque porro quisquam est qui dolorem
//       </div>
//       <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//     </div>
//   </div>
//   <div className="text-right right">
//     <span className="text-nowrap text-dark pho_14_600 d-block">
//       Price: $ 2451.81
//     </span>
//     <span className="pho_14_400 pho_ellips">By UserName</span>
//   </div>
//   <div className="last pl-2">
//       <Button variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
//       <Button variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
//   </div>
// </li>
// <li>
//   <div className="left d-flex">
//     <div className="symbol symbol-60 pho_rounded_12">
//       <span className="symbol-label">
//         <img
//           src={toAbsoluteUrl("/media/custom/1.jpg")}
//           className="h-100 align-self-center"
//           alt="..."
//         />
//       </span>
//     </div>
//     <div className="content">
//       <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//       <div className="pho_16_400 text-dark pho_ellips">
//         Neque porro quisquam est qui dolorem
//       </div>
//       <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//     </div>
//   </div>
//   <div className="text-right right">
//     <span className="text-nowrap text-dark pho_14_600 d-block">
//       Price: $ 2451.81
//     </span>
//     <span className="pho_14_400 pho_ellips">By UserName</span>
//   </div>
//   <div className="last pl-2">
//       <Button variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
//       <Button variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
//   </div>
// </li>
// <li>
//   <div className="left d-flex">
//     <div className="symbol symbol-60 pho_rounded_12">
//       <span className="symbol-label">
//         <img
//           src={toAbsoluteUrl("/media/custom/1.jpg")}
//           className="h-100 align-self-center"
//           alt="..."
//         />
//       </span>
//     </div>
//     <div className="content">
//       <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//       <div className="pho_16_400 text-dark pho_ellips">
//         Neque porro quisquam est qui dolorem
//       </div>
//       <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//     </div>
//   </div>
//   <div className="text-right right">
//     <span className="text-nowrap text-dark pho_14_600 d-block">
//       Price: $ 2451.81
//     </span>
//     <span className="pho_14_400 pho_ellips">By UserName</span>
//   </div>
//   <div className="last pl-2">
//       <Button variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
//       <Button variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
//   </div>
// </li> */}