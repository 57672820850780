/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import * as subHeaderAction from "../../../../app/modules/Subheader";
import FilterActions from "./components/FilterActions";
import FilterActions2 from "./components/FilterActions2";
import DashboardFilter from "./components/DashboardFilter";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";

function SubHeader(props) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  let history = useHistory();

  // State
  const [link, setLink] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const [filter2Show, setFilter2Show] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showDashFilter, setShowDashFilter] = useState(false);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  // On cancel button click handler
  const onCancelButtonHandler = (e) => {
    e.preventDefault();

    setTimeout(() => {
      // Go back to previous page
      history.push("/dashboard");
    }, 500);
  };

  // On back button click handler
  const onBackButtonHandler = (e) => {
    e.preventDefault();

    setTimeout(() => {
      if (
        location.pathname === `/user-details/${props.userId}` ||
        location.pathname === `/user-accommodations/${props.userId}`
      ) {
        // Go to specific routes
        history.push("/user-management");
      } else if (
        location.pathname === `/landlord-details/${props.landlordId}` ||
        location.pathname === `/landlord-accommodations/${props.landlordId}`
      ) {
        // Go to specific routes
        history.push("/landlord-management");
      } else {
        // Go back to previous page
        history.goBack();
      }
    }, 500);
  };

  // On add new click handler function
  const onAddNewClickHandler = () => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);

    switch (aside.title) {
      case "User Management":
        history.push("/add-user");
        break;

      case "Landlord Management":
        history.push("/add-landlord");
        break;

      case "Template Management":
        history.push("/add-template");
        break;

      case "Tax Management":
        // Call add new function
        props.addNew(true);
        break;

      case "Invitation Link":
        // Call add new function
        props.addNew(true);
        break;

      default:
        setLink("");
        break;
    }
  };

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {
    // For cancel button
    if (location.pathname === "/profile") {
      // Set show cancel button state to true
      setShowCancelButton(true);
    } else {
      // Set show cancel button state to false
      setShowCancelButton(false);
    }

    // For back button
    if (
      location.pathname === "/add-user" ||
      location.pathname === `/user-details/${props.userId}` ||
      location.pathname === `/user-accommodations/${props.userId}` ||
      location.pathname === "/add-landlord" ||
      location.pathname === `/landlord-details/${props.landlordId}` ||
      location.pathname === `/landlord-accommodations/${props.landlordId}` ||
      location.pathname === `/accommodation-details/${props.accommodationId}` ||
      location.pathname === `/support-details/${props.queryId}` ||
      location.pathname === `/about-us/${props.cmsId}` ||
      location.pathname === `/cancellation-policy/${props.cmsId}` ||
      location.pathname === `/how-we-works/${props.cmsId}` ||
      location.pathname === `/privacy-policy/${props.cmsId}` ||
      location.pathname === `/terms-conditions/${props.cmsId}` ||
      location.pathname === `/trust-safety/${props.cmsId}` ||
      location.pathname === "/add-template" ||
      location.pathname === `/edit-template/${props.templateId}` ||
      location.pathname === `/user-payment-details/${props.landlordId}`
    ) {
      // Set show back button state to true
      setShowBackButton(true);
    } else {
      // Set show back button state to true
      setShowBackButton(false);
    }

    // For add new button
    if (
      location.pathname === "/user-management" ||
      location.pathname === "/landlord-management" ||
      location.pathname === "/template-management" ||
      location.pathname === "/tax-management" ||
      location.pathname === "/invitation-link"
    ) {
      // Set show add new button state to true
      setShowButton(true);
    } else {
      // Set show add new button state to true
      setShowButton(false);
    }

    // For filter
    if (
      location.pathname === "/user-management" ||
      location.pathname === "/landlord-management" ||
      location.pathname === "/accommodation-management" ||
      location.pathname === "/accommodation-request" ||
      // location.pathname === '/master-management' ||
      location.pathname === "/support"
    ) {
      // Set show filter state to true
      setFilterShow(true);
    } else {
      // Set show filter state to false
      setFilterShow(false);
    }

    // For filter2
    if (
      location.pathname === "/booking-management" ||
      location.pathname === "/payment-received-by-users"
    ) {
      // Set show filter state to true
      setFilter2Show(true);
    } else {
      // Set show filter state to false
      setFilter2Show(false);
    }

    // For dashboard filter
    if (location.pathname === "/dashboard") {
      // Set show dash filter
      setShowDashFilter(true);
    } else {
      // Set show dash filter
      setShowDashFilter(false);
    }
  }, [subheader, location.pathname]);

  return (
    <div
      id="kt_subheader"
      className={`subheader pt-7   ${layoutProps.subheaderCssClasses}`}
    >
      {showBackButton && (
        <button
          type="button"
          className="btn btn-link text-dark pho_14_600 pho_back_btn"
          onClick={onBackButtonHandler}
        >
          BACK
        </button>
      )}

      <div
        className={`
            ${layoutProps.subheaderContainerCssClasses} d-flex align-items-start justify-content-between flex-wrap flex-sm-nowrap
          `}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-center mr-5">
            <h5 className="text-dark pho_24_600 my-2 mr-5">
              <>{subheader.title}</>
              {/*<small></small>*/}
            </h5>
            {showButton && (
              // <Link to={link} className="text-nowrap pho_16_500 btn btn-link text-success ml-3">
              // </Link>
              <button
                className="text-nowrap pho_16_500 btn btn-link text-success ml-3"
                onClick={onAddNewClickHandler}
              >
                ADD NEW
              </button>
            )}
          </div>

          {/* <BreadCrumbs items={subheader.breadcrumbs} /> */}
        </div>

        {/* Toolbar */}
        {filterShow && (
          <div className="d-flex flex-wrap pho_filter_wraper justify-content-end align-items-center">
            <FilterActions />
          </div>
        )}

        {/* Toolbar */}
        {filter2Show && (
          <div className="d-flex flex-wrap pho_filter_wraper phofilter2 justify-content-end align-items-center">
            <FilterActions2 />
          </div>
        )}
        {showDashFilter && <DashboardFilter />}
        {showCancelButton && (
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={onCancelButtonHandler}
          >
            <span className="px24 mr-1">&times;</span>
            <span>CANCEL</span>
          </button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cmsId: state.cms.cmsId,
    userId: state.user.userId,
    queryId: state.support.queryId,
    landlordId: state.landlord.landlordId,
    templateId: state.template.templateId,
    accommodationId: state.accommodation.accommodationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNew: (value) => dispatch(subHeaderAction.addNew(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
