import * as Yup from "yup";

// Phone number validation regex
const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

// Password validation regex
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;

// Property validation schema
export const adminSchema = Yup.object().shape({
    // firstName: Yup.string()
    //     .trim()
    //     .min(2, "Please enter valid First name")
    //     .max(50, "Please enter valid First name")
    //     .required('Please enter First name'),
    // lastName: Yup.string()
    //     .trim()
    //     .min(2, "Please enter valid Last name")
    //     .max(50, "Please enter valid Last name")
    //     .required('Please enter Last name'),
    firstName: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter First name")
        .matches(/^[^<>*.!@#?%$]*$/, 'Please enter valid First name')
        .test('First name', 'Please enter valid First name', (value) => {
            if (!/^\d+$/.test(value)) {        
                return true;
            }
        })
        .required('Please enter First name'),
    lastName: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter Last name")
        .matches(/^[^<>*.!@#?%$]*$/, 'Please enter valid Last name')
        .test('Last name', 'Please enter valid Last name', (value) => {
            if (!/^\d+$/.test(value)) {        
                return true;
            }
        })
        .required('Please enter Last name'),
    contactNumber: Yup.string()
        .matches(rePhoneNumber, 'Please enter valid Contact number')
        .required('Please enter Contact number'),
    profilePicture: Yup.string()
        .required('Profile picture is required')
});

// Initial value
export const initialValues = {
  firstName: '',
  lastName: '',
  contactNumber: '',
  profilePicture: '' ,
//   key: ''
};

// Initial value
export const changePasswordInitialValues = {
    oldPassword: ''    ,
    newPassword: '',
    confirmPassword: ''
};

// Property validation schema
export const changePasswordSchema = Yup.object().shape({
    // oldPassword: Yup.string()
    //     .min(6, 'Please enter valid Old password')
    //     .required('Please enter Old password'),
    // newPassword: Yup.string()
    //     .min(6, 'Please enter valid New password')
    //     .required('Please enter New password'),
    // confirmPassword: Yup.string()
    //     .min(6, 'Please enter valid Confirm password')
    //     .required('Please enter Confirm password')      
    //     .when("newPassword", {
    //         is: val => (val && val.length > 0 ? true : false),
    //         then: Yup.string().oneOf(
    //         [Yup.ref("newPassword")],
    //         "Confirm password does not match with New password"
    //         )
    //     })          
    oldPassword: Yup.string()
        .trim()
        .min(6, 'Please enter valid Old password')
        .required('Please enter Old password'),
    newPassword: Yup.string()
        .trim()
        .min(6, 'Please enter valid New password')
        .max(32, 'Please enter valid New password')
        .matches(
            passwordRegex,
            "Password should contain min 6 and max 32 characters with at least one Alphanumerical and Special character"
            // "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required('Please enter New password'),
    confirmPassword: Yup.string()
        .trim()
        .min(6, 'Please enter valid Confirm password')
        .max(32, 'Please enter valid Confirm password')
        .matches(
            passwordRegex,
            "Password should contain min 6 and max 32 characters with at least one Alphanumerical and Special character"
            // "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )       
        .required('Please enter Confirm password')      
        .when("newPassword", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("newPassword")],
                "Confirm password does not match with New password"
            )
        })
});