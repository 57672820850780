import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    queryReply,
    getAllQueries,
    getQueryDetails,
    updateQueryStatus,
} from '../supportCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all queries function
export function* fetchingAllQueries(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield getAllQueries(
            action.adminId,
            action.column, 
            action.dir, 
            action.skip, 
            action.limit,
            action.status
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allQueryFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get user function
export function* fetchingQuery(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield getQueryDetails(action.adminId, action.queryId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.queryFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Update user data function
export function* replayingQuery(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield queryReply(action.adminId, action.queryId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // // Store dashboard data into store
        // yield put(actions.fetchAllUsers(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Message replied successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// // Delete user function
// export function* deletingUser(action) {
//     try {
//         // // Call fetch start to displaying loading spinner
//         // yield put(actions.fetchStart());

//         // Call function to fetch properties
//         const result = yield deleteUser(action.adminId, action.userId);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }
        
//         // // Store dashboard data into store
//         // yield put(actions.fetchAllUsers(action.adminId));

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//         // Notification
//         notify('success', 'User updated successfully');

//     } catch (err) {
//         console.log(err);

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };

// Change user status function
export function* changingQueryStatus(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield updateQueryStatus(action.adminId, action.queryId, action.value);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        if (action.supportDetails) {
            yield put(actions.fetchQuery(
                action.adminId,
                action.supportDetailsQueryId
            ));

        } else {
            // Store dashboard data into store
            yield put(actions.fetchAllQueries(
                action.adminId,
                action.column, 
                action.dir, 
                action.skip, 
                action.limit,
                action.status
            ));
        }
        

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Query status updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// // Add user function
// export function* addingUser(action) { 
//     try {
//         // // Call fetch start to displaying loading spinner
//         // yield put(actions.fetchStart());

//         // Call function to fetch properties
//         const result = yield addUser(action.adminId, action.values);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }
        
//         // // Store dashboard data into store
//         // yield put(actions.fetchAllUsers(action.adminId));

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//         // Notification
//         notify('success', 'User added successfully');

//     } catch (err) {
//         console.log(err);

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };