import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    dir: null,
    type: null,
    error: null,
    find: false,
    search: null,
    column: null,
    status: null,
    toDate: null,
    sorting: null,
    addNew: false,
    fromDate: null,
    findClicked: false,
    year: new Date().getFullYear()
};

// Reducer
export const subheaderReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SUBHEADER_SEARCH:
            return {
                ...state,
                search: action.search
            };
        case actionTypes.SUBHEADER_SORTING:
            return {
                ...state,
                column: action.column,
                dir: action.dir
            };
        case actionTypes.SUBHEADER_INIT:
            return {
                ...initialState
            };
        case actionTypes.SUBHEADER_TYPE:
            return {
                ...state,
                type: action.typeValue
            };
        case actionTypes.SUBHEADER_STATUS:
            return {
                ...state,
                status: action.status
            };
        case actionTypes.SUBHEADER_FROM_DATE:
            return {
                ...state,
                fromDate: action.fromDate
            };
        case actionTypes.SUBHEADER_TO_DATE:
            return {
                ...state,
                toDate: action.toDate
            };
        case actionTypes.UPDATE_FIND:
            return {
                ...state,
                find: action.value
            };
        case actionTypes.FIND_CLICKED:
            return {
                ...state,
                findClicked: action.value
            };
        case actionTypes.YEAR_UPDATE:
            return {
                ...state,
                year: action.year
            };
        case actionTypes.ADD_NEW:
            return {
                ...state,
                addNew: action.value
            };
        default:
            return state;
    };
};