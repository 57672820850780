import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";

import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

// Initial value
const initialValues = {
  emailId: "",
};

function ForgotPassword(props) {
  // Props
  const { intl } = props;

  // State
  const [isRequested, setIsRequested] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    emailId: Yup.string()
      .email("Please enter valid Email")
      .min(3, "Please enter valid Email")
      .max(50, "Please enter valid Email")
      .required('Please enter Email'),
  });

  // Get input class function
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {      
      initialValues.emailId = values.emailId;

      // Sent request to get new password
      requestPassword(values.emailId)
        .then(res => {

          // If error found in response then throw new error
          if (res.data.responseCode !== 200) {
            throw new Error(res.data.responseMessage);
          }          

          // Set is requested to true
          setIsRequested(true);

          // Set initial value of emailId
          initialValues.emailId = '';
        })
        .catch(err => {
          console.log('err', err);

          // Set is requested to false
          setIsRequested(false);

          // Set submitting
          setSubmitting(false);

          // Set initial value of emailId
          initialValues.emailId = '';

          if (err.message) {
            // Set error message to status
            setStatus(err.message);

          } else {
            // Set validation error to status
            setStatus(
              intl.formatMessage(
                { id: "AUTH.VALIDATION.NOT_FOUND" },
                { name: values.emailId }
              )
            );
          }
        });
    },
  });

  return (
    <>
      {
        !isRequested && (
          <div className="bg-white flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              {/*begin::Content header*/}
              <div className="position-absolute top-0 right-20 mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="pho_18_500">Having issues?</span>
                <Link to="/auth/login" className="pho_18_500 ml-2 text-success" id="kt_login_signup">Get Help</Link>
              </div>
              {/*end::Content header*/}          

              <div className="login-form login-forgot" style={{ display: "block" }}>
                <div className="mb-10 mb-lg-20">
                  <img
                    alt="Logo"
                    className="max-h-70px mb-30"
                    src={toAbsoluteUrl("/media/logos/pho_logo.svg")}
                  />
                  <h3 className="pho_26_600">Forgotten Password ?</h3>
                  <div className="text-muted pho_18_500">
                    Enter your email to reset your password
                  </div>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                  {/* begin: Alert */}
                  {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">
                        {formik.status}
                      </div>
                    </div>
                  )}
                  {/* end: Alert */}
                  <div className="form-group fv-plugins-icon-container">
                  <label className="pho_14_500">Email</label>
                    <input
                      type="email"
                      className={`bg_light_gray form-control border-0 h-auto py-5 px-6 ${getInputClasses(
                        "email"
                      )}`}
                      name="emailId"
                      {...formik.getFieldProps("emailId")}
                    />
                    {formik.touched.emailId && formik.errors.emailId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.emailId}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between">
                    <Link to={ `/auth/login` }>
                      <button
                        type="button"
                        id="kt_login_forgot_cancel"
                        className="btn btn-outline-success px-8 py-4 my-3"
                      >
                        Cancel
                      </button>
                    </Link>
                    <button
                      id="kt_login_forgot_submit"
                      type="submit"
                      className="btn btn-success px-8 py-4 my-3 mr-4"
                      disabled={formik.isSubmitting}
                    >
                      Submit
                    </button>
                    
                  </div>
                </form>
              </div>
            </div>    
          </div>
        )
      }

      {/*bellow design after submit*/}

      {
        isRequested && 
          <div className="m-auto text-center mx-width-532">
            <img
              alt="Logo"
              className="max-h-80px mb-10"
              src={toAbsoluteUrl("/media/custom/pho_success_icon.svg")}
            />
              <div className="pho_title26">Instructions Sent</div>
              <div className="text-muted pho_subtitle18">
                We have sent the instructions on { initialValues.emailId }. Please check and follow the steps.
              </div>
          </div>
      }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
