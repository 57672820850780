import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    fetchingDashboardCounts
} from './saga';

export function* watchDashboard() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.DASHBOARD_COUNT_FETCH, fetchingDashboardCounts)
    ]);
};