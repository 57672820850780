import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from 'react-router-dom';
import { Table, Pagination, Modal } from "react-bootstrap";
import { Switch } from '@material-ui/core';
import SVG from "react-inlinesvg";
import { Formik, FieldArray } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import FuzzySearch from 'fuzzy-search';

import { notify } from '../toaster';
import { toAbsoluteUrl } from "../../_helpers";
import { initialLandlordTableValues } from './landlordSchema';
import * as landlordAction from '../../../app/modules/Landlord';
import * as subheaderAction from '../../../app/modules/Subheader';
import ItemDropdown from './ItemDropdown';

import 'react-toastify/dist/ReactToastify.min.css';

const LandlordManagement = (props) => {
    const history = useHistory();
    const pageNumbers = [];
    let totalPages;
    let result = [];
    let skipInit = props.skip;

    const modifiedInitialTabObj = Object.assign({}, initialLandlordTableValues);
    
    // State
    const [deleteModal, setDeleteModal] = useState(false);
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });
    const [user, setUser] = useState({
        _id: '',
        status: '',
        emailId: '',
        lastName: '',
        firstName: '',
        createdAt: '',
        contactNumber: ''        
    });
    const [commonDelete, setCommonDelete] = useState({
        count: 0,
        value: false
    });
    
    // const handleChange = name => event => {
    //     setState({ ...state, [name]: event.target.checked });
    // };

    // Show delete modal
    const showDeleteModal = (e, user, multiple) => {
        // Show delete modal
        setDeleteModal(true);

        if (multiple) {
            // Set user state
            setUser({
                values: user,
                multiple
            });

        } else {
            // Set user state
            setUser({
                _id: user._id,
                status: user.status,
                emailId: user.emailId,
                lastName: user.lastName,
                firstName: user.firstName,
                createdAt: user.createdAt,
                contactNumber: user.contactNumber
            });
        }
    };

    // Hide delete modal function
    const hideDeleteModal = (e) => {
        // hide delete modal
        setDeleteModal(false);

        // Set user state
        setUser({
            _id: '',
            status: '',
            emailId: '',
            lastName: '',
            firstName: '',
            createdAt: '',
            contactNumber: '' 
        });
    };

    // On delete button click
    const onDeleteButtonClick = async (e) => {
        // if (todo.multiple) {
        //     // check if common delete state is true
        //     if (commonDelete.count > 0) {
        //         setCommonDelete({
        //             count: 0,
        //             value: false
        //         });
        //     }  

        //     // Make deleting selected doc request
        //     await props.deleteMultipleTodos(
        //         todo.values, 
        //         props.sort, 
        //         props.currentPage, 
        //         props.limit,
        //         props.filter,
        //         props.tabKey,
        //         props.selectedDate
        //     );

        //     if (todo.values.todos.length > 0) {
        //         const array = [];

        //         todo.values.todos.forEach(async el => {
        //             array.push(el.property);
        //         });

        //         const sorted_array = array.sort((a, b) => {
        //             return a.localeCompare(b);
        //         });

        //         let uniqueList = [];
        //         let dupList = [];
                
        //         Array.prototype.contains = function(item) {
        //             let filtered_item = this.filter((i) => {
        //                 return i.type === item.type;
        //             });
        //             return !!filtered_item.length;
        //         };
                
        //         function pushToUniqueList(item){
        //             if(!uniqueList.contains(item)) uniqueList.push(item);
        //         }
                
        //         function pushToDuplicateList(item){
        //             if(!dupList.contains(item)) dupList.push(item);
        //         }
                
        //         for (let i = 0; i < sorted_array.length; i++){
        //             if(uniqueList.contains(sorted_array[i])){
        //                 pushToDuplicateList(sorted_array[i]);
        //             } else {
        //                 pushToUniqueList(sorted_array[i]);
        //             }
        //         };

        //         uniqueList.forEach(async propertyId => {
        //             // Call fetch property function
        //             const propertyData = await propertyDataFetch(propertyId);

        //             if (propertyData) {
        //                 const data = {
        //                     propertyName: propertyData.propertyName,
        //                     sender: props.user._id,
        //                     notification: `Todo deleted`,
        //                     detail: `Seleced todos deleted by ${props.user.firstName} ${props.user.lastName}`,
        //                     url: `to-do-management`
        //                 };

        //                 if (props.user.subRole) {
        //                     data.subRole = props.user.subRole;
        //                 }
                
        //                 // Add new notification to database
        //                 await props.addNotification(data);
                
        //                 if (props.user.subRole) {
        //                     // Send notification to admin
        //                     socket.emit('sendNotification', { admin: propertyData.owner });
                            
        //                 } else {
        //                     if (propertyData.assignedTo && propertyData.assignedTo.manager) {
        //                         // Send notification to manager
        //                         socket.emit('sendNotification', { managerName: propertyData.assignedTo.manager });
        //                     }
        //                 }

        //                 // Get all tenants of property
        //                 const res = await fetchAllTenants(undefined, undefined, undefined, undefined, undefined, propertyData.propertyName);
                
        //                 if (res.data.response && res.data.response.tenants && res.data.response.tenants.count > 0) {
        //                     res.data.response.tenants.limit.forEach(async (tenant) => {
            
        //                         // Send notification to tenants
        //                         socket.emit('sendNotification', { tenantId: tenant._id });
        //                     });
        //                 }
        //             }
        //         });

        //         // todo.values.todos.forEach(async el => {
        //         //     // Call fetch property function
        //         //     const propertyData = await propertyDataFetch(el.property);

        //         //     if (propertyData) {
        //         //         const data = {
        //         //             propertyName: propertyData.propertyName,
        //         //             sender: props.user._id,
        //         //             notification: `Todo deleted`,
        //         //             detail: `Seleced todos deleted by ${props.user.firstName} ${props.user.lastName}`,
        //         //             url: `to-do-management`
        //         //         };

        //         //         if (props.user.subRole) {
        //         //             data.subRole = props.user.subRole;
        //         //         }
                
        //         //         // Add new notification to database
        //         //         await props.addNotification(data);
                
        //         //         if (props.user.subRole) {
        //         //             // Send notification to admin
        //         //             socket.emit('sendNotification', { admin: propertyData.owner });
                            
        //         //         } else {
        //         //             if (propertyData.assignedTo && propertyData.assignedTo.manager) {
        //         //                 // Send notification to manager
        //         //                 socket.emit('sendNotification', { managerName: propertyData.assignedTo.manager });
        //         //             }
        //         //         }
        //         //     }
        //         // }); 
        //     }

        // } else {
        //     // Delete todo
        //     await props.deleteTodo(
        //         todo._id,
        //         props.sort,
        //         props.currentPage,
        //         props.limit,
        //         props.filter,
        //         props.tabKey,
        //         props.selectedDate
        //     );

        //     // Call fetch property function
        //     const propertyData = await propertyDataFetch(todo.property);

        //     if (propertyData) {
        //         const data = {
        //             propertyName: propertyData.propertyName,
        //             sender: props.user._id,
        //             notification: `Todo deleted`,
        //             detail: `Todo (${todo.task}) deleted by ${props.user.firstName} ${props.user.lastName}`,
        //             url: `to-do-management`
        //         };
    
        //         if (props.user.subRole) {
        //             data.subRole = props.user.subRole;
        //         }
        
        //         // Add new notification to database
        //         await props.addNotification(data);
        
        //         if (props.user.subRole) {
        //             // Send notification to admin
        //             socket.emit('sendNotification', { admin: propertyData.owner });
                    
        //         } else {
        //             if (propertyData.assignedTo && propertyData.assignedTo.manager) {
        //                 // Send notification to manager
        //                 socket.emit('sendNotification', { managerName: propertyData.assignedTo.manager });
        //             }
        //         }
        
        //         // Get all tenants of property
        //         const res = await fetchAllTenants(undefined, undefined, undefined, undefined, undefined, propertyData.propertyName);
        
        //         if (res.data.response && res.data.response.tenants && res.data.response.tenants.count > 0) {
        //             res.data.response.tenants.limit.forEach(async (tenant) => {
    
        //                 // Send notification to tenants
        //                 socket.emit('sendNotification', { tenantId: tenant._id });
        //             });
        //         }
        //     }
        // }

        // // Hide delete modal
        // hideDeleteModal();
    };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    // On Name sort handler
    const onNameSort = () => {
        if (props.column && props.column === 'firstName' && props.dir && props.dir === 'dsc') {
            props.addSorting('firstName', 'asc');
        } else {
            props.addSorting('firstName', 'dsc');
        }
    };

    // On Email sort handler
    const onEmailSort = () => {
        if (props.column && props.column === 'emailId' && props.dir && props.dir === 'dsc') {
            props.addSorting('emailId', 'asc');
        } else {
            props.addSorting('emailId', 'dsc');
        }
    };

    // On Contact Number sort handler
    const onContactNumberSort = () => {
        if (props.column && props.column === 'contactNumber' && props.dir && props.dir === 'dsc') {
            props.addSorting('contactNumber', 'asc');
        } else {
            props.addSorting('contactNumber', 'dsc');
        }
    };

    // On view button click
    const onViewButtonHandler = (e, landlord) => {
        e.preventDefault();

        if (landlord._id) {

            // Go to property detail page
            history.push(`/landlord-details/${landlord._id}`);
        }
    };

    useEffect(() => {
        let skip = (props.skip - 1) * props.limit;

        if (props.search) {
            skip = 0;
        }

        // Get all landlords from database
        props.getAllLandlords(
            props.user._id,
            props.column,
            props.dir,
            skip,
            props.limit,
            props.search
        );

        // Clean up function
        return () => {

            // Remoce all landlords data from store
            props.removeAllLandlordsData();

            // // Remove search and sort values also
            // props.initSearchSorting();
        };
    }, [props.column, props.dir, props.skip, props.search]);

    useEffect(() => {
        // Clean up function
        return () => {

            // Remoce all users data from store
            props.removeAllLandlordsData();

            // Remove search and sort values also
            props.initSearchSorting();

            // Init skip
            props.skipInit();
        };
    }, []);

    // Update modified initial tab object when landlords found
    if (props.landlords && props.landlords.records && props.landlords.records.length > 0) {
        // // Integrate fuzzy search here
        // if (props.search && props.search.length > 0) {
        //     skipInit = 1;
        //     const searcher = new FuzzySearch(props.landlords.records, ['firstName', 'lastName', 'emailId'], {
        //         sort: true
        //     });

        //     result = searcher.search(props.search);

        //     totalPages = Math.ceil(result.length / props.limit);
        //     for (let i = 1; i <= totalPages; i++) {
        //         pageNumbers.push(i);
        //     };
    
        //     const array = [];
    
        //     result.forEach(landlord => {
        //         const data = { ...landlord };
        //         data.checkbox = false;
        //         array.push(data);
        //     });
    
        //     modifiedInitialTabObj.landlords = array;

        // } else {
        //     totalPages = Math.ceil(props.landlords.records.length / props.limit);
        //     for (let i = 1; i <= totalPages; i++) {
        //         pageNumbers.push(i);
        //     };
    
        //     const array = [];
    
        //     props.landlords.records.forEach(landlord => {
        //         const data = { ...landlord };
        //         data.checkbox = false;
        //         array.push(data);
        //     });

        //     modifiedInitialTabObj.landlords = array; 
        // }

        totalPages = Math.ceil(props.landlords.records.length / props.limit);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        };

        const array = [];

        props.landlords.records.forEach(landlord => {
            const data = { ...landlord };
            data.checkbox = false;
            array.push(data);
        });

        modifiedInitialTabObj.landlords = array; 
    }

    if (props.search) {
        skipInit = 1;
    }
      
    return (
        <>
            <Modal
                show={ deleteModal }
                onHide={ hideDeleteModal }
                size="md"
                className="pho_delete_modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 pho_18_600 text-danger text-center">
                        Delete Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="pho_18_500 text-center">
                        Are you sure want to delete { !user.multiple ? `user (${ user.firstName } ${ user.lastName })` : 'selected users' }?
                    </p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <button className="btn btn-outline-primary btn-sm mr-5" onClick={ hideDeleteModal }>Cancel</button>
                    <button className="btn btn-outline-danger btn-sm" onClick={ onDeleteButtonClick }>Delete</button>
                </Modal.Footer>
            </Modal>

            <div className="card card-custom">       
                <Formik
                    enableReinitialize
                    initialValues={ modifiedInitialTabObj }
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        try {
                            setSubmitting(true);

                            // Show delete modal
                            showDeleteModal(undefined, values, true);

                            if (deleteModal) {
                                setTimeout(() => {                                
                                    // Reset form to initial value
                                    resetForm(modifiedInitialTabObj);
    
                                    // Set submitting form to false
                                    setSubmitting(false);  
                                }, 500);
                            }
                            
                        } catch (err) {
                            console.log(err);

                            // Notification
                            notify('error', 'Something went wrong');
                        }
                    }}
                >
                    {({
                        values, 
                        setFieldValue,
                    }) => (
                        <Table hover responsive className="phoTable">
                            <thead>
                                <tr>
                                    {/* <th>
                                        <div className="pho_checkbox iskl_border">
                                            <input
                                                type="checkbox"
                                                name="checkbox"
                                                className={`mr-2 checkbox`}
                                                checked={ values.checkbox }
                                                onChange={ (e) => {   
                                                    let total = 0;
                                                    // Set current check box value
                                                    setFieldValue('checkbox', !values.checkbox);

                                                    values.landlords.forEach((el, index) => {  
                                                        total += 1;
                                                        
                                                        // Set all checkbox value
                                                        setFieldValue(`landlords.${index}.checkbox`, !values.checkbox);
                                                    });                                                                            

                                                    if (values.checkbox || (!values.checkbox && total === 0)) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: 0,
                                                            value: false
                                                        });
                                                    } else if (!values.checkbox && total > 0) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: total,
                                                            value: true
                                                        });
                                                    }
                                                }}
                                            />
                                            <label></label>
                                        </div>
                                    </th> */}
                                    <th>
                                        Date
                                        {
                                            props.column && props.column === 'createdAt' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th>Picture</th>
                                    <th
                                        onClick={ onNameSort }
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                    >
                                        Name
                                        {
                                            props.column && props.column === 'firstName' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th
                                        onClick={ onEmailSort }
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                    >
                                        Email
                                        {
                                            props.column && props.column === 'emailId' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th
                                        onClick={ onContactNumberSort }
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                    >
                                        Call
                                        {
                                            props.column && props.column === 'contactNumber' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th className="text-center">Accommodations</th>
                                    <th className="text-center">Active / Deactive</th>
                                    <th width="90px"
                                        style={{
                                            userSelect: 'none'
                                        }}
                                    >
                                        Actions
                                    </th>
                                    {/* {
                                        commonDelete.count !== 0 ?
                                            <th width="90px">
                                                <button
                                                    style={{
                                                        userSelect: 'none',
                                                        cursor: 'pointer',
                                                        color: 'red'
                                                    }}
                                                    className="btn btn-sm btn-danger text-white"
                                                    type='submit'
                                                >
                                                    Delete  
                                                </button>
                                            </th>
                                        :   
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                    } */}
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray name="landlords">
                                    {({ insert, remove, push }) => (
                                        values.landlords && values.landlords.length > 0 ?
                                            values.landlords.map((landlord, index) => (
                                                <tr key={ index }>
                                                    {/* <td width="80px" >
                                                        <div className="pho_checkbox iskl_border">
                                                            <input                                                                                    
                                                                type="checkbox"
                                                                name={ `landlords.${index}.checkbox` }
                                                                className={`mr-2 checkbox`}
                                                                checked={ landlord.checkbox }
                                                                onChange={ (e) => {
                                                                    if (landlord.checkbox) {
                                                                        // Set common delete state
                                                                        setCommonDelete({
                                                                            count: commonDelete.count - 1,
                                                                            value: false
                                                                        });
                                                                    } else {
                                                                        // Set common delete state
                                                                        setCommonDelete({
                                                                            count: commonDelete.count + 1,
                                                                            value: true
                                                                        });
                                                                    }

                                                                    // Set field values
                                                                    setFieldValue(`landlords.${index}.checkbox`, !landlord.checkbox);
                                                                } }
                                                            />
                                                            <label></label>
                                                        </div>
                                                    </td> */}
                                                    <td width="115px">{ landlord.createdAt ? moment(landlord.createdAt).format('L') : '' }</td>
                                                    <td width="80px">
                                                        <div className="symbol symbol-50 pho_rounded_12">
                                                            <span className="symbol-label">
                                                                <img
                                                                    src={ landlord.profilePicture ? landlord.profilePicture : toAbsoluteUrl("/media/users/100_2.jpg") }
                                                                    className="h-100 align-self-center"
                                                                    alt="..."
                                                                />
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td width="120px">
                                                        { landlord.firstName ? landlord.firstName : ''} { landlord.lastName ? landlord.lastName : '' }
                                                        {/* <NavLink to={ `landlord-details/${landlord._id}` }>
                                                            { `${landlord.firstName ? landlord.firstName : ''} ${landlord.lastName ? landlord.lastName : ''}`  }
                                                        </NavLink> */}
                                                    </td>
                                                    <td width="190px">{ landlord.emailId ? landlord.emailId : '' }</td>
                                                    <td width="130px">{ landlord.contactNumber ? landlord.contactNumber : '' }</td>
                                                    <td className="text-center">{ landlord.accomodations ? landlord.accomodations : 0 }</td>
                                                    <td className="text-center" width="135px">
                                                        <Switch
                                                            value={ landlord.status === 'ACTIVE' ? true : false }
                                                            color="primary"
                                                            checked={ landlord.status === 'ACTIVE' ? true : false }
                                                            onChange={ (e) => {

                                                                // if (landlord.status === 'ACTIVE') {
                                                                //     setFieldValue(`users.${index}.status`, 'DEACTIVE');
                                                                // } else {
                                                                //     setFieldValue(`users.${index}.status`, 'ACTIVE');
                                                                // }
                                                                const skip = (props.skip - 1) * props.limit;
                                                                
                                                                // Update user status
                                                                props.updateLandlordStatus(
                                                                    props.user._id, 
                                                                    landlord._id,
                                                                    props.column,
                                                                    props.dir,
                                                                    skip,
                                                                    props.limit
                                                                );
                                                            } }
                                                        />
                                                    </td>
                                                    <td>
                                                        <ItemDropdown 
                                                            item="" 
                                                            doc={ landlord }
                                                            view={ onViewButtonHandler }
                                                        />
                                                    </td>
                                                </tr>
                                                
                                            ))
                                        : 
                                            props.loading ?
                                                (
                                                    // <div colSpan="7" id="propertyDetail-splash-screen" className="propertyDetail-splash-screen">
                                                    //     <svg className="propertyDetail-splash-spinner" viewBox="0 0 50 50">
                                                    //         <circle 
                                                    //             className="path"
                                                    //             cx="25"
                                                    //             cy="25"
                                                    //             r="20"
                                                    //             fill="none"
                                                    //             strokeWidth="5"
                                                    //         ></circle>
                                                    //     </svg>
                                                    // </div>
                                                    <tr>
                                                        <td colSpan="9" className="text-center">Loading...</td>
                                                    </tr>
                                                )
                                            : 
                                                <tr>
                                                    <td colSpan="9" className="text-center">No Data Available</td>
                                                </tr>
                                    )}
                                </FieldArray>                                
                            </tbody>
                        </Table>  
                    )}
                </Formik>
            </div>
            { 
                props.landlords && props.landlords.recordsTotal > 0 && 
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                        <div className="pho_14_400 pho_opacity_5">
                            {/* Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.landlords.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.landlords.recordsTotal } of { props.landlords.recordsTotal } records */}
                            {
                                props.search ? 
                                    `Displaying ${ skipInit > 1 ? ((skipInit - 1) * props.limit) + 1 : 1 } - ${ props.landlords.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.landlords.recordsTotal } of ${ props.landlords.recordsTotal } records`
                                :
                                    `Displaying ${ props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - ${ props.landlords.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.landlords.recordsTotal } of ${ props.landlords.recordsTotal } records`
                            }
                        </div>
                        {/* {
                            props.search && !result.length > 0 ?
                                null
                            : */}
                                <Pagination>
                                    {/* Go to first page */}
                                    <Pagination.First
                                        disabled={ props.skip === 1 || skipInit === 1 } 
                                        onClick={ goToFirstPage }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                                    </Pagination.First>

                                    {/* Go to previous page */}
                                    <Pagination.Prev
                                        disabled={ props.skip === 1 || skipInit === 1 } 
                                        onClick={ prevPageHandler }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                                    </Pagination.Prev>

                                    {/* All pages */}
                                    {
                                        pageNumbers.map((number, i) => {
                                            if (
                                                !props.search &&
                                                number >= parseInt(props.skip) - 3 &&
                                                number <= parseInt(props.skip) + 3 
                                            ) {
                                                return (
                                                    <Pagination.Item
                                                        key={ i }
                                                        id={ number }
                                                        disabled={ number === props.skip }
                                                        active={ number === props.skip }
                                                        onClick={ pageChangeHandler }
                                                    >
                                                        { number }
                                                    </Pagination.Item>
                                                );
                                            } else if (
                                                props.search &&
                                                number >= parseInt(skipInit) - 3 &&
                                                number <= parseInt(skipInit) + 3 
                                            ) {
                                                return (
                                                    <Pagination.Item
                                                        key={ i }
                                                        id={ number }
                                                        disabled={ number === skipInit }
                                                        active={ number === skipInit }
                                                        onClick={ pageChangeHandler }
                                                    >
                                                        { number }
                                                    </Pagination.Item>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }

                                    {/* Go to next page */}
                                    <Pagination.Next
                                        disabled={ props.skip === totalPages || skipInit === totalPages }  
                                        onClick={ nextPageHandler }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                                    </Pagination.Next>

                                    {/* Go to last page */}
                                    <Pagination.Last
                                        disabled={ props.skip === totalPages || skipInit === totalPages }  
                                        onClick={ goToLastPage }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                                    </Pagination.Last>
                                </Pagination>
                        {/* } */}
                    </div>
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        skip: state.landlord.skip,
        limit: state.landlord.limit,
        loading: state.landlord.loading,
        search: state.subHeader.search,
        column: state.subHeader.column,
        dir: state.subHeader.dir,
        landlords: state.landlord.landlords,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        skipInit: () => dispatch(landlordAction.skipInit()),
        initSearchSorting: () => dispatch(subheaderAction.initState()),
        updateSkip: (skip) => dispatch(landlordAction.skipUpdate(skip)),
        removeAllLandlordsData: () => dispatch(landlordAction.removeAllLandlordsData()),
        addSorting: (column, dir) => dispatch(subheaderAction.addSorting(column, dir)),
        getAllLandlords: (
            adminId,
            column, 
            dir, 
            skip, 
            limit,
            search
        ) => dispatch(landlordAction.fetchAllLandlord(adminId, column, dir, skip, limit, search)),
        updateLandlordStatus: (
            adminId, 
            landlordId,
            column, 
            dir, 
            skip, 
            limit
        ) => dispatch(landlordAction.landlordStatusChange(adminId, landlordId, column, dir, skip, limit)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandlordManagement);

{/* <tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="120px">John Deo</td>
    <td width="190px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td className="text-center">10</td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checkedB}
        onChange={handleChange('checkedB')}
        value="active"
        color="primary"
    />
    </td>
    <td width="130px"><ItemDropdown item="" /></td>
</tr> */}