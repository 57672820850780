import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.PAYMENT_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.PAYMENT_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.PAYMENT_SUCCESS
    };
};

// When fetch payment received
export const fetchAllPaymentReceived = (adminId, column , dir, status, skip, limit, userId) => {
    return {
        type: actionTypes.PAYMENT_RECEIVED_FETCH,
        adminId, 
        column, 
        dir, 
        status, 
        skip, 
        limit,
        userId
    };
};

// When all payment received fetched
export const allpaymentReceivedFetched = (paymentReceived) => {
    return {
        type: actionTypes.PAYMENT_RECEIVED_FETCHED,
        paymentReceived
    };
};

// When fetch pay outs
export const fetchPayouts = (adminId, landlordId) => {
    return {
        type: actionTypes.PAYMENT_PAYOUT_FETCH,
        adminId,
        landlordId
    };
};

// When pay out fetched
export const payoutFetched = (payouts) => {
    return {
        type: actionTypes.PAYMENT_PAYOUT_FETCHED,
        payouts
    };
};

// When relase payment
export const releasePayment = (adminId, landlordId, values) => {
    return {
        type: actionTypes.PAYMENT_RELEASE,
        adminId,
        landlordId,
        values
    };
};

// When relase payment done
export const releasePaymentDone = (value) => {
    return {
        type: actionTypes.PAYMENT_RELEASE_DONE,
        value
    };
};

// When refund payment
export const refundPayment = (adminId, userId, bookingId, values) => {
    return {
        type: actionTypes.PAYMENT_REFUND,
        adminId,
        userId, 
        bookingId,
        values
    };
};

// When refund payment done
export const refundPaymentDone = (value) => {
    return {
        type: actionTypes.PAYMENT_REFUND_DONE,
        value
    };
};

// Payment init function
export const paymentSkipUpdate = (skip) => {
    return {
        type: actionTypes.PAYMENT_SKIP_UPDATE,
        skip
    };
};

// Payment init function
export const paymentSkipInit = () => {
    return {
        type: actionTypes.PAYMENT_SKIP_INIT,
    };
};

// Payment init function
export const paymentInit = () => {
    return {
        type: actionTypes.PAYMENT_INIT,
    };
};