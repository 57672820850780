import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Form, Row, Table, Button } from "react-bootstrap";
import Select from 'react-select';
import moment from 'moment';
import { connect } from 'react-redux';
import { Formik, Field, FieldArray } from 'formik';

import { notify } from '../toaster';
import ItemDropdown from './ItemDropdown';
import { useSubheader } from '../../layout';
import * as supportActions from '../../../app/modules/Support';
import * as subheaderAction from '../../../app/modules/Subheader';
import { 
    queryReplayInitValue, 
    queryReplaySchema, 
    initialSupportTableValues 
} from './SupportSchema';

const modifiedInitialObj = Object.create(queryReplayInitValue);

const SupportDetails = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const subHeader = useSubheader();

    const statusOptions = [
        { value: 'OPEN', label: 'OPEN' },
        { value: 'CLOSE', label: 'CLOSED' },
    ];

    const modifiedInitialTabObj = Object.assign({}, initialSupportTableValues);

    // State
    const [queryData, setQueryData] = useState(null);
    const [commonDelete, setCommonDelete] = useState({
        count: 0,
        value: false
    });

    const handleChange = (value) => {
    };

    useEffect(() => {
        // Set sub header title
        const title = 'Support';

        subHeader.setTitle(title);

        // Add query id to store
        props.addQueryId(id);

        // Get query data
        props.getQuery(props.user._id, id);

        return () => {
            // Remoce all users data from store
            props.removeAllQueriesData();
        };
    }, [id]);

    useEffect(() => {

        if (props.queryDetail && props.queryDetail.queryDetails) {

            // if (moditest 6etQueryData({
            //         ...props.queryDetail.queryDetails,
            //         message: modifiedInitialObj.replay
            //     });

            // } else {
            // }
            // Set query data
            setQueryData({
                queryDetail: props.queryDetail.queryDetails,
                previousQueries: props.queryDetail.previousQueries
            });
        }
    }, [props.queryDetail]);

    console.log('modifiedInitialObj', modifiedInitialObj);

    if (queryData) {
        if (queryData.queryDetail) {
            modifiedInitialObj.status = queryData.queryDetail.status;

            if (queryData.queryDetail.reply) {
                modifiedInitialObj.reply = queryData.queryDetail.reply;        
            }
        }

        if (queryData.previousQueries && queryData.previousQueries.length > 0) {
            const array = [];
    
            queryData.previousQueries.forEach(query => {
                const data = { ...query };
                data.checkbox = false;
                array.push(data);
            });
    
            modifiedInitialTabObj.queries = array;
        }
    }

    return (
        <>
            {
                !props.loading && queryData && queryData.queryDetail ?
                    <div>
                        <Row className="justify-content-center">
                            <Col xl={8}>
                                <div className="pho_support_box">
                                <Row className="justify-content-md-between">
                                    <Col className="bg-light-success py-4 ">
                                        <div className="pho_16_400">
                                            Name: { queryData.queryDetail.name }
                                        </div>
                                    </Col>
                                    <Col className="text-center bg-light-success py-4 ">
                                        <div className="pho_14_400">Ticket No: { queryData.queryDetail.ticketNo }</div>
                                    </Col>
                                    <Col className="text-right bg-light-success py-4 ">
                                        <div className="pho_14_400">Date: { moment(queryData.queryDetail.createdAt).format('L') }</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="bg-white py-4 border-right">
                                        <div className="pho_14_500 pho_opacity_5">Email</div>
                                        <div className="pho_16_500">{ queryData.queryDetail.emailId }</div>
                                    </Col>
                                    <Col md={6} className="bg-white py-4">
                                        <div className="pho_14_500 pho_opacity_5">Phone</div>
                                        <div className="pho_16_500">{ queryData.queryDetail.contactNumber }</div>
                                    </Col>
                                    <Col md={12} className="bg-white py-4 border-top">
                                        <div className="pho_14_500 pho_opacity_5">Message</div>
                                        <div className="pho_16_500">{ queryData.queryDetail.message }</div>
                                    </Col>
                                </Row>
                                </div>
                                <Row className="my-5">
                                    <Col md={12}>
                                        <Formik
                                            enableReinitialize
                                            initialValues={ modifiedInitialObj }
                                            validationSchema={ queryReplaySchema }
                                            onSubmit={(values, { setSubmitting, resetForm }) => { 
                                                console.log('values', values);
                                                // Set submitting to true
                                                setSubmitting(true);
                                                
                                                // Send replay data to backend
                                                props.replyQuery(props.user._id, id, values);
                        
                                                setTimeout(() => {
                                                    // Set submitting form to false
                                                    setSubmitting(false);
        
                                                    // Reset form to initial values
                                                    resetForm(queryReplayInitValue);
                        
                                                    // Go back to property management page                            
                                                    history.push('/support');
                                                }, 2000);
                                            }}
                                        >
                                            {({
                                                values,
                                                touched,
                                                errors,
                                                handleSubmit,
                                                isSubmitting,
                                                setFieldValue,
                                                setFieldTouched,
                                            }) => (
                                                <Form onSubmit={ handleSubmit }>
                                                    <Form.Group>
                                                        <Form.Label className="pho_14_500">Reply</Form.Label>
                                                        <Field 
                                                            rows="3" 
                                                            name="reply"
                                                            as="textarea" 
                                                            disabled={ values.status === 'CLOSE' }
                                                            value={ values.reply }
                                                            placeholder="Enter message here" 
                                                            className="form-control border-0 py-4 px-4 pho_16_500"
                                                            // onChange={ (e) => {

                                                            //     // Set reply field value
                                                            //     setFieldValue(e.target.value);
                                                            // } }
                                                        />
                                                        {touched.reply && errors.reply ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{errors.reply}</div>
                                                            </div>
                                                        ) : null}
                                                    </Form.Group>

                                                    <Form.Group className="d-flex justify-content-md-end">
                                                        {/* <Select
                                                            value={ { value: values.status, label: values.status } }
                                                            onChange={ (e) => {
                                                                // Set field value
                                                                setFieldValue('status', e.value);
                                                            } }
                                                            placeholder="Type"
                                                            className={`form-control w-100px p-0 border-0`}
                                                            classNamePrefix="phoSelect"
                                                            // options={ statusOptions }
                                                            theme={ theme => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#DCF4E4',
                                                                    primary75: '#DCF4E4',
                                                                    primary50: '#DCF4E4',
                                                                    primary:"#50C878",
                                                                },
                                                            }) }
                                                        /> */}
                                                        <span className={`form-control w-100px py-3 pho_14_600 border-0 text-center`}>
                                                            { values.status }
                                                        </span>
                                                        {
                                                            values.status && values.status === 'CLOSE' ?
                                                                <Button 
                                                                    type="button"
                                                                    variant="success" 
                                                                    className="ml-2 px-5 py-3"
                                                                    onClick={ (e) => {
                                                                        // Go back to support page
                                                                        history.push('/support');
                                                                    } }
                                                                >
                                                                    Cancel
                                                                </Button>   
                                                            :
                                                                <Button 
                                                                    type="submit"
                                                                    variant="success" 
                                                                    className="ml-2 px-5 py-3"
                                                                    disabled={ isSubmitting }
                                                                >
                                                                    Submit
                                                                </Button>
                                                        }                                                        
                                                    </Form.Group>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="pho_24_600 mb-4">Previous Tickets <span className="pho_14_400 pho_opacity_5">5 Records</span></div>
                        <div className="card card-custom">      
                            <Formik
                                enableReinitialize
                                initialValues={ modifiedInitialTabObj }
                            >
                                {({
                                    values,
                                    setFieldValue
                                }) => (
                                    <Table hover responsive className="phoTable">
                                        <thead>
                                            <tr>
                                                {/* <th>
                                                    <div className="pho_checkbox iskl_border">
                                                        <input
                                                            type="checkbox"
                                                            name="checkbox"
                                                            className={`mr-2 checkbox`}
                                                            checked={ values.checkbox }
                                                            onChange={ (e) => {   
                                                                let total = 0;
                                                                // Set current check box value
                                                                setFieldValue('checkbox', !values.checkbox);

                                                                values.queries.forEach((el, index) => {  
                                                                    total += 1;
                                                                    
                                                                    // Set all checkbox value
                                                                    setFieldValue(`queries.${index}.checkbox`, !values.checkbox);
                                                                });                                                                            

                                                                if (values.checkbox || (!values.checkbox && total === 0)) {
                                                                    // Set common delete state
                                                                    setCommonDelete({
                                                                        count: 0,
                                                                        value: false
                                                                    });
                                                                } else if (!values.checkbox && total > 0) {
                                                                    // Set common delete state
                                                                    setCommonDelete({
                                                                        count: total,
                                                                        value: true
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <label></label>
                                                    </div>
                                                </th> */}
                                                <th>Date</th>
                                                <th>Ticket No</th>
                                                <th>Message</th>
                                                <th className="text-center">Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <FieldArray name="queries">
                                                {({ insert, remove, push }) => (
                                                    <>
                                                        {
                                                            values.queries && values.queries.length > 0 ?
                                                                values.queries.map((query, index) => (
                                                                    <tr key={ index }>
                                                                        {/* <td width="80px" >
                                                                            <div className="pho_checkbox iskl_border">
                                                                                <input                                                                                    
                                                                                    type="checkbox"
                                                                                    name={ `queries.${index}.checkbox` }
                                                                                    className={`mr-2 checkbox`}
                                                                                    checked={ query.checkbox }
                                                                                    onChange={ (e) => {
                                                                                        if (query.checkbox) {
                                                                                            // Set common delete state
                                                                                            setCommonDelete({
                                                                                                count: commonDelete.count - 1,
                                                                                                value: false
                                                                                            });
                                                                                        } else {
                                                                                            // Set common delete state
                                                                                            setCommonDelete({
                                                                                                count: commonDelete.count + 1,
                                                                                                value: true
                                                                                            });
                                                                                        }

                                                                                        // Set field values
                                                                                        setFieldValue(`queries.${index}.checkbox`, !query.checkbox);
                                                                                    } }
                                                                                />
                                                                                <label></label>
                                                                            </div>
                                                                        </td> */}
                                                                        <td width="115px">
                                                                            { query.createdAt ? moment(query.createdAt).format('L') : '' }
                                                                        </td>
                                                                        <td width="90px">
                                                                            <span className="pho_16_500">
                                                                                { query.ticketNo }
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="pho_ellips w-500">
                                                                                { query.message }
                                                                            </span>
                                                                        </td>
                                                                        <td width="140px">
                                                                            <Select
                                                                                placeholder="Type"
                                                                                classNamePrefix="phoSelect"
                                                                                options={ statusOptions }
                                                                                value={ { value: query.status, label: query.status } }
                                                                                onChange={ (e) => {
                                                                                    const skip = (props.skip - 1) * props.limit;
                                                                                    
                                                                                    // Change query status
                                                                                    props.queryStatusChange(
                                                                                        props.user._id,
                                                                                        query._id,
                                                                                        e.value,
                                                                                        props.column,
                                                                                        props.dir,
                                                                                        skip,
                                                                                        props.limit,
                                                                                        props.status,
                                                                                        true,
                                                                                        id
                                                                                    );
                                                                                } }
                                                                                className={`form-control p-0 border-0
                                                                                    ${query.status === "OPEN" && "text-orange bg-light-orange"}
                                                                                    ${query.status === "CLOSE" && "text-success bg-light-success"}
                                                                                `}
                                                                                theme={ theme => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary25: '#DCF4E4',
                                                                                        primary75: '#DCF4E4',
                                                                                        primary50: '#DCF4E4',
                                                                                        primary:"#50C878",
                                                                                    },
                                                                                }) }                                
                                                                            />
                                                                        </td>
                                                                        <td width="90px"><ItemDropdown item="" /></td>
                                                                    </tr>
                                                                ))
                                                            : 
                                                                <tr>
                                                                    <td colSpan="7" className="text-center">No Data Available</td>
                                                                </tr>
                                                        }
                                                    </>
                                                )}
                                            </FieldArray>
                                        </tbody>
                                    </Table> 
                                )}
                            </Formik>
                                   
                        </div>  
                    </div>
                :
                    <div id="compnent-splash-screen" className="compnent-splash-screen">
                        <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                            <circle 
                                className="path"
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                            ></circle>
                        </svg>
                    </div>
            }              
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        dir: state.subHeader.dir,
        skip: state.support.skip,
        limit: state.support.limit,
        loading: state.support.loading,
        search: state.subHeader.search,
        status: state.subHeader.status,
        column: state.subHeader.column,
        queryDetail: state.support.queryDetail,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        skipInit: () => dispatch(supportActions.skipInit()),
        initSearchSorting: () => dispatch(subheaderAction.initState()),
        updateSkip: (skip) => dispatch(supportActions.skipUpdate(skip)),
        addQueryId: (queryId) => dispatch(supportActions.addQueryId(queryId)),
        removeAllQueriesData: () => dispatch(supportActions.removeAllQueriesData()),
        addSorting: (column, dir) => dispatch(subheaderAction.addSorting(column, dir)),
        getQuery: (adminId, queryId) => dispatch(supportActions.fetchQuery(adminId, queryId)),
        replyQuery: (adminId, queryId, values) => dispatch(supportActions.replyQuery(adminId, queryId, values)),
        queryStatusChange: (
            adminId, 
            queryId,
            value,
            column, 
            dir, 
            skip, 
            limit,
            status,
            supportDetails,
            supportDetailsQueryId
        ) => dispatch(supportActions.queryStatusChange(adminId, queryId, value, column, dir, skip, limit, status, supportDetails, supportDetailsQueryId)),        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportDetails);


{/* <tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td><span className="pho_ellips w-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nibh porta, posuere lorem sit amet aliquam...</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td><span className="pho_ellips w-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nibh porta, posuere lorem sit amet aliquam...</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td><span className="pho_ellips w-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nibh porta, posuere lorem sit amet aliquam...</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td><span className="pho_ellips w-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis nibh porta, posuere lorem sit amet aliquam...</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr> */}