import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    refundingPayment,
    releasingPayment,
    fetchingPayout,
    fetchingAllPaymentReceived
} from './saga';

export function* watchPayment() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.PAYMENT_RECEIVED_FETCH, fetchingAllPaymentReceived),
        takeEvery(actionTypes.PAYMENT_PAYOUT_FETCH, fetchingPayout),
        takeEvery(actionTypes.PAYMENT_REFUND, refundingPayment),
        takeEvery(actionTypes.PAYMENT_RELEASE, releasingPayment)
    ]);
};