import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 10,
    error: null,
    loading: null,
    landlords: null,
    landlordId: null,
    landlordList: null,
    landlordDetail: null,
    pendingLandlords: null
};

// Reducer
export const landlordReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.LANDLORD_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.LANDLORD_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.LANDLORD_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.LANDLORDS_ALL_FETCHED:
            return {
                ...state,
                landlords: action.landlords
            };  
        case actionTypes.LANDLORDS_ALL_PENDING_FETCHED:
            return {
                ...state,
                pendingLandlords: action.pendingLandlords
            };
        case actionTypes.LANDLORD_LIST_FETCHED:
            return {
                ...state,
                landlordList: action.landlordList
            };   
        case actionTypes.LANDLORDS_ALL_DATA_REMOVE:
            return {
                ...state,
                landlords: null
            }; 
        case actionTypes.LANDLORD_FETCHED:
            return {
                ...state,
                landlordDetail: action.landlordDetail
            };  
        case actionTypes.LANDLORD_ID_ADD:
            return {
                ...state,
                landlordId: action.landlordId
            };  
        case actionTypes.LANDLORD_ID_REMOVE:
            return {
                ...state,
                landlordId: null
            };  
        case actionTypes.LANDLORD_DATA_REMOVE:
            return {
                ...state,
                landlordDetail: null
            };  
        case actionTypes.LANDLORD_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.LANDLORD_SKIP_INIT:
            return {
                ...state,
                skip: initialState.skip
            };
        default:
            return state;
    };
};