import * as Yup from 'yup';

// Initial tab value
export const initialSupportTableValues = {
    queries: [
        // {
        //     _id: '',
        //     status: '',
        //     name: '',
        //     emailId: '',
        //     contactNumber: '',
        //     checkbox: false,
        //     message: '',
        //     createdAt: '',
        //     ticketNo: ''
        // }
    ],
    checkbox: false
};

// Query replay schema
export const queryReplaySchema = Yup.object().shape({
    reply: Yup.string()
        .trim()
        .nullable('Please enter valid Replay')
        .required('Please enter Replay')
});

export const queryReplayInitValue = {
    reply: '',
    status: ''
};