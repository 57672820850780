import axios from "axios";

// Template related URL
export const TEMPLATE_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllTemplates`;
export const TEMPLATE_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/createTemplate`;
export const TEMPLATE_DELETED_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteTemplate`;
export const TEMPLATE_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getTemplateDetails`;
export const TEMPLATE_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateTemplate`;
export const TEMPLATE_ENTITIES_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllTemplateEntities`;

// All Template get function
export async function getAllTemplate(adminId, column, dir, skip, limit) {
  return await axios.get(`${TEMPLATE_ALL_URL}/${adminId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }`);
};

// All Template entities get function
export async function getAllEntities(adminId) {
  return await axios.get(`${TEMPLATE_ENTITIES_URL}/${adminId}`);
};

// Template get detail function
export async function getTemplateDetail(adminId, templateId) {
  return await axios.get(`${TEMPLATE_DETAIL_URL}/${adminId}/${templateId}`);
};

// Template add function
export async function addTemplate(adminId, values) {
  return await axios.post(`${TEMPLATE_ADD_URL}/${adminId}`, { ...values });
};

// Template status change function
export async function templateStatusChange(adminId, templateId) {
  return await axios.delete(`${TEMPLATE_DELETED_URL}/${adminId}/${templateId}`);
};

// Template verification function
export async function updateTemplate(adminId, templateId, values) {
  return await axios.put(`${TEMPLATE_UPDATE_URL}/${adminId}/${templateId}`, { ...values });
};