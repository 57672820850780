import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.NOTIFICATION_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.NOTIFICATION_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.NOTIFICATION_SUCCESS
    };
};

// All Notification fetch function
export const fetchAllNotification = (adminId, skip, limit) => {
    return {
        type: actionTypes.NOTIFICATION_ALL_FETCH,
        adminId,
        skip,
        limit
    };
};

// All Notification fetched function
export const allNotificationFetched = (allNotifications) => {
    return {
        type: actionTypes.NOTIFICATION_ALL_FETCHED,
        allNotifications
    };
};

// Notification count fetch function
export const fetchNotificationCount = (adminId) => {
    return {
        type: actionTypes.NOTIFICATION_COUNT_FETCH,
        adminId
    };
};

// All Notification fetched function
export const notificationCountFetched = (count) => {
    return {
        type: actionTypes.NOTIFICATION_COUNT_FETCHED,
        count
    };
};

// Notification status changes function
export const notificationUpdate = (adminId, notificationIds, skip, limit) => {
    return {
        type: actionTypes.NOTIFICATION_UPDATE,
        adminId,
        notificationIds,
        skip,
        limit
    };
};

// Notification init function
export const notificationSkipUpdate = (skip) => {
    return {
        type: actionTypes.NOTIFICATION_SKIP_UPDATE,
        skip
    };
};

// Notification init function
export const notificationInit = () => {
    return {
        type: actionTypes.NOTIFICATION_INIT,
    };
};

// Notification init function
export const notificationSkipInit = () => {
    return {
        type: actionTypes.NOTIFICATION_SKIP_INIT,
    };
};