import axios from "axios";

// Accommodation related URL
export const LANDLORD_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/addUser`;
export const LANDLORD_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllLandlords`;
export const LANDLORD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getUserDetails`;
export const LANDLORD_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateUser`;
export const LANDLORD_DELETE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteUser`;
export const LANDLORD_LIST_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllLandlordList`;
export const LANDLORD_ALL_PENDING_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getPendingLandlordRequests`;


// All Landlord get function
export async function getAllLanlords(adminId, column, dir, skip, limit, search) {
  return await axios.get(`${LANDLORD_ALL_URL}/${adminId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${search ? `&search=${search}` : '' }`);
};

// Landlord get function
export async function getLandlord(adminId, landlordId) {
  return await axios.get(`${LANDLORD_URL}/${adminId}/${landlordId}`);
};

// Landlord update function
export async function updateLandlord(adminId, landlordId, updateData) {
  return await axios.put(`${LANDLORD_UPDATE_URL}/${adminId}/${landlordId}`, { ...updateData });
};

// Landlord delete function
export async function deleteLandlord(adminId, landlordId) {
  return await axios.delete(`${LANDLORD_DELETE_URL}/${adminId}/${landlordId}`);
};

// Landlord delete function
export async function addLandlord(adminId, values) {
  return await axios.post(`${LANDLORD_ADD_URL}/${adminId}`, { ...values });
};

// Landlord list get function
export async function getAllLandlordList(adminId) {
  return await axios.get(`${LANDLORD_LIST_URL}/${adminId}`);
};

// Landlord list get function
export async function getAllPendingLandlordList(adminId, column, dir, skip, limit) {
  return await axios.get(`${LANDLORD_ALL_PENDING_URL}/${adminId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }`);
};