import React, { useState, useEffect } from "react";
import { Table, Pagination, Form, Button, Dropdown, Row, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import Select from 'react-select';
import DatePicker from "react-datepicker"; 
import { connect } from 'react-redux';
import moment from 'moment';
import FuzzySearch from 'fuzzy-search';

import { notify } from '../toaster';
import { toAbsoluteUrl } from "../../_helpers";
import * as reportActions from '../../../app/modules/Report';

import "react-datepicker/dist/react-datepicker.css";

// type enum : ['USER', 'LANDLORD', 'BOOKING'];
//view
const view = [
    { value: 'ListView', label: 'List View' },
    { value: 'GridView', label: 'Grid View' },
];

const typeOptions = [
    { value: 'USER', label: 'User' },
    { value: 'LANDLORD', label: 'Landlord' },
    { value: 'BOOKING', label: 'Booking' },
];

const Reports = (props) => {
    let totalPages;
    let result = [];
    let listViewdata;
    let gridViewData;
    const pageNumbers = [];

    // State
    const [inputValue, setInputValue] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [filterType, setFilterType] = useState(null);
    const [startDate, setStartDate] = useState(new Date().setMonth(new Date().getMonth() - 1));
    const [selectType, setSelectType] = useState({
        value: 'USER',
        label: 'User'
    });
    const [selectView, setSelectView] = useState({ 
        value: 'ListView', 
        label: 'List View' 
    });
    
    // View change handler
    const handleViewChange = (value) => {

        // Set select view state
        setSelectView(value);
    };

    // Type change handler
    const handleChange = (value) => {

        // Set select type state
        setSelectType(value);
    };

    // On find result click handler function
    const onFindResultClickHandler = async () => {
        try {
            // Set filter type state
            setFilterType(selectType.value);

            const skip = (props.skip - 1) * props.limit;

            if (inputValue.length > 0) {
                // Call Skip init
                props.skipInit();
            }

            // Get report data
            await props.getAllReport(
                props.user._id,
                props.column,
                props.dir,
                new Date(startDate).getTime(),
                new Date(endDate).getTime(),
                skip,
                props.limit,
                selectType.value,
                inputValue
            );
        } catch (err) {
            console.log(err);
        }
    };

    // Return searchColumn
    const returnSearchColumn = (type) => {
        if (type === 'USER') {
            return 'firstName';

        } else if (type === 'LANDLORD') {
            return 'firstName';

        } else {
            return 'propertyType';
        }
    };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    // On download report click handler
    const onDownloadReportClick = async () => {
        try {
            const skip = (props.skip - 1) * props.limit;

            // Call search column function
            const searchColumn = returnSearchColumn(selectType.value);

            // // Download report data
            // await props.downloadReport(
            //     props.user._id,
            //     props.column,
            //     props.dir,
            //     new Date(startDate).getTime(),
            //     new Date(endDate).getTime(),
            //     skip,
            //     props.limit,
            //     selectType.value,
            //     searchColumn,
            //     inputValue
            // );
            const adminId = props.user._id;
            const column = props.column;
            const dir = props.dir;
            const fromDate = new Date(startDate).getTime();
            const toDate = new Date(endDate).getTime();
            const limit = props.limit;
            const type = selectType.value;
            const searchVal = inputValue;

            const downloadLink = `${process.env.REACT_APP_BACKEND_LIVE_API}/downloadReport/${adminId}?skip=${skip}&limit=${limit}${fromDate && `&fromDate=${fromDate}` }${toDate && `&toDate=${toDate}` }${column && `&column=${column}` }${dir && `&dir=${dir}` }&type=${type && type}&searchColumn=${searchColumn && searchColumn}&searchVal=${searchVal && searchVal}`;

            // Download csv file in new tab
            window.open(downloadLink, "_blank")
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        const skip = (props.skip - 1) * props.limit;

        // Get all report data
        props.getAllReport(
            props.user._id,
            props.column,
            props.dir,
            new Date(startDate).getTime(),
            new Date(endDate).getTime(),
            skip,
            props.limit,
            selectType.value,
            inputValue
        );        
    }, [props.skip, props.column, props.dir]);

    useEffect(() => {
        // Remove data from store when component destroy
        return () => {

            // Report init
            props.reportInit();

            // Skip init
            props.skipInit();
        };
    }, []);

    if (props.allReport && props.allReport.records && props.allReport.records.length > 0) {
        totalPages = Math.ceil(props.allReport.recordsTotal / props.limit);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        };

        if (filterType && filterType === 'BOOKING') {
            listViewdata = props.allReport.records.map((record, index) => (
                <tr key={ index }>
                    <td width="115px">
                        { moment(record.createdAt).format('L') }
                    </td>
                    <td width="150px" className="pho_16_500">{ record._id }</td>
                    <td width="115px">
                        { record.propertyDetails && record.propertyDetails.type ? record.propertyDetails.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' : '' }
                    </td>
                    <td >
                        <div className="w-170px">
                            { record.propertyDetails && record.propertyDetails.name ? record.propertyDetails.name.en : '' }
                        </div>
                    </td>
                    <td width="125px">
                        { record.userDetails ? `${record.userDetails.firstName} ${record.userDetails.lastName}` : '' }
                    </td>
                    <td width="190px">
                        <label className={`m-0 pho_14_500 w-100
                            ${record.status === 'REQUESTED' && 'pho_orengeLbl'}
                            ${record.status === 'COMPLETED' && 'pho_greenLbl'}
                            ${record.status === 'CANCELLED' && 'pho_blackLbl'}
                            ${record.status === 'REJECTED' && 'pho_redLbl'}
                            ${record.status === 'APPROVED' && 'pho_yelloLbl'}
                            ${record.status === 'PAYMENT_RELEASED' && 'pho_pinkLbl'}
                            ${record.status === 'PAYMENT_REFUNDED' && 'pho_blueLbl'}
                            ${record.status === 'REQUEST_EXPIRED' && 'pho_purpleLbl'}
                        `}>
                            { record.status === 'REQUESTED' ? 'PENDING' : record.status ? record.status.replace('_', ' ') : '' }
                        </label>
                    </td>                    
                </tr>  
            ));

            gridViewData = props.allReport.records.map((record, index) => (
                <Col lg={ 4 } md={6 } key={ index }>
                    <div className="card card-custom p-5">
                        <div className="pho_16_500 mb-2 mt-4">
                            <span className="pho_16_600">Booking Number:</span> { record._id }
                        </div>
                        <div className="pho_16_500 mb-2 mt-4">
                            <span className="pho_16_600">Property Name:</span> { record.propertyDetails && record.propertyDetails.name ? record.propertyDetails.name.en : '' }
                        </div>
                        <div className="pho_16_500 mb-2">
                            <span className="pho_16_600">Type:</span> { record.propertyDetails && record.propertyDetails.type ? record.propertyDetails.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' : '' }
                        </div>
                        <div className="pho_16_500 mb-2">
                            <span className="pho_16_600">Status:</span> { record.status === 'REQUESTED' ? 'PENDING' : record.status }
                        </div>
                        <div className="pho_16_500 mb-2">
                            <span className="pho_16_600">Booked By:</span> { record.userDetails ? `${record.userDetails.firstName} ${record.userDetails.lastName}` : '' }
                        </div>
                        <div className="pho_16_500">
                            <span className="pho_16_600">Date:</span> { moment(record.createdAt).format('L') }
                        </div>
                    </div>
                </Col>
            ));

        } else {
            listViewdata = props.allReport.records.map((record, index) => (
                <tr key={ index }>
                    <td width="115px">
                        { moment(record.createdAt).format('L') }
                    </td>
                    <td width="80px">
                        <div className="symbol symbol-50 pho_rounded_12">
                            <span className="symbol-label">
                            <img
                                src={ record.profilePicture }
                                className="h-100 align-self-center"
                                alt="profilePicture"
                            />
                            </span>
                        </div>
                    </td>
                    <td width="150px">
                        { record.firstName } { record.lastName }
                    </td>
                    <td width="150px">{ record.emailId }</td>
                    <td width="130px">{ record.contactNumber }</td>
                </tr>  
            ));

            gridViewData = props.allReport.records.map((record, index) => (
                <Col lg={ 4 } md={6 } key={ index }>
                    <div className="card card-custom p-5">
                        <span className="symbol symbol-50 pho_rounded_12">
                            <span className="symbol-label pho_rounded_12">
                                <img
                                    src={ record.profilePicture }
                                    className="h-100 align-self-center"
                                    alt="profilePicture"
                                />
                            </span>
                        </span>
                        <div className="pho_16_500 mb-2 mt-4">
                            <span className="pho_16_600">Name:</span> { record.firstName } { record.lastName }
                        </div>
                        <div className="pho_16_500 mb-2">
                            <span className="pho_16_600">Email:</span> { record.emailId }
                        </div>
                        <div className="pho_16_500 mb-2">
                            <span className="pho_16_600">Call:</span> { record.contactNumber }
                        </div>
                        <div className="pho_16_500">
                            <span className="pho_16_600">Date:</span> { moment(record.createdAt).format('L') }
                        </div>
                    </div>
                </Col>
            ));
        }        
    }    

    return (
        <>
            <Form className="d-flex flex-wrap mt-5">
                <Form.Group className="mr-3">
                    <Select
                        value={ selectType }
                        onChange={ (e) => handleChange(e) }
                        placeholder="Select field for search"
                        className={`form-control p-0 border-0 w-200`}
                        classNamePrefix="phoSelect"
                        options={ typeOptions }
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#DCF4E4',
                                primary75: '#DCF4E4',
                                primary50: '#DCF4E4',
                                primary:"#50C878",
                            },
                        })}

                    />
                </Form.Group>
                <Form.Group className="mr-3">
                    <DatePicker
                        selected={ startDate }
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={ startDate }
                        className="form-control border-0 py-5 px-6 pho_14_400 w-250"
                    />
                </Form.Group>
                <Form.Group className="mr-3">
                    <DatePicker
                        selected={ endDate }
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        className="form-control border-0 py-5 px-6 pho_14_400 w-250"
                        // startDate={ startDate }
                        endDate={ endDate}
                        // minDate={ startDate }
                    />
                </Form.Group>
                <Form.Group className="mr-3">
                    <Form.Control 
                        className="border-0 py-5 px-6 pho_14_400 w-250"
                        onChange={ (e) => {

                            // Set input value state
                            setInputValue(e.target.value);
                        } }
                    />
                </Form.Group>
                <Form.Group>
                    <Button 
                        variant="success" 
                        className="px-5 py-3"
                        onClick={ (e) => {
                            // Call find result function
                            onFindResultClickHandler();
                        } }
                    >
                        Find Results
                    </Button>
                </Form.Group>
            </Form>
            <div className="d-flex flex-wrap justify-content-between align-items-center my-5">
                <div className="d-flex flex-wrap justify-content-start align-items-center">
                        {
                            !props.loading && props.allReport && props.allReport.recordsTotal > 0 &&
                                <div className="pho_14_400 pho_opacity_5 icon-plus mr-3">
                                    {
                                        `Displaying ${ props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - ${ props.allReport.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.allReport.recordsTotal } of ${ props.allReport.recordsTotal } records`
                                    }
                                </div>
                        }                
                    <Select
                        value={ selectView }
                        onChange={ (e) => handleViewChange(e) }
                        placeholder="Select field for search"
                        className={`form-control transperent p-0 border-0 w-120px`}
                        classNamePrefix="phoSelect"
                        options={view}
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#DCF4E4',
                            primary75: '#DCF4E4',
                            primary50: '#DCF4E4',
                            primary:"#50C878",
                        },
                        })}

                    />
                </div>
                <div className="d-flex flex-wrap justify-content-end">
                    <Dropdown alignRight className="mr-4">
                        <Dropdown.Item
                            variant="link" 
                            id="dropdown-basic"
                            className="text-success" 
                            onClick={ (e) => {

                                if (props.loading) {
                                    return;
                                }

                                // Call download report function
                                onDownloadReportClick();
                            } }
                        >
                            Download
                        </Dropdown.Item>
                        {/* <Dropdown.Toggle 
                            variant="link" 
                            className="text-success" id="dropdown-basic"
                        >
                            Download
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Excel</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">PDF</Dropdown.Item>
                        </Dropdown.Menu> */}
                    </Dropdown>
                    {/* Pagination */}
                    { 
                        props.allReport && props.allReport.recordsTotal > 0 && 
                            <Pagination>
                                {/* Go to first page */}
                                <Pagination.First
                                    disabled={ props.skip === 1 } 
                                    onClick={ goToFirstPage }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                                </Pagination.First>

                                {/* Go to previous page */}
                                <Pagination.Prev
                                    disabled={ props.skip === 1 } 
                                    onClick={ prevPageHandler }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                                </Pagination.Prev>

                                {/* All pages */}
                                {
                                    pageNumbers.map((number, i) => {
                                        if (
                                            number >= parseInt(props.skip) - 2 &&
                                            number <= parseInt(props.skip) + 2 
                                        ) {
                                            return (
                                                <Pagination.Item
                                                    key={ i }
                                                    id={ number }
                                                    active={ number === props.skip }
                                                    onClick={ pageChangeHandler }
                                                >
                                                    { number }
                                                </Pagination.Item>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }

                                {/* Go to next page */}
                                <Pagination.Next
                                    disabled={ props.skip === totalPages } 
                                    onClick={ nextPageHandler }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                                </Pagination.Next>

                                {/* Go to last page */}
                                <Pagination.Last
                                    disabled={ props.skip === totalPages } 
                                    onClick={ goToLastPage }
                                >
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                                </Pagination.Last>
                            </Pagination>
                    }
                </div>
            </div>   
            {selectView.value === "ListView" && 
                <div className="card card-custom">            
                    <Table hover responsive className="phoTable">
                        <thead>
                            {
                                filterType && filterType === 'BOOKING' ?
                                    <tr>
                                        <th>Date</th>
                                        <th>Booking Number</th>
                                        <th>Type</th>
                                        <th>Property Name</th>
                                        <th>Booked By</th>
                                        <th>Status</th>
                                    </tr>
                                :
                                    <tr>
                                        <th>Date</th>
                                        <th>Picture</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Call</th>
                                    </tr>
                            }
                        </thead>
                        <tbody>
                            {
                                !props.loading && props.allReport && props.allReport.recordsTotal > 0 ?
                                    listViewdata
                                :
                                    props.loading ?
                                        <tr>
                                            <td colSpan="6" className="text-center">Loading...</td>
                                        </tr>
                                    : 
                                        <tr>
                                            <td colSpan="6" className="text-center">No Data Available</td>
                                        </tr>
                            }                         
                        </tbody>
                    </Table>        
                </div> 
            }
            {selectView.value === "GridView" && 
                <>
                    <Row className="phoGap14">
                        {
                            !props.loading && props.allReport && props.allReport.recordsTotal > 0 ?
                                gridViewData
                            :
                                props.loading ?
                                    <div className="text-center">Loading...</div>
                                : 
                                    <div className="text-center">No Data Available</div>
                        }
                    </Row>
                </>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        dir: state.report.dir,
        skip: state.report.skip,
        limit: state.report.limit,
        column: state.report.column,
        loading: state.report.loading,
        allReport: state.report.allReport
    };
};

const mapDispatchToProps = dispatch => {
    return {
        reportInit: () => dispatch(reportActions.reportInit()),
        skipInit: () => dispatch(reportActions.reportSkipInit()),
        updateSkip: (skip) => dispatch(reportActions.reportSkipUpdate(skip)),
        getAllReport: (
            adminId, 
            column, 
            dir, 
            fromDate,
            toDate,
            skip, 
            limit,
            type,
            searchColumn,
            searchVal
        ) => dispatch(reportActions.fetchAllReport(adminId, column, dir, fromDate, toDate, skip, limit, type, searchColumn, searchVal)),
        downloadReport: (
            adminId, 
            column, 
            dir, 
            fromDate,
            toDate,
            skip, 
            limit,
            type,
            searchColumn,
            searchVal
        ) => dispatch(reportActions.reportDownload(adminId, column, dir, fromDate, toDate, skip, limit, type, searchColumn, searchVal)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

{/* <tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr>
<tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr>
<tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr>
<tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr>
<tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr>
<tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr>
<tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr>
<tr>
    <td width="115px">06/03/2020</td>
    <td width="80px">
        <div className="symbol symbol-50 pho_rounded_12">
            <span className="symbol-label">
            <img
                src={toAbsoluteUrl("/media/users/100_2.jpg")}
                className="h-100 align-self-center"
                alt="..."
            />
            </span>
        </div>
    </td>
    <td width="150px">John Deo</td>
    <td width="150px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
</tr> */}

// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>
// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>
// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>
// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>
// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>
// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>
// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>
// <Col lg={4} md={6}>
// <div className="card card-custom p-5">
//     <span className="symbol symbol-50 pho_rounded_12">
//         <span className="symbol-label pho_rounded_12">
//         <img
//             src={toAbsoluteUrl("/media/users/100_2.jpg")}
//             className="h-100 align-self-center"
//             alt="..."
//         />
//         </span>
//     </span>
//     <div className="pho_16_500 mb-2 mt-4"><span className="pho_16_600">Name:</span> John Deo</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Email:</span> johndeo@company.com</div>
//     <div className="pho_16_500 mb-2"><span className="pho_16_600">Call:</span> +1 234 567 8900</div>
//     <div className="pho_16_500"><span className="pho_16_600">Date:</span> 06/03/2020</div>
// </div>
// </Col>