import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as userActions from '../../../app/modules/User';
import * as landlordActions from '../../../app/modules/Landlord';
import * as dashboardActions from '../../../app/modules/Dashboard';
import * as subheaderActions from '../../../app/modules/Subheader';
import * as accommodationActions from '../../../app/modules/Accommodation';
import {
    StatsWidget11,
    StatsWidget12,
    StatsWidget13,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
} from "../widgets";

const Dashboard = (props) => {    

    useEffect(() => {
        const skip = (props.skip - 1) * props.limit;

        // Fetch all accommodation of admin
        props.getAllAccommodations(
            props.user._id,
            undefined,
            undefined,
            skip,
            props.limit
        );

        // Fetch pending accommodation of admin
        props.getPendingAccommodations(
            props.user._id,
            undefined,
            undefined,
            skip,
            props.limit
        );

        // Fetch pending landlords
        props.getPendingLandlords(
            props.user._id,
            undefined,
            undefined,
            0,
            10
        );

        // Cleanup funtion
        return () => {
            // Clean accommodation data
            props.accommodationInit();

            // Dash board init
            props.dashboardInit();

            // Subheader init
            props.subheaderInit();
        };
    }, []);

    useEffect(() => {

        // Fetch dashboard conts
        props.getDashboardCounts(props.user._id, props.year);
    }, [props.year]);

    return (
        <>
            {
                !props.loading && !props.accommodationLoading ?
                    <div className="row ">
                        <div className="col-md-12 col-xl-4">
                            <StatsWidget13  
                                users={ props.users }
                                totalUsers={ props.totalUsers }
                                className="card-stretch gutter-b pho_chart_card pho_card"
                            />
                        </div>
                        <div className="col-md-12 col-xl-4">
                            <StatsWidget12 
                                landlords={ props.landlords }
                                totalLandlords={ props.totalLandlords }
                                className="card-stretch gutter-b pho_chart_card pho_card"
                            />
                        </div>
                        <div className="col-md-12 col-xl-4">
                            <StatsWidget11 
                                bookings={ props.bookings }
                                totalBookings={ props.totalBookings }
                                className="card-stretch gutter-b pho_chart_card pho_card"
                            />
                        </div>
                        <div className="col-md-12 col-xl-6">
                            <AdvanceTablesWidget2 
                                className="card-stretch gutter-b pho_card"
                                allAccommodations={ props.allAccommodations }
                            />
                        </div> 
                        <div className="col-md-12 col-xl-6">
                            <AdvanceTablesWidget4 
                                className="card-stretch gutter-b pho_card"
                                pendingLandlords={ props.pendingLandlords }
                                updateUser={ props.updateUser }
                                adminId={ props.user._id }
                            />
                        </div>            
                    </div>
                :
                    <div id="compnent-splash-screen" className="compnent-splash-screen">
                        <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                            <circle 
                                className="path"
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                            ></circle>
                        </svg>
                    </div>
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        year: state.subHeader.year,
        users: state.dashboard.users,
        skip: state.accommodation.skip,
        limit: state.accommodation.limit,
        loading: state.dashboard.loading,
        bookings: state.dashboard.bookings,
        landlords: state.dashboard.landlords,
        totalUsers: state.dashboard.totalUsers,
        totalBookings: state.dashboard.totalBookings,
        totalLandlords: state.dashboard.totalLandlords,
        pendingLandlords: state.landlord.pendingLandlords,
        accommodationLoading: state.accommodation.loading,
        allAccommodations: state.accommodation.allAccommodations,
        pendingAccommodations: state.accommodation.pendingAccommodations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        subheaderInit: () => dispatch(subheaderActions.initState()),
        dashboardInit: () => dispatch(dashboardActions.dashboardInit()),
        accommodationInit: () => dispatch(accommodationActions.accommodationInit()),
        getDashboardCounts: (adminId, year) => dispatch(dashboardActions.fetchDashboardCounts(adminId, year)),
        getAllAccommodations: (
            adminId,
            column,
            dir,
            skip,
            limit
        ) => dispatch(accommodationActions.fetchAllAccommodation(adminId, column, dir, skip, limit)),
        getPendingAccommodations: (
            adminId,
            column,
            dir,
            skip,
            limit
        ) => dispatch(accommodationActions.fetchPendingAccommodation(adminId, column, dir, skip, limit)),
        getPendingLandlords: (
            adminId,
            column,
            dir,
            skip,
            limit
        ) => dispatch(landlordActions.fetchAllPendingLandlord(adminId, column, dir, skip, limit)),
        updateUser: (
            adminId,
            userId,
            updateData,
            isDashboard
        ) => dispatch(userActions.updateUserData(adminId, userId, updateData, isDashboard))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);