import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { 
    getAllBookings,
    getUserBookings,
    getBookingDetails
} from '../bookingCrud';

// Get all bookings functions
export function* fetchingAllBookings(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all bookings
        const result = yield getAllBookings(
            action.adminId,
            action.fromDate, 
            action.toDate, 
            action.status, 
            action.typeValue, 
            action.skip, 
            action.limit,
            action.search
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store booking data into store
        yield put(actions.allBookingsFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get booking detail functions
export function* fetchingBookingDetail(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.bookingDetailStart());

        // Call function to fetch booking detail
        const result = yield getBookingDetails(action.adminId, action.bookingId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store booking data into store
        yield put(actions.bookingDetailFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get user booking functions
export function* fetchingUserBookings(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch user bookings
        const result = yield getUserBookings(action.adminId, action.userId, action.skip, action.limit);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store booking data into store
        yield put(actions.userBookingFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};