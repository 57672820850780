import axios from "axios";

// Booking url
export const BOOKING_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllBookings`;
export const BOOKING_USER_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllUserBookings`;
export const BOOKING_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getBookingDetails`;

// All booking get function
export function getAllBookings(adminId, fromDate, toDate, status, type, skip, limit, search) {
  return axios.get(`${BOOKING_ALL_URL}/${adminId}?skip=${skip}&limit=${limit}${fromDate ? `&fromDate=${fromDate}` : '' }${toDate ? `&toDate=${toDate}` : '' }${status ? `&status=${status}` : '' }${type ? `&propertyType=${type}` : '' }${search ? `&landlord=${search}` : '' }`);
  // ${fromDate && `&fromDate=${fromDate}` }${toDate && `&toDate=${toDate}` }${column && `&column=${column}` }${dir && `&dir=${dir}` }&type=${type && type}&searchColumn=${searchColumn && searchColumn}&searchVal=${searchVal && searchVal}
};

// User bookings get function
export function getUserBookings(adminId, userId, skip, limit) {
  return axios.get(`${BOOKING_USER_URL}/${adminId}/${userId}?skip=${skip}&limit=${limit}`);
};

// Booking details get function
export function getBookingDetails(adminId, bookingId) {
  return axios.get(`${BOOKING_DETAIL_URL}/${adminId}/${bookingId}`);
};