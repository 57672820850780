import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    getAllLanguages,
    languageStatusChange
} from '../languageCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all Languages function
export function* fetchingAllLanguages(action) {  
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all cms
        const result = yield getAllLanguages(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allLanguageFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// // Add template function
// export function* addingTemplate(action) { 
//     try {
//         // Call fetch start to displaying loading spinner
//         yield put(actions.fetchStart());

//         // Call function to add CMS
//         const result = yield addTemplate(action.adminId, action.values);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//     } catch (err) {
//         console.log(err);

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };

// // Get Template detail function
// export function* fetchingTemplateDetail(action) {    
//     try {
//         // Call fetch start to displaying loading spinner
//         yield put(actions.fetchStart());

//         // Call function to fetch CMS detail
//         const result = yield getTemplateDetail(action.adminId, action.cmsId);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }
        
//         // Store dashboard data into store
//         yield put(actions.templateDetailFetched(result.data.responseData));

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//     } catch (err) {
//         console.log(err);

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };

// Change Language status function
export function* changingLanguageStatus(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield languageStatusChange(action.adminId, action.languageId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Store dashboard data into store
        yield put(actions.fetchAllLanguage(action.adminId));     

        // Notification
        notify('success', 'Language status updated successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// // Template update function
// export function* updatingTemplate(action) {    
//     try {
//         // // Call fetch start to displaying loading spinner
//         // yield put(actions.fetchStart());

//         // Call function to change CMS status
//         const result = yield updateTemplate(action.adminId, action.cmsId, action.values);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }

//         // Store dashboard data into store
//         yield put(actions.fetchAllTemplate(action.adminId));

//         // Notification
//         notify('success', 'Template updated successfully');

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//     } catch (err) {
//         console.log(err);

//         // Notification
//         notify('error', 'Error while updating Template');

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };

// // Get all entities of template function
// export function* fetchingTemplateEntities(action) {    
//     try {
//         // // Call fetch start to displaying loading spinner
//         // yield put(actions.fetchStart());

//         // Call function to change CMS status
//         const result = yield getAllEntities(action.adminId);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }

//         // Store dashboard data into store
//         yield put(actions.allTemplateEntitiesFetched(result.data.responseData));

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//     } catch (err) {
//         console.log(err);

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };