import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 9,
    dir: null,
    error: null,
    column: null,
    loading: null,
    allReport: null
};

// Reducer
export const reportReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.REPORT_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.REPORT_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.REPORT_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.REPORT_ALL_FETCHED:
            return {
                ...state,
                allReport: action.allReport
            };        
        case actionTypes.REPORT_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.REPORT_INIT:
            return {
                ...state,
                error: null,
                loading: null,
                allReport: null
            };
        case actionTypes.REPORT_SKIP_INIT:
            return {
                ...state,
                skip: initialState.skip
            };
        default:
            return state;
    };
};