import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Button } from "react-bootstrap";
import { Formik, Field, FieldArray } from "formik";
import { connect } from "react-redux";
import { Switch } from "@material-ui/core";
import moment from "moment";

import * as subheaderAction from "../../../app/modules/Subheader";
import * as invitationLinkAction from "../../../app/modules/InvitaionLink";
import {
  initialLinkSchema,
  linkAddSchema,
  linkAddValidationSchema,
} from "./LinkSchema";

const InvitationLink = (props) => {
  const {
    user,
    fetchAllLinks,
    updateLink,
    addLink,
    deleteLink,
    modalOpen,
  } = props;

  const modifiedLinkAddSchema = Object.assign({}, linkAddSchema);
  const modifiedInitialSchema = Object.assign({}, initialLinkSchema);

  // State
  const [editMode, setEditMode] = useState({
    edit: false,
    type: null,
    url: null,
    _id: null,
  });

  // On edit button click handler
  const onEditButtonClickHandler = (link, resetForm) => {
    // Open modal
    modalOpen(true);

    // Set edit mode state
    setEditMode({
      edit: true,
      _id: link._id,
      URL: link.URL,
      type: link.type,
    });
  };

  // Submit tax data function
  const submitLinkData = async (values, setSubmitting, resetForm) => {
    // Add init type and rate in values object
    const addLinkData = { ...values };

    try {
      if (editMode.edit) {
        // Call update link function
        await updateLink(user._id, editMode._id, values);
      } else {
        // Call add link function
        await addLink(user._id, addLinkData);
      }

      setTimeout(() => {
        // Close modal
        onModalClose();

        // Set submitting to false
        setSubmitting(false);

        // Reset form
        resetForm(linkAddSchema);
      }, 2000);
    } catch (err) {
      console.log("err", err);
    }
  };

  // On modal close function
  const onModalClose = () => {
    // Call modal open function
    modalOpen(false);

    if (editMode.edit) {
      // Reset form
      setEditMode({
        _id: null,
        edit: false,
        URL: null,
        type: null,
      });
    }
  };

  useEffect(() => {
    // Call get all links function
    fetchAllLinks(user._id);
  }, [user, fetchAllLinks]);

  if (props.links && props.links.length > 0) {
    modifiedInitialSchema.links = [...props.links];
  }

  if (editMode.edit) {
    modifiedLinkAddSchema.URL = editMode.URL;
    modifiedLinkAddSchema.type = editMode.type;
  }

  return (
    <div className="card card-custom">
      <Formik enableReinitialize initialValues={modifiedInitialSchema}>
        {({ values, resetForm, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Table hover responsive className="phoTable">
              <thead>
                <tr>
                  <th width="140px">Added On</th>
                  <th width="140px">Name/Type</th>
                  <th width="400x">Link</th>
                  <th width="140px">Active / Deactive</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="links">
                  {({ insert, remove, push }) => (
                    <>
                      {values.links && values.links.length > 0 ? (
                        values.links.map((el, index) => (
                          <tr key={index}>
                            <td width="140px">
                              {el.createdAt
                                ? moment(el.createdAt).format("L")
                                : ""}
                            </td>
                            <td width="140px">{el.type ? el.type : ""}</td>
                            <td width="400x">{el.URL ? el.URL : ""}</td>
                            <td className="text-center" width="140px">
                              <Switch
                                color="primary"
                                value={el.status === "ACTIVE" ? true : false}
                                checked={el.status === "ACTIVE" ? true : false}
                                onChange={(e) => {
                                  // Acitve/Deactive link
                                  deleteLink(user._id, el._id);
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                type="button"
                                className="px-4 py-2"
                                variant="outline-success"
                                onClick={() =>
                                  onEditButtonClickHandler(el, resetForm)
                                }
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : props.loading ? (
                        <tr>
                          <td colSpan="87" className="text-center">
                            Loading...
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No Data Available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </FieldArray>
              </tbody>
            </Table>
          </Form>
        )}
      </Formik>

      {/* Add Link Modal */}
      <Modal show={props.openModal} onHide={onModalClose} centered>
        <Modal.Header className="border-0 pb-0" closeButton>
          <Modal.Title className="pho_18_600">
            {editMode.edit ? "Update Link" : "Add New Link"}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={modifiedLinkAddSchema}
          validationSchema={linkAddValidationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log("values", values);
            // Set submitting to true
            setSubmitting(true);

            // Call submit tax data function
            submitLinkData(values, setSubmitting, resetForm);
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body className="pb-0">
                <div className="form-group">
                  <label className="pho_14_500 text-left">Name/Type</label>
                  <Field
                    type="text"
                    name="type"
                    className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 "
                  />
                  {touched.type && errors.type ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.type}</div>
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label className="pho_14_500 text-left">Link</label>
                  <Field
                    type="text"
                    name="URL"
                    className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 "
                  />
                  {touched.URL && errors.URL ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.URL}</div>
                    </div>
                  ) : null}
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 pt-0">
                <Button type="submit" variant="success" className="px-5 py-3">
                  {editMode.edit ? "Update" : "Add Now"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    links: state.link.links,
    loading: state.link.loading,
    openModal: state.subHeader.addNew,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modalOpen: (value) => dispatch(subheaderAction.addNew(value)),
    fetchAllLinks: (adminId) =>
      dispatch(invitationLinkAction.fetchAllLinks(adminId)),
    addLink: (adminId, taxData) =>
      dispatch(invitationLinkAction.addLink(adminId, taxData)),
    deleteLink: (adminId, taxId) =>
      dispatch(invitationLinkAction.deleteLink(adminId, taxId)),
    updateLink: (adminId, taxId, updateData) =>
      dispatch(invitationLinkAction.updateLink(adminId, taxId, updateData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationLink);
