// Action types
export const MASTER_START = 'MASTER_START';
export const MASTER_FAILD = 'MASTER_FAILD';
export const MASTER_SUCCESS = 'MASTER_SUCCESS';
export const MASTER_INIT = 'MASTER_INIT';

export const CATEGORY_CREATE = 'CATEGORY_CREATE';
export const CATEGORY_UPDATE = 'CATEGORY_UPDATE';
export const CATEGORIES_ALL_FETCH = 'CATEGORIES_ALL_FETCH';
export const CATEGORIES_ALL_FETCHED = 'CATEGORIES_ALL_FETCHED';
export const CATEGORY_FETCH = 'CATEGORY_FETCH';
export const CATEGORY_FETCHED = 'CATEGORY_FETCHED';
export const CATEGORY_DELETE = 'CATEGORY_DELETE';

export const AMENITY_CREATE = 'AMENITY_CREATE';
export const AMENITY_UPDATE = 'AMENITY_UPDATE';
export const AMENITIES_ALL_FETCH = 'AMENITIES_ALL_FETCH';
export const AMENITIES_ALL_FETCHED = 'AMENITIES_ALL_FETCHED';
export const AMENITY_FETCH = 'AMENITY_FETCH';
export const AMENITY_FETCHED = 'AMENITY_FETCHED';
export const AMENITY_DELETE = 'AMENITY_DELETE';

export const CATEGORY_AMENITY_CREATE = 'CATEGORY_AMENITY_CREATE';
export const CATEGORY_AMENITY_UPDATE = 'CATEGORY_AMENITY_UPDATE';
export const CATEGORY_AMENITY_DELETE = 'CATEGORY_AMENITY_DELETE';