import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { IconButton, InputAdornment } from '@material-ui/core';

import { actions } from '../_redux/authRedux';
import { setNewPassword } from "../_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

// Initial value
const initialValues = {
  newpassword: "",
  confirmpassword: ""
};

function SetNewPassword(props) {
  let history = useHistory();
  const { intl } = props;

  // State
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({
    newpassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });

  const SetNewPasswordSchema = Yup.object().shape({
    newpassword: Yup.string()
      .min(6, "Please enter valid New password")
      .max(20, "Please enter valid New password")
      .required('Please enter New password!'),
    confirmpassword: Yup.string()
      .min(6, "Please enter valid Confirm password")
      .required('Please enter Confirm password!')
      .when("newpassword", {
        is: (val) => (val && val.length > 5 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newpassword")],
          "Confirm password does not match with New password"
        ),
      }),
  });

  // Show new password function
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  // Show confirm password function
  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  // Enable loding function
  const enableLoading = () => {
    setLoading(true);
  };

  // Disable loding function
  const disableLoading = () => {
    setLoading(false);
  };

  // Get input class function
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: SetNewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // Enable loading
      enableLoading();

      // Send reset token request
      setNewPassword(props.user._id, values.newpassword)
        .then(res => {

          // Throw error when error found
          if (res.data.responseCode !== 200) {
            throw new Error(res.data.responseMessage);
          }

          // Disable loading
          disableLoading();

          // Set Success state to true
          setSuccess(true);

          // Set status to success
          setStatus('Password changed successfully.');

          setTimeout(() => {
            // Send back user to home page
            history.push('/home');
          }, 4000);
        })
        .catch(err => {
          // Set submitting false
          setSubmitting(false);

          // Disable loading when error found
          disableLoading();

          // Set Success state to true
          setSuccess(false);

          if (err.message) {
            // Set new error status to state
            setStatus(err.message);

          } else {
            // Set validation status to state
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          }
        });
    },
  });  

  // Get reset token from url
  const { resetToken } = useParams();

  useEffect(() => {
    // Set resetToken value to token state
    setToken(resetToken);
  }, [resetToken]);

  return (
    <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
    {/* begin::Content body */}
      <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
        <div className="login-form login-signin" style={{ display: "block" }}>
          <div className=" mb-10">
            <img
              alt="Logo"
              className="max-h-70px mb-30"
              src={toAbsoluteUrl("/media/logos/pho_logo.svg")}
            />
            <h3 className="pho_26_600">
              Set New Password
            </h3>
            <p className="text-muted font-weight-bold">
              Please never share with anyone for safe use.
            </p>
          </div>

          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status && (
              <div 
                className={ ['mb-10', 'alert', 'alert-custom', success ? 'alert-light-success' : 'alert-light-danger', 'alert-dismissible'].join(' ') }
              >
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            {/* end: Alert */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
            <label className="pho_14_500">New Password<sup className="text-danger">*</sup></label>
              <input
                type={values.showPassword ? 'text' : 'password'}
                className={`bg_light_gray form-control border-0 h-auto py-5 px-6 ${getInputClasses(
                "newpassword"
                )}`}
                name="newpassword"
                {...formik.getFieldProps("newpassword")}
              />
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {values.showPassword ? <img height="15px" alt="visibility off" src={toAbsoluteUrl("/media/custom/pho_visibilityoff.svg")}/> : <span className="pho-visibility"></span>}
                </IconButton>
              </InputAdornment>
              {formik.touched.newpassword && formik.errors.newpassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.newpassword}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}

                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                <label className="pho_14_500">Confirm Password<sup className="text-danger">*</sup></label>
                <input
                type={values.showConfirmPassword ? 'text' : 'password'}
                  className={`bg_light_gray form-control border-0 h-auto py-5 px-6 ${getInputClasses(
                  "confirmpassword"
                  )}`}
                  name="confirmpassword"
                  {...formik.getFieldProps("confirmpassword")}
              />
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                >
                  {values.showConfirmPassword ? <img height="15px" alt="visibility off" src={toAbsoluteUrl("/media/custom/pho_visibilityoff.svg")}/> : <span className="pho-visibility"></span>}
                </IconButton>
              </InputAdornment>
              {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirmpassword}
                  </div>
                </div>
              ) : null}

            </div>
            {/* end: Confirm Password */}
            
            <div className="form-group d-flex flex-wrap">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="btn btn-success px-8 py-4 my-3 mr-4"
              >
                <span>Submit</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (updateData) => dispatch(actions.updateUser(updateData))
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SetNewPassword));

