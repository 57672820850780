/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

// import { toAbsoluteUrl } from "../../../_helpers";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function AdvanceTablesWidget2(props) {

  let allAccommodations = (
    <li>
      No Data Available
    </li>
  );
  
  if (props.allAccommodations && props.allAccommodations.records && props.allAccommodations.records.length > 0) {
    allAccommodations = props.allAccommodations.records.map((accommodation, index) => {
      let amount = 0;

      if (accommodation.price && accommodation.price.length > 0) {
        accommodation.price.forEach(el => {
          amount += el.rate;
        });
      }

      return (
        <li key={ index }>
          <div className="left d-flex">
            <div className="symbol symbol-60 pho_rounded_12">
              <span className="symbol-label">
                <img
                  src={ accommodation.media[0] }
                  className="h-100 align-self-center"
                  alt="..."
                />
              </span>
            </div>
            <div className="content">
              <div className="pho_14_400 text-success text-uppercase">{ accommodation.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' }</div>
              <div className="pho_16_400 text-dark pho_ellips">
                { accommodation.name.en }
              </div>
              <div className="pho_14_400 text-dark pho_ellips">
                Location: { accommodation.address?accommodation.address.flat:'' } { accommodation.address?accommodation.address.streetAddress:'' }, { accommodation.address?accommodation.address.city:'' }, { accommodation.address?accommodation.address.country:'' }, { accommodation.address?accommodation.address.postcode:'' }
              </div>
            </div>
          </div>
          <div className="text-right right">
            <span className="text-nowrap text-dark pho_14_600 d-block">
              Price: $ { amount.toFixed(2) }
            </span>
            <span className="pho_14_400 pho_ellips">By { accommodation.landlordDetails?accommodation.landlordDetails.firstName:'' } { accommodation.landlordDetails?accommodation.landlordDetails.lastName:'' }</span>
          </div>
        </li>
      )
    });
  }

  return (
    <div className={`card card-custom ${props.className}`}>
      {/* Head */}
      <div className="card-header border-0 align-items-center">
        <h3 className="card-title pho_18_600 text-dark m-0">
          Accommodation
        </h3>
        <Link to="/accommodation-management" className="pho_16_400 text-success text-uppercase">More</Link>
      </div>
      {/* Body */}
      <div className="card-body p-0">
        <PerfectScrollbar
            options={perfectScrollbarOptions}
            className="scroll"
            style={{ maxHeight: 340, maxWidth:"100%", position: "relative" }}
        >
          <ul className="list-unstyled pho_accomodation_dashboard_tbl m-0">
            { allAccommodations }
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  );
}


// {/* <li>
//   <div className="left d-flex">
//     <div className="symbol symbol-60 pho_rounded_12">
//       <span className="symbol-label">
//         <img
//           src={toAbsoluteUrl("/media/custom/1.jpg")}
//           className="h-100 align-self-center"
//           alt="..."
//         />
//       </span>
//     </div>
//     <div className="content">
//       <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//       <div className="pho_16_400 text-dark pho_ellips">
//         Neque porro quisquam est qui dolorem
//       </div>
//       <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//     </div>
//   </div>
//   <div className="text-right right">
//     <span className="text-nowrap text-dark pho_14_600 d-block">
//       Price: $ 2451.81
//     </span>
//     <span className="pho_14_400 pho_ellips">By UserName</span>
//   </div>
// </li>
// <li>
// <div className="left d-flex">
//   <div className="symbol symbol-60 pho_rounded_12">
//     <span className="symbol-label">
//       <img
//         src={toAbsoluteUrl("/media/custom/1.jpg")}
//         className="h-100 align-self-center"
//         alt="..."
//       />
//     </span>
//   </div>
//   <div className="content">
//     <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//     <div className="pho_16_400 text-dark pho_ellips">
//       Neque porro quisquam est qui dolorem
//     </div>
//     <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//   </div>
// </div>
// <div className="text-right right">
//   <span className="text-nowrap text-dark pho_14_600 d-block">
//     Price: $ 2451.81
//   </span>
//   <span className="pho_14_400 pho_ellips">By UserName</span>
// </div>
// </li>
// <li>
// <div className="left d-flex">
//   <div className="symbol symbol-60 pho_rounded_12">
//     <span className="symbol-label">
//       <img
//         src={toAbsoluteUrl("/media/custom/1.jpg")}
//         className="h-100 align-self-center"
//         alt="..."
//       />
//     </span>
//   </div>
//   <div className="content">
//     <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//     <div className="pho_16_400 text-dark pho_ellips">
//       Neque porro quisquam est qui dolorem
//     </div>
//     <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//   </div>
// </div>
// <div className="text-right right">
//   <span className="text-nowrap text-dark pho_14_600 d-block">
//     Price: $ 2451.81
//   </span>
//   <span className="pho_14_400 pho_ellips">By UserName</span>
// </div>
// </li>
// <li>
// <div className="left d-flex">
//   <div className="symbol symbol-60 pho_rounded_12">
//     <span className="symbol-label">
//       <img
//         src={toAbsoluteUrl("/media/custom/1.jpg")}
//         className="h-100 align-self-center"
//         alt="..."
//       />
//     </span>
//   </div>
//   <div className="content">
//     <div className="pho_14_400 text-success text-uppercase">Workplace</div>
//     <div className="pho_16_400 text-dark pho_ellips">
//       Neque porro quisquam est qui dolorem
//     </div>
//     <div className="pho_14_400 text-dark pho_ellips">Location: New York, NY</div>
//   </div>
// </div>
// <div className="text-right right">
//   <span className="text-nowrap text-dark pho_14_600 d-block">
//     Price: $ 2451.81
//   </span>
//   <span className="pho_14_400 pho_ellips">By UserName</span>
// </div>
// </li> */}
