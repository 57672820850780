// Action types
export const CMS_START = 'CMS_START';
export const CMS_FAILD = 'CMS_FAILD';
export const CMS_SUCCESS = 'CMS_SUCCESS';
export const CMS_CREATE = 'CMS_CREATE';
export const CMS_CREATED = 'CMS_CREATED';
export const CMS_UPDATE = 'CMS_UPDATE';
export const CMS_UPDATED = 'CMS_UPDATED';
export const CMS_ALL_FETCH = 'CMS_ALL_FETCH';
export const CMS_ALL_FETCHED = 'CMS_ALL_FETCHED';
export const CMS_DETAIL_FETCH = 'CMS_DETAIL_FETCH';
export const CMS_DETAIL_FETCHED = 'CMS_DETAIL_FETCHED';
export const CMS_STATUS_CHANGE = 'CMS_STATUS_CHANGE';
export const CMS_ID_ADD = 'CMS_ID_ADD';
export const CMS_INIT = 'CMS_INIT';

// export const CMS_SKIP_UPDATE = 'CMS_SKIP_UPDATE';
// export const CMS_SKIP_INIT = 'CMS_SKIP_INIT';