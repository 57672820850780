// Action types
export const LANGUAGE_START = 'LANGUAGE_START';
export const LANGUAGE_FAILD = 'LANGUAGE_FAILD';
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS';
export const LANGUAGE_CREATE = 'LANGUAGE_CREATE';
export const LANGUAGE_UPDATE = 'LANGUAGE_UPDATE';
export const LANGUAGE_ALL_FETCH = 'LANGUAGE_ALL_FETCH';
export const LANGUAGE_ALL_FETCHED = 'LANGUAGE_ALL_FETCHED';
export const LANGUAGE_DETAIL_FETCH = 'LANGUAGE_DETAIL_FETCH';
export const LANGUAGE_DETAIL_FETCHED = 'LANGUAGE_DETAIL_FETCHED';
export const LANGUAGE_STATUS_CHANGE = 'LANGUAGE_STATUS_CHANGE';
export const LANGUAGE_ID_ADD = 'LANGUAGE_ID_ADD';
export const LANGUAGE_INIT = 'LANGUAGE_INIT';
// export const LANGUAGE_SKIP_UPDATE = 'LANGUAGE_SKIP_UPDATE';
// export const LANGUAGE_SKIP_INIT = 'LANGUAGE_SKIP_INIT';