// Action types
export const LINK_START = "LINK_START";
export const LINK_FAILD = "LINK_FAILD";
export const LINK_SUCCESS = "LINK_SUCCESS";
export const LINK_ADD = "LINK_ADD";
export const LINK_ALL_FETCH = "LINK_ALL_FETCH";
export const LINK_ALL_FETCHED = "LINK_ALL_FETCHED";
export const LINK_UPDATE = "LINK_UPDATE";
export const LINK_DELETE = "LINK_DELETE";
export const LINK_INIT = "LINK_INIT";
