import React, { useEffect, useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import { useHistory } from 'react-router-dom';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Col, Button, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import Select from 'react-select';

import { notify } from '../toaster';
import { useSubheader } from '../../layout';
import * as templateActions from '../../../app/modules/EmailTemplate';
import { addTemplateInitValues, addTemplateSchema } from './TemplateSchema';
import { addTemplate } from '../../../app/modules/EmailTemplate/templateCrud';

const AddTemplate = (props) => {
    const templateOptions = [];
    const history = useHistory();
    const subHeader = useSubheader();

    const modifiedInitialTabObj = Object.assign({}, addTemplateInitValues);

    const addTemplateFunction = async (values, resetForm, setSubmitting) => {
        try {
            values.type = 'EMAIL';

            // Add template function
            const { data } = await addTemplate(props.user._id, values);

            // If response error found throw error
            if (data.responseCode !== 200) {
                throw new Error(data.responseMessage);
            }

            setTimeout(() => {
                // Set submitting form to false
                setSubmitting(false);

                // Reset form to initial values
                resetForm(addTemplateInitValues);

                // Go back to property management page                            
                history.push('/template-management');
            }, 3000);

        } catch (err) { 
            console.log(err);

            // Set submitting form to false
            setSubmitting(false);

            // Notification
            notify('error', err.message);
        }
    };

    useEffect(() => {
        // Set sub header title
        const title = 'Add New Template';

        subHeader.setTitle(title);

        // Get all template entities
        props.getAllTemplateEntities(props.user._id);
    }, []);

    if (props.entities && props.entities.length > 0) {
        modifiedInitialTabObj.templates = props.entities;

        props.entities.forEach(entity => {
            templateOptions.push({ value: entity.templateName, label: entity.templateName });
        });
    }

    return (
        <>
            {
                !props.loading && props.entities ?
                    <Formik
                        enableReinitialize
                        initialValues={ modifiedInitialTabObj }
                        validationSchema={ addTemplateSchema }
                        onSubmit={(values, { setSubmitting, resetForm }) => {  
                            console.log('values', values);

                            // // Set submitting to true
                            // setSubmitting(true);

                            // Call add user function
                            addTemplateFunction(values, resetForm, setSubmitting);
                        }}
                    >
                        {({
                            values, 
                            errors, 
                            touched, 
                            isSubmitting,
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched
                        }) => (
                            <Form className="justify-content-center pho_cmspage row" onSubmit={ handleSubmit }>
                                <Col xl={ 6 }>
                                    <div className="card card-custom">
                                        <Button 
                                            type="submit"
                                            variant="success" 
                                            // disabled={ isSubmitting }
                                            className="desktopTopRight px-5 py-3"
                                        >
                                            Submit
                                        </Button>
                                        <div className="form-group">
                                            <label className="pho_14_500">Template Name</label>
                                            {/* <Field 
                                                name="mailName"
                                                placeholder="Enter Template Name"
                                                className="form-control py-5 px-6 pho_16_500"
                                            /> */}
                                            <Select
                                                value={ { value: values.mailName, label: values.mailName } }
                                                placeholder="Select Template"
                                                className={`form-control p-0 pho_16_500`}
                                                classNamePrefix="phoSelect"
                                                options={ templateOptions }
                                                onChange={ (e) => {

                                                    const entities = [{ values: '', label: '' }];
                                                    values.templates.forEach(template => {
                                                        if (e.value && template.templateName === e.value) {
                                                            template.templateEntities.forEach(entity => {
                                                                entities.push({ value: entity, label: entity });
                                                            });
                                                        }
                                                    });

                                                    // Set field value
                                                    setFieldValue('entity', '');
                                                    setFieldValue('mailName', e.value);
                                                    setFieldValue('entities', entities);
                                                } }
                                                theme={ theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DCF4E4',
                                                        primary75: '#DCF4E4',
                                                        primary50: '#DCF4E4',
                                                        primary:"#50C878",
                                                    },
                                                }) }
                                            />
                                            {touched.mailName && errors.mailName ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{ errors.mailName }</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label className="pho_14_500">Email Title</label>
                                            <Field 
                                                name="mailTitle"
                                                placeholder="Enter Email Title"
                                                className="form-control py-5 px-6 pho_16_500"
                                            />
                                            {touched.mailTitle && errors.mailTitle ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.mailTitle}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label className="pho_14_500">Email Subject</label>
                                            <Field 
                                                name="mailSubject"
                                                placeholder="Enter Email Subject"
                                                className="form-control py-5 px-6 pho_16_500"
                                            />
                                            {touched.mailSubject && errors.mailSubject ? (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.mailSubject}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label className="pho_14_500">Entity</label>
                                            <Select
                                                value={ { value: values.entity, label: values.entity } }
                                                onChange={ (e) => {
                                                    // Set field value
                                                    setFieldValue('entity', e.value);
                                                } }
                                                placeholder="Select"
                                                className={`form-control p-0  pho_16_500`}
                                                classNamePrefix="phoSelect"
                                                options={ values.entities }
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DCF4E4',
                                                        primary75: '#DCF4E4',
                                                        primary50: '#DCF4E4',
                                                        primary:"#50C878",
                                                    },
                                                })}

                                            />                                            
                                        </div>
                                        <Button 
                                            size="sm"
                                            type="button"
                                            variant="success" 
                                            className="px-5 py-3"
                                            onClick={ (e) => {
                                                e.preventDefault();
                                                let emailBody = '';

                                                if (values.entity && values.entity.length > 0) {
                                                    const string = values.mailBody.replace(/<\/?[^>]+(>|$)/g, "")
                                                    // Add current entity into email body
                                                    emailBody = string + '${' + values.entity + '}';

                                                    // Set field value
                                                    setFieldValue('mailBody', emailBody);
                                                }                                        
                                            } }
                                        >
                                            Append
                                        </Button>
                                        {/* <div className="pho_16_400 mt-5 mb-3">Image</div>
                                        <div className="phobox">
                                            <input type="file" onChange={imageUpload} />
                                            <div className="displayLabel" ><span>{name}</span><Button variant="success">Upload</Button></div>
                                        </div> */}
                                        <div className="pho_16_400 mt-5 mb-3">Email Body</div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={ values.mailBody }
                                            // onInit={ editor => {
                                            //     // You can store the "editor" and use when it is needed.
                                            //     console.log( 'Editor is ready to use!', editor );
                                            // } }
                                            // onChange={ ( event, editor ) => {
                                            //     const data = editor.getData();
                                            //     console.log( { event, editor, data } );
                                            // } }
                                            // onBlur={ ( event, editor ) => {
                                            //     console.log( 'Blur.', editor );
                                            // } }
                                            onBlur={ ( event, editor ) => {
                                                const data = editor.getData();                                                

                                                // Set field touched value
                                                setFieldTouched('mailBody', true);

                                                // Set field value
                                                setFieldValue('mailBody', data);
                                            } }
                                        />
                                        {touched.mailBody && errors.mailBody ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.mailBody}</div>
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                            </Form>
                        )}                
                    </Formik>
                :
                    <div id="compnent-splash-screen" className="compnent-splash-screen">
                        <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                            <circle 
                                className="path"
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                            ></circle>
                        </svg>
                    </div>
            }            
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,   
        error: state.template.error,
        loading: state.template.loading,
        entities: state.template.entities
    };
};

const mapDispatchToProps = dispatch => {
    return {
        templateInit: () => dispatch(templateActions.templateInit()),
        addTemplate: (adminId, values) => dispatch(templateActions.addTemplate(adminId, values)),
        getAllTemplateEntities: (adminId) => dispatch(templateActions.fetchAllTemplateEntities(adminId))     
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);
