import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    addCMS,
    updateCMS,
    getAllCms,
    getCMSDetail,
    cmsStatusChange
} from '../cmsCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all cms function
export function* fetchingAllCms(action) {  
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all cms
        const result = yield getAllCms(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allCMSFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Add CMS function
export function* addingCMS(action) { 
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to add CMS
        const result = yield addCMS(action.adminId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get CMS detail function
export function* fetchingCMSDetail(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch CMS detail
        const result = yield getCMSDetail(action.adminId, action.cmsId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.cmsDetailFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Change CMS status function
export function* changingCMSStatus(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield cmsStatusChange(action.adminId, action.cmsId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Store dashboard data into store
        yield put(actions.fetchAllCMS(action.adminId));     

        // Notification
        notify('success', 'CMS status updated successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// CMS update function
export function* updatingCMS(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield updateCMS(action.adminId, action.cmsId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Store dashboard data into store
        yield put(actions.fetchAllCMS(action.adminId));

        // Notification
        notify('success', 'CMS updated successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Notification
        notify('error', 'Error while updating CMS page');

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};