import * as Yup from 'yup';

// Phone number validation regex
const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

// Password validation regex
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;

// Init value for landlord Edit
export const initialValues = {
    firstName: '',
    lastName: '',
    emailId: '',
    contactNumber: '',
    profilePicture: '',
    document: []
};

// Update landlord schema
export const updateLandlordSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter First name")
        .matches(/^[^<>*.!@#?%$]*$/, 'Please enter valid First name')
        .test('First name', 'Please enter valid First name', (value) => {
            if (!/^\d+$/.test(value)) {        
                return true;
            }
        })
        .required('Please enter First name'),
    lastName: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter Last name")
        .matches(/^[^<>*.!@#?%$]*$/, 'Please enter valid Last name')
        .test('Last name', 'Please enter valid Last name', (value) => {
            if (!/^\d+$/.test(value)) {        
                return true;
            }
        })
        .required('Please enter Last name'),
    contactNumber: Yup.string()
        .matches(rePhoneNumber, 'Please enter valid Contact number')
        .required('Please enter Contact number'),
    profilePicture: Yup.string()
        .required('Profile picture is required'),
    emailId: Yup.string()
        .email("Please enter valid Email")
        .min(3, "Please enter valid Email")
        .max(50, "Please enter valid Email")
        .required('Please enter Email')
});

// Init value for landlord add
export const landlordAddInitialValues = {
    firstName: '',
    lastName: '',
    emailId: '',
    contactNumber: '',
    profilePicture: '',
    password: '',
    confirmPassword: ''
};

// Add landlord schema
export const addLandlordSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter First name")
        .matches(/^[^<>*.!@#?%$]*$/, 'Please enter valid First name')
        .test('First name', 'Please enter valid First name', (value) => {
            if (!/^\d+$/.test(value)) {        
                return true;
            }
        })
        .required('Please enter First name'),
    lastName: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter Last name")
        .matches(/^[^<>*.!@#?%$]*$/, 'Please enter valid Last name')
        .test('Last name', 'Please enter valid Last name', (value) => {
            if (!/^\d+$/.test(value)) {        
                return true;
            }
        })
        .required('Please enter Last name'),
    contactNumber: Yup.string()
        .matches(rePhoneNumber, 'Please enter valid Contact number')
        .required('Please enter Contact number'),
    profilePicture: Yup.string()
        .required('Profile picture is required'),
    emailId: Yup.string()
        .email("Please enter valid Email")
        .min(3, "Please enter valid Email")
        .max(50, "Please enter valid Email")
        .required('Please enter Email'),
    password: Yup.string()
        .trim()
        .min(6, 'Please enter valid Password')
        .max(32, 'Please enter valid Password')
        .matches(
            passwordRegex,
            "Password should contain min 6 and max 32 characters with at least one Alphanumerical and Special character"
            // "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required('Please enter Password'),
    confirmPassword: Yup.string()
        .trim()
        .min(6, 'Please enter valid Confirm password')
        .max(32, 'Please enter valid Confirm password')
        .matches(
            passwordRegex,
            "Password should contain min 6 and max 32 characters with at least one Alphanumerical and Special character"
            // "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )       
        .required('Please enter Confirm password')      
        .when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Confirm password does not match with Password"
            )
        }) 
});

// Initial tab value
export const initialLandlordTableValues = {
    landlords: [
        // {
        //     status: '',
        //     emailId: '',
        //     lastName: '',
        //     createdAt: '',
        //     firstName: '',
        //     checkbox: false,
        //     contactNumber: '',
        //     profilePicture: '',
        // }
    ],
    checkbox: false
};