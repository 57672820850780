// Action types
export const TEMPLATE_START = 'TEMPLATE_START';
export const TEMPLATE_FAILD = 'TEMPLATE_FAILD';
export const TEMPLATE_SUCCESS = 'TEMPLATE_SUCCESS';
export const TEMPLATE_CREATE = 'TEMPLATE_CREATE';
export const TEMPLATE_UPDATE = 'TEMPLATE_UPDATE';
export const TEMPLATE_ALL_FETCH = 'TEMPLATE_ALL_FETCH';
export const TEMPLATE_ALL_FETCHED = 'TEMPLATE_ALL_FETCHED';
export const TEMPLATE_ENTITIES_FETCH = 'TEMPLATE_ENTITIES_FETCH';
export const TEMPLATE_ENTITIES_FETCHED = 'TEMPLATE_ENTITIES_FETCHED';
export const TEMPLATE_DETAIL_FETCH = 'TEMPLATE_DETAIL_FETCH';
export const TEMPLATE_DETAIL_FETCHED = 'TEMPLATE_DETAIL_FETCHED';
export const TEMPLATE_STATUS_CHANGE = 'TEMPLATE_STATUS_CHANGE';
export const TEMPLATE_ID_ADD = 'TEMPLATE_ID_ADD';
export const TEMPLATE_INIT = 'TEMPLATE_INIT';
export const TEMPLATE_SKIP_UPDATE = 'TEMPLATE_SKIP_UPDATE';
export const TEMPLATE_SKIP_INIT = 'TEMPLATE_SKIP_INIT';