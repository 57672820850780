import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 10,
    error: null,
    queryId: null,
    queries: null,
    loading: null,
    queryDetail: null,
};

// Reducer
export const queryReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.QUERY_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.QUERY_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.QUERY_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.QUERY_ALL_FETCHED:
            return {
                ...state,
                queries: action.queries
            };    
        case actionTypes.QUERY_ALL_DATA_REMOVE:
            return {
                ...state,
                queries: null,
                queryId: null,
                queryDetail: null
            }; 
        case actionTypes.QUERY_FETCHED:
            return {
                ...state,
                queryDetail: action.queryDetail
            };  
        case actionTypes.QUERY_ID_ADD:
            return {
                ...state,
                queryId: action.queryId
            };  
        case actionTypes.QUERY_ID_REMOVE:
            return {
                ...state,
                queryId: null
            };  
        case actionTypes.QUERY_DATA_REMOVE:
            return {
                ...state,
                queryDetail: null
            };  
        case actionTypes.QUERY_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.QUERY_SKIP_INIT:
            return {
                ...state,
                skip: 1
            };
        default:
            return state;
    };
};