import React, { useState, useEffect } from "react";
import { Button, Col, Pagination, Row, Modal, Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import moment from 'moment';
import FuzzySearch from 'fuzzy-search';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { toAbsoluteUrl } from "../../_helpers";
import * as subheaderAction from '../../../app/modules/Subheader';
import * as accommodationActions from '../../../app/modules/Accommodation';
// import ItemDropdown from '../../../_metronic/layout/components/extras/dropdowns/ItemDropdown';

const AccommodationRequest = (props) => {
    let pendingAccommodations = [];
    const pageNumbers = [];
    let totalPages;
    let result = [];
    let skipInit = props.skip;

    // State
    const [showAccept, setShowAccept] = useState({
        show: false,
        accommodation: null
    });
    const [showRejection, setShowRejection] = useState({
        show: false,
        accommodation: null
    });

    // Handle accept close function    
    const handleAcceptClose = () => setShowAccept({ show: false });

    // Handle accept show function
    const handleAcceptShow = (e, accommodation) => {
        
        // Set show accept state
        setShowAccept({
            show: true,
            accommodation: { ...accommodation }
        });
    };

    // Handle reject close function
    const handleRejectionClose = () => setShowRejection({ show: false });

    // Handle reject show function
    const handleRejectionShow = (e, accommodation) => {

        // Set show rejection state
        setShowRejection({
            show: true,
            accommodation: { ...accommodation }
        });
    };

    // On yes click handler
    const onYesClickHandler = async () => {        
        try {
            const skip = (props.skip - 1) * props.limit;
            const accommodation = { ...showAccept.accommodation };
            const values = {
                isVerified: true
            };

            // Call accommodation request verification update function
            await props.accommodationReqVerification(
                props.user._id,
                accommodation._id,
                values,
                props.column,
                props.dir,
                skip,
                props.limit,
                props.type
            );

            setTimeout(() => {

                // Call handle accept close function to hide modal
                handleAcceptClose();
            }, 2000);
        } catch (err) {
            console.log(err);
        }
    };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    useEffect(() => {
        let skip = (props.skip - 1) * props.limit;

        if (props.search) {
            skip = 0;
        }

        // Fetch all accommodation of admin
        props.getPendingAccommodations(
            props.user._id,
            props.column,
            props.dir,
            skip,
            props.limit,
            props.type,
            props.search
        );
    }, [props.skip, props.type, props.column, props.dir, props.search]);

    useEffect(() => {

        // Remove data from store
        return () => {
            // Clean accommodation data
            props.accommodationInit();

            // Clean accommodation data
            props.accommodationSkipInit();

            // Clean subheader search, sort and type value
            props.initSearchSorting();
        };
    }, []);

    if (props.pendingAccommodations && props.pendingAccommodations.records && props.pendingAccommodations.records.length > 0) {        
        // Integrate fuzzy search here
        if (props.search && props.search.length > 0) {
            skipInit = 1;
            const searcher = new FuzzySearch(props.pendingAccommodations.records, [
                'name.en', 'address.flat', 'address.streetAddress', 'address.city', 'address.country', 'address.postCode'
            ], {
                sort: true
            });

            result = searcher.search(props.search);

            totalPages = Math.ceil(result.length / props.limit);
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            };

        } else {
            result = props.pendingAccommodations.records;

            totalPages = Math.ceil(props.pendingAccommodations.recordsTotal / props.limit);
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            };
        }
        
        // totalPages = Math.ceil(result.length / props.limit);
        // for (let i = 1; i <= totalPages; i++) {
        //     pageNumbers.push(i);
        // };

        result.forEach((accommodation, index) => {            
            if (!accommodation.hasOwnProperty('isVerified')) {
                let amount = 0;
        
                if (accommodation.price && accommodation.price.length > 0) {
                    accommodation.price.forEach(el => {
                        amount += el.rate;
                    });
                }

                pendingAccommodations.push(
                    <Col xl={ 6 } key={ index }>
                        <div className="pho_bx1 border-0 bg-white card-custom">
                            <div className="left">
                                <img
                                    className="phoimgSize120"
                                    src={ accommodation.media[0] }
                                    alt="..."
                                />
    
                                <div className="leftInRight">
                                    <div className="pho_16_400 pho_ellips">
                                        { accommodation.name.en }
                                    </div>
                                    <div className="pho_14_400">
                                        Added on: { accommodation.createdAt ? moment().format('L') : '' }
                                    </div>
                                    <div className="pho_14_400 mb-2">
                                        Location: { accommodation.address.flat } { accommodation.address.streetAddress }, { accommodation.address.city }, { accommodation.address.country }, { accommodation.address.postcode }
                                    </div>
                                    <div className="pho_14_600">Price: $ { amount.toFixed(2) }</div>
                                    <div className="pho_14_400 text-success">By { accommodation.landlordDetails.firstName }</div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="pho_detailMark pho_14_400">{ accommodation.type === 'WORKPLACE' ? 'Workplace' : 'Home Office' }</div>
                                    <div>
                                        <Button 
                                            onClick={ (e) => handleAcceptShow(e, accommodation) } 
                                            variant="link" 
                                            className="p-0"
                                        >
                                            <SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")} />
                                        </Button>
                                        <Button 
                                            onClick={ (e) => handleRejectionShow(e, accommodation) } 
                                            variant="link" 
                                            className="p-0 ml-2"
                                        >
                                            <SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")} />
                                        </Button>
                                    </div>
                            </div>
                        </div>
                    </Col>
                );
            }
        });
    }
    
    return (
        <>
            {
                !props.loading ?
                    <Row className="phoGap14">
                        { 
                            pendingAccommodations.length > 0 ?
                                pendingAccommodations
                            :
                                <Col>No Data Available</Col>
                        }
                    </Row>  
                :
                    <div id="compnent-splash-screen" className="compnent-splash-screen">
                        <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                            <circle 
                                className="path"
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                            ></circle>
                        </svg>
                    </div>
            } 

            {/* Accept modal */}
            <Modal
                show={ showAccept.show }
                onHide={ handleAcceptClose }
                size="sm"
                className="pho_delete_modal"
                centered
            >
                <Modal.Body>
                    <p className="pho_16_400 text-center">
                        Are you sure to accept the accommodation { showAccept.accommodation && `(${showAccept.accommodation.name.en})` }? 
                    </p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center border-0">
                    <Button 
                        variant="link" 
                        className="text-dark px-5 mr-5" 
                        onClick={ handleAcceptClose }
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="success" 
                        className="px-5" 
                        onClick={ onYesClickHandler }
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Rejection modal */}
            <Modal 
                show={ showRejection.show } 
                onHide={ handleRejectionClose } 
                className="pho_delete_modal" 
                centered
            >
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title className="pho_18_600">Reason of Rejection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        enableReinitialize
                        initialValues={ { comment: '' } }
                        validationSchema={ Yup.object().shape({
                            comment: Yup.string()
                                .trim()
                                .required('Please enter Comment')
                        }) }
                        onSubmit={(values, { setSubmitting, resetForm }) => {  
                            // Set submitting to true
                            setSubmitting(true);

                            const skip = (props.skip - 1) * props.limit;
                            const accommodation = { ...showRejection.accommodation };
                            const valuesObj = {
                                isVerified: false,
                                comment: values.comment
                            };
                            
                            // Call accommodation request verification update function
                            props.accommodationReqVerification(
                                props.user._id,
                                accommodation._id,
                                valuesObj,
                                props.column,
                                props.dir,
                                skip,
                                props.limit,
                                props.type
                            );
    
                            setTimeout(() => {
                                // Set submitting form to false
                                setSubmitting(false);

                                // Reset form to initial values
                                resetForm({ comment: '' });
    
                                // Call reject close function to hide comment modal
                                handleRejectionClose();
                            }, 2000);
                        }}
                    >
                        {({
                            values, 
                            errors, 
                            touched, 
                            isSubmitting,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <Form onSubmit={ handleSubmit }>
                                <Form.Label className="pho_14_500">Comment</Form.Label>
                                <Field 
                                    name="comment"
                                    value={ values.comment }
                                    className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 " 
                                />
                                {touched.comment && errors.comment ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{errors.comment}</div>
                                    </div>
                                ) : null}
                                <div className="text-right pt-5">
                                    <Button 
                                        type="submit"
                                        variant="success" 
                                        className="px-5 py-3" 
                                        disabled={ isSubmitting }
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            {/* Pagination */}
            { 
                pendingAccommodations.length > 0 && 
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                        <div className="pho_14_400 pho_opacity_5">
                            {
                                props.search ? 
                                    result.length > 0 ? `Displaying ${ skipInit > 1 ? ((skipInit - 1) * props.limit) + 1 : 1 } - ${ result.length > (props.limit * props.skip) ? props.limit * props.skip : result.length } of ${ result.length } records` : ''
                                :
                                    `Displaying ${ props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - ${ pendingAccommodations.length > (props.limit * props.skip) ? props.limit * props.skip : pendingAccommodations.length } of ${ pendingAccommodations.length } records`
                            }
                        </div>
                        {
                            props.search && !result.length > 0 ?
                                null
                            :
                                <Pagination>
                                    {/* Go to first page */}
                                    <Pagination.First
                                        disabled={ props.skip === 1 || skipInit === 1 } 
                                        onClick={ goToFirstPage }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                                    </Pagination.First>

                                    {/* Go to previous page */}
                                    <Pagination.Prev
                                        disabled={ props.skip === 1 || skipInit === 1 } 
                                        onClick={ prevPageHandler }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                                    </Pagination.Prev>

                                    {/* All pages */}
                                    {
                                        pageNumbers.map((number, i) => {
                                            if (
                                                !props.search &&
                                                number >= parseInt(props.skip) - 3 &&
                                                number <= parseInt(props.skip) + 3 
                                            ) {
                                                return (
                                                    <Pagination.Item
                                                        key={ i }
                                                        id={ number }
                                                        disabled={ number === props.skip }
                                                        active={ number === props.skip }
                                                        onClick={ pageChangeHandler }
                                                    >
                                                        { number }
                                                    </Pagination.Item>
                                                );
                                            } else if (
                                                props.search &&
                                                number >= parseInt(skipInit) - 3 &&
                                                number <= parseInt(skipInit) + 3 
                                            ) {
                                                return (
                                                    <Pagination.Item
                                                        key={ i }
                                                        id={ number }
                                                        disabled={ number === skipInit }
                                                        active={ number === skipInit }
                                                        onClick={ pageChangeHandler }
                                                    >
                                                        { number }
                                                    </Pagination.Item>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }

                                    {/* Go to next page */}
                                    <Pagination.Next
                                        disabled={ props.skip === totalPages || skipInit === totalPages }  
                                        onClick={ nextPageHandler }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                                    </Pagination.Next>

                                    {/* Go to last page */}
                                    <Pagination.Last
                                        disabled={ props.skip === totalPages || skipInit === totalPages }  
                                        onClick={ goToLastPage }
                                    >
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                                    </Pagination.Last>
                                </Pagination>
                        }
                    </div>
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        skip: state.accommodation.skip,
        limit: state.accommodation.limit,
        column: state.subHeader.column,
        dir: state.subHeader.dir,
        type: state.subHeader.type,
        search: state.subHeader.search,   
        loading: state.accommodation.loading,    
        pendingAccommodations: state.accommodation.pendingAccommodations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // addUser: (adminId, values) => dispatch(userActions.addUser(adminId, values)),
        initSearchSorting: () => dispatch(subheaderAction.initState()),
        updateSkip: (skip) => dispatch(accommodationActions.updateSkip(skip)),
        accommodationInit: () => dispatch(accommodationActions.accommodationInit()),
        accommodationSkipInit: () => dispatch(accommodationActions.accommodationSkipInit()),
        getPendingAccommodations: (
            adminId,
            column,
            dir,
            skip,
            limit,
            type,
            search
        ) => dispatch(accommodationActions.fetchPendingAccommodation(adminId, column, dir, skip, limit, type, search)),
        accommodationReqVerification: (
            adminId,
            accommodationId,
            values,
            column,
            dir,
            skip,
            limit,
            type
        ) => dispatch(accommodationActions.accommodationReqVerification(adminId, accommodationId, values, column, dir, skip, limit, type))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationRequest);

{/* <Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400 text-success">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className=" pho_14_400">Home Office</div>
            <div>
                <Button onClick={handleAcceptShow} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
                <Button onClick={handleRejectionShow} variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400 text-success">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Button onClick={handleAcceptShow} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
                <Button onClick={handleRejectionShow} variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400 text-success">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Button onClick={handleAcceptShow} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
                <Button onClick={handleRejectionShow} variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400 text-success">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Home Office</div>
            <div>
                <Button onClick={handleAcceptShow} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
                <Button onClick={handleRejectionShow} variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400 text-success">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Button onClick={handleAcceptShow} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
                <Button onClick={handleRejectionShow} variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400 text-success">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Home Office</div>
            <div>
                <Button onClick={handleAcceptShow} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
                <Button onClick={handleRejectionShow} variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
            </div>
        </div>
    </div>
</Col>
<Col xl={6}>
    <div className="pho_bx1 border-0 bg-white card-custom">
        <div className="left">
            <img
                className="phoimgSize120"
                src={toAbsoluteUrl("/media/custom/1.jpg")}
                alt="..."
            />
            <div className="leftInRight">
                <div className="pho_16_400 pho_ellips">Neque porro quisquam est qui dolorem</div>
                <div className="pho_14_400">Added on: 06/03/2020</div>
                <div className="pho_14_400 mb-2">Location: New York, NY</div>
                <div className="pho_14_600">Price: $ 2451.81</div>
                <div className="pho_14_400 text-success">By UserName</div>
            </div>
        </div>
        <div className="right">
            <div className="pho_detailMark pho_14_400">Workplace</div>
            <div>
                <Button onClick={handleAcceptShow} variant="link" className="p-0"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_reject.svg")}/></Button>
                <Button onClick={handleRejectionShow} variant="link" className="p-0 ml-2"><SVG width="30px" src={toAbsoluteUrl("/media/custom/pho_accept.svg")}/></Button>
            </div>
        </div>
    </div>
</Col> */}