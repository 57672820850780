import axios from "axios";

// Booking url
export const PAYMENT_RECEIVED_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllPaymentsReceived`;
export const PAY_OUT_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllPayOuts`;
export const REFUND_PAYMENT_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/refundPayment`;
export const RELEASE_PAYMENT_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/releasePayment`;

// All payment received function
export function getAllPaymentsReceived(adminId, column, dir, status, skip, limit, user) {
  return axios.get(`${PAYMENT_RECEIVED_URL}/${adminId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${status ? `&status=${status}` : '' }${user ? `&user=${user}`: '' } `);
  // ${fromDate && `&fromDate=${fromDate}` }${toDate && `&toDate=${toDate}` }${column && `&column=${column}` }${dir && `&dir=${dir}` }&type=${type && type}&searchColumn=${searchColumn && searchColumn}&searchVal=${searchVal && searchVal}
};

// Payout function
export function getAllPayOuts(adminId, landlordId) {
  return axios.get(`${PAY_OUT_URL}/${adminId}/${landlordId}`);
};

// Refund payment function
export function refundPayment(adminId, userId, bookingId, values) {
  return axios.post(`${REFUND_PAYMENT_URL}/${adminId}/${userId}/${bookingId}`, { ...values });
};

// Relase payment function
export function releasePayment(adminId, landlordId, values) {
  return axios.post(`${RELEASE_PAYMENT_URL}/${adminId}/${landlordId}`, { ...values });
};