import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.BOOKING_START
    };
};

// When booking detail fetch and update start
export const bookingDetailStart = () => {
    return {
        type: actionTypes.BOOKING_DETAIL_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.BOOKING_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.BOOKING_SUCCESS
    };
};

// When fetch Bookings
export const fetchAllBookings = (adminId, fromDate, toDate, status, typeValue, skip, limit, search) => {
    return {
        type: actionTypes.BOOKING_ALL_FETCH,
        adminId, 
        fromDate, 
        toDate, 
        status, 
        typeValue, 
        skip, 
        limit,
        search
    };
};

// When bookings fetched
export const allBookingsFetched = (bookings) => {
    return {
        type: actionTypes.BOOKING_ALL_FETCHED,
        bookings
    };
};

// When fetch Booking detail
export const fetchBookingDetail = (adminId, bookingId) => {
    return {
        type: actionTypes.BOOKING_DETAIL_FETCH,
        adminId,
        bookingId
    };
};

// When booking detail fetched
export const bookingDetailFetched = (bookingDetail) => {
    return {
        type: actionTypes.BOOKING_DETAIL_FETCHED,
        bookingDetail
    };
};

// When fetch user Booking
export const fetchUserBooking = (adminId, userId, skip, limit) => {
    return {
        type: actionTypes.BOOKING_USER_FETCH,
        adminId,
        userId,
        skip,
        limit
    };
};

// When user booking fetched
export const userBookingFetched = (userBookings) => {
    return {
        type: actionTypes.BOOKING_USER_FETCHED,
        userBookings
    };
};

// Booking init function
export const bookingSkipUpdate = (skip) => {
    return {
        type: actionTypes.BOOKING_SKIP_UPDATE,
        skip
    };
};

// Remvove booking detail function
export const removeBookingDetail = () => {
    return {
        type: actionTypes.BOOKING_DETAIL_REMOVE
    };
};

// Booking init function
export const bookingSkipInit = () => {
    return {
        type: actionTypes.BOOKING_SKIP_INIT,
    };
};

// Booking init function
export const bookingInit = () => {
    return {
        type: actionTypes.BOOKING_INIT,
    };
};