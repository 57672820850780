import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Table, Pagination } from "react-bootstrap";
import { Switch } from "@material-ui/core";
import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import moment from "moment";

import { notify } from "../toaster";
import ItemDropdown from "./ItemDropdown";
import { toAbsoluteUrl } from "../../_helpers";
import { initialUserTableValues } from "./UserSchema";
import * as userActions from "../../../app/modules/User";
import * as subheaderAction from "../../../app/modules/Subheader";

import "react-toastify/dist/ReactToastify.min.css";

const UserManagement = (props) => {
  const history = useHistory();
  const pageNumbers = [];
  let totalPages;
  let skipInit = props.skip;

  const modifiedInitialTabObj = Object.assign({}, initialUserTableValues);

  // State
  const [deleteModal, setDeleteModal] = useState(false);
  const [user, setUser] = useState({
    _id: "",
    status: "",
    emailId: "",
    lastName: "",
    firstName: "",
    createdAt: "",
    contactNumber: "",
  });

  // Show delete modal
  const showDeleteModal = (e, user, multiple) => {
    // Show delete modal
    setDeleteModal(true);

    if (multiple) {
      // Set user state
      setUser({
        values: user,
        multiple,
      });
    } else {
      // Set user state
      setUser({
        _id: user._id,
        status: user.status,
        emailId: user.emailId,
        lastName: user.lastName,
        firstName: user.firstName,
        createdAt: user.createdAt,
        contactNumber: user.contactNumber,
      });
    }
  };

  // Page change handler
  const pageChangeHandler = (e) => {
    // Update current page in store
    props.updateSkip(parseInt(e.target.id));
  };

  // Page change handler
  const nextPageHandler = (e) => {
    const nextPage = props.skip + 1;

    // Update current page in store
    props.updateSkip(nextPage);
  };

  // Page change handler
  const prevPageHandler = (e) => {
    const prevPage = props.skip - 1;

    // Update current page in store
    props.updateSkip(prevPage);
  };

  // Page change handler
  const goToFirstPage = (e) => {
    const firstPage = 1;

    // Update current page in store
    props.updateSkip(firstPage);
  };

  // Page change handler
  const goToLastPage = (e) => {
    const lastPage = totalPages;

    // Update current page in store
    props.updateSkip(lastPage);
  };

  // On Name sort handler
  const onNameSort = () => {
    if (
      props.column &&
      props.column === "firstName" &&
      props.dir &&
      props.dir === "dsc"
    ) {
      props.addSorting("firstName", "asc");
    } else {
      props.addSorting("firstName", "dsc");
    }
  };

  // On Email sort handler
  const onEmailSort = () => {
    if (
      props.column &&
      props.column === "emailId" &&
      props.dir &&
      props.dir === "dsc"
    ) {
      props.addSorting("emailId", "asc");
    } else {
      props.addSorting("emailId", "dsc");
    }
  };

  // On Contact Number sort handler
  const onContactNumberSort = () => {
    if (
      props.column &&
      props.column === "contactNumber" &&
      props.dir &&
      props.dir === "dsc"
    ) {
      props.addSorting("contactNumber", "asc");
    } else {
      props.addSorting("contactNumber", "dsc");
    }
  };

  // On view button click
  const onViewButtonHandler = (e, user) => {
    e.preventDefault();

    if (user._id) {
      // Go to property detail page
      history.push(`/user-details/${user._id}`);
    }
  };

  useEffect(() => {
    let skip = (props.skip - 1) * props.limit;

    if (props.search) {
      skip = 0;
    }

    // Get all users from database
    props.getAllUsers(
      props.user._id,
      props.column,
      props.dir,
      skip,
      props.limit,
      props.search
    );

    // Clean up function
    return () => {
      // Remoce all users data from store
      props.removeAllUsersData();

      // // Remove search and sort values also
      // props.initSearchSorting();
    };
  }, [props.column, props.dir, props.skip, props.search]);

  useEffect(() => {
    // Clean up function
    return () => {
      // Remoce all users data from store
      props.removeAllUsersData();

      // Remove search and sort values also
      props.initSearchSorting();

      // Init skip
      props.skipInit();
    };
  }, []);

  // Update modified initial tab object when users found
  if (props.users && props.users.records && props.users.records.length > 0) {
    totalPages = Math.ceil(props.users.recordsTotal / props.limit);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    const array = [];

    props.users.records.forEach((user) => {
      const data = { ...user };
      data.checkbox = false;
      array.push(data);
    });

    modifiedInitialTabObj.users = array;
  }

  if (props.search) {
    skipInit = 1;
  }

  return (
    <>
      <div className="card card-custom">
        <Formik
          enableReinitialize
          initialValues={modifiedInitialTabObj}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);

              // Show delete modal
              showDeleteModal(undefined, values, true);

              if (deleteModal) {
                setTimeout(() => {
                  // Reset form to initial value
                  resetForm(modifiedInitialTabObj);

                  // Set submitting form to false
                  setSubmitting(false);
                }, 500);
              }
            } catch (err) {
              console.log(err);

              // Notification
              notify("error", "Something went wrong");
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Table hover responsive className="phoTable">
              <thead>
                <tr>
                  <th>
                    Date
                    {props.column && props.column === "createdAt" && (
                      <span
                        className={`pho_filter_arrow ml-5 ${props.dir ===
                          "dsc" && "rotate-arrow-img"}`}
                      ></span>
                    )}
                  </th>
                  <th>Picture</th>
                  <th
                    onClick={onNameSort}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    Name
                    {props.column && props.column === "firstName" && (
                      <span
                        className={`pho_filter_arrow ml-5 ${props.dir ===
                          "dsc" && "rotate-arrow-img"}`}
                      ></span>
                    )}
                  </th>
                  <th
                    onClick={onEmailSort}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    Email
                    {props.column && props.column === "emailId" && (
                      <span
                        className={`pho_filter_arrow ml-5 ${props.dir ===
                          "dsc" && "rotate-arrow-img"}`}
                      ></span>
                    )}
                  </th>
                  <th
                    onClick={onContactNumberSort}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    Call
                    {props.column && props.column === "contactNumber" && (
                      <span
                        className={`pho_filter_arrow ml-5 ${props.dir ===
                          "dsc" && "rotate-arrow-img"}`}
                      ></span>
                    )}
                  </th>
                  <th>Active / Deactive</th>
                  <th
                    width="90px"
                    style={{
                      userSelect: "none",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="users">
                  {({ insert, remove, push }) => (
                    <>
                      {values.users && values.users.length > 0 ? (
                        values.users.map((el, index) => (
                          <tr key={index}>
                            <td width="115px">
                              {el.createdAt
                                ? moment(el.createdAt).format("L")
                                : ""}
                            </td>
                            <td width="80px">
                              <div className="symbol symbol-50 pho_rounded_12">
                                <span className="symbol-label">
                                  <img
                                    src={
                                      el.profilePicture
                                        ? el.profilePicture
                                        : toAbsoluteUrl(
                                            "/media/users/100_2.jpg"
                                          )
                                    }
                                    className="h-100 align-self-center"
                                    alt="..."
                                  />
                                </span>
                              </div>
                            </td>
                            <td width="150px">
                              {el.firstName ? el.firstName : ""}{" "}
                              {el.lastName ? el.lastName : ""}
                            </td>
                            <td width="150px">
                              {el.emailId ? el.emailId : ""}
                            </td>
                            <td width="130px">
                              {el.contactNumber ? el.contactNumber : ""}
                            </td>
                            <td className="text-center" width="135px">
                              <Switch
                                value={el.status === "ACTIVE" ? true : false}
                                color="primary"
                                checked={el.status === "ACTIVE" ? true : false}
                                onChange={(e) => {
                                  const skip = (props.skip - 1) * props.limit;

                                  // Update user status
                                  props.updateUserStatus(
                                    props.user._id,
                                    el._id,
                                    props.column,
                                    props.dir,
                                    skip,
                                    props.limit
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <ItemDropdown
                                item=""
                                doc={el}
                                view={onViewButtonHandler}
                              />
                            </td>
                          </tr>
                        ))
                      ) : props.loading ? (
                        <tr>
                          <td colSpan="87" className="text-center">
                            Loading...
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No Data Available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </FieldArray>
              </tbody>
            </Table>
          )}
        </Formik>
      </div>

      {/* Pagination */}
      {props.users && props.users.recordsTotal > 0 && (
        <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
          <div className="pho_14_400 pho_opacity_5">
            {props.search
              ? `Displaying ${
                  skipInit > 1 ? (skipInit - 1) * props.limit + 1 : 1
                } - ${
                  props.users.recordsTotal > props.limit * props.skip
                    ? props.limit * props.skip
                    : props.users.recordsTotal
                } of ${props.users.recordsTotal} records`
              : `Displaying ${
                  props.skip > 1 ? (props.skip - 1) * props.limit + 1 : 1
                } - ${
                  props.users.recordsTotal > props.limit * props.skip
                    ? props.limit * props.skip
                    : props.users.recordsTotal
                } of ${props.users.recordsTotal} records`}
          </div>
          <Pagination>
            {/* Go to first page */}
            <Pagination.First
              disabled={props.skip === 1 || skipInit === 1}
              onClick={goToFirstPage}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-double-left.svg"
                )}
              />
            </Pagination.First>

            {/* Go to previous page */}
            <Pagination.Prev
              disabled={props.skip === 1 || skipInit === 1}
              onClick={prevPageHandler}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-left.svg"
                )}
              />
            </Pagination.Prev>

            {/* All pages */}
            {pageNumbers.map((number, i) => {
              if (
                !props.search &&
                number >= parseInt(props.skip) - 3 &&
                number <= parseInt(props.skip) + 3
              ) {
                return (
                  <Pagination.Item
                    key={i}
                    id={number}
                    disabled={number === props.skip}
                    active={number === props.skip}
                    onClick={pageChangeHandler}
                  >
                    {number}
                  </Pagination.Item>
                );
              } else if (
                props.search &&
                number >= parseInt(skipInit) - 3 &&
                number <= parseInt(skipInit) + 3
              ) {
                return (
                  <Pagination.Item
                    key={i}
                    id={number}
                    disabled={number === skipInit}
                    active={number === skipInit}
                    onClick={pageChangeHandler}
                  >
                    {number}
                  </Pagination.Item>
                );
              } else {
                return null;
              }
            })}

            {/* Go to next page */}
            <Pagination.Next
              disabled={props.skip === totalPages || skipInit === totalPages}
              onClick={nextPageHandler}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-right.svg"
                )}
              />
            </Pagination.Next>

            {/* Go to last page */}
            <Pagination.Last
              disabled={props.skip === totalPages || skipInit === totalPages}
              onClick={goToLastPage}
            >
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Navigation/Angle-double-right.svg"
                )}
              />
            </Pagination.Last>
          </Pagination>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    skip: state.user.skip,
    users: state.user.users,
    limit: state.user.limit,
    loading: state.user.loading,
    search: state.subHeader.search,
    column: state.subHeader.column,
    dir: state.subHeader.dir,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    skipInit: () => dispatch(userActions.skipInit()),
    updateSkip: (skip) => dispatch(userActions.skipUpdate(skip)),
    initSearchSorting: () => dispatch(subheaderAction.initState()),
    removeAllUsersData: () => dispatch(userActions.removeAllUsersData()),
    addSorting: (column, dir) =>
      dispatch(subheaderAction.addSorting(column, dir)),
    updateUserStatus: (adminId, userId, column, dir, skip, limit) =>
      dispatch(
        userActions.userStatusChange(adminId, userId, column, dir, skip, limit)
      ),
    getAllUsers: (userId, column, dir, skip, limit, search) =>
      dispatch(
        userActions.fetchAllUsers(userId, column, dir, skip, limit, search)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
