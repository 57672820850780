import axios from "axios";

// Language related URL
export const LANGUAGE_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllLanguages`;
// export const LANGUAGE_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/addLanguage`;
export const LANGUAGE_DELETED_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteLanguage`;
// export const LANGUAGE_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getLanguageDetails`;
// export const LANGUAGE_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateLanguage`;

// All Language get function
export async function getAllLanguages(adminId) {
  return await axios.get(`${LANGUAGE_ALL_URL}/${adminId}`);
};

// All Language entities get function
// export async function getAllEntities(adminId) {
//   return await axios.get(`${LANGUAGE_ENTITIES_URL}/${adminId}`);
// };

// // Language get detail function
// export async function getLanguageDetail(adminId, languageId) {
//   return await axios.get(`${LANGUAGE_DETAIL_URL}/${adminId}/${languageId}`);
// };

// // Language add function
// export async function addLanguage(adminId, values) {
//   return await axios.post(`${LANGUAGE_ADD_URL}/${adminId}`, { ...values });
// };

// Language status change function
export async function languageStatusChange(adminId, languageId) {
  return await axios.delete(`${LANGUAGE_DELETED_URL}/${adminId}/${languageId}`);
};

// // Language verification function
// export async function updateLanguage(adminId, languageId, values) {
//   return await axios.put(`${LANGUAGE_UPDATE_URL}/${adminId}/${languageId}`, { ...values });
// };