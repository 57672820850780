import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.QUERY_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.QUERY_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.QUERY_SUCCESS
    };
};

// All QUERYs fetch function
export const fetchAllQueries = (adminId, column, dir, skip, limit, status) => {
    return {
        type: actionTypes.QUERY_ALL_FETCH,
        adminId,
        column,
        dir, 
        skip, 
        limit,
        status
    };
};

// All QUERYs fetched function
export const allQueryFetched = (queries) => {
    return {
        type: actionTypes.QUERY_ALL_FETCHED,
        queries
    };
};

// Remove all QUERY data function
export const removeAllQueriesData = () => {
    return {
        type: actionTypes.QUERY_ALL_DATA_REMOVE
    };
};

// Get QUERY fetch function
export const fetchQuery = (adminId, queryId) => {
    return {
        type: actionTypes.QUERY_FETCH,
        adminId,
        queryId
    };
};

// Get QUERY fetched function
export const queryFetched = (queryDetail) => {
    return {
        type: actionTypes.QUERY_FETCHED,
        queryDetail
    };
};

// Add QUERY id function
export const addQueryId = (queryId) => {
    return {
        type: actionTypes.QUERY_ID_ADD,
        queryId
    };
};

// Remove QUERY id function
export const removeQueryId = () => {
    return {
        type: actionTypes.QUERY_ID_REMOVE        
    };
};

// Remove QUERY data function
export const removeQueryData = () => {
    return {
        type: actionTypes.QUERY_DATA_REMOVE
    };
};

// // Update QUERY data function
// export const updateQueryData = (adminId, queryId, updateData) => {
//     return {
//         type: actionTypes.QUERY_DATA_UPDATE,
//         adminId, 
//         queryId,
//         updateData
//     };
// };

// // Delete QUERY data function
// export const deleteQueryData = (adminId, queryId) => {
//     return {
//         type: actionTypes.QUERY_DATA_DELETE,
//         adminId, 
//         queryId
//     };
// };

// QUERY status change function
export const queryStatusChange = (adminId, queryId, value, column, dir, skip, limit, status, supportDetails, supportDetailsQueryId) => {
    return {
        type: actionTypes.QUERY_STATUS_CHANGE,
        adminId, 
        queryId,
        value,
        column,
        dir,
        skip,
        limit,
        status,
        supportDetails,
        supportDetailsQueryId
    };
};

// Add QUERY function
export const addQuery = (adminId, values) => {
    return {
        // type: actionTypes.QUERY_ADD,
        // adminId,
        // values
    };
};

// Replay QUERY function
export const replyQuery = (adminId, queryId, values) => {
    return {
        type: actionTypes.QUERY_REPLAY,
        adminId,
        queryId,
        values
    };
};

// Skip update function
export const skipUpdate = (skip) => {
    return {
        type: actionTypes.QUERY_SKIP_UPDATE,
        skip
    };
};

// Skip init function
export const skipInit = () => {
    return {
        type: actionTypes.QUERY_SKIP_INIT
    };
};