import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    addAmenity,
    addCategory,
    createAmenity,
    deleteAmenity,
    updateAmenity,
    deleteCategory,
    updateCategory,
    getAllAmenities,
    getAllCategories,
} from '../masterCrud';

import 'react-toastify/dist/ReactToastify.min.css';

///////////////////////////// AMENITY /////////////////////////////

// Get all amenities function
export function* fetchingAllAmenities(action) {  
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all cms
        const result = yield getAllAmenities(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allAmenitiesFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Add amenity function
export function* addingAmenity(action) { 
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to add CMS
        const result = yield addAmenity(action.adminId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Fetch all Amenities
        yield put(actions.fetchAllAmenities(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// // Get Template detail function
// export function* fetchingTemplateDetail(action) {    
//     try {
//         // Call fetch start to displaying loading spinner
//         yield put(actions.fetchStart());

//         // Call function to fetch CMS detail
//         const result = yield getTemplateDetail(action.adminId, action.cmsId);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }
        
//         // Store dashboard data into store
//         yield put(actions.templateDetailFetched(result.data.responseData));

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//     } catch (err) {
//         console.log(err);

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };

// Update amenity detail function
export function* updatingAmenity(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield updateAmenity(action.adminId, action.amenityId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Fetch all Amenities
        yield put(actions.fetchAllAmenities(action.adminId));     

        // Notification
        notify('success', 'Amenity updated successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Delete amenity detail function
export function* deletingAmenity(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield deleteAmenity(action.adminId, action.amenityId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Fetch all Amenities
        yield put(actions.fetchAllAmenities(action.adminId));     

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

///////////////////////////// CATEGORY /////////////////////////////

// Get all categories function
export function* fetchingAllCategories(action) {  
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all cms
        const result = yield getAllCategories(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allCategoriesFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Add category function
export function* addingcategory(action) { 
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to add CMS
        const result = yield addCategory(action.adminId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Fetch all Categories
        yield put(actions.fetchAllCategories(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// // Get Template detail function
// export function* fetchingTemplateDetail(action) {    
//     try {
//         // Call fetch start to displaying loading spinner
//         yield put(actions.fetchStart());

//         // Call function to fetch CMS detail
//         const result = yield getTemplateDetail(action.adminId, action.cmsId);

//         // If response error found throw error
//         if (result.data.responseCode !== 200) {
//             throw new Error(result.data.responseMessage);
//         }
        
//         // Store dashboard data into store
//         yield put(actions.templateDetailFetched(result.data.responseData));

//         // Call fetch success to set loading false
//         yield put(actions.fetchSuccess());

//     } catch (err) {
//         console.log(err);

//         // Call fetch faild to store error message in state
//         yield put(actions.fetchFaild(err.message));
//     }
// };

// Update category detail function
export function* updatingCategory(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield updateCategory(action.adminId, action.amenityId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Fetch all Categories
        yield put(actions.fetchAllCategories(action.adminId));     

        // Notification
        notify('success', 'Category updated successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Delete category detail function
export function* deletingCategory(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield deleteCategory(action.adminId, action.categoryId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Fetch all Categories
        yield put(actions.fetchAllCategories(action.adminId));     

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};