import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    addingLandlord,
    fetchingLandlord,
    deletingLandlord,
    updatingLandlord,
    fetchingAllLandlords,
    changingLandlordStatus,
    fetchingAllLandlordList,
    fetchingAllPendingLandlords
} from './saga';

export function* watchLandlords() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.LANDLORDS_ALL_FETCH, fetchingAllLandlords),
        takeEvery(actionTypes.LANDLORDS_ALL_PENDING_FETCH, fetchingAllPendingLandlords),
        takeEvery(actionTypes.LANDLORD_FETCH, fetchingLandlord),
        takeEvery(actionTypes.LANDLORD_ADD, addingLandlord),
        takeEvery(actionTypes.LANDLORD_STATUS_CHANGE, changingLandlordStatus),
        takeEvery(actionTypes.LANDLORD_DATA_UPDATE, updatingLandlord),
        takeEvery(actionTypes.LANDLORD_DATA_DELETE, deletingLandlord),
        takeEvery(actionTypes.LANDLORD_LIST_FETCH, fetchingAllLandlordList)
    ]);
};