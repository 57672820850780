import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

import { notify } from '../../../../_metronic/_partials/toaster';
import { setStorage, removeStorage } from '../../../../_metronic/_helpers/LocalStorageHelpers';
import { 
  getUserByToken, 
  updateUser, 
  changeUserPassword,
  updateServiceFee,
  getServiceFee
} from "./authCrud";

import 'react-toastify/dist/ReactToastify.min.css';

export const actionTypes = {
  Faild: "[FAILD] Action",
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Loading: "[Loading] Action",
  Register: "[Register] Action",
  UserLoaded: "[Load User] Auth API",
  UpdateUser: "[UPDATE_USER] Action",
  UpdatedUser: "[UPDATED_USER] Action",
  UserRequested: "[Request User] Action",
  GetServiceFee: "[GET_SERVICE_FEE] Action",
  ChangePassword: "[CHANGE_PASSWORD] Action",
  UpdateServiceFee: "[UPDATE_SERVICE_FEE] Action",
  ServiceFeeFetched: "[FETCHED_SERVICE_FEE] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  error: null,
  loading: null,
  fee: null
};

export const reducer = persistReducer(
  { storage, key: "prohoff", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, user } = action.payload;

        // Store accessToken in localStorage
        setStorage(process.env.REACT_APP_PROHOFF_TOKEN, authToken);

        return { 
          authToken, 
          user 
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.

        // Remove accessToken from localStorage
        removeStorage(process.env.REACT_APP_PROHOFF_TOKEN);

        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.ServiceFeeFetched: {
        const { fee } = action.payload;
        return { ...state, fee };
      }

      case actionTypes.Faild: {
        const { error } = action.payload;
        return { 
          ...state,
          error
        };
      }

      case actionTypes.Loading: {
        const { loading } = action.payload;
        return { 
          ...state,
          loading
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, user) => ({ 
    type: actionTypes.Login, 
    payload: { 
      authToken: authToken, 
      user: user 
    } 
  }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: (userId) => ({ 
    type: actionTypes.Logout,
    payload: { userId }
  }),
  requestUser: user => ({ 
    type: actionTypes.UserRequested, 
    payload: { user } 
  }),
  fulfillUser: user => ({ 
    type: actionTypes.UserLoaded, 
    payload: { user } 
  }),
  updateUser: (userId, updateData) => ({
    type: actionTypes.UpdateUser,
    payload: {
      userId,
      updateData
    }
  }),
  userUpdated: updatedData => ({
    type: actionTypes.UpdatedUser,
    payload: {
      updatedData
    }
  }),
  getServiceFee: (adminId) => ({
    type: actionTypes.GetServiceFee,
    payload: {
      adminId
    }
  }),
  serviceFeeFetched: (fee) => ({
    type: actionTypes.ServiceFeeFetched,
    payload: {
      fee
    }
  }),
  updateServiceFee: (adminId, values) => ({
    type: actionTypes.UpdateServiceFee,
    payload: {
      adminId,
      values
    }
  }),
  changePasword: (userId, passwordData) => ({
    type: actionTypes.ChangePassword,
    payload: {
      userId,
      passwordData
    }
  }),
  faild: error => ({
    type: actionTypes.Faild,
    payload: {
      error
    }
  }),
  loading: loading => ({
    type: actionTypes.Loading,
    payload: {
      loading
    }
  })
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });

  yield takeLatest(actionTypes.UserRequested, function* userRequested(action) {
    const { data: user } = yield getUserByToken(action.userId);

    yield put(actions.fulfillUser(user));
  });

  // Update user saga
  yield takeLatest(actionTypes.UpdateUser, function* updateUserSaga(action) {
    try {
      const { userId, updateData } = action.payload;

      const { data } = yield updateUser(userId, updateData);
  
      if (data.responseCode !== 200) {
        throw new Error(data.responseMessage);        
      }

      // Update user data in store
      yield put(actions.fulfillUser(data.responseData));

      // Notification
      notify('success', 'Profile updated successfully');
            
    } catch (err) {
      console.log(err);

      // Notification
      notify('success', 'Error while updating profile');
    }
  });

  // Update user password saga
  yield takeLatest(actionTypes.ChangePassword, function* updateUserPasswordSaga(action) {
    try {
      // Set loading to true
      // yield put(actions.loading(true));

      const { data } = yield changeUserPassword(action.payload.passwordData);

      if (data.response && data.response.responseCode === 400) {        
        // Set error
        yield put(actions.faild(data.response.responseMessage));

        // Set loading to false
        yield put(actions.loading(false));

        // Throw error
        throw new Error(data.response.responseMessage);
      }

      // If validation error found throw error
      if (data.error && data.error.errors.lenth > 0) {
        // Set error
        yield put(actions.faild(data.response.responseMessage));

        // Set loading to false
        yield put(actions.loading(false));

        // Throw error
        throw new Error(data.error.errors[0].message);
      }

      // Notification
      notify('success', 'Password updated successfully');
      
    } catch (err) {
      console.log(err);
      
      // Notification
      notify('error', err.message);
    }    
  });

  // Update service fee saga
  yield takeLatest(actionTypes.UpdateServiceFee, function* updateServiceFeeSaga(action) {
    try {
      const { adminId, values } = action.payload;

      const { data } = yield updateServiceFee(adminId, values);
  
      if (data.responseCode !== 200) {
        throw new Error(data.responseMessage);        
      }

      // Update user data in store
      yield put(actions.getServiceFee(adminId));

      // Notification
      notify('success', 'Service Fee updated successfully');
            
    } catch (err) {
      console.log(err);

      // Notification
      notify('success', 'Error while updating Service Fee');
    }
  });

  // Get service fee saga
  yield takeLatest(actionTypes.GetServiceFee, function* getServiceFeeSage(action) {
    try {
      const { adminId } = action.payload;

      const { data } = yield getServiceFee(adminId);
  
      if (data.responseCode !== 200) {
        throw new Error(data.responseMessage);        
      }

      // Update user data in store
      yield put(actions.serviceFeeFetched(data.responseData));
            
    } catch (err) {
      console.log(err);

      // Notification
      notify('success', 'Error while fetching Service Fee');
    }
  });
}
