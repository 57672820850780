// Action types
export const QUERY_START = 'QUERY_START';
export const QUERY_FAILD = 'QUERY_FAILD';
export const QUERY_SUCCESS = 'QUERY_SUCCESS';
export const QUERY_FETCH = 'QUERY_FETCH';
export const QUERY_FETCHED = 'QUERY_FETCHED';
export const QUERY_ALL_FETCH = 'QUERY_ALL_FETCH';
export const QUERY_ALL_FETCHED = 'QUERY_ALL_FETCHED';
export const QUERY_STATUS_CHANGE = 'QUERY_STATUS_CHANGE';
export const QUERY_ID_ADD = 'QUERY_ID_ADD';
export const QUERY_ID_REMOVE = 'QUERY_ID_REMOVE';
export const QUERY_REPLAY = 'QUERY_REPLAY';
export const QUERY_DATA_REMOVE = 'QUERY_DATA_REMOVE';
export const QUERY_ALL_DATA_REMOVE = 'QUERY_ALL_DATA_REMOVE';
export const QUERY_SKIP_UPDATE = 'QUERY_SKIP_UPDATE';
export const QUERY_SKIP_INIT = 'QUERY_SKIP_INIT';