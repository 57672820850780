import React, { useState, useEffect } from "react";
import { Table, Pagination, Modal, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, FieldArray } from 'formik';
import { connect } from 'react-redux';
import SVG from "react-inlinesvg";
import moment from 'moment';

import ItemDropdown from "./ItemDropdown";
import { toAbsoluteUrl } from "../../_helpers";
import * as paymentActions from '../../../app/modules/Payment';
import { initialUserTableValues } from './UserPaymentSchema';
import * as subheaderActions from '../../../app/modules/Subheader';

const LandlordPayment = (props) => {
  let totalPages;
  const pageNumbers = [];

  const modifiedInitialTabObj = Object.assign({}, initialUserTableValues);

  // State
  const [commonDelete, setCommonDelete] = useState({
    count: 0,
    value: false
  });

  const [userPaymentDetail, setUserPaymentDetail] = useState({
		show: false
	});

  // Page change handler
  const pageChangeHandler = (e) => {

    if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
    }
    
    // Update current page in store
    props.updateSkip(parseInt(e.target.id));
  };

  // Page change handler
  const nextPageHandler = (e) => {
    const nextPage = props.skip + 1;

    if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
    }
    
    // Update current page in store
    props.updateSkip(nextPage);
  };

  // Page change handler
  const prevPageHandler = (e) => {
    const prevPage = props.skip - 1;

    if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

    // Update current page in store
    props.updateSkip(prevPage);
  };

  // Page change handler
  const goToFirstPage = (e) => {
    const firstPage = 1;

    if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

    // Update current page in store
    props.updateSkip(firstPage);
  };

  // Page change handler
  const goToLastPage = (e) => {
    const lastPage = totalPages;

    if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

    // Update current page in store
    props.updateSkip(lastPage);
  };

  // On admin commission sort handler
  const onAdminCommissionSort = () => {
    if (props.column && props.column === 'serviceFees' && props.dir && props.dir === 'dsc') {
      props.addSorting('serviceFees', 'asc');
    } else {
      props.addSorting('serviceFees', 'dsc');
    }
  };

  // On amount sort handler
  const onAmountSort = () => {
    if (props.column && props.column === 'price' && props.dir && props.dir === 'dsc') {
      props.addSorting('price', 'asc');
    } else {
      props.addSorting('price', 'dsc');
    }
  };

  // On payment completed sort handler
  const onPaymentCompletedSort = () => {
    if (props.column && props.column === 'totalPrice' && props.dir && props.dir === 'dsc') {
      props.addSorting('totalPrice', 'asc');
    } else {
      props.addSorting('totalPrice', 'dsc');
    }
  };

  // On view button click handler function
	const onViewButtonClickHandler = async (e, paymentDetail) => {
		try {
			// Set user payment detail state
			setUserPaymentDetail({
				show: true,
				...paymentDetail
      });
      
		} catch (err) {
			console.log(err);
		}	
  };
  
  // Handle refund modal close function
	const handleRefundModalClose = () => {

		// Set  click state to false
		setUserPaymentDetail({
			show: false
		});
	};

  // useEffect(() => {
  //   const skip = (props.skip - 1) * props.limit;

  //   // Call get all payment received function to get data
  //   props.getAllPaymentReceived(
  //     props.user._id,
  //     props.column,
  //     props.dir,
  //     props.status,
  //     skip,
  //     props.limit
  //   );
  // }, [props.skip]);

  // useEffect(() => {
	// 	if (!props.findClickedValue) {			
	// 		const skip = (props.skip - 1) * props.limit;

  //     // Call get all payment received function to get data
  //     props.getAllPaymentReceived(
  //       props.user._id,
  //       props.column,
  //       props.dir,
  //       undefined,
  //       skip,
  //       props.limit
  //     );
	// 	}
	// }, [props.skip]);

	useEffect(() => {
    const skip = (props.skip - 1) * props.limit;
    
		if (props.findClickedValue && props.find) {
      // Call get all payment received function to get data
      props.getAllPaymentReceived(
        props.user._id,
        props.column,
        props.dir,
        props.status,
        skip,
        props.limit,
        props.search
      );

    } else if (!props.findClickedValue) {			
      // Call get all payment received function to get data
      props.getAllPaymentReceived(
        props.user._id,
        props.column,
        props.dir,
        undefined,
        skip,
        props.limit
      );
		}
	}, [props.find, props.skip, props.column, props.dir]);

  useEffect(() => {

    // Remove data when component destroy
    return () => {

			// Subheader init state
			props.subheaderInit();

			// Payment init function
			props.paymentInit();

			// Skip init function
			props.skipInit();
		};
  }, []);

  // Update modified initial tab object when users found
  if (props.paymentReceived && props.paymentReceived.records && props.paymentReceived.records.length > 0) {
    totalPages = Math.ceil(props.paymentReceived.recordsTotal / props.limit);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    };

    const array = [];

    props.paymentReceived.records.forEach(payment => {
      const data = { ...payment };
      data.checkbox = false;
      array.push(data);
    });

    modifiedInitialTabObj.payments = array; 
  }

  return (
    <>
      {/* Detail modal */}
      <Modal 
				centered
				show={ userPaymentDetail.show } 
				onHide={ handleRefundModalClose } 
			>
				<Modal.Header className="border-0 pb-0" closeButton>
					<Modal.Title className="pho_18_600">Payment Details</Modal.Title>
				</Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col className="d-flex align-items-center justify-content-between">
                <div className="pho_14_400">Payment Date: { moment(userPaymentDetail.createdAt).format('DD MMM YYYY') }</div>
                <label className={`m-0 pho_14_500
                  ${userPaymentDetail.status === 'REQUESTED' && 'pho_orengeLbl'}
                  ${userPaymentDetail.status === 'COMPLETED' && 'pho_greenLbl'}
                  ${userPaymentDetail.status === 'CANCELLED' && 'pho_blackLbl'}
                  ${userPaymentDetail.status === 'REJECTED' && 'pho_redLbl'}
                  ${userPaymentDetail.status === 'APPROVED' && 'pho_yelloLbl'}
                  ${userPaymentDetail.status === 'PAYMENT_RELEASED' && 'pho_pinkLbl'}
                  ${userPaymentDetail.status === 'PAYMENT_REFUNDED' && 'pho_blueLbl'}
                  ${userPaymentDetail.status === 'REQUEST_EXPIRED' && 'pho_purpleLbl'}
                `}>
                  { userPaymentDetail.status === 'REQUESTED' ? 'PENDING' : userPaymentDetail.status ? userPaymentDetail.status.replace('_', ' ') : ''  }
                </label>
              </Col>
              <Col md={12}>
                <hr/>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Payment Number</div>
                <div className="pho_16_600 pho_ellips pb-3">{ userPaymentDetail._id }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Property Name</div>
                <div className="pho_16_600 pho_ellips pb-3">{ userPaymentDetail.propertyDetails ? userPaymentDetail.propertyDetails.name.en : '' }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Type</div>
                <div className="pho_16_600 pb-3">{ userPaymentDetail.propertyType }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Location</div>
                <div className="pho_16_600 pb-3">{ userPaymentDetail.propertyDetails ? `${userPaymentDetail.propertyDetails.address.city}, ${userPaymentDetail.propertyDetails.address.country}` : '' }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Landlord</div>
                <div className="pho_16_600 pb-3">{ userPaymentDetail.landlordDetails ? `${userPaymentDetail.landlordDetails.firstName} ${userPaymentDetail.landlordDetails.lastName}` : '' }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Booked By</div>
                <div className="pho_16_600 pb-3">{ userPaymentDetail.userDetails ? `${userPaymentDetail.userDetails.firstName} ${userPaymentDetail.userDetails.lastName}` : '' }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Booked Date</div>
                <div className="pho_16_600 pb-3">{ moment(userPaymentDetail.fromDate).format('DD MMM YYYY') }</div>
              </Col>						
              <Col md={6} lg={4}>
                <div className="pho_14_400">To Date</div>
                <div className="pho_16_600 pb-3">{ moment(userPaymentDetail.toDate).format('DD MMM YYYY') }</div>
              </Col>
              <Col md={12}>
                  <hr/>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Price</div>
                <div className="pho_16_600 pb-3">${ userPaymentDetail.price ? userPaymentDetail.price.toFixed(2) : '' }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Service Fee</div>
                <div className="pho_16_600 pb-3">${ userPaymentDetail.serviceFees ? userPaymentDetail.serviceFees.toFixed(2) : '' }</div>
              </Col>
              <Col md={6} lg={4}>
                <div className="pho_14_400">Final Amount</div>
                <div className="pho_16_600 pb-3">${ userPaymentDetail.totalPrice ? userPaymentDetail.totalPrice.toFixed(2) : '' }‬</div>
              </Col>
            </Row>
          </Modal.Body>	
          <Modal.Footer className="border-0 justify-content-start">
            {
              userPaymentDetail.receiptUrl &&
                <a 
                  target="_blank"
                  rel="noopener noreferrer"
                  href={ userPaymentDetail.receiptUrl }
                >
                  click to view receipt
                </a>
            }
          </Modal.Footer>
			</Modal>

      <div className="card card-custom">
        <Formik
          enableReinitialize
          initialValues={ modifiedInitialTabObj }
          // onSubmit={(values, { setSubmitting, resetForm }) => {
          //   try {
          //     setSubmitting(true);

          //     // Show delete modal
          //     showDeleteModal(undefined, values, true);

          //     if (deleteModal) {
          //       setTimeout(() => {                                
          //         // Reset form to initial value
          //         resetForm(modifiedInitialTabObj);

          //         // Set submitting form to false
          //         setSubmitting(false);  
          //       }, 500);
          //     }
                
          //   } catch (err) {
          //     console.log(err);

          //     // Notification
          //     notify('error', 'Something went wrong');
          //   }
          // }}
        >
          {({
            values, 
            setFieldValue,
          }) => (
            <Table responsive hover className="phoTable">
              <thead>
                <tr>
                  {/* <th width="80px">
                    <div className="pho_checkbox iskl_border">
                      <input
                        type="checkbox"
                        name="checkbox"
                        className={`mr-2 checkbox`}
                        checked={ values.checkbox }
                        onChange={ (e) => {   
                          let total = 0;
                          // Set current check box value
                          setFieldValue('checkbox', !values.checkbox);

                          values.payments.forEach((el, index) => {  
                            total += 1;
                            
                            // Set all checkbox value
                            setFieldValue(`payments.${index}.checkbox`, !values.checkbox);
                          });                                                                            

                          if (values.checkbox || (!values.checkbox && total === 0)) {
                            // Set common delete state
                            setCommonDelete({
                              count: 0,
                              value: false
                            });
                          } else if (!values.checkbox && total > 0) {
                            // Set common delete state
                            setCommonDelete({
                              count: total,
                              value: true
                            });
                          }
                        }}
                      />
                      <label></label>
                    </div>
                  </th> */}
                  <th width="130px">ID</th>
                  <th width="130px">Landlord</th>
                  <th 
                    width="170px" 
                    onClick={ onAdminCommissionSort }
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                  >
                    Admin Commission
                    {
                      props.column && props.column === 'serviceFees' &&
                        <span 
                          className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                        >
                        </span>
                    }
                  </th>
                  <th 
                    width="80px" 
                    onClick={ onAmountSort }
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                  >
                    Amount
                    {
                      props.column && props.column === 'price' &&
                        <span 
                          className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                        >
                        </span>
                    }
                  </th>
                  <th 
                    width="180px" 
                    onClick={ onPaymentCompletedSort }
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                  >
                    Payment Completed
                    {
                      props.column && props.column === 'totalPrice' &&
                        <span 
                          className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                        >
                        </span>
                    }
                  </th>
                  <th width="170px" className="text-center">
                    Status
                  </th>
                  <th>
                    Actions
                  </th>
                  {/* <th width="120px">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                <FieldArray name="payments">
                  {({ insert, remove, push }) => (
                    <>
                      {
                        !props.loading && values.payments && values.payments.length > 0 ?
                          values.payments.map((payment, index) => (
                            <tr key={ index }>
                              {/* <td width="80px">
                                <div className="pho_checkbox iskl_border">
                                  <input                                                                                    
                                    type="checkbox"
                                    name={ `payments.${index}.checkbox` }
                                    className={`mr-2 checkbox`}
                                    checked={ payment.checkbox }
                                    onChange={ (e) => {
                                      if (payment.checkbox) {
                                        // Set common delete state
                                        setCommonDelete({
                                          count: commonDelete.count - 1,
                                          value: false
                                        });
                                      } else {
                                        // Set common delete state
                                        setCommonDelete({
                                          count: commonDelete.count + 1,
                                          value: true
                                        });
                                      }

                                      // Set field values
                                      setFieldValue(`payments.${index}.checkbox`, !payment.checkbox);
                                    } }
                                  />
                                  <label></label>
                                </div>
                              </td> */}
                              <td>{ payment._id }</td>
                              <td>{ payment.landlordDetails ? `${payment.landlordDetails.firstName} ${payment.landlordDetails.lastName}` : '' }</td>
                              <td className="text-center">${ payment.serviceFees ? payment.serviceFees.toFixed(2) : '' }</td>
                              <td className="text-center">${ payment.price ? payment.price.toFixed(2) : '' }</td>
                              <td className="text-center">${ payment.totalPrice ? payment.totalPrice.toFixed(2) : '' }</td>
                              <td className="text-center">
                                <label className={`m-0 pho_14_500 w-100
                                  ${payment.status === 'REQUESTED' && 'pho_orengeLbl'}
                                  ${payment.status === 'COMPLETED' && 'pho_greenLbl'}
                                  ${payment.status === 'CANCELLED' && 'pho_blackLbl'}
                                  ${payment.status === 'REJECTED' && 'pho_redLbl'}
                                  ${payment.status === 'APPROVED' && 'pho_yelloLbl'}
                                  ${payment.status === 'PAYMENT_RELEASED' && 'pho_pinkLbl'}
                                  ${payment.status === 'PAYMENT_REFUNDED' && 'pho_blueLbl'}
                                  ${payment.status === 'REQUEST_EXPIRED' && 'pho_purpleLbl'}
                                `}>
                                  { payment.status === 'REQUESTED' ? 'PENDING' : payment.status ? payment.status.replace('_', ' ') : '' }
                                </label>
                              </td>
                              {/* {
                                payment.landlordDetails &&
                                  <td className="text-center">
                                    <Button 
                                      className="text-success pho_14_500"
                                      to={`/user-payment-details/${payment.landlordDetails._id}`} 
                                    >
                                      View Detail
                                    </Button>
                                  </td>
                              } */}
                              <td className="text-right">
                                <ItemDropdown 
                                  item="" 
                                  doc={ payment }
                                  view={ onViewButtonClickHandler }
                                />
                              </td>
                              {/* <td className="text-right">
                                <ItemDropdown />
                              </td> */}
                            </tr>
                          ))
                        : 
                          props.loading ?
                          <tr>
                            <td colSpan="8" className="text-center">Loading...</td>
                          </tr>
                        : 
                          <tr>
                            <td colSpan="8" className="text-center">No Data Available</td>
                          </tr>
                      }
                    </>
                  )}
                </FieldArray>
                
              </tbody>
            </Table>
          )}
        </Formik>
      </div>

      {/* Pagination */}
      { 
        !props.loading && props.paymentReceived && props.paymentReceived.recordsTotal > 0 && 
          <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
            <div className="pho_14_400 pho_opacity_5">
              Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.paymentReceived.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.paymentReceived.recordsTotal } of { props.paymentReceived.recordsTotal } records
            </div>
            <Pagination>
              {/* Go to first page */}
              <Pagination.First
                disabled={ props.skip === 1 } 
                onClick={ goToFirstPage }
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
              </Pagination.First>

              {/* Go to previous page */}
              <Pagination.Prev
                disabled={ props.skip === 1 } 
                onClick={ prevPageHandler }
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
              </Pagination.Prev>

              {/* All pages */}
              {
                pageNumbers.map((number, i) => {
                  if (
                    number >= parseInt(props.skip) - 3 &&
                    number <= parseInt(props.skip) + 3 
                  ) {
                    return (
                        <Pagination.Item
                          key={ i }
                          id={ number }
                          active={ number === props.skip }
                          onClick={ pageChangeHandler }
                        >
                          { number }
                      </Pagination.Item>
                    );
                  } else {
                      return null;
                  }
                })
              }

              {/* Go to next page */}
              <Pagination.Next
                disabled={ props.skip === totalPages } 
                onClick={ nextPageHandler }
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
              </Pagination.Next>

              {/* Go to last page */}
              <Pagination.Last
                disabled={ props.skip === totalPages } 
                onClick={ goToLastPage }
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
              </Pagination.Last>
            </Pagination>
          </div>
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    dir: state.subHeader.dir,
    skip: state.payment.skip,
    find: state.subHeader.find,
    limit: state.payment.limit,
    loading: state.payment.loading,
    search: state.subHeader.search,
    column: state.subHeader.column,
    status: state.subHeader.status,
    findClickedValue: state.subHeader.findClicked,
    paymentReceived: state.payment.paymentReceived
  };
};

const mapDispatchToProps = dispatch => {
  return {
    paymentInit: () => dispatch(paymentActions.paymentInit()),
    skipInit: () => dispatch(paymentActions.paymentSkipInit()),
    subheaderInit: () => dispatch(subheaderActions.initState()),
    updateFind: (value) => dispatch(subheaderActions.updateFind(value)),
	  findClicked: (value) => dispatch(subheaderActions.findClicked(value)),
    updateSkip: (skip) => dispatch(paymentActions.paymentSkipUpdate(skip)),
    addSorting: (column, dir) => dispatch(subheaderActions.addSorting(column, dir)),
    getAllPaymentReceived: (
      adminId,
      column,
      dir,
      status,
      skip,
      limit,
      userId    
    ) => dispatch(paymentActions.fetchAllPaymentReceived(adminId, column, dir, status, skip, limit, userId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandlordPayment);


{/* <tr>
  <td width="80px">
    <div className="pho_checkbox iskl_border">
      <input type="checkbox" className={`mr-2 checkbox`} />
      <label></label>
    </div>
  </td>
  <td>012345678900</td>
  <td>LandlordName</td>
  <td className="text-center">$15</td>
  <td className="text-center">$2500</td>
  <td className="text-center">$4200</td>
  <td className="text-center">
    <label className="m-0 pho_greenLbl pho_14_500 w-100">
      COMPLETED
    </label>
  </td>
  <td className="text-center">
    <Link to="/user-payment-details" className="text-success pho_14_500">
      View Detail
    </Link>
  </td>
  <td className="text-right">
    <ItemDropdown />
  </td>
</tr>
<tr>
  <td width="80px">
    <div className="pho_checkbox iskl_border">
      <input type="checkbox" className={`mr-2 checkbox`} />
      <label></label>
    </div>
  </td>
  <td>012345678900</td>
  <td>LandlordName</td>
  <td className="text-center">$15</td>
  <td className="text-center">$2500</td>
  <td className="text-center">$4200</td>
  <td className="text-center">
    <label className="m-0 pho_redLbl pho_14_500 w-100">
      PAYMENT FAILED
    </label>
  </td>
  <td className="text-center">
    <Link to="/user-payment-details" className="text-success pho_14_500">
      View Detail
    </Link>
  </td>
  <td className="text-right">
    <ItemDropdown />
  </td>
</tr>
<tr>
  <td width="80px">
    <div className="pho_checkbox iskl_border">
      <input type="checkbox" className={`mr-2 checkbox`} />
      <label></label>
    </div>
  </td>
  <td>012345678900</td>
  <td>LandlordName</td>
  <td className="text-center">$15</td>
  <td className="text-center">$2500</td>
  <td className="text-center">$4200</td>
  <td className="text-center">
    <label className="m-0 pho_redLbl pho_14_500 w-100">
      CLOSED
    </label>
  </td>
  <td className="text-center">
    <Link to="/user-payment-details" className="text-success pho_14_500">
      View Detail
    </Link>
  </td>
  <td className="text-right">
    <ItemDropdown />
  </td>
</tr> */}