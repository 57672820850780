import React from 'react';
import { Dropdown,DropdownButton, ButtonGroup } from 'react-bootstrap';

const ItemDropdown = (props) => {
    return (
        <>
        {['left'].map((direction) => (
        <DropdownButton
            as={ButtonGroup}
            key={direction}
            id={`dropdown-button-drop-left`}
            drop={direction}
            className="pho_dropdown_btn"
            variant="transparent"
            title=""
        >
        <Dropdown.Item href="#/action-1">View</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
        </DropdownButton>
        ))}            
        </>
    );
};

export default ItemDropdown;