import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row, Carousel } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Slider from "react-slick";
import SVG from "react-inlinesvg";
import { connect } from 'react-redux';
import moment from 'moment';

import { toAbsoluteUrl } from "../../_helpers";
import * as subheaderAction from '../../../app/modules/Subheader';
import * as accommodationActions from '../../../app/modules/Accommodation';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AccommodationDetails = (props) => {
    let amount = 0;
    const { id } = useParams();

    const items = [
        "https://res.cloudinary.com/dizkwji5k/image/upload/v1602252889/wheg81jrnefhs0hem6ax.jpg",
        "https://res.cloudinary.com/dizkwji5k/image/upload/v1602252889/wheg81jrnefhs0hem6ax.jpg",
        "https://res.cloudinary.com/dizkwji5k/image/upload/v1602252889/wheg81jrnefhs0hem6ax.jpg"
    ];
    
    // State
    const [index, setIndex] = useState(1);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
        arrows: true,
        afterChange: function(i) { 
            // Set index state
            setIndex(i + 1);            
        }
    };

    // Capitalize function
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // Lease type changing function
    const changeLeaseType = (type) => {
        if (type) {
            // Lowercase type
            const typeLowercase = type.toLowerCase();

            return capitalize(typeLowercase);
        }

        return '';
    };

    useEffect(() => {
        // Add accommodation id to store
        props.addAccommodationId(id);

        // Get accommodation detail
        props.getAccommodationDetail(props.user._id, id);

        return () => {
            // Remove accommodation data from store
            props.accommodationInit();  
        };
    }, [id]);

    if (props.accommodationDetail && props.accommodationDetail.price && props.accommodationDetail.price.length > 0) {
        props.accommodationDetail.price.forEach(el => {
            amount += el.rate;
        });
    }
     
    return (
        <>
            {
                !props.loading && props.accommodationDetail ?
                    <div className="card card-custom">
                        <div className="cardPadding">
                            <Row>
                                <Col lg={ 6 }>
                                    <div className="pho_24_600 pb-4">Basic Details</div>
                                    <div className="pho_14_400 pho_opacity_7">Name</div>
                                    <div className="pho_14_500 pb-4">{ props.accommodationDetail.name.en }</div>
                                    <div className="pho_14_400 pho_opacity_7">Description</div>
                                    <div className="pho_14_500 pb-4">{ props.accommodationDetail.description.en }</div>
                                    <div className="pho_14_400 pho_opacity_7">Location</div>
                                    <div className="pho_14_500 pb-4">
                                        { props.accommodationDetail.address.flat } { props.accommodationDetail.address.streetAddress }, { props.accommodationDetail.address.city }, { props.accommodationDetail.address.country }, { props.accommodationDetail.address.postcode }
                                    </div>
                                    <Row>
                                        <Col md={6}>
                                            <div className="pho_14_400 pho_opacity_7">Type</div>
                                            <div className="pho_14_500 pb-4">{ props.accommodationDetail.type }</div>                        
                                        </Col>
                                        <Col md={6}>
                                            <div className="pho_14_400 pho_opacity_7">Price</div>
                                            {
                                                props.accommodationDetail && props.accommodationDetail.price && props.accommodationDetail.price.length > 0 &&
                                                    props.accommodationDetail.price.map((el, index) => {
                                                        let period = '';
                                                        if (props.accommodationDetail.spaceAvailability.spaceCycle === 'HOURLY') {
                                                            period = 'Hour';
                                                        } else if (props.accommodationDetail.spaceAvailability.spaceCycle === 'DAILY') {
                                                            period = 'Day';
                                                        } else if (props.accommodationDetail.spaceAvailability.spaceCycle === 'MONTHLY') {
                                                            period = 'Month';
                                                        }

                                                        return (
                                                            <div className="pho_14_500 pb-4" key={ index }>
                                                                { el.name } - $ { el.rate.toFixed(2) } / { period }
                                                            </div>
                                                        );
                                                    })
                                            }                                                                    
                                        </Col>
                                        <Col md={6}>
                                            <div className="pho_14_400 pho_opacity_7">Hosted By</div>
                                            <div className="pho_14_500 pb-4">
                                                { props.accommodationDetail.landlordDetails.firstName } { props.accommodationDetail.landlordDetails.lastName }
                                            </div>                        
                                        </Col>
                                        <Col md={6}>
                                            <div className="pho_14_400 pho_opacity_7">Added on</div>
                                            <div className="pho_14_500 pb-4">{ moment(props.accommodationDetail.createdAt).format('L') }</div>                        
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={ 6 }>
                                    <div className="text-right pho_16_400">{ index } / { props.accommodationDetail.media.length }</div>
                                    <Slider {...settings}>
                                        {
                                            props.accommodationDetail.media.map((item, i) => (
                                                <div key={ i }>
                                                    <span 
                                                        className="phoSlideItem" 
                                                        style = {{
                                                            background:
                                                            "url(" +
                                                            item +
                                                            ") no-repeat scroll center center / cover",
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </Col>
                            </Row>
                        </div>
                        <hr className="m-0"/>
                        <div className="cardPadding">
                            <Row>
                                <Col lg={12}>
                                    <div className="pho_24_600 pb-4">Lease Details</div>
                                </Col>
                                <Col lg={9}>
                                    <Row>
                                        <Col md={4}>
                                            <div className="pho_14_400 pho_opacity_7">Lease Type</div>
                                            <div className="pho_14_500 pb-4">{ changeLeaseType(props.accommodationDetail.spaceAvailability.spaceCycle) }</div>   
                                        </Col>
                                        <Col md={4}>
                                            <div className="pho_14_400 pho_opacity_7">Available</div>
                                            { 
                                                props.accommodationDetail.spaceAvailability.hours && props.accommodationDetail.spaceAvailability.hours.length > 0 && 
                                                    props.accommodationDetail.spaceAvailability.hours.map((el, index) => (
                                                        <div className="pho_14_500 pb-4" key={ index }>
                                                            { el.name } ({ el.from } - { el.to })
                                                        </div>   
                                                    ))
                                            }                                               
                                        </Col>
                                        <Col md={4}>
                                            <div className="pho_14_400 pho_opacity_7">Space ready in</div>
                                            <div className="pho_14_500 pb-4">{ props.accommodationDetail.spaceReadyIn } Hours</div>   
                                        </Col>
                                        <Col md={4}>
                                            <div className="pho_14_400 pho_opacity_7">Private Office</div>
                                            <div className="pho_14_500 pb-4">1800 Sq/ft</div>   
                                        </Col>
                                        <Col md={4}>
                                            <div className="pho_14_400 pho_opacity_7">Meeting Room</div>
                                            <div className="pho_14_500 pb-4">3200 Sq/ft</div>   
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={3}>
                                    <div className="pho_14_400 pho_opacity_7">General Rules</div>
                                    <div className="pho_14_500 pb-4">
                                        { props.accommodationDetail.generalRules.en }
                                    </div>
                                </Col>
                            </Row>  
                        </div>  
                        <hr className="m-0"/>
                        <div className="cardPadding">
                            <Row>
                                <Col lg={ 12 }>
                                    <div className="pho_24_600 pb-4">Amenities</div>
                                </Col>
                                {
                                    props.accommodationDetail.amenities && props.accommodationDetail.amenities.length > 0 &&
                                        props.accommodationDetail.amenities.map((amenity, index) => (
                                            <Fragment key={ index }>
                                                <Col lg={ 3 } className="pb-4">
                                                    <div className="pho_18_600 pb-2">{ amenity.name ? amenity.name : `Room ${index + 1}` }</div>
                                                    {
                                                    amenity.amt && amenity.amt.length > 0 &&
                                                        amenity.amt.map((el, i) => (
                                                                <div key={ i } className="pho_14_500 pt-2">{ el }</div>
                                                            // <Col md={ 3 } key={ i }>
                                                            //     <div className="pho_14_500 pb-4">{ el }</div>
                                                            // </Col>
                                                        ))
                                                    }
                                                </Col>      
                                                
                                            </Fragment>
                                        ))
                                }
                                {/* <Col md={3}>
                                    <div className="pho_14_500 pb-4">Wi-fi</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Coffe/Tea</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Bottled Water</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Hosted Reception</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Print/Scan/Copy</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Air Conditioned</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Bike Rack</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Projector</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Phone</div>
                                </Col>
                                <Col md={3}>
                                    <div className="pho_14_500 pb-4">Video Conference</div>
                                </Col> */}
                            </Row>
                        </div>
                    </div> 
                :
                    props.loading ?
                        <div id="compnent-splash-screen" className="compnent-splash-screen">
                            <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                                <circle 
                                    className="path"
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    strokeWidth="5"
                                ></circle>
                            </svg>
                        </div>
                    :
                        <div className="card card-custom">
                            No Data Available
                        </div>
            }
        </>                   
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,   
        loading: state.accommodation.loading, 
        accommodationDetail: state.accommodation.accommodationDetail,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        accommodationInit: () => dispatch(accommodationActions.accommodationInit()),
        addAccommodationId: (accommodationId) => dispatch(accommodationActions.addAccommodationId(accommodationId)),
        getAccommodationDetail: (
            adminId, 
            accommodationId
        ) => dispatch(accommodationActions.fetchAccommodationDetail(adminId, accommodationId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationDetails);
