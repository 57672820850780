import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    fetchingAllReport,
    downloadingReport
} from './saga';

export function* watchReport() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.REPORT_ALL_FETCH, fetchingAllReport),
        takeEvery(actionTypes.REPORT_DOWNLOAD, downloadingReport)
    ]);
};