// Action types
export const USER_START = 'USER_START';
export const USER_FAILD = 'USER_FAILD';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FETCH = 'USER_FETCH';
export const USER_ID_ADD = 'USER_ID_ADD';
export const USER_ADD = 'USER_ADD';
export const USER_FETCHED = 'USER_FETCHED';
export const USERS_ALL_FETCH = 'USERS_ALL_FETCH';
export const USERS_ALL_FETCHED = 'USERS_ALL_FETCHED';
export const USER_LIST_FETCH = 'USER_LIST_FETCH';
export const USER_LIST_FETCHED = 'USER_LIST_FETCHED';
export const USER_ID_REMOVE = 'USER_ID_REMOVE';
export const USER_DATA_REMOVE = 'USER_DATA_REMOVE';
export const USER_DATA_UPDATE = 'USER_DATA_UPDATE';
export const USER_DATA_DELETE = 'USER_DATA_DELETE';
export const USERS_ALL_DATA_REMOVE = 'USERS_ALL_DATA_REMOVE';
export const USER_STATUS_CHANGE = 'USER_STATUS_CHANGE';
export const USER_SKIP_UPDATE = 'USER_SKIP_UPDATE';
export const USER_SKIP_INIT = 'USER_SKIP_INIT';