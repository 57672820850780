import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 8,
    error: null,
    loading: null,
    accommodationId: null,
    allAccommodations: null,
    accommodationDetail: null,
    hostedAccommodations: null,
    pendingAccommodations: null
};

// Reducer
export const accommodationReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.ACCOMODATION_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.ACCOMODATION_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.ACCOMODATION_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.ACCOMODATIONS_ALL_FETCHED:
            return {
                ...state,
                allAccommodations: action.allAccommodations
            };
        case actionTypes.ACCOMODATIONS_HOSTED_FETCHED:
            return {
                ...state,
                hostedAccommodations: action.hostedAccommodations
            };
        case actionTypes.ACCOMODATIONS_PENDING_FETCHED:
            return {
                ...state,
                pendingAccommodations: action.pendingAccommodations
            };
        case actionTypes.ACCOMODATIONS_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.ACCOMODATION_DETAIL_FETCHED:
            return {
                ...state,
                accommodationDetail: action.accommodationDetail
            };
        case actionTypes.ACCOMODATION_ID_ADD:
            return {
                ...state,
                accommodationId: action.accommodationId
            };
        case actionTypes.ACCOMODATIONS_SKIP_INIT:
            return {
                ...state,
                skip: initialState.skip
            };            
        case actionTypes.ACCOMODATIONS_INIT:
            return {
                ...initialState
            };
        default:
            return state;
    };
};