import * as actionTypes from "./actionTypes";

// When fetch or add or update or delete start
export const fetchStart = () => {
  return {
    type: actionTypes.LINK_START,
  };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
  return {
    type: actionTypes.LINK_FAILD,
    error,
  };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
  return {
    type: actionTypes.LINK_SUCCESS,
  };
};

// All links fetch function
export const fetchAllLinks = (adminId) => {
  return {
    type: actionTypes.LINK_ALL_FETCH,
    adminId,
  };
};

// All links fetched function
export const allLinksFetched = (links) => {
  return {
    type: actionTypes.LINK_ALL_FETCHED,
    links,
  };
};

// Add link function
export const addLink = (adminId, linkData) => {
  return {
    type: actionTypes.LINK_ADD,
    adminId,
    linkData,
  };
};

// Update link function
export const updateLink = (adminId, linkId, updateData) => {
  return {
    type: actionTypes.LINK_UPDATE,
    adminId,
    linkId,
    updateData,
  };
};

// Delete link function
export const deleteLink = (adminId, linkId) => {
  return {
    type: actionTypes.LINK_DELETE,
    adminId,
    linkId,
  };
};
