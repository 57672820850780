import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    getLandlord,
    addLandlord,
    deleteLandlord,
    updateLandlord,
    getAllLanlords,
    getAllLandlordList,
    getAllPendingLandlordList
} from '../landlordCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all pending landlords function
export function* fetchingAllPendingLandlords(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch landlords
        const result = yield getAllPendingLandlordList(
            action.adminId,
            action.column,
            action.dir,
            action.skip,
            action.limit
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store landlord data into store
        yield put(actions.allPendingLandlordFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get all landlords function
export function* fetchingAllLandlords(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch landlords
        const result = yield getAllLanlords(
            action.adminId,
            action.column,
            action.dir,
            action.skip,
            action.limit,
            action.search
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store landlord data into store
        yield put(actions.allLandlordFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get all landlords list function
export function* fetchingAllLandlordList(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch landlord lists
        const result = yield getAllLandlordList(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store landlord data into store
        yield put(actions.landlordListFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get landlord function
export function* fetchingLandlord(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield getLandlord(action.adminId, action.landlordId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store landlord data into store
        yield put(actions.landlordFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Update landlord data function
export function* updatingLandlord(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield updateLandlord(action.adminId, action.landlordId, action.updateData);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // // Store landlord data into store
        // yield put(actions.fetchAllUsers(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Landlord updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Delete landlord function
export function* deletingLandlord(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield deleteLandlord(action.adminId, action.landlordId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // // Store landlord data into store
        // yield put(actions.fetAllLandlords(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Landlord updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Change landlord status function
export function* changingLandlordStatus(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield deleteLandlord(action.adminId, action.landlordId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store landlord data into store
        yield put(actions.fetchAllLandlord(
            action.adminId,
            action.column,
            action.dir,
            action.skip,
            action.limit
        ));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Landlord updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Add landlord function
export function* addingLandlord(action) { 
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to fetch properties
        const result = yield addLandlord(action.adminId, action.values);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // // Store landlord data into store
        // yield put(actions.fetchAllLandlord(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Landlord added successfully');

    } catch (err) {
        console.log(err);

        // Notification
        notify('error', err.message);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};