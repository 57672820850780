// Action types
export const BOOKING_START = 'BOOKING_START';
export const BOOKING_DETAIL_START = 'BOOKING_DETAIL_START';
export const BOOKING_FAILD = 'BOOKING_FAILD';
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_ALL_FETCH = 'BOOKING_ALL_FETCH';
export const BOOKING_ALL_FETCHED = 'BOOKING_ALL_FETCHED';
export const BOOKING_DETAIL_FETCH = 'BOOKING_DETAIL_FETCH';
export const BOOKING_DETAIL_FETCHED = 'BOOKING_DETAIL_FETCHED';
export const BOOKING_DETAIL_REMOVE = 'BOOKING_DETAIL_REMOVE';
export const BOOKING_USER_FETCH = 'BOOKING_USER_FETCH';
export const BOOKING_USER_FETCHED = 'BOOKING_USER_FETCHED';
export const BOOKING_SKIP_UPDATE = 'BOOKING_SKIP_UPDATE';
export const BOOKING_SKIP_INIT = 'BOOKING_SKIP_INIT';
export const BOOKING_INIT = 'BOOKING_INIT';