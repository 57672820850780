/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import Dropdown from "react-bootstrap/Dropdown";

import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl} from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

function UserProfileDropdown(props) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  return (
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
            as={ DropdownTopbarItemToggler }
            id="dropdown-toggle-user-profile"
        >
          <div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"}>
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
            <span className="text-dark font-weight-bolder font-size-base d-none d-md-inline mr-3">
              { props.user && props.user.firstName } { props.user && props.user.lastName }              
            </span>
            <span className="symbol symbol-40 symbol-dark">            
              <span className="symbol-label pho_18_600 rounded-circle">{ props.user && props.user.firstName[0] }</span>
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
            className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <>
            {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            {layoutProps.light && (<>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt=""/>
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{ props.user && props.user.firstName } { props.user && props.user.lastName } </div>
                <span className="label label-light-success label-lg font-weight-bold label-inline">3 messages</span>
              </div>
              <div className="separator separator-solid"></div>
            </>)}

            {!layoutProps.light && (
                <div className="p-5 text-center">
                <span className="symbol symbol-80 symbol-dark d-table mx-auto mt-5 pt-5">            
                  <span className="symbol-label pho_18_600 font-weight-bold rounded">{props.user && props.user.firstName[0]}</span>
                  {/* <span className="symbol-label pho_18_600 font-weight-bold rounded">S</span> */}
                  {/* <img alt="Pic" className="hidden" src={ props.user &&props.user.profilePicture } /> */}
                </span>
                <div className="pho_18_600 m-auto pt-5 pb-1">
                  { props.user && props.user.firstName } { props.user && props.user.lastName }   
                </div>
                <span className="pho_14_400 text-muted d-table m-auto pb-5">
                  { props.user && props.user.emailId }
                </span>
                <Link to="/profile" className="pho_14_600 btn btn-outline-success">
                  EDIT PROFILE
                </Link>                
              </div>
            )}
          </>

          <div className="navi navi-spacer-x-0 pt-5">
            <div className="navi-footer p-0 rounded-bottom">
              <Link to="/logout" className="btn rounded-0 py-5 btn-light-danger pho_14_600 w-100">
                SIGN OUT
              </Link>              
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
  );
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(UserProfileDropdown)
