import * as Yup from 'yup';

// Initial tab value
export const initialTemplateTableValues = {
    templates: [],
    checkbox: false
};

// Initial add template values
export const addTemplateInitValues = {
    mailName: '',
    entity: '',
    mailTitle: '',
    mailSubject: '',
    mailBody: '',
    templates: [],
    entities: []
};

// Add template schema
export const addTemplateSchema = Yup.object().shape({
    mailName: Yup.string()
        .trim()
        .required('Please select Template name'),
    mailTitle: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter Email name")
        .required('Please enter Email title'),
    mailSubject: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], "Please enter Email subject")
        .required('Please enter Email subject'),
    mailBody: Yup.string()
        .trim()
        .notOneOf(['null', 'NULL', 'Null', 'Undefined', 'undefined'], 'Please enter valid Email body')
        .required('Please enter Email body')
});