import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    skip: 1,
    limit: 10,
    error: null,
    users: null,
    userId: null,
    loading: null,
    userList: null,
    userDetail: null,
};

// Reducer
export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.USER_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.USER_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.USER_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.USERS_ALL_FETCHED:
            return {
                ...state,
                users: action.users
            };    
        case actionTypes.USERS_ALL_DATA_REMOVE:
            return {
                ...state,
                users: null
            }; 
        case actionTypes.USER_FETCHED:
            return {
                ...state,
                userDetail: action.userDetail
            };  
        case actionTypes.USER_ID_ADD:
            return {
                ...state,
                userId: action.userId
            };  
        case actionTypes.USER_ID_REMOVE:
            return {
                ...state,
                userId: null
            };  
        case actionTypes.USER_DATA_REMOVE:
            return {
                ...state,
                userDetail: null
            };  
        case actionTypes.USER_LIST_FETCHED:
            return {
                ...state,
                userList: action.userList
            }; 
        case actionTypes.USER_SKIP_UPDATE:
            return {
                ...state,
                skip: action.skip
            };
        case actionTypes.USER_SKIP_INIT:
            return {
                ...state,
                skip: 1
            };
        default:
            return state;
    };
};