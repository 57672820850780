import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    addingUser,
    fetchingUser,
    updatingUser,
    deletingUser,
    fetchingAllUsers,
    changingUserStatus, 
    fetchingAllUserList
} from './saga';

export function* watchUsers() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.USER_ADD, addingUser),
        takeEvery(actionTypes.USER_FETCH, fetchingUser),
        takeEvery(actionTypes.USER_DATA_UPDATE, updatingUser),
        takeEvery(actionTypes.USER_DATA_DELETE, deletingUser),
        takeEvery(actionTypes.USERS_ALL_FETCH, fetchingAllUsers),
        takeEvery(actionTypes.USER_STATUS_CHANGE, changingUserStatus),
        takeEvery(actionTypes.USER_LIST_FETCH, fetchingAllUserList),
    ]);
};