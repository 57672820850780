import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.DASHBOARD_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.DASHBOARD_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.DASHBOARD_SUCCESS
    };
};

// When fetch properties
export const fetchDashboardCounts = (userId, year) => {
    return {
        type: actionTypes.DASHBOARD_COUNT_FETCH,
        userId,
        year
    };
};

// When properties fetched
export const dashboardCountsFetched = (counts) => {
    return {
        type: actionTypes.DASHBOARD_COUNT_FETCHED,
        users: counts.users,
        bookings: counts.booking,
        landlords: counts.landlords,
        totalUsers: counts.totalUsers,
        totalBookings: counts.totalBookings,
        totalLandlords: counts.totalLandlords
    };
};


// Dashboard init
export const dashboardInit = () => {
    return {
        type: actionTypes.DASHBOARD_INIT        
    };
};