import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from 'react-select';

import { useSubheader } from "../../../_core/MetronicSubheader";
import * as subheaderAction from '../../../../../app/modules/Subheader';

const sortingOptions = [
  // { value: '', label: 'Sort By' },
  { value: 'asc', label: 'A-Z' },
  { value: 'dsc', label: 'Z-A' },
];

const typeOptions = [
  // { value: '', label: 'Type' },
  { value: 'HOME_OFFICE', label: 'Home Office' },
  { value: 'WORKPLACE', label: 'Workplace' },
];

const statusOptions = [
  { value: 'OPEN', label: 'Open' },
  { value: 'CLOSE', label: 'Closed' },
];

const enterKey = 13;
const intervalTime = 1000;
let timeout = null;

function FilterActions(props) {
  const subheader = useSubheader();
  const location = useLocation();
  
  // State
  const [showSupport, setShowSupport] = useState(false)
  const [selectedOption, setselectedOption] = useState(null)
  const [selectedOption2, setselectedOption2] = useState(null)
  const [selectedOption3, setselectedOption3] = useState(null)
  const [hideField, setHideField] = useState(true);
  const [typeOption, setTypeOption] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [statusOption, setStatusOption] = useState(null);
  const [inputSearchValue, setInputSearchValue] = useState('');

  // // On handle change
  // const handleChange = value => {
  //   // Set state
  //   setselectedOption(value);
  // };

  // On input change handler
  const onInputSearchChangeHandler = (e) => {
    // Clear timeout
    clearTimeout(timeout);

    // Set input search value inot state
    setInputSearchValue(e.target.value);
  };

  // On key down handler
  const onKeyDownHandler = (e) => {

    if (e.keyCode === enterKey) {
      clearTimeout(timeout);

      // Call function to get data by search
      props.addSearch(inputSearchValue);
    }
  }; 

  // On sort change handler
  const onSortChangeHandler = (e) => {

    // Set state
    setSortOption(e);

    // Store sorting value in store
    props.addSorting('createdAt', e.value);
  };

  // On status change handler
  const onStatusChangeHandler = (e) => {

    // Set state
    setStatusOption(e);

    // Store sorting value in store
    props.addStatus(e.value);
  };

  // On type change handler
  const onTypeChangeHandler = (e) => {

    // Set state
    setTypeOption(e);

    // Store sorting value in store
    props.addType(e.value);
  };
  
  const handleChange = value => {
    setselectedOption(value);
  };
  const handleChange2 = value => {
    setselectedOption2(value);
  };
  const handleChange3 = value => {
    setselectedOption3(value);
  };
  
  useEffect(() => {
    if(
      location.pathname === '/accommodation-management' ||
      location.pathname === '/accommodation-request'
    ){
      // Set hide field value
      setHideField(false);

    } else {
      // Set hide field value
      setHideField(true);
    }

    //Support page
    if(
      location.pathname === '/support' 
    ){
      // Set show support value
      setShowSupport(true);

    } else {
      // Set show support value
      setShowSupport(false);  
    }
  }, [subheader, location.pathname]);

  useEffect(() => {
    timeout = setTimeout(() => {

      // Call function to get data by search
      props.addSearch(inputSearchValue);
    }, intervalTime);
  }, [inputSearchValue]);

  useEffect(() => {
    if (typeOption) {
      // Set type option
      setTypeOption(null);
    }

    if (sortOption) {
      // Set sort option
      setSortOption(null);
    }

    if (inputSearchValue) {
      // Set input search value
      setInputSearchValue('');
    }
  }, [location.pathname]);

  return (
    <>
      <Form 
        className="pho_subheader_filter" 
      >
        <Form.Row className="justify-content-md-end">
          <Form.Group controlId="formGridEmail">
            <Form.Control 
              type="text" 
              name="Search"
              placeholder="Search" 
              value={ inputSearchValue }
              onChange={ onInputSearchChangeHandler }
              onKeyDown={ onKeyDownHandler }
            />
          </Form.Group> 
          {
            !hideField && <Form.Group controlId="exampleForm.ControlSelect2">
              <Select
                value={ typeOption }
                onChange={ onTypeChangeHandler }
                placeholder="Type"
                className="form-control p-0 border-0"
                classNamePrefix="phoSelect"
                options={ typeOptions }
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#DCF4E4',
                    primary75: '#DCF4E4',
                    primary50: '#DCF4E4',
                    primary:"#50C878",
                  },
                })}
              />
            </Form.Group>
          }
          {
            showSupport && <Form.Group controlId="exampleForm.ControlSelect2">
              <Select
                value={ statusOption }
                onChange={ onStatusChangeHandler }
                placeholder="Status"
                className="form-control p-0 border-0"
                classNamePrefix="phoSelect"
                options={ statusOptions }
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#DCF4E4',
                    primary75: '#DCF4E4',
                    primary50: '#DCF4E4',
                    primary:"#50C878",
                  },
                })}

              />
            </Form.Group>
          }
                     
          <Form.Group controlId="exampleForm.ControlSelect2">
            <Select
              value={ sortOption }
              onChange={ onSortChangeHandler }
              placeholder="Sort By"
              className="form-control p-0 border-0"
              classNamePrefix="phoSelect"
              options={ sortingOptions }
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#DCF4E4',
                  primary75: '#DCF4E4',
                  primary50: '#DCF4E4',
                  primary:"#50C878",
                },
              })}
            />
          </Form.Group>
        </Form.Row>  
      </Form>
    </>
  );
};

// Dispatch actions
const mapDispatchToProps = dispatch => {
  return {
    addType: (value) => dispatch(subheaderAction.addType(value)),
    addStatus: (value) => dispatch(subheaderAction.addStatus(value)),
    addSearch: (value) => dispatch(subheaderAction.addSearch(value)),
    addSorting: (column, dir) => dispatch(subheaderAction.addSorting(column, dir)),
  };
};

export default connect(null, mapDispatchToProps)(FilterActions);