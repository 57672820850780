import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.TEMPLATE_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.TEMPLATE_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.TEMPLATE_SUCCESS
    };
};

// Add template function
export const addTemplate = (adminId, values) => {
    return {
        type: actionTypes.TEMPLATE_CREATE,
        adminId, 
        values
    };
};

// All TEMPLATE entities fetch function
export const fetchAllTemplateEntities = (adminId) => {
    return {
        type: actionTypes.TEMPLATE_ENTITIES_FETCH,
        adminId
    };
};

// All TEMPLATE entities fetched function
export const allTemplateEntitiesFetched = (entities) => {
    return {
        type: actionTypes.TEMPLATE_ENTITIES_FETCHED,
        entities
    };
};

// All TEMPLATE fetch function
export const fetchAllTemplate = (adminId, column, dir, skip, limit) => {
    return {
        type: actionTypes.TEMPLATE_ALL_FETCH,
        adminId,
        column,
        dir,
        skip,
        limit
    };
};

// All TEMPLATE fetched function
export const allTemplateFetched = (allTemplates) => {
    return {
        type: actionTypes.TEMPLATE_ALL_FETCHED,
        allTemplates
    };
};

// Update skip function
export const updateSkip = (skip) => {
    return {
        type: actionTypes.TEMPLATE_SKIP_UPDATE,
        skip
    };
};

// TEMPLATE skip init function
export const templateSkipInit = () => {
    return {
        type: actionTypes.TEMPLATE_SKIP_INIT
    };
};

// TEMPLATE init function
export const templateInit = () => {
    return {
        type: actionTypes.TEMPLATE_INIT
    };
};

// TEMPLATE status change function
export const templateStatusChange = (adminId, templateId, column, dir, skip, limit) => {
    return {
        type: actionTypes.TEMPLATE_STATUS_CHANGE,
        adminId,
        templateId,
        column, 
        dir, 
        skip, 
        limit
    };
};

// Get TEMPLATE detail function
export const fetchTemplateDetail = (adminId, templateId) => {
    return {
        type: actionTypes.TEMPLATE_DETAIL_FETCH,
        adminId,
        templateId
    };
};

// TEMPLATE detail fetched function
export const templateDetailFetched = (templateDetail) => {
    return {
        type: actionTypes.TEMPLATE_DETAIL_FETCHED,
        templateDetail
    };
};

// TEMPLATE ID add function
export const addTemplateId = (templateId) => {
    return {
        type: actionTypes.TEMPLATE_ID_ADD,
        templateId
    };
};

// TEMPLATE page detail update function
export const updateTemplatePage = (adminId, templateId, values) => {
    return {
        type: actionTypes.TEMPLATE_UPDATE,
        adminId, 
        templateId, 
        values
    };
};