import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Pagination } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { Switch } from '@material-ui/core';
import { Formik, FieldArray } from 'formik';
import { connect } from "react-redux";
import moment from 'moment';

import ItemDropdown from './ItemDropdown';
import { toAbsoluteUrl } from "../../_helpers";
import { initialTemplateTableValues } from './TemplateSchema';
import * as templateActions from '../../../app/modules/EmailTemplate';

const TemplateManagement = (props) => {
    const history = useHistory();
    const pageNumbers = [];
    let totalPages;
    let result = [];

    const modifiedInitialTabObj = Object.assign({}, initialTemplateTableValues);

    // State
    const [state, setState] = useState({
        checked: true,
    });
    const [commonDelete, setCommonDelete] = useState({
        count: 0,
        value: false
    });
    
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    // On view button click
    const onViewButtonHandler = (e, template) => {
        e.preventDefault();

        if (template._id) {

            // Go to property detail page
            history.push(`/edit-template/${template._id}`);
        }
    };

    useEffect(() => {
        const skip = (props.skip - 1) * props.limit;

        // Get Template all data from database
        props.getAllTemplate(
            props.user._id,
            undefined,
            undefined,
            skip,
            props.limit
        );
    }, [props.skip]);

    useEffect(() => {
        // Remove Template store data when component destroy
        return () => {

            // Template init function
            props.templateInit();
        };
    }, []);

    if (props.allTemplates && props.allTemplates.records && props.allTemplates.records.length > 0) {
        const array = [];

        totalPages = Math.ceil(props.allTemplates.recordsTotal / props.limit);
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        };
    
        props.allTemplates.records.forEach(templates => {
            const data = { ...templates };
            data.checkbox = false;
            array.push(data);
        });

        modifiedInitialTabObj.templates = array; 
    }

    return (
        <>
            <div className="card card-custom"> 
                <Formik
                    enableReinitialize
                    initialValues={ modifiedInitialTabObj }
                >
                    {({
                        values, 
                        setFieldValue,
                    }) => (
                        <Table hover responsive className="phoTable">
                            <thead>
                                <tr>
                                    {/* <th>
                                        <div className="pho_checkbox iskl_border">
                                            <input
                                                type="checkbox"
                                                name="checkbox"
                                                className={`mr-2 checkbox`}
                                                checked={ values.checkbox }
                                                onChange={ (e) => {   
                                                    let total = 0;
                                                    // Set current check box value
                                                    setFieldValue('checkbox', !values.checkbox);

                                                    values.templates.forEach((el, index) => {  
                                                        total += 1;
                                                        
                                                        // Set all checkbox value
                                                        setFieldValue(`templates.${index}.checkbox`, !values.checkbox);
                                                    });                                                                            

                                                    if (values.checkbox || (!values.checkbox && total === 0)) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: 0,
                                                            value: false
                                                        });
                                                    } else if (!values.checkbox && total > 0) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: total,
                                                            value: true
                                                        });
                                                    }
                                                }}
                                            />  
                                            <label></label>
                                        </div>
                                    </th> */}
                                    <th>Date</th>
                                    <th>Email</th>
                                    <th>Active / Deactive</th>
                                    {
                                        commonDelete.count !== 0 ?
                                            // <th width="90px">
                                            //     <button
                                            //         style={{
                                            //             userSelect: 'none',
                                            //             cursor: 'pointer',
                                            //             color: 'red'
                                            //         }}
                                            //         className="btn btn-sm btn-danger text-white"
                                            //         type='submit'
                                            //     >
                                            //         Delete  
                                            //     </button>
                                            // </th>
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                        :   
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray name="templates">
                                    {({ insert, remove, push }) => (
                                        <>
                                            {
                                                !props.loading && values.templates && values.templates.length > 0 ?
                                                    values.templates.map((el, index) => (
                                                        <tr key={ index }>
                                                            {/* <td width="80px" >
                                                                <div className="pho_checkbox iskl_border">
                                                                    <input                                                                                    
                                                                        type="checkbox"
                                                                        name={ `templates.${index}.checkbox` }
                                                                        className={`mr-2 checkbox`}
                                                                        checked={ el.checkbox }
                                                                        onChange={ (e) => {
                                                                            if (el.checkbox) {
                                                                                // Set common delete state
                                                                                setCommonDelete({
                                                                                    count: commonDelete.count - 1,
                                                                                    value: false
                                                                                });
                                                                            } else {
                                                                                // Set common delete state
                                                                                setCommonDelete({
                                                                                    count: commonDelete.count + 1,
                                                                                    value: true
                                                                                });
                                                                            }

                                                                            // Set field values
                                                                            setFieldValue(`templates.${index}.checkbox`, !el.checkbox);
                                                                        } }
                                                                    />
                                                                    <label></label>
                                                                </div>
                                                            </td> */}
                                                            <td width="115px">
                                                                { moment(el.createdAt).format('L') }
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    { el.mailName }
                                                                </span>
                                                            </td>
                                                            <td className="text-center" width="135px">
                                                                <Switch
                                                                    color="primary"
                                                                    value={ el.status === 'ACTIVE' ? true : false }
                                                                    checked={ el.status === 'ACTIVE' ? true : false }
                                                                    onChange={ (e) => {
                                                                        const skip = (props.skip - 1) * props.limit;

                                                                        // if (el.status === 'ACTIVE') {
                                                                        //     setFieldValue(`CMS.${index}.status`, 'DEACTIVE');
                                                                        // } else {
                                                                        //     setFieldValue(`CMS.${index}.status`, 'ACTIVE');
                                                                        // }
                                                                        
                                                                        // Update template status
                                                                        props.templateStatusChange(
                                                                            props.user._id, 
                                                                            el._id,
                                                                            undefined,
                                                                            undefined,
                                                                            skip,
                                                                            props.limit
                                                                        );
                                                                    } }
                                                                />
                                                            </td>
                                                            <td width="90px">
                                                                <ItemDropdown 
                                                                    item="" 
                                                                    doc={ el }
                                                                    view={ onViewButtonHandler }
                                                                />
                                                            </td>
                                                        </tr> 
                                                    ))
                                                : 
                                                    props.loading ?
                                                        (
                                                            // <div id="compnent-splash-screen" className="compnent-splash-screen">
                                                            //     <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                                                            //         <circle 
                                                            //             className="path"
                                                            //             cx="25"
                                                            //             cy="25"
                                                            //             r="20"
                                                            //             fill="none"
                                                            //             strokeWidth="5"
                                                            //         ></circle>
                                                            //     </svg>
                                                            // </div>
                                                            <tr>
                                                                <td colSpan="5" className="text-center">Loading...</td>
                                                            </tr>
                                                        )
                                                    : 
                                                        <tr>
                                                            <td colSpan="5" className="text-center">No Data Available</td>
                                                        </tr>
                                            }
                                        </>
                                    )}
                                </FieldArray>          
                            </tbody>
                        </Table>
                    )}
                </Formik>    
            </div> 

            {/* Pagination */}
            { 
                !props.loading && props.allTemplates && props.allTemplates.recordsTotal > 0 && 
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                        <div className="pho_14_400 pho_opacity_5">
                            {
                                // props.search ? 
                                //     `Displaying ${ props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - ${ result.length > (props.limit * props.skip) ? props.limit * props.skip : result.length } of ${ result.length } records`
                                // :
                                    `Displaying ${ props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - ${ props.allTemplates.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.allTemplates.recordsTotal } of ${ props.allTemplates.recordsTotal } records`
                            }
                            {/* Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.allTemplates.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.allTemplates.recordsTotal } of { props.allTemplates.recordsTotal } records */}
                        </div>
                        <Pagination>
                            {/* Go to first page */}
                            <Pagination.First
                                disabled={ props.skip === 1 } 
                                onClick={ goToFirstPage }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                            </Pagination.First>

                            {/* Go to previous page */}
                            <Pagination.Prev
                                disabled={ props.skip === 1 } 
                                onClick={ prevPageHandler }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                            </Pagination.Prev>

                            {/* All pages */}
                            {
                                pageNumbers.map((number, i) => {
                                    if (
                                        number >= parseInt(props.skip) - 3 &&
                                        number <= parseInt(props.skip) + 3 
                                    ) {
                                        return (
                                            <Pagination.Item
                                                key={ i }
                                                id={ number }
                                                active={ number === props.skip }
                                                onClick={ pageChangeHandler }
                                            >
                                                { number }
                                            </Pagination.Item>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }

                            {/* Go to next page */}
                            <Pagination.Next
                                disabled={ props.skip === totalPages } 
                                onClick={ nextPageHandler }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                            </Pagination.Next>

                            {/* Go to last page */}
                            <Pagination.Last
                                disabled={ props.skip === totalPages } 
                                onClick={ goToLastPage }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                            </Pagination.Last>
                        </Pagination>
                    </div>
            }       
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,   
        error: state.template.error,
        loading: state.template.loading,            
        allTemplates: state.template.allTemplates,
        skip: state.template.skip,
        limit: state.template.limit
    };
};

const mapDispatchToProps = dispatch => {
    return {
        templateInit: () => dispatch(templateActions.templateInit()),
        updateSkip: (skip) => dispatch(templateActions.updateSkip(skip)),
        getAllTemplate: (
            adminId,
            column,
            dir,
            skip,
            limit
        ) => dispatch(templateActions.fetchAllTemplate(adminId, column, dir, skip, limit)),  
        templateStatusChange: (
            adminId, 
            templateId,
            column,
            dir,
            skip,
            limit
        ) => dispatch(templateActions.templateStatusChange(adminId, templateId, column, dir, skip, limit))      
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagement);