import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import { notify } from '../toaster';
import { useSubheader } from '../../layout';
import * as landlordActions from '../../../app/modules/Landlord';
import { landlordAddInitialValues, addLandlordSchema } from './landlordSchema';
import { uploadImage } from '../../../app/modules/ImageUpload/imageUpload';
import { connect } from 'react-redux';
import { addLandlord } from '../../../app/modules/Landlord/landlordCrud';

const AddLandlord = (props) => {
    const subHeader = useSubheader();
    const inputFile = useRef(null);
    const history = useHistory();

    const modifiedInitialObj = Object.assign({}, landlordAddInitialValues);

    // State
    const [profileImage, setProfileImage] = useState(null);

    // // Image change handler
    // const handleImageChange = (e) => {
    //     if (e.target.files.length) {
    //         setProfileImage(URL.createObjectURL(e.target.files[0]));
    //     }
    // };

    // Image change handler
    const handleImageChange = async (e, setFieldValue) => {
        const file = e.target.files[0];  

        if (!file) {          
            return;
        }

        const type = file.type.split('/');

        if (
            type[1] === 'png' ||
            type[1] === 'jpg' ||
            type[1] === 'jpeg' 
        ) {

            // Convert file into form data
            const formData = new FormData();
            formData.append('img', file);
    
            // Upload file to storage service
            return uploadImage(formData)
                .then(async result => {    

                    if (result.data.responseCode === 200) {
                        // Set field values
                        setFieldValue('profilePicture', result.data.responseData);
                    }

                    // if (oldImage && oldImage.length > 0) {
                    //     await deleteFile(oldImage);
                    // }
                })
                .catch(err => {                    
                    console.log(err);
                });            
        } else {
            document.getElementById('upload-button').value = '';

            // Notification
            notify('error', 'Please upload image');
        }
    };

    // Add landlord function
    const addLandlordFunction = async (values, resetForm, setSubmitting) => {
        try {
            values.role = 'LANDLORD';
                        
            // Submit add user function
            // props.addLandlord(props.user._id, values);

            const { data } = await addLandlord(props.user._id, values);

            // If response error found throw error
            if (data.responseCode !== 200) {
                throw new Error(data.responseMessage);
            }

            setTimeout(() => {
                console.log(props.error);
                // Set submitting form to false
                setSubmitting(false);

                // Reset form to initial values
                resetForm(landlordAddInitialValues);

                // Go back to property management page                            
                history.push('/landlord-management');
            }, 3000);

        } catch (err) {
            console.log(err);

            // Set submitting form to false
            setSubmitting(false);

            // Notification
            notify('error', err.message);
        }
    };

    // On button click
    const onButtonClick = (e) => {
        // `current` points to the mounted file input element
       inputFile.current.click();
    };

    useEffect(() => {
        const title = 'Landlord Details';

        // Set sub header title
        subHeader.setTitle(title);
    }, []);

    return (
        <>
            <div>  
                <Formik
                    enableReinitialize
                    initialValues={ modifiedInitialObj }
                    validationSchema={ addLandlordSchema }
                    onSubmit={(values, { setSubmitting, resetForm }) => {  
                        // Set submitting to true
                        setSubmitting(true);

                        // Add landlord to database
                        addLandlordFunction(values, resetForm, setSubmitting);                        
                    }}
                >
                    {({
                        values, 
                        errors, 
                        touched, 
                        isSubmitting,
                        handleSubmit,
                        setFieldValue
                    }) => (
                        <Form className="pho_mt_negative70">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-end pb-5">
                                    <button
                                        type="submit"     
                                        disabled={ isSubmitting }                                           
                                        className={`btn btn-success px-8 py-4 mb-5`}
                                    >
                                        <span>Add</span>
                                    </button>
                                </div>
                                <div className="col-md-12 col-lg-4 text-center">
                                    <div className="pho_profile_pic_bx">
                                        <div className="pho_pic_bx">
                                            {
                                                values.profilePicture ? (
                                                    <img 
                                                        src={ values.profilePicture } 
                                                        alt="Admin Pic" 
                                                        className="w-100 h-100" 
                                                    />
                                                ) : (
                                                    <div>
                                                        <div className="pho_opacity_3 pho_plus pt-18 pb-10">+</div>
                                                        <label className="pho_opacity_3 pho_14_500">Upload Photo</label>
                                                    </div>
                                                )
                                            }
                                            <input
                                                type="file"
                                                ref={ inputFile }
                                                name="profilePicture"
                                                id="upload-button"
                                                accept=".png, .jpg, .jpeg"
                                                // onChange={ handleImageChange }
                                                onChange={ async (e) => {
                                                    
                                                    // Call handle image change function to upload image file
                                                    await handleImageChange(e, setFieldValue);
                                                } }
                                            />
                                        </div>                                        
                                        {touched.profilePicture && errors.profilePicture ? (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.profilePicture}</div>
                                            </div>
                                        ) : null}
                                        {
                                            values.profilePicture &&
                                                <div className="d-flex justify-content-center">   
                                                    <button
                                                        type="button"
                                                        className="text-center btn btn-sm btn-outline-success pho_90 mr-1"
                                                        onClick={ async (e) => {
                                                            onButtonClick(e);
                                                        } }
                                                    >
                                                        <span>EDIT</span>
                                                    </button>          
                                                    <button 
                                                        type="button" 
                                                        className="text-center btn btn-sm btn-outline-danger pho_90"
                                                        onClick={ (e) => {
                                                            // Remove image from state
                                                            // remove(e);

                                                            // Set field values
                                                            setFieldValue('profilePicture', '');
                                                        }} 
                                                    >
                                                        <span>REMOVE</span>
                                                    </button>
                                                </div>
                                        }
                                    </div>                                    
                                </div>
                                <div className="col-md-12 col-lg-8">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">First Name<sup className="text-danger">*</sup></label>
                                                <Field
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="First Name"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                    
                                                />
                                                {touched.firstName && errors.firstName ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.firstName}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Last Name<sup className="text-danger">*</sup></label>
                                                <Field
                                                    type="text"
                                                    name="lastName"           
                                                    placeholder="Last Name"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6`}
                                                />
                                                {touched.lastName && errors.lastName ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.lastName}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Contact Number<sup className="text-danger">*</sup></label>
                                                <Field
                                                    type="text"
                                                    name="contactNumber" 
                                                    placeholder="Contact Number"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                />
                                                {touched.contactNumber && errors.contactNumber ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.contactNumber}</div>
                                                    </div>
                                                ) : null}
                                            </div>                                            
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Email</label>
                                                <Field
                                                    type="email"
                                                    name="emailId" 
                                                    placeholder="Email"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                />
                                                {touched.emailId && errors.emailId ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.emailId}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>  
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Password<sup className="text-danger">*</sup></label>
                                                <Field
                                                    type="password"
                                                    name="password" 
                                                    placeholder="Password"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                />
                                                {touched.password && errors.password ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.password}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div> 
                                        <div className="col-md-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Confirm Password<sup className="text-danger">*</sup></label>
                                                <Field
                                                    type="password"
                                                    name="confirmPassword" 
                                                    placeholder="Confirm Password"
                                                    className={`form-control pho_16_500 border-0 h-auto py-5 px-6 `}
                                                />
                                                {touched.confirmPassword && errors.confirmPassword ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.confirmPassword}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>                               
                                    </div>
                                </div>
                            </div>      
                        </Form>
                    )}
                </Formik>    
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        error: state.landlord.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addLandlord: (adminId, values) => dispatch(landlordActions.addLandlord(adminId, values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLandlord);
