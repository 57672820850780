import axios from "axios";

// For demo purpose
// export const LOGIN_URL = "api/auth/login";
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
// export const RESET_PASSWORD_URL = "api/auth/reset-password";
// export const ME_URL = "api/me"; 

// For live project purpose
export const LOGIN_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/login`;
// export const REGISTER_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/api/auth/register`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/forgotPassword`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/resetPassword`;
export const ME_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getProfile`; 
export const LOGOUT_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/logout`;

// USER
export const UPDATE_USER_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateProfile`;
export const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/resetPassword`;

// Service Fee
export const UPDATE_SERVICE_FEE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/setServiceFee`;
export const GET_SERVICE_FEE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getServiceFee`;

export async function login(emailId, password) {
  return await axios.post(LOGIN_URL, { emailId, password });
}

export async function register(email, fullname, username, password) {
  // return await axios.post(REGISTER_URL, { email, fullname, username, password });
}

export async function requestPassword(emailId) {
  return await axios.post(REQUEST_PASSWORD_URL, { emailId });
}

// Reset new password function
export async function setNewPassword(userId, password) {
  return await axios.post(`${RESET_PASSWORD_URL}/${userId}`, { password });
};

export async function getUserByToken(userId) {
  // Authorization head should be fulfilled in interceptor.
  return await axios.get(`${ME_URL}/${userId}`);
}

// User logout function
export function logout(userId) {
  return axios.get(`${LOGOUT_URL}/${userId}`);
};

// Update user
export function updateUser(userId, updateData) {
  return axios.put(`${UPDATE_USER_URL}/${userId}`, { ...updateData });
};

// Change user password
export function changeUserPassword(userId, oldPassword, newPassword) {
  return axios.post(`${CHANGE_PASSWORD_URL}/${userId}`, { oldPassword, newPassword });
};

// Update service fee
export function updateServiceFee(adminId, values) {
  return axios.post(`${UPDATE_SERVICE_FEE_URL}/${adminId}`, { ...values });
};

// Get service fee
export function getServiceFee(adminId) {
  return axios.get(`${GET_SERVICE_FEE_URL}/${adminId}`);
};