import * as Yup from 'yup';

// Category initial values
export const categoryInitValues = {
    name: '',
    type: ''
};

// Category schema
export const categorySchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .nullable('Please enter valid Title')
        .required('Please enter Title'),
    type: Yup.string()
        .required('Please select Type')
});

// Amenity initial values
export const amenityInitValues = {
    name: ''
};

// Amenity schema
export const amenitySchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .nullable('Please enter valid Amenity')
        .required('Please enter Amenity')
});

// Category and Amenity initial values
export const categoryAndAmenityInitValue = {
    title: '',
    amenities: [{
        name: ''
    }]
};

// Category and Amenity schema
export const categoryAndAmenitySchema = Yup.object().shape({
    title: Yup.string()
        .trim()
        .nullable('Please enter valid Category title')
        .required('Please enter Category title'),
    amenities: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string()
                    .strict()
                    .nullable('Please enter valid Amenity')
                    .required('Please enter Amenity')
            })
        )
});