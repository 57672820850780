import React, { useState, useEffect } from "react";
import { Table, Pagination, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import Select from 'react-select';
import { Formik, FieldArray } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import FuzzySearch from 'fuzzy-search';

import { notify } from '../toaster';
import ItemDropdown from './ItemDropdown';
import { toAbsoluteUrl } from "../../_helpers";
import { initialSupportTableValues } from './SupportSchema';
import * as supportActions from '../../../app/modules/Support';
import * as subheaderAction from '../../../app/modules/Subheader';
// import ItemDropdown from '../../../_metronic/layout/components/extras/dropdowns/ItemDropdown';

const Support = (props) => {
    const pageNumbers = [];
    let totalPages;
    let result = [];
    const history = useHistory();

    let skipInit = props.skip;

    const modifiedInitialTabObj = Object.assign({}, initialSupportTableValues);

    const statusOptions = [
        { value: 'OPEN', label: 'OPEN' },
        { value: 'CLOSE', label: 'CLOSED' },
    ];

    // State
    const [deleteModal, setDeleteModal] = useState(false);
    const [user, setUser] = useState({
        _id: '',
        status: '',
        emailId: '',
        lastName: '',
        firstName: '',
        createdAt: '',
        contactNumber: ''        
    });
    const [commonDelete, setCommonDelete] = useState({
        count: 0,
        value: false
    })

    // Show delete modal
    const showDeleteModal = (e, user, multiple) => {
        // Show delete modal
        setDeleteModal(true);

        if (multiple) {
            // Set user state
            setUser({
                values: user,
                multiple
            });

        } else {
            // Set user state
            setUser({
                _id: user._id,
                status: user.status,
                emailId: user.emailId,
                lastName: user.lastName,
                firstName: user.firstName,
                createdAt: user.createdAt,
                contactNumber: user.contactNumber
            });
        }
    };

    // Hide delete modal function
    const hideDeleteModal = (e) => {
        // hide delete modal
        setDeleteModal(false);

        // Set user state
        setUser({
            _id: '',
            status: '',
            emailId: '',
            lastName: '',
            firstName: '',
            createdAt: '',
            contactNumber: '' 
        });
    };

    // On view button click
    const onViewButtonHandler = (e, query) => {
        e.preventDefault();

        if (query._id) {

            // Go to property detail page
            history.push(`/support-details/${query._id}`);
        }
    };

    // On delete button click
    const onDeleteButtonClick = async (e) => {
        // if (todo.multiple) {
        //     // check if common delete state is true
        //     if (commonDelete.count > 0) {
        //         setCommonDelete({
        //             count: 0,
        //             value: false
        //         });
        //     }  

        //     // Make deleting selected doc request
        //     await props.deleteMultipleTodos(
        //         todo.values, 
        //         props.sort, 
        //         props.currentPage, 
        //         props.limit,
        //         props.filter,
        //         props.tabKey,
        //         props.selectedDate
        //     );

        //     if (todo.values.todos.length > 0) {
        //         const array = [];

        //         todo.values.todos.forEach(async el => {
        //             array.push(el.property);
        //         });

        //         const sorted_array = array.sort((a, b) => {
        //             return a.localeCompare(b);
        //         });

        //         let uniqueList = [];
        //         let dupList = [];
                
        //         Array.prototype.contains = function(item) {
        //             let filtered_item = this.filter((i) => {
        //                 return i.type === item.type;
        //             });
        //             return !!filtered_item.length;
        //         };
                
        //         function pushToUniqueList(item){
        //             if(!uniqueList.contains(item)) uniqueList.push(item);
        //         }
                
        //         function pushToDuplicateList(item){
        //             if(!dupList.contains(item)) dupList.push(item);
        //         }
                
        //         for (let i = 0; i < sorted_array.length; i++){
        //             if(uniqueList.contains(sorted_array[i])){
        //                 pushToDuplicateList(sorted_array[i]);
        //             } else {
        //                 pushToUniqueList(sorted_array[i]);
        //             }
        //         };

        //         uniqueList.forEach(async propertyId => {
        //             // Call fetch property function
        //             const propertyData = await propertyDataFetch(propertyId);

        //             if (propertyData) {
        //                 const data = {
        //                     propertyName: propertyData.propertyName,
        //                     sender: props.user._id,
        //                     notification: `Todo deleted`,
        //                     detail: `Seleced todos deleted by ${props.user.firstName} ${props.user.lastName}`,
        //                     url: `to-do-management`
        //                 };

        //                 if (props.user.subRole) {
        //                     data.subRole = props.user.subRole;
        //                 }
                
        //                 // Add new notification to database
        //                 await props.addNotification(data);
                
        //                 if (props.user.subRole) {
        //                     // Send notification to admin
        //                     socket.emit('sendNotification', { admin: propertyData.owner });
                            
        //                 } else {
        //                     if (propertyData.assignedTo && propertyData.assignedTo.manager) {
        //                         // Send notification to manager
        //                         socket.emit('sendNotification', { managerName: propertyData.assignedTo.manager });
        //                     }
        //                 }

        //                 // Get all tenants of property
        //                 const res = await fetchAllTenants(undefined, undefined, undefined, undefined, undefined, propertyData.propertyName);
                
        //                 if (res.data.response && res.data.response.tenants && res.data.response.tenants.count > 0) {
        //                     res.data.response.tenants.limit.forEach(async (tenant) => {
            
        //                         // Send notification to tenants
        //                         socket.emit('sendNotification', { tenantId: tenant._id });
        //                     });
        //                 }
        //             }
        //         });

        //         // todo.values.todos.forEach(async el => {
        //         //     // Call fetch property function
        //         //     const propertyData = await propertyDataFetch(el.property);

        //         //     if (propertyData) {
        //         //         const data = {
        //         //             propertyName: propertyData.propertyName,
        //         //             sender: props.user._id,
        //         //             notification: `Todo deleted`,
        //         //             detail: `Seleced todos deleted by ${props.user.firstName} ${props.user.lastName}`,
        //         //             url: `to-do-management`
        //         //         };

        //         //         if (props.user.subRole) {
        //         //             data.subRole = props.user.subRole;
        //         //         }
                
        //         //         // Add new notification to database
        //         //         await props.addNotification(data);
                
        //         //         if (props.user.subRole) {
        //         //             // Send notification to admin
        //         //             socket.emit('sendNotification', { admin: propertyData.owner });
                            
        //         //         } else {
        //         //             if (propertyData.assignedTo && propertyData.assignedTo.manager) {
        //         //                 // Send notification to manager
        //         //                 socket.emit('sendNotification', { managerName: propertyData.assignedTo.manager });
        //         //             }
        //         //         }
        //         //     }
        //         // }); 
        //     }

        // } else {
        //     // Delete todo
        //     await props.deleteTodo(
        //         todo._id,
        //         props.sort,
        //         props.currentPage,
        //         props.limit,
        //         props.filter,
        //         props.tabKey,
        //         props.selectedDate
        //     );

        //     // Call fetch property function
        //     const propertyData = await propertyDataFetch(todo.property);

        //     if (propertyData) {
        //         const data = {
        //             propertyName: propertyData.propertyName,
        //             sender: props.user._id,
        //             notification: `Todo deleted`,
        //             detail: `Todo (${todo.task}) deleted by ${props.user.firstName} ${props.user.lastName}`,
        //             url: `to-do-management`
        //         };
    
        //         if (props.user.subRole) {
        //             data.subRole = props.user.subRole;
        //         }
        
        //         // Add new notification to database
        //         await props.addNotification(data);
        
        //         if (props.user.subRole) {
        //             // Send notification to admin
        //             socket.emit('sendNotification', { admin: propertyData.owner });
                    
        //         } else {
        //             if (propertyData.assignedTo && propertyData.assignedTo.manager) {
        //                 // Send notification to manager
        //                 socket.emit('sendNotification', { managerName: propertyData.assignedTo.manager });
        //             }
        //         }
        
        //         // Get all tenants of property
        //         const res = await fetchAllTenants(undefined, undefined, undefined, undefined, undefined, propertyData.propertyName);
        
        //         if (res.data.response && res.data.response.tenants && res.data.response.tenants.count > 0) {
        //             res.data.response.tenants.limit.forEach(async (tenant) => {
    
        //                 // Send notification to tenants
        //                 socket.emit('sendNotification', { tenantId: tenant._id });
        //             });
        //         }
        //     }
        // }

        // // Hide delete modal
        // hideDeleteModal();
    };

    // Page change handler
    const pageChangeHandler = (e) => {

        // Update current page in store
        props.updateSkip(parseInt(e.target.id));
    };

    // Page change handler
    const nextPageHandler = (e) => {
        const nextPage = props.skip + 1;

        // Update current page in store
        props.updateSkip(nextPage);
    };

    // Page change handler
    const prevPageHandler = (e) => {
        const prevPage = props.skip - 1;

        // Update current page in store
        props.updateSkip(prevPage);
    };

    // Page change handler
    const goToFirstPage = (e) => {
        const firstPage = 1;

        // Update current page in store
        props.updateSkip(firstPage);
    };

    // Page change handler
    const goToLastPage = (e) => {
        const lastPage = totalPages;

        // Update current page in store
        props.updateSkip(lastPage);
    };

    // On Name sort handler
    const onNameSort = () => {
        if (props.column && props.column === 'firstName' && props.dir && props.dir === 'dsc') {
            props.addSorting('firstName', 'asc');
        } else {
            props.addSorting('firstName', 'dsc');
        }
    };

    // On Email sort handler
    const onEmailSort = () => {
        if (props.column && props.column === 'emailId' && props.dir && props.dir === 'dsc') {
            props.addSorting('emailId', 'asc');
        } else {
            props.addSorting('emailId', 'dsc');
        }
    };

    // On Contact Number sort handler
    const onContactNumberSort = () => {
        if (props.column && props.column === 'contactNumber' && props.dir && props.dir === 'dsc') {
            props.addSorting('contactNumber', 'asc');
        } else {
            props.addSorting('contactNumber', 'dsc');
        }
    };

    useEffect(() => {
        const skip = (props.skip - 1) * props.limit;

        // Get all users from database
        props.getAllQueries(
            props.user._id,
            props.column,
            props.dir,
            skip,
            props.limit,
            props.status
        );

        // Clean up function
        return () => {

            // Remoce all users data from store
            props.removeAllQueriesData();

            // // Remove search and sort values also
            // props.initSearchSorting();
        };
    }, [props.column, props.dir, props.skip, props.status]);

    useEffect(() => {
        // Clean up function
        return () => {

            // Remoce all users data from store
            props.removeAllQueriesData();

            // Remove search and sort values also
            props.initSearchSorting();

            // Init skip
            props.skipInit();
        };
    }, []);

    // Update modified initial tab object when queries found
    if (props.queries && props.queries.records && props.queries.records.length > 0) {
        // Integrate fuzzy search here
        if (props.search && props.search.length > 0) {
            skipInit = 1;
            const searcher = new FuzzySearch(props.queries.records, ['name', 'emailId'], {
                sort: true
            });

            result = searcher.search(props.search);

            totalPages = Math.ceil(result.length / props.limit);
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            };
    
            const array = [];
    
            result.forEach(user => {
                const data = { ...user };
                data.checkbox = false;
                array.push(data);
            });
    
            modifiedInitialTabObj.queries = array;

        } else {
            totalPages = Math.ceil(props.queries.recordsTotal / props.limit);
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            };
    
            const array = [];
    
            props.queries.records.forEach(user => {
                const data = { ...user };
                data.checkbox = false;
                array.push(data);
            });
    
            modifiedInitialTabObj.queries = array; 
        }
    }

    return (
        <>
            <Modal
                show={ deleteModal }
                onHide={ hideDeleteModal }
                size="md"
                className="pho_delete_modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 pho_18_600 text-danger text-center">
                        Delete Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="pho_18_500 text-center">
                        Are you sure want to delete { !user.multiple ? `user (${ user.firstName } ${ user.lastName })` : 'selected users' }?
                    </p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <button className="btn btn-outline-primary btn-sm mr-5" onClick={ hideDeleteModal }>Cancel</button>
                    <button className="btn btn-outline-danger btn-sm" onClick={ onDeleteButtonClick }>Delete</button>
                </Modal.Footer>
            </Modal>

            <div className="card card-custom">     
                <Formik
                    enableReinitialize
                    initialValues={ modifiedInitialTabObj }
                >
                    {({
                        values, 
                        setFieldValue
                    }) => (
                        <Table hover responsive className="phoTable">
                            <thead>
                                <tr>
                                    {/* <th>
                                        <div className="pho_checkbox iskl_border">
                                            <input
                                                type="checkbox"
                                                name="checkbox"
                                                className={`mr-2 checkbox`}
                                                checked={ values.checkbox }
                                                onChange={ (e) => {   
                                                    let total = 0;
                                                    // Set current check box value
                                                    setFieldValue('checkbox', !values.checkbox);

                                                    values.queries.forEach((el, index) => {  
                                                        total += 1;
                                                        
                                                        // Set all checkbox value
                                                        setFieldValue(`queries.${index}.checkbox`, !values.checkbox);
                                                    });                                                                            

                                                    if (values.checkbox || (!values.checkbox && total === 0)) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: 0,
                                                            value: false
                                                        });
                                                    } else if (!values.checkbox && total > 0) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: total,
                                                            value: true
                                                        });
                                                    }
                                                }}
                                            />
                                            <label></label>
                                        </div>
                                    </th> */}
                                    <th>
                                        Date
                                        {
                                            props.column && props.column === 'createdAt' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th>Ticket No</th>
                                    <th
                                        onClick={ onNameSort }
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                    >
                                        Name
                                        {
                                            props.column && props.column === 'firstName' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th
                                        onClick={ onEmailSort }
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                    >
                                        Email
                                        {
                                            props.column && props.column === 'emailId' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th
                                        onClick={ onContactNumberSort }
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                    >
                                        Phone
                                        {
                                            props.column && props.column === 'contactNumber' &&
                                                <span 
                                                    className={`pho_filter_arrow ml-5 ${props.dir === 'dsc' && 'rotate-arrow-img'}`}                                                        
                                                >
                                                </span>
                                        }
                                    </th>
                                    <th>Message</th>
                                    <th className="text-center">Status</th>
                                    <th width="90px"
                                        style={{
                                            userSelect: 'none'
                                        }}
                                    >
                                        Actions
                                    </th>
                                    {/* {
                                        commonDelete.count !== 0 ?
                                            <th width="90px">
                                                <button
                                                    style={{
                                                        userSelect: 'none',
                                                        cursor: 'pointer',
                                                        color: 'red'
                                                    }}
                                                    className="btn btn-sm btn-danger text-white"
                                                    type='submit'
                                                >
                                                    Delete  
                                                </button>
                                            </th>
                                        :   
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                    } */}
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray name="queries">
                                    {({ insert, remove, push }) => (
                                        <>
                                            { 
                                                values.queries && values.queries.length > 0 ?
                                                    values.queries.map((query, index) => (
                                                        <tr key={ index }>
                                                            {/* <td width="80px" >
                                                                <div className="pho_checkbox iskl_border">
                                                                    <input                                                                                    
                                                                        type="checkbox"
                                                                        name={ `queries.${index}.checkbox` }
                                                                        className={`mr-2 checkbox`}
                                                                        checked={ query.checkbox }
                                                                        onChange={ (e) => {
                                                                            if (query.checkbox) {
                                                                                // Set common delete state
                                                                                setCommonDelete({
                                                                                    count: commonDelete.count - 1,
                                                                                    value: false
                                                                                });
                                                                            } else {
                                                                                // Set common delete state
                                                                                setCommonDelete({
                                                                                    count: commonDelete.count + 1,
                                                                                    value: true
                                                                                });
                                                                            }

                                                                            // Set field values
                                                                            setFieldValue(`queries.${index}.checkbox`, !query.checkbox);
                                                                        } }
                                                                    />
                                                                    <label></label>
                                                                </div>
                                                            </td> */}
                                                            <td width="125px">
                                                                { query.createdAt ? moment(query.createdAt).format('L') : '' }
                                                            </td>
                                                            <td width="100px">
                                                                <span className="pho_16_500">
                                                                    { query.ticketNo }
                                                                </span>
                                                            </td>
                                                            <td width="100px">
                                                                { query.name }
                                                            </td>
                                                            <td width="200px">
                                                                { query.emailId }
                                                            </td>
                                                            <td width="130px">
                                                                { query.contactNumber }
                                                            </td>
                                                            <td>
                                                                <span className="pho_ellips w-250">
                                                                    { query.message }
                                                                </span>
                                                            </td>
                                                            <td width="140px">
                                                                <Select
                                                                    value={ { value: query.status, label: query.status } }
                                                                    onChange={ (e) => {
                                                                        const skip = (props.skip - 1) * props.limit;

                                                                        // Query status change
                                                                        props.queryStatusChange(
                                                                            props.user._id,
                                                                            query._id,
                                                                            e.value,
                                                                            props.column,
                                                                            props.dir,
                                                                            skip,
                                                                            props.limit,
                                                                            props.status
                                                                        );
                                                                    } }
                                                                    placeholder="Type"
                                                                    classNamePrefix="phoSelect"
                                                                    options={ statusOptions }
                                                                    className={`form-control p-0 border-0
                                                                        ${query.status === "OPEN" && "text-orange bg-light-orange"}
                                                                        ${query.status === "CLOSE" && "text-success bg-light-success"}
                                                                    `}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#DCF4E4',
                                                                            primary75: '#DCF4E4',
                                                                            primary50: '#DCF4E4',
                                                                            primary:"#50C878",
                                                                        },
                                                                    })}

                                                                />
                                                            </td>
                                                            <td width="90px">
                                                                <ItemDropdown 
                                                                    item="" 
                                                                    doc={ query }
                                                                    view={ onViewButtonHandler }
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                :
                                                    props.loading ?
                                                        (
                                                            // <div colSpan="7" id="propertyDetail-splash-screen" className="propertyDetail-splash-screen">
                                                            //     <svg className="propertyDetail-splash-spinner" viewBox="0 0 50 50">
                                                            //         <circle 
                                                            //             className="path"
                                                            //             cx="25"
                                                            //             cy="25"
                                                            //             r="20"
                                                            //             fill="none"
                                                            //             strokeWidth="5"
                                                            //         ></circle>
                                                            //     </svg>
                                                            // </div>
                                                            <tr>
                                                                <td colSpan="9" className="text-center">Loading...</td>
                                                            </tr>
                                                        )
                                                    : 
                                                        <tr>
                                                            <td colSpan="9" className="text-center">No Data Available</td>
                                                        </tr>

                                            }
                                        </>
                                    )}
                                </FieldArray>      
                            </tbody>
                        </Table>   
                    )}
                </Formik> 
            </div>
            {/* <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                <div className="pho_14_400 pho_opacity_5">Displaying 1 - 9 of 100 records</div>
                <Pagination>
                <Pagination.First><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/></Pagination.First>
                <Pagination.Prev><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/></Pagination.Prev>
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Next><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/></Pagination.Next>
                <Pagination.Last><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/></Pagination.Last>
                </Pagination>
            </div> */}
            {/* Pagination */}
            { 
                props.queries && props.queries.recordsTotal > 0 && 
                    <div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
                        <div className="pho_14_400 pho_opacity_5">
                            {
                                props.search ? 
                                    `Displaying ${ skipInit > 1 ? ((skipInit - 1) * props.limit) + 1 : 1 } - ${ result.length > (props.limit * props.skip) ? props.limit * props.skip : result.length } of ${ result.length } records`
                                :
                                    `Displaying ${ props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - ${ props.queries.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.queries.recordsTotal } of ${ props.queries.recordsTotal } records`
                            }
                            {/* Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.queries.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.queries.recordsTotal } of { props.users.recordsTotal } records */}
                        </div>
                        <Pagination>
                            {/* Go to first page */}
                            <Pagination.First
                                disabled={ props.skip === 1 || skipInit === 1 } 
                                onClick={ goToFirstPage }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                            </Pagination.First>

                            {/* Go to previous page */}
                            <Pagination.Prev
                                disabled={ props.skip === 1 || skipInit === 1 } 
                                onClick={ prevPageHandler }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
                            </Pagination.Prev>

                            {/* All pages */}
                            {
                                pageNumbers.map((number, i) => {
                                    if (
                                        !props.search &&
                                        number >= parseInt(props.skip) - 3 &&
                                        number <= parseInt(props.skip) + 3 
                                    ) {
                                        return (
                                            <Pagination.Item
                                                key={ i }
                                                id={ number }
                                                disabled={ number === props.skip }
                                                active={ number === props.skip }
                                                onClick={ pageChangeHandler }
                                            >
                                                { number }
                                            </Pagination.Item>
                                        );
                                    } else if (
                                        props.search &&
                                        number >= parseInt(skipInit) - 3 &&
                                        number <= parseInt(skipInit) + 3 
                                    ) {
                                        return (
                                            <Pagination.Item
                                                key={ i }
                                                id={ number }
                                                disabled={ number === skipInit }
                                                active={ number === skipInit }
                                                onClick={ pageChangeHandler }
                                            >
                                                { number }
                                            </Pagination.Item>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }

                            {/* Go to next page */}
                            <Pagination.Next
                                disabled={ props.skip === totalPages || skipInit === totalPages } 
                                onClick={ nextPageHandler }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
                            </Pagination.Next>

                            {/* Go to last page */}
                            <Pagination.Last
                                disabled={ props.skip === totalPages || skipInit === totalPages } 
                                onClick={ goToLastPage }
                            >
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                            </Pagination.Last>
                        </Pagination>
                    </div>
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        dir: state.subHeader.dir,
        skip: state.support.skip,
        limit: state.support.limit,
        queries: state.support.queries,
        loading: state.support.loading,
        search: state.subHeader.search,
        status: state.subHeader.status,
        column: state.subHeader.column,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        skipInit: () => dispatch(supportActions.skipInit()),
        updateSkip: (skip) => dispatch(supportActions.skipUpdate(skip)),
        initSearchSorting: () => dispatch(subheaderAction.initState()),
        removeAllQueriesData: () => dispatch(supportActions.removeAllQueriesData()),
        addSorting: (column, dir) => dispatch(subheaderAction.addSorting(column, dir)),
        queryStatusChange: (
            adminId, 
            queryId,
            value,
            column, 
            dir, 
            skip, 
            limit,
            status
        ) => dispatch(supportActions.queryStatusChange(adminId, queryId, value, column, dir, skip, limit, status)),
        getAllQueries: (
            adminId, 
            column, 
            dir, 
            skip, 
            limit,
            status
        ) => dispatch(supportActions.fetchAllQueries(adminId, column, dir, skip, limit, status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);

{/* <tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td width="90px"><span className="pho_16_500">12345678</span></td>
    <td width="100px">John Deo</td>
    <td width="200px">johndeo@company.com</td>
    <td width="130px">+1 234 567 8900</td>
    <td><span className="pho_ellips w-250">Lorem ipsum dolor sit amet consectetur</span></td>
    <td width="140px">
    <Select
        value={selectedOption}
        onChange={(e) => handleChange(e)}
        placeholder="Type"
        className={`form-control p-0 border-0
        ${selectedOption.value === "open" && "text-orange bg-light-orange"}
        ${selectedOption.value === "closed" && "text-success bg-light-success"}
        `}
        classNamePrefix="phoSelect"
        options={options}
        theme={theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#DCF4E4',
            primary75: '#DCF4E4',
            primary50: '#DCF4E4',
            primary:"#50C878",
        },
        })}

    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr> */}