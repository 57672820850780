import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    getAllNotification,
    getNotificationCount,
    notificationUpdate
} from '../notificationCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all notification function
export function* fetchingAllNotification(action) {  
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all notification
        const result = yield getAllNotification(action.adminId, action.skip, action.limit);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allNotificationFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Update notification function
export function* updatingNotification(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield notificationUpdate(action.adminId, action.notificationIds);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Store dashboard data into store
        yield put(actions.fetchAllNotification(action.adminId, action.skip, action.limit));     

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get notification count function
export function* fetchingNotificationCount(action) {    
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield getNotificationCount(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Store dashboard data into store
        yield put(actions.notificationCountFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};