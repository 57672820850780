// Action types
export const SUBHEADER_SEARCH = 'SUBHEADER_SEARCH';
export const SUBHEADER_SORTING = 'SUBHEADER_SORTING';
export const SUBHEADER_INIT = 'SUBHEADER_INIT';
export const SUBHEADER_TYPE = 'SUBHEADER_TYPE';
export const SUBHEADER_STATUS = 'SUBHEADER_STATUS';
export const SUBHEADER_FROM_DATE = 'SUBHEADER_FROM_DATE';
export const SUBHEADER_TO_DATE = 'SUBHEADER_TO_DATE';
export const UPDATE_FIND = 'UPDATE_FIND';
export const FIND_CLICKED = 'FIND_CLICKED';
export const YEAR_UPDATE = 'YEAR_UPDATE';
export const ADD_NEW = 'ADD_NEW';