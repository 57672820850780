import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as auth from "../_redux/authRedux";
import { logout } from '../_redux/authCrud';
import { notify } from '../../../../_metronic/_partials/toaster';
import { LayoutSplashScreen } from "../../../../_metronic/layout";

import 'react-toastify/dist/ReactToastify.min.css';

const Logout = (props) => {
  // Props
  const { hasAuthToken } = props;

  useEffect(() => {
    // Call logout function to remove user token from database
    logout(props.user._id)
      .then(res => {

        // If error found then show error
        if (res.data.responseCode !== 200) {
          throw new Error(res.data.responseMessage);
        }

        // // Check for error
        // if (result.response.responseCode === 404) {
        //   if (result.response.responseMessage === 'User not exist') {
        //     // // Init store state
        //     // props.todoInit();
        //     // props.leaseInit();
        //     // props.tenantInit();
        //     // props.managerInit();
        //     // props.propertyInit();
        //     // props.subHeaderInit();
        //     // props.notificationInit();

        //     // Remove state data
        //     props.logout();
        //   }
          
        //   throw new Error(result.response.responseMessage);
        // }

        // // // Init store state
        // // props.todoInit();
        // // props.leaseInit();
        // // props.tenantInit();
        // // props.managerInit();
        // // props.propertyInit();
        // // props.subHeaderInit();
        // // props.notificationInit();

        // Notification
        notify('success', 'Signed out successfully');

        setTimeout(() => {
          // Remove state data
          props.logout();
        }, 2000);
      })
      .catch(err => {
        console.log(err);

        // Notification
        notify('error', err.message);
      });
  }, []);

  return hasAuthToken 
    ? 
      <LayoutSplashScreen /> 
    : 
      <Redirect to="/auth/login" />;
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    hasAuthToken: state.auth.authToken
  };
};

// Dispatch actions
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.actions.logout()),
    // todoInit: () => dispatch(todoActions.initState()),
    // tenantInit: () => dispatch(tenantActions.initState()),
    // leaseInit: () => dispatch(leaseActions.initState()),
    // managerInit: () => dispatch(managerActions.initState()),
    // propertyInit: () => dispatch(propertyActions.initState()),
    // notificationInit: () => dispatch(notificationActions.initState()),
    // subHeaderInit: () => dispatch(subHeaderActions.actions.initState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
