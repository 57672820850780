import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.TAX_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.TAX_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.TAX_SUCCESS
    };
};

// All taxes fetch function
export const fetchAllTaxes = (adminId) => {
    return {
        type: actionTypes.TAX_ALL_FETCH,
        adminId
    };
};

// All taxes fetched function
export const allTaxesFetched = (taxes) => {
    return {
        type: actionTypes.TAX_ALL_FETCHED,
        taxes
    };
};

// Add tax function
export const addTax = (adminId, taxData) => {
    return {
        type: actionTypes.TAX_ADD,
        adminId,
        taxData
    };
};

// Update tax function
export const updateTax = (adminId, taxId, updateData) => {
    return {
        type: actionTypes.TAX_UPDATE,
        adminId,
        taxId,
        updateData 
    };
};

// Delete tax function
export const deleteTax = (adminId, taxId) => {
    return {
        type: actionTypes.TAX_DELETE,
        adminId,
        taxId
    };
};