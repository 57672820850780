import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.LANGUAGE_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.LANGUAGE_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.LANGUAGE_SUCCESS
    };
};

// All Language fetch function
export const fetchAllLanguage = (adminId) => {
    return {
        type: actionTypes.LANGUAGE_ALL_FETCH,
        adminId
    };
};

// All Language fetched function
export const allLanguageFetched = (allLanguages) => {
    return {
        type: actionTypes.LANGUAGE_ALL_FETCHED,
        allLanguages
    };
};

// Language init function
export const languageInit = () => {
    return {
        type: actionTypes.LANGUAGE_INIT,
    };
};

// Language status changes function
export const languageStatusChange = (adminId, languageId) => {
    return {
        type: actionTypes.LANGUAGE_STATUS_CHANGE,
        adminId,
        languageId
    };
};