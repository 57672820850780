import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    addingTax,
    updatingTax,
    deletingTax,
    fetchingAllTaxes
} from './saga';

export function* watchTaxes() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.TAX_ADD, addingTax),
        takeEvery(actionTypes.TAX_DELETE, deletingTax),
        takeEvery(actionTypes.TAX_UPDATE, updatingTax),
        takeEvery(actionTypes.TAX_ALL_FETCH, fetchingAllTaxes)
    ]);
};