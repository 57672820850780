// Action types
export const LANDLORD_START = 'LANDLORD_START';
export const LANDLORD_FAILD = 'LANDLORD_FAILD';
export const LANDLORD_SUCCESS = 'LANDLORD_SUCCESS';
export const LANDLORD_FETCH = 'LANDLORD_FETCH';
export const LANDLORD_ID_ADD = 'LANDLORD_ID_ADD';
export const LANDLORD_ADD = 'LANDLORD_ADD';
export const LANDLORD_FETCHED = 'LANDLORD_FETCHED';
export const LANDLORDS_ALL_FETCH = 'LANDLORDS_ALL_FETCH';
export const LANDLORDS_ALL_FETCHED = 'LANDLORDS_ALL_FETCHED';
export const LANDLORDS_ALL_PENDING_FETCH = 'LANDLORDS_ALL_PENDING_FETCH';
export const LANDLORDS_ALL_PENDING_FETCHED = 'LANDLORDS_ALL_PENDING_FETCHED';
export const LANDLORD_ID_REMOVE = 'LANDLORD_ID_REMOVE';
export const LANDLORD_DATA_REMOVE = 'LANDLORD_DATA_REMOVE';
export const LANDLORD_DATA_UPDATE = 'LANDLORD_DATA_UPDATE';
export const LANDLORD_DATA_DELETE = 'LANDLORD_DATA_DELETE';
export const LANDLORDS_ALL_DATA_REMOVE = 'LANDLORDS_ALL_DATA_REMOVE';
export const LANDLORD_STATUS_CHANGE = 'LANDLORD_STATUS_CHANGE';
export const LANDLORD_SKIP_UPDATE = 'LANDLORD_SKIP_UPDATE';
export const LANDLORD_SKIP_INIT = 'LANDLORD_SKIP_INIT';
export const LANDLORD_LIST_FETCH = 'LANDLORD_LIST_FETCH';
export const LANDLORD_LIST_FETCHED = 'LANDLORD_LIST_FETCHED';
