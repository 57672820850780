import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.MASTER_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.MASTER_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.MASTER_SUCCESS
    };
};

// Master init function
export const masterInit = () => {
    return {
        type: actionTypes.MASTER_INIT,
    };
};

// Add Categoriy function
export const addCategory = (adminId, values) => {
    return {
        type: actionTypes.CATEGORY_CREATE,
        adminId,
        values
    };
};

// All Categories fetch function
export const fetchAllCategories = (adminId) => {
    return {
        type: actionTypes.CATEGORIES_ALL_FETCH,
        adminId
    };
};

// All Categories fetched function
export const allCategoriesFetched = (categories) => {
    return {
        type: actionTypes.CATEGORIES_ALL_FETCHED,
        categories
    };
};

// Update Category function
export const updateCategory = (adminId, categoryId, values) => {
    return {
        type: actionTypes.CATEGORY_UPDATE,
        adminId,
        categoryId,
        values
    };
};

// Delete Category function
export const deleteCategory = (adminId, categoryId) => {
    return {
        type: actionTypes.CATEGORY_DELETE,
        adminId,
        categoryId
    };
};

// Add Amenity function
export const addAmenity = (adminId, values) => {
    return {
        type: actionTypes.AMENITY_CREATE,
        adminId,
        values
    };
};

// All Amenities fetch function
export const fetchAllAmenities = (adminId) => {
    return {
        type: actionTypes.AMENITIES_ALL_FETCH,
        adminId
    };
};

// All Amenities fetched function
export const allAmenitiesFetched = (amenities) => {
    return {
        type: actionTypes.AMENITIES_ALL_FETCHED,
        amenities
    };
};

// Update Amenity function
export const updateAmenity = (adminId, amenityId, values) => {
    return {
        type: actionTypes.AMENITY_UPDATE,
        adminId,
        amenityId,
        values
    };
};

// Delete Amenity function
export const deleteAmenity = (adminId, amenityId) => {
    return {
        type: actionTypes.AMENITY_DELETE,
        adminId,
        amenityId
    };
};

// Add category and amenity function
export const addCategoryAndAmenity = (adminId, values) => {
    return {
        type: actionTypes.CATEGORY_AMENITY_CREATE,
        adminId,
        values
    };
};

// Update category and amenity function
export const updateCategoryAndAmenity = (adminId, id, values) => {
    return {
        type: actionTypes.CATEGORY_AMENITY_UPDATE,
        adminId,
        id,
        values
    };
};

// Delete category and amenity function
export const deleteCategoryAndAmenity = (adminId, id) => {
    return {
        type: actionTypes.CATEGORY_AMENITY_DELETE,
        adminId,
        id
    };
};