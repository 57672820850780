import React from 'react';
import { Dropdown,DropdownButton, ButtonGroup } from 'react-bootstrap';

const ItemDropdown = (props) => {
    return (
        <>
            {['left'].map((direction) => (
                <DropdownButton
                    as={ ButtonGroup }
                    key={ direction }
                    id={`dropdown-button-drop-left`}
                    drop={ direction }
                    className="pho_dropdown_btn"
                    variant="transparent"
                    title=""
                >
                    {
                        props.view &&
                            <Dropdown.Item onClick={ (e) => props.view(e, props.doc) }>
                                Edit
                            </Dropdown.Item>
                    }
                    {
                        props.delete &&
                            <Dropdown.Item onClick={ (e) => props.delete(e, props.doc) }>Delete</Dropdown.Item>
                    }
                </DropdownButton>
            ))}            
        </>
    );
};

export default ItemDropdown;