import { takeEvery, all } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import {
  addingLink,
  updatingLink,
  deletingLink,
  fetchingAllLinks,
} from "./saga";

export function* watchLinks() {
  yield all([
    // takeEvery call every functions that matches
    takeEvery(actionTypes.LINK_ADD, addingLink),
    takeEvery(actionTypes.LINK_DELETE, deletingLink),
    takeEvery(actionTypes.LINK_UPDATE, updatingLink),
    takeEvery(actionTypes.LINK_ALL_FETCH, fetchingAllLinks),
  ]);
}
