import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.CMS_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.CMS_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.CMS_SUCCESS
    };
};

// All CMS fetch function
export const fetchAllCMS = (adminId) => {
    return {
        type: actionTypes.CMS_ALL_FETCH,
        adminId
    };
};

// All CMS fetched function
export const allCMSFetched = (allCMS) => {
    return {
        type: actionTypes.CMS_ALL_FETCHED,
        allCMS
    };
};

// // Update skip function
// export const updateSkip = (skip) => {
//     return {
//         type: actionTypes.CMS_SKIP_UPDATE,
//         skip
//     };
// };

// // CMS skip init function
// export const cmsSkipInit = () => {
//     return {
//         type: actionTypes.CMS_SKIP_INIT
//     };
// };

// CMS init function
export const cmsInit = () => {
    return {
        type: actionTypes.CMS_INIT
    };
};

// CMS status change function
export const cmsStatusChange = (adminId, cmsId) => {
    return {
        type: actionTypes.CMS_STATUS_CHANGE,
        adminId,
        cmsId
    };
};

// Get CMS detail function
export const fetchCMSDetail = (adminId, cmsId) => {
    return {
        type: actionTypes.CMS_DETAIL_FETCH,
        adminId,
        cmsId
    };
};

// CMS detail fetched function
export const cmsDetailFetched = (cmsDetail) => {
    return {
        type: actionTypes.CMS_DETAIL_FETCHED,
        cmsDetail
    };
};

// CMS ID add function
export const addcmsId = (cmsId) => {
    return {
        type: actionTypes.CMS_ID_ADD,
        cmsId
    };
};

// CMS page detail update function
export const updateCmsPage = (adminId, cmsId, values) => {
    return {
        type: actionTypes.CMS_UPDATE,
        adminId, 
        cmsId, 
        values
    };
};