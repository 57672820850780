import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    users: null,
    error: null,
    loading: null,
    totalUsers: 0,
    landlords: null,
    bookings: null,
    totalBookings: 0,
    totalLandlords: 0
};

// Reducer
export const dashboardReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.DASHBOARD_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.DASHBOARD_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.DASHBOARD_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.DASHBOARD_COUNT_FETCHED:
            return {
                ...state,
                users: action.users,
                bookings: action.bookings,
                landlords: action.landlords,
                totalUsers: action.totalUsers,
                totalBookings: action.totalBookings,
                totalLandlords: action.totalLandlords
            };
        case actionTypes.DASHBOARD_INIT:
            return {
                ...initialState
            };
        default:
            return state;
    };
};