// Action types
export const ACCOMODATION_START = 'ACCOMODATION_START';
export const ACCOMODATION_FAILD = 'ACCOMODATION_FAILD';
export const ACCOMODATION_SUCCESS = 'ACCOMODATION_SUCCESS';
export const ACCOMODATIONS_ALL_FETCH = 'ACCOMODATIONS_ALL_FETCH';
export const ACCOMODATIONS_ALL_FETCHED = 'ACCOMODATIONS_ALL_FETCHED';
export const ACCOMODATIONS_HOSTED_FETCH = 'ACCOMODATIONS_HOSTED_FETCH';
export const ACCOMODATIONS_HOSTED_FETCHED = 'ACCOMODATIONS_HOSTED_FETCHED';
export const ACCOMODATIONS_PENDING_FETCH = 'ACCOMODATIONS_PENDING_FETCH';
export const ACCOMODATIONS_PENDING_FETCHED = 'ACCOMODATIONS_PENDING_FETCHED';
export const ACCOMODATIONS_INIT = 'ACCOMODATIONS_INIT';
export const ACCOMODATIONS_SKIP_UPDATE = 'ACCOMODATIONS_SKIP_UPDATE';
export const ACCOMODATIONS_SKIP_INIT = 'ACCOMODATIONS_SKIP_INIT';
export const ACCOMODATION_DETAIL_FETCH = 'ACCOMODATION_DETAIL_FETCH';
export const ACCOMODATION_STATUS_CHANGE = 'ACCOMODATION_STATUS_CHANGE';
export const ACCOMODATION_DETAIL_FETCHED = 'ACCOMODATION_DETAIL_FETCHED';
export const ACCOMODATION_ID_ADD = 'ACCOMODATION_ID_ADD';
export const ACCOMODATION_REQ_VERIFICATION = 'ACCOMODATION_REQ_VERIFICATION';
