import React, {useEffect, useState} from "react";
import {Table} from "react-bootstrap";
import { Switch } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Fromik, FieldArray, Formik } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import { notify } from '../toaster'

import ItemDropdown from './ItemDropdown';
import { initialLanguageTableValues } from './LanguageSchem';
import * as languageActions from '../../../app/modules/LanguageSetting';

const LanguageSettings = (props) => {
    const modifiInitialTabObj = Object.assign({}, initialLanguageTableValues);

    // state
    const [state, setState] = useState({
        checked: true,
    });
    const [commonDelete, setCommonDelete] = useState({
        count: 0,
        value: false
    });
    
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    useEffect(() => {
        // Call get all language function
        props.getAllLanguages(props.user._id);

        // Remove store values when component destroy
        return () => {

            // Call language init function
            props.languageInit();
        };
    }, []);

    if (props.allLanguages) {
        modifiInitialTabObj.languages = props.allLanguages;
    }

    return (
        <>
            <div className="card card-custom"> 
                <Formik
                    enableReinitialize
                    initialValues={ modifiInitialTabObj }
                >
                    {({
                        values,
                        setFieldValue
                    }) => (
                        <Table hover responsive className="phoTable">
                            <thead>
                                <tr>
                                    {/* <th>
                                        <div className="pho_checkbox iskl_border">
                                            <input
                                                type="checkbox"
                                                name="checkbox"
                                                className={`mr-2 checkbox`}
                                                checked={ values.checkbox }
                                                onChange={ (e) => {   
                                                    let total = 0;
                                                    // Set current check box value
                                                    setFieldValue('checkbox', !values.checkbox);

                                                    values.languages.forEach((el, index) => {  
                                                        total += 1;
                                                        
                                                        // Set all checkbox value
                                                        setFieldValue(`languages.${index}.checkbox`, !values.checkbox);
                                                    });                                                                            

                                                    if (values.checkbox || (!values.checkbox && total === 0)) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: 0,
                                                            value: false
                                                        });
                                                    } else if (!values.checkbox && total > 0) {
                                                        // Set common delete state
                                                        setCommonDelete({
                                                            count: total,
                                                            value: true
                                                        });
                                                    }
                                                }}
                                            /> 
                                            <label></label>
                                        </div>
                                    </th> */}
                                    <th>Date</th>
                                    <th>Language</th>
                                    <th width="170px">Active / Deactive</th>
                                    {/* {
                                        commonDelete.count !== 0 ?
                                            // <th width="90px">
                                            //     <button
                                            //         style={{
                                            //             userSelect: 'none',
                                            //             cursor: 'pointer',
                                            //             color: 'red'
                                            //         }}
                                            //         className="btn btn-sm btn-danger text-white"
                                            //         type='submit'
                                            //     >
                                            //         Delete  
                                            //     </button>
                                            // </th>
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                        :   
                                            <th width="90px"
                                                style={{
                                                    userSelect: 'none'
                                                }}
                                            >
                                                Actions
                                            </th>
                                    } */}
                                </tr>
                            </thead>
                            <tbody>
                                <FieldArray name="languages">
                                    {({ insert, remove, push }) => (
                                        <>
                                            {
                                                !props.loading && values.languages && values.languages.length > 0 ?
                                                    values.languages.map((language, index) => (
                                                        <tr key={ index }>
                                                            {/* <td width="80px" >
                                                                <div className="pho_checkbox iskl_border">
                                                                    <input                                                                                    
                                                                        type="checkbox"
                                                                        name={ `languages.${index}.checkbox` }
                                                                        className={`mr-2 checkbox`}
                                                                        checked={ language.checkbox }
                                                                        onChange={ (e) => {
                                                                            if (language.checkbox) {
                                                                                // Set common delete state
                                                                                setCommonDelete({
                                                                                    count: commonDelete.count - 1,
                                                                                    value: false
                                                                                });
                                                                            } else {
                                                                                // Set common delete state
                                                                                setCommonDelete({
                                                                                    count: commonDelete.count + 1,
                                                                                    value: true
                                                                                });
                                                                            }

                                                                            // Set field values
                                                                            setFieldValue(`languages.${index}.checkbox`, !language.checkbox);
                                                                        } }
                                                                    />
                                                                    <label></label>
                                                                </div>
                                                            </td> */}
                                                            <td width="115px">
                                                                { moment(language.createdAt).format('L') }
                                                            </td>
                                                            <td>
                                                                { language.name }
                                                                {/* <Link to="/about-us" className="text-dark">
                                                                    { language.name }
                                                                </Link> */}
                                                            </td>
                                                            <td className="text-center" width="135px">
                                                                <Switch
                                                                    color="primary"
                                                                    value={ language.status === 'ACTIVE' ? true : false }
                                                                    checked={ language.status === 'ACTIVE' ? true : false }
                                                                    onChange={ (e) => {
                                                                        // if (el.status === 'ACTIVE') {
                                                                        //     setFieldValue(`CMS.${index}.status`, 'DEACTIVE');
                                                                        // } else {
                                                                        //     setFieldValue(`CMS.${index}.status`, 'ACTIVE');
                                                                        // }

                                                                        if (language.name === 'English') {
                                                                            return notify('info', 'English is default language and default language cannot be deactivated')
                                                                        }
                                                                        
                                                                        // Update language status
                                                                        props.languageStatusChange(props.user._id, language._id);
                                                                    } }
                                                                />
                                                            </td>
                                                            {/* <td width="90px">
                                                                <ItemDropdown item="" />
                                                            </td> */}
                                                        </tr>
                                                    ))
                                                : 
                                                    props.loading ?
                                                        (
                                                            // <div id="compnent-splash-screen" className="compnent-splash-screen">
                                                            //     <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                                                            //         <circle 
                                                            //             className="path"
                                                            //             cx="25"
                                                            //             cy="25"
                                                            //             r="20"
                                                            //             fill="none"
                                                            //             strokeWidth="5"
                                                            //         ></circle>
                                                            //     </svg>
                                                            // </div>
                                                            <tr>
                                                                <td colSpan="5" className="text-center">Loading...</td>
                                                            </tr>
                                                        )
                                                    : 
                                                        <tr>
                                                            <td colSpan="5" className="text-center">No Data Available</td>
                                                        </tr>
                                            }
                                        </>
                                    )}
                                </FieldArray>                                
                            </tbody>
                        </Table>
                    )}
                </Formik> 
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,   
        error: state.language.error,
        loading: state.language.loading,            
        allLanguages: state.language.allLanguages
    };
};

const mapDispatchToProps = dispatch => {
    return {
        languageInit: () => dispatch(languageActions.languageInit()),
        getAllLanguages: (adminId) => dispatch(languageActions.fetchAllLanguage(adminId)),  
        languageStatusChange: (
            adminId, 
            languageId
        ) => dispatch(languageActions.languageStatusChange(adminId, languageId))      
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettings);

{/* <tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td><Link to="/about-us" className="text-dark">Spanish</Link></td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checked}
        onChange={handleChange('checked')}
        value="active"
        color="primary"
    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr>
<tr>
    <td width="80px" >
        <div className="pho_checkbox iskl_border">
        <input
            type="checkbox"
            className={`checkbox`}
            
        />
            <label></label>
        </div>
    </td>
    <td width="115px">06/03/2020</td>
    <td><Link to="/about-us" className="text-dark">Portuguese</Link></td>
    <td className="text-center" width="135px">
    <Switch
        checked={state.checked}
        onChange={handleChange('checked')}
        value="active"
        color="primary"
    />
    </td>
    <td width="90px"><ItemDropdown item="" /></td>
</tr> */}