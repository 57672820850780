import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    error: null,
    cmsId: null,
    allCMS: null,
    loading: null,
    cmsDetail: null,
};

// Reducer
export const cmsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.CMS_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.CMS_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.CMS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.CMS_ALL_FETCHED:
            return {
                ...state,
                allCMS: action.allCMS
            };
        case actionTypes.CMS_DETAIL_FETCHED:
            return {
                ...state,
                cmsDetail: action.cmsDetail
            };
        case actionTypes.CMS_ID_ADD:
            return {
                ...state,
                cmsId: action.cmsId
            };      
        case actionTypes.CMS_INIT:
            return {
                ...initialState
            };
        default:
            return state;
    };
};