import axios from "axios";

// Report related URL
export const REPORT_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getReports`;
export const REPORT_DOWNLOAD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/downloadReport`;

// All report get function
export async function getAllReport(adminId, column, dir, fromDate, toDate, skip, limit, type, search) {
  return await axios.get(`${REPORT_ALL_URL}/${adminId}?skip=${skip}&limit=${limit}${fromDate ? `&fromDate=${fromDate}` : '' }${toDate ? `&toDate=${toDate}` : '' }${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${type ? `&type=${type}` : '' }${search ? `&search=${search}` : '' }`);
};

// Download report function
export async function downloadReport(adminId, column, dir, fromDate, toDate, skip, limit, type, search) {
  return await axios.get(`${REPORT_DOWNLOAD_URL}/${adminId}?skip=${skip}&limit=${limit}${fromDate ? `&fromDate=${fromDate}` : '' }${toDate ? `&toDate=${toDate}` : '' }${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${type ? `&type=${type}` : '' }${search ? `&search=${search}` : '' }`);
};