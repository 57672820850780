import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    fetchingQuery,
    replayingQuery,
    fetchingAllQueries ,
    changingQueryStatus,
} from './saga';

export function* watchQueries() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.QUERY_FETCH, fetchingQuery),
        takeEvery(actionTypes.QUERY_REPLAY, replayingQuery),
        takeEvery(actionTypes.QUERY_ALL_FETCH, fetchingAllQueries),
        takeEvery(actionTypes.QUERY_STATUS_CHANGE, changingQueryStatus),
    ]);
};