import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
    fetchingAllNotification,
    fetchingNotificationCount,
    updatingNotification
} from './saga';

export function* watchNotification() {
    yield all([
        // takeEvery call every functions that matches
        takeEvery(actionTypes.NOTIFICATION_ALL_FETCH, fetchingAllNotification),
        takeEvery(actionTypes.NOTIFICATION_COUNT_FETCH, fetchingNotificationCount),
        takeEvery(actionTypes.NOTIFICATION_UPDATE, updatingNotification)
    ]);
};