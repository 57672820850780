import * as Yup from 'yup';

const stateTaxesSchema = Yup.array().of(
    Yup.object().shape({
        state: Yup.string().required('Please enter valid State')
    })    
);

export const taxSchema = Yup.object().shape({
    taxes: Yup.array()
        .of(
            Yup.object().shape({
                country: Yup.string().required('Please enter valid Country'),
                stateTaxes: stateTaxesSchema
            })
        )
});

export const taxAddSchema = Yup.object().shape({
    country: Yup.string().required('Please select Country')
});