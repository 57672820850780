import * as actionTypes from '../actions/actionTypes';

// Initial state
const initialState = {
    error: null,
    loading: null,
    languageId: null,
    allLanguages: null,
    languageDetail: null,
};

// Reducer
export const languageReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.LANGUAGE_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.LANGUAGE_FAILD:
            return {
                ...state,
                error: action.error,
                loading: null
            };
        case actionTypes.LANGUAGE_SUCCESS:
            return {
                ...state,
                error: null,
                loading: null
            };
        case actionTypes.LANGUAGE_ALL_FETCHED:
            return {
                ...state,
                allLanguages: action.allLanguages
            };
        case actionTypes.LANGUAGE_INIT:
            return {
                ...initialState
            };
        default:
            return state;
    };
};