import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.ACCOMODATION_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.ACCOMODATION_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.ACCOMODATION_SUCCESS
    };
};

// All accommodation fetch function
export const fetchAllAccommodation = (userId, column, dir, skip, limit, type, search) => {
    return {
        type: actionTypes.ACCOMODATIONS_ALL_FETCH,
        userId, 
        column,
        dir,
        skip,
        limit,
        typeValue: type,
        search
    };
};

// All accommodation fetched function
export const allAccommodationFetched = (allAccommodations) => {
    return {
        type: actionTypes.ACCOMODATIONS_ALL_FETCHED,
        allAccommodations
    };
};

// Pending accommodation fetch function
export const fetchPendingAccommodation = (userId, column, dir, skip, limit, type, search) => {
    return {
        type: actionTypes.ACCOMODATIONS_PENDING_FETCH,
        userId, 
        column,
        dir,
        skip,
        limit,
        typeValue: type,
        search
    };
};

// Pending accommodation fetched function
export const pendingAccommodationFetched = (pendingAccommodations) => {
    return {
        type: actionTypes.ACCOMODATIONS_PENDING_FETCHED,
        pendingAccommodations
    };
};

// Hosted accommodation fetch function
export const fetchHostedAccommodation = (adminId, id, column, dir, skip, limit) => {
    return {
        type: actionTypes.ACCOMODATIONS_HOSTED_FETCH,
        adminId,
        id,
        column,
        dir,
        skip,
        limit
    };
};

// Hosted accommodation fetched function
export const hostedAccommodationFetched = (hostedAccommodations) => {
    return {
        type: actionTypes.ACCOMODATIONS_HOSTED_FETCHED,
        hostedAccommodations
    };
};

// Update skip function
export const updateSkip = (skip) => {
    return {
        type: actionTypes.ACCOMODATIONS_SKIP_UPDATE,
        skip
    };
};

// Accommodation skip init function
export const accommodationSkipInit = () => {
    return {
        type: actionTypes.ACCOMODATIONS_SKIP_INIT
    };
};

// Accommodation init function
export const accommodationInit = () => {
    return {
        type: actionTypes.ACCOMODATIONS_INIT
    };
};

// Accommodation status change function
export const accommodationStatusChange = (adminId, accommodationId, column, dir, skip, limit, type, condition, id) => {
    return {
        type: actionTypes.ACCOMODATION_STATUS_CHANGE,
        adminId,
        accommodationId,
        column,
        dir,
        skip,
        limit,
        typeValue: type,
        condition,
        id
    };
};

// Get accommodation detail function
export const fetchAccommodationDetail = (adminId, accommodationId) => {
    return {
        type: actionTypes.ACCOMODATION_DETAIL_FETCH,
        adminId,
        accommodationId
    };
};

// Accommodation detail fetched function
export const accommodationDetailFetched = (accommodationDetail) => {
    return {
        type: actionTypes.ACCOMODATION_DETAIL_FETCHED,
        accommodationDetail
    };
};

// Accommodation ID add function
export const addAccommodationId = (accommodationId) => {
    return {
        type: actionTypes.ACCOMODATION_ID_ADD,
        accommodationId
    };
};

// Accommodation req verification add function
export const accommodationReqVerification = (adminId, accommodationId, values, column, dir, skip, limit, type) => {
    return {
        type: actionTypes.ACCOMODATION_REQ_VERIFICATION,
        adminId,
        accommodationId,
        values,
        column,
        dir,
        skip,
        limit,
        typeValue: type
    };
};