import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Form, Row, Modal, Spinner } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { connect } from 'react-redux';

import { toAbsoluteUrl } from "../../_helpers";
import * as paymentActions from '../../../app/modules/Payment';
import * as landlordActions from '../../../app/modules/Landlord';

const Payout = (props) => {
  const landlordOptions = [];

  // State
  const [landlordOption, setLandlordOption] = useState(null);
  const [showPaymentSuccessModal, setPaymentSuccessModalShow] = useState(false);

  // On Landlord change handler
  const onLandlordChangeHandler = (e) => {

    // Set state
    setLandlordOption(e);

    // Get payout of landlord
    props.getPayouts(props.user._id, e.value);
  };

  // Handle payment now close function
  const handlePaymentNowClose = () => { 
    // Set payment success modal show state
    setPaymentSuccessModalShow(false);

    // Change landlord option state to null
    setLandlordOption(null);
  };

  // Handle payment now function
  const handlePaymentNow = () => {
    // Set payment success modal show state
    setPaymentSuccessModalShow(true);
  };

  // On pay now click handler function
  const onPayNowClickHandler = (payouts, totalAmount) => {
    try {
      const ids = [];
      payouts.forEach((payout) => {
        ids.push(payout._id);
      });
      
      const landlordId = landlordOption.value;
      const values = {
        totalAmount: parseFloat(totalAmount),
        paymentIds: ids
      };

      // Call relase payment function
      props.releasePayment(props.user._id, landlordId, values);
      
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {    

    if (props.released) {
      // Set payment success modal show state to true
      setPaymentSuccessModalShow(true);
    }

    // setTimeout(() => {
    //   // Call handle payment now close function
    //   handlePaymentNowClose();

    // // Change landlord option state to null
    // setLandlordOption(null);
    // }, 3000);
  }, [props.released]);

  useEffect(() => {
    // Fetch landlord list
    props.getLandlordList(props.user._id);

    // Remove data from store when component destroy
    return () => {

      // Payment init function
      props.paymentInit();
    };
  }, []);

  if (props.landlordList && props.landlordList.length > 0) {
    props.landlordList.forEach(landlord => {
      landlordOptions.push({ value: landlord._id, label: `${landlord.firstName} ${landlord.lastName}` });
    });
  }

  return (
    <>
      <Modal
        show={ showPaymentSuccessModal }
        onHide={ handlePaymentNowClose }
        centered
        size="sm"
      >
        <Modal.Body className="text-center">
          <SVG
            alt="success"
            className="h-70px w-70px mb-6"
            src={toAbsoluteUrl("/media/custom/pho_success_icon.svg")}
          />
          <div className="pho_18_600">Payment Done</div>
          <p className="pho_opacity_5 my-4">
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet lorem.
          </p>
          <Button
            variant="success"
            onClick={handlePaymentNowClose}
            className="px-5 py-3"
          >
            OKAY
          </Button>
        </Modal.Body>
      </Modal>

      <div className="card card-custom px-5 py-3 ">
        <div className="d-flex align-items-center phoSearchLandlord">
          <span className="pho_14_400 w-150px">Search For Landlord</span>
          <Select
            value={ landlordOption }
            onChange={ onLandlordChangeHandler }
            placeholder="Select Landlord"
            className="form-control p-0 h-auto"
            classNamePrefix="phoSelect"
            options={ landlordOptions }
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#DCF4E4",
                primary75: "#DCF4E4",
                primary50: "#DCF4E4",
                primary: "#50C878",
              },
            })}
          />
        </div>
      </div>
      
      {
        !props.loading && props.payouts && props.payouts.payouts && props.payouts.payouts.length > 0 ?
          <div className="card card-custom mt-3">
            <Form>
              {
                props.payouts.payouts.map((payout, index) => (
                  <div className="px-5 py-3 border-bottom" key={ index }>
                    <div className="pho_18_600 mb-4">Payable Orders To Landlord</div>
                    <Row className="phoGap14">
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Order Number
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout._id }
                            className="pho_14_400" 
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Property Name
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.propertyDetails && payout.propertyDetails.name ? payout.propertyDetails.name.en : '' }
                            className="pho_14_400" 
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Property Type
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.propertyType ? payout.propertyType === 'WORKPLACE' ? 'Workplace' : 'Home Office' : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Amount
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.price ? payout.price.toFixed(2) : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Other Charge
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.serviceFees ? payout.serviceFees.toFixed(2) : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Net Amount
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.totalPrice ? payout.totalPrice.toFixed(2) : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <div className="text-right d-flex justify-content-end pb-5">
                          <div className="pr-5">
                            <div className="pho_14_400">Total Net Amount:</div>
                            {/* <div className="pho_14_400">Admin Commission (10%):</div> */}
                            <div className="pho_14_400">Admin Commission:</div>
                            <div className="pho_14_600">Total Amount to Pay:</div>
                          </div>
                          <div>
                            <div className="pho_16_500">$ { payout.totalPrice ? payout.totalPrice.toFixed(2) : '' }</div>
                            <div className="pho_16_500">$ { payout.serviceFees ? payout.serviceFees.toFixed(2) : '' }</div>
                            <div className="pho_16_600">$ { payout.price ? payout.price.toFixed(2) : '' }</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div> 
                ))
              }

              <div className="p-5">
                <div className="bg-light-success p-3 d-flex justify-content-end align-items-center">
                  <div className="pho_16_400 pr-5">
                    Final Total: <span className="pho_16_600">
                      { props.payouts.totalPayableAmount ? `${props.payouts.totalPayableAmount.toFixed(2)} USD` : '' }
                    </span>
                  </div>
                  <Button 
                    variant="success" 
                    onClick={ (e) => {

                      // Call on pay now click handler function
                      onPayNowClickHandler(props.payouts.payouts, props.payouts.totalPayableAmount.toFixed(2))
                    } }
                  >
                    { props.loading ? <Spinner animation="border" variant="light" /> : 'Pay Now' }
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        :
          props.loading ?
          <div id="compnent-splash-screen" className="compnent-splash-screen">
            <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
              <circle 
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          </div>
        : 
          <div className="py-3">No Data Available</div>        
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,    
    loading: state.payment.loading,
    payouts: state.payment.payouts,
    released: state.payment.released,
    landlordList: state.landlord.landlordList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    paymentInit: () => dispatch(paymentActions.paymentInit()),
    getLandlordList: (adminId) => dispatch(landlordActions.getLandlordList(adminId)),
    getPayouts: (adminId, landlordId) => dispatch(paymentActions.fetchPayouts(adminId, landlordId)),
    releasePayment: (
      adminId, 
      landlordId, 
      values
    ) => dispatch(paymentActions.releasePayment(adminId, landlordId, values))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payout);

{/* <div className="card card-custom mt-3">
  <Form>
    <div className="px-5 py-3 border-bottom">
      <div className="pho_18_600 mb-4">Payable Orders To Landlord</div>
      <Row className="phoGap14">
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Order Number
            </Form.Label>
            <Form.Control className="pho_14_400"/>
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Property Name
            </Form.Label>
            <Form.Control className="pho_14_400" />
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Property Type
            </Form.Label>
            <Form.Control className="pho_14_400"/>
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Amount
            </Form.Label>
            <Form.Control className="pho_14_400" />
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Other Charge
            </Form.Label>
            <Form.Control className="pho_14_400"/>
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Net Amount
            </Form.Label>
            <Form.Control className="pho_14_400"  />
          </Form.Group>
        </Col>
        <Col md={12}>
          <div className="text-right d-flex justify-content-end pb-5">
            <div className="pr-5">
              <div className="pho_14_400">Total Net Amount:</div>
              <div className="pho_14_400">Admin Commission (10%):</div>
              <div className="pho_14_600">Total Amount to Pay:</div>
            </div>
            <div>
              <div className="pho_16_500">$ 519.80</div>
              <div className="pho_16_500">$ 51.98</div>
              <div className="pho_16_600">$ 519.8</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div className="px-5 py-3 border-bottom">
      <div className="pho_18_600 mb-4">Payable Orders To Landlord</div>
      <Row className="phoGap14">
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Order Number
            </Form.Label>
            <Form.Control className="pho_14_400"  />
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Property Name
            </Form.Label>
            <Form.Control className="pho_14_400" />
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Property Type
            </Form.Label>
            <Form.Control className="pho_14_400"  />
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Amount
            </Form.Label>
            <Form.Control className="pho_14_400"  />
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Other Charge
            </Form.Label>
            <Form.Control className="pho_14_400"  />
          </Form.Group>
        </Col>
        <Col xl={2} lg={4} md={6}>
          <Form.Group>
            <Form.Label className="pho_14_400 pho_opacity_5">
              Net Amount
            </Form.Label>
            <Form.Control className="pho_14_400" />
          </Form.Group>
        </Col>
        <Col md={12}>
          <div className="text-right d-flex justify-content-end pb-5">
            <div className="pr-5">
              <div className="pho_14_400">Total Net Amount:</div>
              <div className="pho_14_400">Admin Commission (10%):</div>
              <div className="pho_14_600">Total Amount to Pay:</div>
            </div>
            <div>
              <div className="pho_16_500">$ 519.80</div>
              <div className="pho_16_500">$ 51.98</div>
              <div className="pho_16_600">$ 519.8</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div className="p-5">
      <div className="bg-light-success p-3 d-flex justify-content-end align-items-center">
        <div className="pho_16_400 pr-5">Final Total: <span className="pho_16_600">960.1300000000001USD</span></div>
        <Button variant="success" onClick={handlePaymentNow}>Pay Now</Button>
      </div>
    </div>
  </Form>
</div> */}