import axios from "axios";

// Accommodation related URL
export const QUERY_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllQueries`;
export const QUERY_REPLAY_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/queryReply`;
export const QUERY_STATUS_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateQueryStatus`;
export const QUERY_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getQueryDetails`;

// All queries get function
export async function getAllQueries(adminId, column, dir, skip, limit, status, search) {
  return await axios.get(`${QUERY_ALL_URL}/${adminId}?skip=${skip}&limit=${limit}${column ? `&column=${column}` : '' }${dir ? `&dir=${dir}` : '' }${status ? `&status=${status}` : '' }${search ? `&search=${search}` : '' }`);
};

// Query replay function
export async function queryReply(adminId, queryId, values) {
  return await axios.post(`${QUERY_REPLAY_URL}/${adminId}/${queryId}`, { reply: values.reply, status: values.status });
};

// Query status update function
export async function updateQueryStatus(adminId, queryId, status) {
  return await axios.get(`${QUERY_STATUS_UPDATE_URL}/${adminId}/${queryId}/${status}`);
};

// Get query detail function
export async function getQueryDetails(adminId, queryId) {
  return await axios.get(`${QUERY_DETAIL_URL}/${adminId}/${queryId}`);
};