import axios from "axios";

// CMS related URL
export const CMS_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAllCMS`;
export const CMS_ADD_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/createCMS`;
export const CMS_DELETED_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/deleteCMS`;
export const CMS_DETAIL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getCMSDetails`;
export const CMS_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateCMS`;

// All CMS get function
export async function getAllCms(adminId) {
  return await axios.get(`${CMS_ALL_URL}/${adminId}`);
};

// CMS get detail function
export async function getCMSDetail(adminId, cmsId) {
  return await axios.get(`${CMS_DETAIL_URL}/${adminId}/${cmsId}`);
};

// CMS add function
export async function addCMS(adminId, cmsId) {
  return await axios.post(`${CMS_ADD_URL}/${adminId}`);
};

// CMS status change function
export async function cmsStatusChange(adminId, cmsId) {
  return await axios.delete(`${CMS_DELETED_URL}/${adminId}/${cmsId}`);
};

// CMS verification function
export async function updateCMS(adminId, cmsId, values) {
  return await axios.put(`${CMS_UPDATE_URL}/${adminId}/${cmsId}`, { ...values });
};