import * as actionTypes from './actionTypes';

// When fetch or add or update or delete start
export const fetchStart = () => {
    return {
        type: actionTypes.REPORT_START
    };
};

// When fetch or add or update or delete faild
export const fetchFaild = (error) => {
    return {
        type: actionTypes.REPORT_FAILD,
        error
    };
};

// When fetch or add or update or delete success
export const fetchSuccess = () => {
    return {
        type: actionTypes.REPORT_SUCCESS
    };
};

// All Report fetch function
export const fetchAllReport = (adminId, column, dir, fromDate, toDate, skip, limit, typeValue, searchColumn, searchVal) => {
    return {
        type: actionTypes.REPORT_ALL_FETCH,
        adminId,
        column, 
        dir, 
        fromDate, 
        toDate, 
        skip, 
        limit, 
        typeValue,
        searchColumn,
        searchVal
    };
};

// All report fetched function
export const allReportFetched = (allReport) => {
    return {
        type: actionTypes.REPORT_ALL_FETCHED,
        allReport
    };
};

// Report download function
export const reportDownload = (adminId, column, dir, fromDate, toDate, skip, limit, typeValue, searchColumn, searchVal) => {
    return {
        type: actionTypes.REPORT_DOWNLOAD,
        adminId,
        column, 
        dir, 
        fromDate, 
        toDate, 
        skip, 
        limit, 
        typeValue,
        searchColumn,
        searchVal
    };
};

// Report init function
export const reportSkipUpdate = (skip) => {
    return {
        type: actionTypes.REPORT_SKIP_UPDATE,
        skip
    };
};

// Report init function
export const reportInit = () => {
    return {
        type: actionTypes.REPORT_INIT,
    };
};

// Report init function
export const reportSkipInit = () => {
    return {
        type: actionTypes.REPORT_SKIP_INIT,
    };
};