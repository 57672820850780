import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    addTax,
    updateTax,
    deleteTax,
    getAllTaxes
} from '../taxCrud';

// Add tax function
export function* addingTax(action) { 
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to add tax
        const result = yield addTax(action.adminId, action.taxData);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // Store fetched tax data into store
        yield put(actions.fetchAllTaxes(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Tax added successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Get all taxes function
export function* fetchingAllTaxes(action) {
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch taxes data
        const result = yield getAllTaxes(action.adminId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allTaxesFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Update tax data function
export function* updatingTax(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to update tax
        const result = yield updateTax(action.adminId, action.taxId, action.updateData);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store fetched tax data into store
        yield put(actions.fetchAllTaxes(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Tax updated successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Delete tax function
export function* deletingTax(action) {
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to delete tax
        const result = yield deleteTax(action.adminId, action.taxId);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store fetched tax data into store
        yield put(actions.fetchAllTaxes(action.adminId));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

        // Notification
        notify('success', 'Tax deleted successfully');

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};