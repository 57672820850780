import * as actionTypes from './actionTypes';

// Sorting add function
export const addSorting = (column, dir) => {
    return {
        type: actionTypes.SUBHEADER_SORTING,
        column,
        dir
    };
};

// Search add function
export const addSearch = (search) => {
    return {
        type: actionTypes.SUBHEADER_SEARCH,
        search
    };
};

// Type add function
export const addType = (type) => {
    return {
        type: actionTypes.SUBHEADER_TYPE,
        typeValue: type
    };
};

// Status add function
export const addStatus = (status) => {
    return {
        type: actionTypes.SUBHEADER_STATUS,
        status
    };
};

// Init state function
export const initState = () => {
    return {
        type: actionTypes.SUBHEADER_INIT
    };
};

// From date add function
export const addFromDate = (fromDate) => {
    return {
        type: actionTypes.SUBHEADER_FROM_DATE,
        fromDate
    };
};

// To date add function
export const addToDate = (toDate) => {
    return {
        type: actionTypes.SUBHEADER_TO_DATE,
        toDate
    };
};

// Update find function
export const updateFind = (value) => {
    return {
        type: actionTypes.UPDATE_FIND,
        value
    };
};

// Find clicked state update function
export const findClicked = (value) => {
    return {
        type: actionTypes.FIND_CLICKED,
        value
    };
};

// Year update function
export const updateYear = (year) => {
    return {
        type: actionTypes.YEAR_UPDATE,
        year
    };
};

// Add new function
export const addNew = (value) => {
    return {
        type: actionTypes.ADD_NEW,
        value
    };
};