import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import { subheaderReducer } from "../app/modules/Subheader";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { watchUsers, userReducer } from "../app/modules/User";
import { watchCms, cmsReducer } from "../app/modules/Cms";
import { watchQueries, queryReducer } from "../app/modules/Support";
import { watchLandlords, landlordReducer } from "../app/modules/Landlord";
import { watchDashboard, dashboardReducer } from "../app/modules/Dashboard";
import {
  watchAccommodation,
  accommodationReducer,
} from "../app/modules/Accommodation";
import { watchTemplate, templateReducer } from "../app/modules/EmailTemplate";
import { watchLanguage, languageReducer } from "../app/modules/LanguageSetting";
import { watchMaster, masterReducer } from "../app/modules/MasterManagement";
import {
  watchNotification,
  notificationReducer,
} from "../app/modules/Notification";
import { watchReport, reportReducer } from "../app/modules/Report";
import { watchBooking, bookingReducer } from "../app/modules/Booking";
import { watchPayment, paymentReducer } from "../app/modules/Payment";
import { watchTaxes, taxReducer } from "../app/modules/TaxManagement";
import { watchLinks, linkReducer } from "../app/modules/InvitaionLink";

// Root reducer function generator
export const rootReducer = combineReducers({
  user: userReducer,
  auth: auth.reducer,
  landlord: landlordReducer,
  subHeader: subheaderReducer,
  dashboard: dashboardReducer,
  accommodation: accommodationReducer,
  support: queryReducer,
  cms: cmsReducer,
  template: templateReducer,
  language: languageReducer,
  master: masterReducer,
  notification: notificationReducer,
  report: reportReducer,
  booking: bookingReducer,
  payment: paymentReducer,
  tax: taxReducer,
  link: linkReducer,
});

// Root saga function generator
export function* rootSaga() {
  yield all([
    auth.saga(),
    watchUsers(),
    watchLandlords(),
    watchDashboard(),
    watchAccommodation(),
    watchQueries(),
    watchCms(),
    watchTemplate(),
    watchLanguage(),
    watchMaster(),
    watchNotification(),
    watchReport(),
    watchBooking(),
    watchPayment(),
    watchTaxes(),
    watchLinks(),
  ]);
}
