// Action types
export const PAYMENT_START = 'PAYMENT_START';
export const PAYMENT_FAILD = 'PAYMENT_FAILD';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_RECEIVED_FETCH = 'PAYMENT_RECEIVED_FETCH';
export const PAYMENT_RECEIVED_FETCHED = 'PAYMENT_RECEIVED_FETCHED';
export const PAYMENT_PAYOUT_FETCH = 'PAYMENT_PAYOUT_FETCH';
export const PAYMENT_PAYOUT_FETCHED = 'PAYMENT_PAYOUT_FETCHED';
export const PAYMENT_RELEASE = 'PAYMENT_RELEASE';
export const PAYMENT_REFUND = 'PAYMENT_REFUND';
export const PAYMENT_RELEASE_DONE = 'PAYMENT_RELEASE_DONE';
export const PAYMENT_REFUND_DONE = 'PAYMENT_REFUND_DONE';
export const PAYMENT_SKIP_UPDATE = 'PAYMENT_SKIP_UPDATE';
export const PAYMENT_SKIP_INIT = 'PAYMENT_SKIP_INIT';
export const PAYMENT_INIT = 'PAYMENT_INIT';