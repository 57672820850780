import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from "react-bootstrap";
import { connect } from 'react-redux';

import { useSubheader } from '../../layout';
import * as paymentActions from '../../../app/modules/Payment';
import * as landlordActions from '../../../app/modules/Landlord';

const LandlordPaymentDetails = (props) => {
  const { id } = useParams();
  const subHeader = useSubheader();

  useEffect(() => {    
    const title = 'User Payment Details';

    // Set sub header title
    subHeader.setTitle(title);

    // Add landlord id into store
    props.addLandlordId(id);

    // Get payout data
    props.getPayouts(props.user._id, id);
  }, [id]);

  useEffect(() => {

    // Remove data from store when component destroy
    return () => {

      // Remove landlord id from store
      props.removeLandlordId(id);

      // Payment init function
      props.paymentInit();
    };
  }, []);

  return (
    <>
      {
        !props.loading && props.payouts && props.payouts.payouts && props.payouts.payouts.length > 0 ?
          <div className="card card-custom mt-3">
            <Form>
              {
                props.payouts.payouts.map((payout, index) => (
                  <div className="px-5 py-3 border-bottom" key={ index }>
                    <div className="pho_18_600 mb-4">Payable Orders To Landlord</div>
                    <Row className="phoGap14">
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Order Number
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout._id }
                            className="pho_14_400" 
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Property Name
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.propertyDetails && payout.propertyDetails.name ? payout.propertyDetails.name.en : '' }
                            className="pho_14_400" 
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Property Type
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.propertyType ? payout.propertyType === 'WORKPLACE' ? 'Workplace' : 'Home Office' : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Amount
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.price ? payout.price.toFixed(2) : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Other Charge
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.serviceFees ? payout.serviceFees.toFixed(2) : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={2} lg={4} md={6}>
                        <Form.Group>
                          <Form.Label className="pho_14_400 pho_opacity_5">
                            Net Amount
                          </Form.Label>
                          <Form.Control 
                            readOnly
                            value={ payout.totalPrice ? payout.totalPrice.toFixed(2) : '' }
                            className="pho_14_400"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <div className="text-right d-flex justify-content-end pb-5">
                          <div className="pr-5">
                            <div className="pho_14_400">Total Net Amount:</div>
                            {/* <div className="pho_14_400">Admin Commission (10%):</div> */}
                            <div className="pho_14_400">Admin Commission:</div>
                            <div className="pho_14_600">Total Amount to Pay:</div>
                          </div>
                          <div>
                            <div className="pho_16_500">$ { payout.totalPrice ? payout.totalPrice.toFixed(2) : '' }</div>
                            <div className="pho_16_500">$ { payout.serviceFees ? payout.serviceFees.toFixed(2) : '' }</div>
                            <div className="pho_16_600">$ { payout.totalPrice ? payout.totalPrice.toFixed(2) : '' }</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div> 
                ))
              }
            </Form>
          </div>
        :
          props.loading ?
          <div id="compnent-splash-screen" className="compnent-splash-screen">
            <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
              <circle 
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
              ></circle>
            </svg>
          </div>
        : 
          <div className="py-3">No Data Available</div>        
      }
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,    
    loading: state.payment.loading,
    payouts: state.payment.payouts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    paymentInit: () => dispatch(paymentActions.paymentInit()),
    addLandlordId: (landlordId) => dispatch(landlordActions.addLandlordId(landlordId)),
    removeLandlordId: (landlordId) => dispatch(landlordActions.removeLandlordId(landlordId)),
    getPayouts: (adminId, landlordId) => dispatch(paymentActions.fetchPayouts(adminId, landlordId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandlordPaymentDetails);

{/* <div className="px-5 py-3 border-bottom">
  <div className="pho_18_600 mb-4">Payable Orders To Landlord</div>
  <Row className="phoGap14">
    <Col xl={2} lg={4} md={6}>
      <Form.Group>
        <Form.Label className="pho_14_400 pho_opacity_5">
          Order Number
        </Form.Label>
        <Form.Control className="pho_14_400"  />
      </Form.Group>
    </Col>
    <Col xl={2} lg={4} md={6}>
      <Form.Group>
        <Form.Label className="pho_14_400 pho_opacity_5">
          Property Name
        </Form.Label>
        <Form.Control className="pho_14_400" />
      </Form.Group>
    </Col>
    <Col xl={2} lg={4} md={6}>
      <Form.Group>
        <Form.Label className="pho_14_400 pho_opacity_5">
          Property Type
        </Form.Label>
        <Form.Control className="pho_14_400"/>
      </Form.Group>
    </Col>
    <Col xl={2} lg={4} md={6}>
      <Form.Group>
        <Form.Label className="pho_14_400 pho_opacity_5">
          Amount
        </Form.Label>
        <Form.Control className="pho_14_400" />
      </Form.Group>
    </Col>
    <Col xl={2} lg={4} md={6}>
      <Form.Group>
        <Form.Label className="pho_14_400 pho_opacity_5">
          Other Charge
        </Form.Label>
        <Form.Control className="pho_14_400" />
      </Form.Group>
    </Col>
    <Col xl={2} lg={4} md={6}>
      <Form.Group>
        <Form.Label className="pho_14_400 pho_opacity_5">
          Net Amount
        </Form.Label>
        <Form.Control className="pho_14_400"/>
      </Form.Group>
    </Col>
    <Col md={12}>
      <div className="text-right d-flex justify-content-end pb-5">
        <div className="pr-5">
          <div className="pho_14_400">Total Net Amount:</div>
          <div className="pho_14_400">Admin Commission (10%):</div>
          <div className="pho_14_600">Total Amount to Pay:</div>
        </div>
        <div>
          <div className="pho_16_500">$ 519.80</div>
          <div className="pho_16_500">$ 51.98</div>
          <div className="pho_16_600">$ 519.8</div>
        </div>
      </div>
    </Col>
  </Row>
</div>  */}