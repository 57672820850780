import { put } from "redux-saga/effects";

import * as actions from "../actions";
import { notify } from "../../../../_metronic/_partials/toaster";
import { addLink, getAllLinks, updateLink, deleteLink } from "../linkCrud";

// Add link function
export function* addingLink(action) {
  try {
    // // Call fetch start to displaying loading spinner
    // yield put(actions.fetchStart());

    // Call function to add link
    const result = yield addLink(action.adminId, action.linkData);

    // If response error found throw error
    if (result.data.responseCode !== 200) {
      throw new Error(result.data.responseMessage);
    }

    // Store fetched link data into store
    yield put(actions.fetchAllLinks(action.adminId));

    // Call fetch success to set loading false
    yield put(actions.fetchSuccess());

    // Notification
    notify("success", "Invitation link added successfully");
  } catch (err) {
    console.log(err);

    // Notification
    notify("error", err.message);

    // Call fetch faild to store error message in state
    yield put(actions.fetchFaild(err.message));
  }
}

// Get all links function
export function* fetchingAllLinks(action) {
  try {
    // Call fetch start to displaying loading spinner
    yield put(actions.fetchStart());

    // Call function to fetch links data
    const result = yield getAllLinks(action.adminId);

    // If response error found throw error
    if (result.data.responseCode !== 200) {
      throw new Error(result.data.responseMessage);
    }

    // Store dashboard data into store
    yield put(actions.allLinksFetched(result.data.responseData));

    // Call fetch success to set loading false
    yield put(actions.fetchSuccess());
  } catch (err) {
    console.log(err);

    // Call fetch faild to store error message in state
    yield put(actions.fetchFaild(err.message));
  }
}

// Update link data function
export function* updatingLink(action) {
  try {
    // // Call fetch start to displaying loading spinner
    // yield put(actions.fetchStart());

    // Call function to update link
    const result = yield updateLink(
      action.adminId,
      action.linkId,
      action.updateData
    );

    // If response error found throw error
    if (result.data.responseCode !== 200) {
      throw new Error(result.data.responseMessage);
    }

    // Store fetched link data into store
    yield put(actions.fetchAllLinks(action.adminId));

    // Call fetch success to set loading false
    yield put(actions.fetchSuccess());

    // Notification
    notify("success", "Invitation link updated successfully");
  } catch (err) {
    console.log(err);

    // Call fetch faild to store error message in state
    yield put(actions.fetchFaild(err.message));
  }
}

// Delete link function
export function* deletingLink(action) {
  try {
    // // Call fetch start to displaying loading spinner
    // yield put(actions.fetchStart());

    // Call function to delete link
    const result = yield deleteLink(action.adminId, action.linkId);

    // If response error found throw error
    if (result.data.responseCode !== 200) {
      throw new Error(result.data.responseMessage);
    }

    // Store fetched link data into store
    yield put(actions.fetchAllLinks(action.adminId));

    // Call fetch success to set loading false
    yield put(actions.fetchSuccess());

    // Notification
    notify("success", "Invitation link deleted successfully");
  } catch (err) {
    console.log(err);

    // Call fetch faild to store error message in state
    yield put(actions.fetchFaild(err.message));
  }
}
