import React from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select';

import * as subheaderAction from '../../../../../app/modules/Subheader';

const year = new Date().getFullYear();

const yearOptions = [
  { value: year, label: year },
  { value: year - 1, label: year - 1 },
  { value: year - 2, label: year - 2 },
];

function DashboardFilter(props) {    

    // On sort change handler
    const onSortChangeHandler = (e) => {

        // Update year state in store
        props.updateYear(e.value);
    };

    return (
        <>
            <Form 
                className="pho_subheader_filter" 
            >
                <Form.Row>  
                    <Form.Group controlId="exampleForm.ControlSelect2">
                        <Select
                            value={ { value: props.year, label: props.year } }
                            onChange={ onSortChangeHandler }
                            placeholder="Sort By"
                            className="form-control p-0 border-0"
                            classNamePrefix="phoSelect"
                            options={ yearOptions }
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#DCF4E4',
                                primary75: '#DCF4E4',
                                primary50: '#DCF4E4',
                                primary:"#50C878",
                                },
                            })}
                        />
                    </Form.Group>
                </Form.Row>  
            </Form>
        </>
    );
};

// State
const mapStateToprops = state => {
    return {
        year: state.subHeader.year
    };
};

// Dispatch actions
const mapDispatchToProps = dispatch => {
    return {        
        updateYear: (year) => dispatch(subheaderAction.updateYear(year))
    };
};

export default connect(mapStateToprops, mapDispatchToProps)(DashboardFilter);