import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Table,
  Row,
  Button,
  Form,
  Modal,
  Pagination,
  InputGroup,
  FormControl,
  Col
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import { connect } from 'react-redux';
import { Formik, Field, FieldArray } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import ItemDropdown from "./ItemDropdown";
import { toAbsoluteUrl } from "../../_helpers";
import { initialTabValues } from './BookingSchema';
import * as bookingActions from '../../../app/modules/Booking';
import * as subheaderActions from '../../../app/modules/Subheader';
import * as paymentActions from '../../../app/modules/Payment';
import { refundPayment } from '../../../app/modules/Payment/paymentCrud';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: true,
  cancelable:false,
  swiping:true
};

const perfectScrollbarOptions2 = {
	wheelSpeed: 2,
	wheelPropagation: false,
  };

const BookingManagement = (props) => {
	let totalPages;
	let bookingsData;
	const pageNumbers = [];

	const modifiedInitialTabObj = Object.assign({}, initialTabValues);

	// State
	const [refundNow, setRefundNow] = useState(false);
	const [activeTabKey, setActiveTabKey] = useState('All');
	const [showRefundModal, setRefundModalShow] = useState(false);
	const [showRefundSuccessModal, setRefundSuccessModalShow] = useState(false);
	const [commonDelete, setCommonDelete] = useState({
		count: 0,
		value: false
	});
	const [bookingDetail, setViewBookingDetail] = useState({
		show: false
	});

	// On tab change get data
	const onTabChange = () => {
		try {
			let status;
			const skip = (props.skip - 1) * props.limit;
	
			if (activeTabKey === 'Completed') {
				status = 'COMPLETED';
			} else if (activeTabKey === 'Pending') {
				status = 'REQUESTED';
			} else if (activeTabKey === 'Cancelled') {
				status = 'CANCELLED';
			} else if (activeTabKey === 'Rejected') {
				status = 'REJECTED';
			} else if (activeTabKey === 'Approved') {
				status = 'APPROVED';
			} else if (activeTabKey === 'Payment Refunded') {
				status = 'PAYMENT_REFUNDED';
			} else if (activeTabKey === 'Payment Released') {
				status = 'PAYMENT_RELEASED';
			} else if (activeTabKey === 'Request Expired') {
				status = 'REQUEST_EXPIRED';
			}
	
			// Get all bookings details
			props.getAllBookings(
				props.user._id,
				undefined, 
				undefined, 
				status, 
				undefined, 
				skip, 
				props.limit,
				undefined
			);

		} catch (err) {
			console.log(err);
		}
	};

	// On find button click handler
	const onFindButtonClick = () => {
		try {
			const skip = (props.skip - 1) * props.limit;
	
			// Get all bookings details
			props.getAllBookings(
				props.user._id,
				new Date(props.fromDate).getTime(), 
				new Date(props.toDate).getTime(),
				undefined, 
				props.type, 
				skip, 
				props.limit,
				props.search
			);

		} catch (err) {
			console.log(err);
		}
	};

	// On Refund button click function
	const onRefundButtonClick = async (values, resetForm) => {
		try {

			// // Call refund function
			// await props.refundPayment(props.user._id, bookingDetail.userId, bookingDetail.bookingId, values);

			const userId = props.bookingDetail.userDetails._id;
			const bookingId = props.bookingDetail._id;

			// Refund payment function 
			const { data } = await refundPayment(
				props.user._id, 
				userId,
				bookingId, 
				values
			);

			// Hide handler refund modal close function
			handleRefundModalClose();

			// If response error found throw error
			if (data.responseCode !== 200) {
				throw new Error(data.responseMessage);
			}

			if (props.findClickedValue) {
				// Call on find button click function
				onFindButtonClick();

			} else {
				// Call on tab change function
				onTabChange();
			}
			
			// Call refund done function to close refund done modal
			props.refundPaymentDone(true);

			setTimeout(() => {
				// Reset init values
				resetForm({ amount: '' });

				// Hide modal 
				handleRefundModalClose();
			}, 500);
		} catch (err) {
			console.log(err);
		}
	};

	// Handle refund modal close function
	const handleRefundModalClose = () => {

		// Remove booking detail from store
		props.removeBookingDetailData();

		if (refundNow) {
			// Set refund now state
			setRefundNow(false);
		}

		// Set view booking detail state to false
		setViewBookingDetail({
			show: false
		});
	};

	// Handle refund done close
	const handleRefundNowClose = async () => {
		try {
			// Call refund done function to close refund done modal
			props.refundPaymentDone(false);

			// Call handle refund modal close function
			handleRefundModalClose();
		} catch (err) {
			console.log(err);
		}
	};

	// Page change handler
	const pageChangeHandler = (e) => {

		if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

		// Update current page in store
		props.updateSkip(parseInt(e.target.id));
	};

	// Page change handler
	const nextPageHandler = (e) => {
		const nextPage = props.skip + 1;

		if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

		// Update current page in store
		props.updateSkip(nextPage);
	};

	// Page change handler
	const prevPageHandler = (e) => {
		const prevPage = props.skip - 1;

		if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

		// Update current page in store
		props.updateSkip(prevPage);
	};

	// Page change handler
	const goToFirstPage = (e) => {
		const firstPage = 1;

		if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

		// Update current page in store
		props.updateSkip(firstPage);
	};

	// Page change handler
	const goToLastPage = (e) => {
		const lastPage = totalPages;

		if (props.findClickedValue) {
			// Update find state in store
			props.updateFind(true);
		}

		// Update current page in store
		props.updateSkip(lastPage);
	};

	// Common tab function
	const commonTabs = (values, setFieldValue) => {
		return (
			<Table hover className="phoTable">
				<thead>
					<tr>
						{/* <th width="80px">
							<div className="pho_checkbox iskl_border">
								<input
									type="checkbox"
									name="checkbox"
									className={`mr-2 checkbox`}
									checked={ values.checkbox }
									onChange={ (e) => {   
										let total = 0;
										// Set current check box value
										setFieldValue('checkbox', !values.checkbox);

										values.bookings.forEach((el, index) => {  
											total += 1;
											
											// Set all checkbox value
											setFieldValue(`bookings.${index}.checkbox`, !values.checkbox);
										});                                                                            

										if (values.checkbox || (!values.checkbox && total === 0)) {
											// Set common delete state
											setCommonDelete({
												count: 0,
												value: false
											});
										} else if (!values.checkbox && total > 0) {
											// Set common delete state
											setCommonDelete({
												count: total,
												value: true
											});
										}
									}}
								/>
								<label></label>
							</div>
						</th> */}
						<th width="100px">Date</th>
						<th width="130px">Booking Number</th>
						<th width="100px">Type</th>
						<th>Property Name</th>
						<th>Location</th>
						<th width="120px">Landlord</th>
						<th width="120px">Booked By</th>
						<th width="120px">Price</th>
						<th width="105px">Status</th>
						<th width="105px">Action</th>
						{/* {
                            commonDelete.count !== 0 ?
                                <th width="90px">
                                    <button
                                        style={{
                                            userSelect: 'none',
                                            cursor: 'pointer',
                                            color: 'red'
                                        }}
                                        className="btn btn-sm btn-danger text-white"
                                        type='submit'
                                    >
                                        Delete  
                                    </button>
                                </th>
                            :   
                                <th width="90px"
                                    style={{
                                        userSelect: 'none'
                                    }}
                                >
                                    Actions
                                </th>
                        } */}
						{/* <th width="71px">Actions</th> */}
					</tr>
				</thead>
				<tbody>
					<FieldArray name='bookings'>
						{({ insert, remove, push }) => (
							<>
								{
									!props.loading && values.bookings && values.bookings.length > 0 ?
										values.bookings.map((booking, index) => (
											<tr key={ index }>
												{/* <td width="80px">
													<div className="pho_checkbox iskl_border">
														<input                                                                                    
                                                            type="checkbox"
                                                            name={ `bookings.${index}.checkbox` }
                                                            className={`mr-2 checkbox`}
                                                            checked={ booking.checkbox }
                                                            onChange={ (e) => {
                                                                if (booking.checkbox) {
                                                                    // Set common delete state
                                                                    setCommonDelete({
                                                                        count: commonDelete.count - 1,
                                                                        value: false
                                                                    });
                                                                } else {
                                                                    // Set common delete state
                                                                    setCommonDelete({
                                                                        count: commonDelete.count + 1,
                                                                        value: true
                                                                    });
                                                                }

                                                                // Set field values
                                                                setFieldValue(`bookings.${index}.checkbox`, !booking.checkbox);
                                                            } }
                                                        />
														<label></label>
													</div>
												</td> */}
												<td>{ moment(booking.createdAt).format('l') }</td>
												<td className="pho_16_500">{ booking._id }</td>
												<td>{ booking.propertyType ? booking.propertyType === 'WORKPLACE' ? 'Workplace' : 'Home Office' : '' }</td>
												<td>
													<div className="pho_ellips w-170px">
														{ booking.propertyDetails && booking.propertyDetails.name && booking.propertyDetails.name.en }
													</div>
												</td>
												<td>
													<div className="pho_ellips w-90px">
														{ booking.propertyDetails && booking.propertyDetails.address && `${booking.propertyDetails.address.city}, ${booking.propertyDetails.address.country}` }
													</div>
												</td>
												<td>{ booking.landlordDetails && `${booking.landlordDetails.firstName} ${booking.landlordDetails.lastName}` }</td>
												<td>{ booking.userDetails && `${booking.userDetails.firstName} ${booking.userDetails.lastName}` }</td>
												<td>{ booking.totalPrice ? booking.totalPrice.toFixed(2) : '' }</td>
												<td>
													<label className={`m-0 pho_14_500 w-100
														${booking.status === 'REQUESTED' && 'pho_orengeLbl'}
														${booking.status === 'COMPLETED' && 'pho_greenLbl'}
														${booking.status === 'CANCELLED' && 'pho_blackLbl'}
														${booking.status === 'REJECTED' && 'pho_redLbl'}
														${booking.status === 'APPROVED' && 'pho_yelloLbl'}
														${booking.status === 'PAYMENT_RELEASED' && 'pho_pinkLbl'}
														${booking.status === 'PAYMENT_REFUNDED' && 'pho_blueLbl'}
														${booking.status === 'REQUEST_EXPIRED' && 'pho_purpleLbl'}
													`}>
														{ booking.status === 'REQUESTED' ? 'PENDING' : booking.status ? booking.status.replace('_', ' ') : ''  }
													</label>
												</td>
												{/* {
													booking.status === 'CANCELLED' &&
														<td>
															<Button
																variant="outline-success"
																className="py-1"
																onClick={ (e) => {
																	
																	// Call refund button click handler
																	onRefundButtonClickHandler(booking);
																} }
															>
																Refund
															</Button>
														</td>
												} */}
												<td className="text-right">
													<ItemDropdown 
														item="" 
														doc={ booking }
														view={ onRefundButtonClickHandler }
													/>
												</td>
											</tr>
										))
									:
										props.loading ?
											<tr>
												<td colSpan="10" className="text-center">Loading...</td>
											</tr>
									: 
										<tr>
											<td colSpan="10" className="text-center">No Data Available</td>
										</tr>
								}
							</>							
						)}
					</FieldArray>					
				</tbody>
			</Table>
		);
	};

	// Refund button click handler function
	const onRefundButtonClickHandler = async (e, booking) => {
		try {
			// Get booking detail
			await props.getBookingDetail(props.user._id, booking._id);

			// Set refund state
			setViewBookingDetail({
				show: true,
				...booking
			});
		} catch (err) {
			console.log(err);
		}	
	};

	// useEffect(() => {
	//   const skip = (props.skip - 1) * props.limit;

	//   // Get all bookings details
	//   props.getAllBookings(
	//     props.user._id,
	//     props.fromDate, 
	//     props.toDate, 
	//     props.status, 
	//     props.typeValue, 
	//     skip, 
	//     props.limit
	//   );
	// }, [props.fromDate, props.toDate, props.status, props.typeValue, props.skip]);

	useEffect(() => {
		if (!props.findClickedValue) {
			
			// Call on tab change function
			onTabChange();
		}
	}, [activeTabKey, props.skip]);

	useEffect(() => {
		if (props.findClickedValue && props.find) {

			// Call find button click function
			onFindButtonClick();
		}
	}, [props.find, props.skip]);

	useEffect(() => {
		// Remove data from store when component destroy
		return () => {

			// Subheader init state
			props.subheaderInit();

			// Booking init function
			props.bookingInit();

			// Skip init function
			props.skipInit();
		};
	}, []);

	if (!props.loading && props.bookings && props.bookings.recordsTotal > 0) {
		totalPages = Math.ceil(props.bookings.recordsTotal / props.limit);
			for (let i = 1; i <= totalPages; i++) {
			pageNumbers.push(i);
		};

		const array = [];
    
		props.bookings.records.forEach(booking => {
			const data = { ...booking };
			data.checkbox = false;
			array.push(data);
		});

		modifiedInitialTabObj.bookings = array; 
	}

	return (
		<>
			{/* <Modal 
				centered
				show={ bookingDetail.show } 
				onHide={ handleRefundModalClose } 
			>
				<Modal.Header className="border-0 pb-0" closeButton>
					<Modal.Title className="pho_18_600">Refund</Modal.Title>
				</Modal.Header>
				<Formik
					initialValues={ {
						amount: ''
					} }
					validationSchema={ Yup.object().shape({
						amount: Yup.string()
							.required('Please enter Amount')
					}) }
					onSubmit={ (values, { setSubmitting, resetForm }) => {
						const amount = values.amount.toFixed(2);

						// Set submitting to true
						setSubmitting(true);

						// Call on refund button click function
						onRefundButtonClick({ amount }, resetForm);
					} }
				>
					{({
						values,
						errors,
						touched,
						isSubmitting,
						handleChange,
						handleSubmit,
						setFieldValue
					}) => (
						<Form onSubmit={ handleSubmit }>
							<Modal.Body className="pb-0">
								<Form.Group>
									<Form.Label className="pho_14_500">Enter amount</Form.Label>
									<Field 
										step={ 0.01 }
										type="number"
										name="amount"
										onChange={ handleChange }
										className="form-control border-0 bg-gray-light h-auto py-5 px-6 pho_16_500 " 
									/>
									{touched.amount && errors.amount ? (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{errors.amount}</div>
										</div>
									) : null}
								</Form.Group>
							</Modal.Body>
							<Modal.Footer className="border-0 pt-0 justify-content-between">
								<Button
									variant="outline-success"
									onClick={ handleRefundModalClose }
									className="px-5 py-3"
								>
									Cancel
								</Button>
								<Button
									type="submit"
									variant="success"
									className="px-5 py-3"
									disabled={ isSubmitting }
								>
									Refund Now
								</Button>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal> */}

			<Modal 
				centered
				show={ bookingDetail.show } 
				onHide={ handleRefundModalClose } 
			>
				<Modal.Header className="border-0 pb-0" closeButton>
					<Modal.Title className="pho_18_600">Booking Details</Modal.Title>
				</Modal.Header>
				{
					!props.detailLoading && props.bookingDetail ?
						<>
							<Modal.Body className="pb-0">
								<Row>
									<Col className="d-flex align-items-center justify-content-between">
									<div className="pho_14_400">Booking Date: { moment(props.bookingDetail.createdAt).format('DD MMM YYYY') }</div>
									<label className={`m-0 pho_14_500
										${props.bookingDetail.status === 'REQUESTED' && 'pho_orengeLbl'}
										${props.bookingDetail.status === 'COMPLETED' && 'pho_greenLbl'}
										${props.bookingDetail.status === 'CANCELLED' && 'pho_blackLbl'}
										${props.bookingDetail.status === 'REJECTED' && 'pho_redLbl'}
										${props.bookingDetail.status === 'APPROVED' && 'pho_yelloLbl'}
										${props.bookingDetail.status === 'PAYMENT_RELEASED' && 'pho_pinkLbl'}
										${props.bookingDetail.status === 'PAYMENT_REFUNDED' && 'pho_blueLbl'}
										${props.bookingDetail.status === 'REQUEST_EXPIRED' && 'pho_purpleLbl'}
									`}>
										{ props.bookingDetail.status === 'REQUESTED' ? 'PENDING' : props.bookingDetail.status ? props.bookingDetail.status.replace('_', ' ') : ''  }
									</label>
									</Col>
									<Col md={12}>
										<hr/>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Booking Number</div>
										<div className="pho_16_600 pho_ellips pb-3">{ props.bookingDetail._id }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Property Name</div>
										<div className="pho_16_600 pho_ellips pb-3">{ props.bookingDetail.propertyDetails ? props.bookingDetail.propertyDetails.name.en : '' }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Type</div>
										<div className="pho_16_600 pb-3">{ props.bookingDetail.propertyType }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Location</div>
										<div className="pho_16_600 pb-3">{ props.bookingDetail.propertyDetails ? `${props.bookingDetail.propertyDetails.address.city}, ${props.bookingDetail.propertyDetails.address.country}` : '' }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Landlord</div>
										<div className="pho_16_600 pb-3">{ props.bookingDetail.landlordDetails ? `${props.bookingDetail.landlordDetails.firstName} ${props.bookingDetail.landlordDetails.lastName}` : '' }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Booked By</div>
										<div className="pho_16_600 pb-3">{ props.bookingDetail.userDetails ? `${props.bookingDetail.userDetails.firstName} ${props.bookingDetail.userDetails.lastName}` : '' }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Booked Date</div>
										<div className="pho_16_600 pb-3">{ moment(props.bookingDetail.fromDate).format('DD MMM YYYY') }</div>
									</Col>						
									<Col md={6} lg={4}>
										<div className="pho_14_400">To Date</div>
										<div className="pho_16_600 pb-3">{ moment(props.bookingDetail.toDate).format('DD MMM YYYY') }</div>
									</Col>
									<Col md={12}>
										<hr/>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Price</div>
										<div className="pho_16_600 pb-3">${ props.bookingDetail.price ? props.bookingDetail.price.toFixed(2) : '' }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Service Fee</div>
										<div className="pho_16_600 pb-3">${ props.bookingDetail.serviceFees ? props.bookingDetail.serviceFees.toFixed(2) : '' }</div>
									</Col>
									<Col md={6} lg={4}>
										<div className="pho_14_400">Final Amount</div>
										<div className="pho_16_600 pb-3">${ props.bookingDetail.totalPrice ? props.bookingDetail.totalPrice.toFixed(2) : '' }‬</div>
									</Col>
								</Row>
								{
									props.bookingDetail.status === 'CANCELLED' &&
										<div className="text-center my-3">
											{
												!refundNow ?
													<Button 
														variant="success"
														onClick={ (e) => {
															// Set refund now state
															setRefundNow(true);
														} }
													>
														Refund Now
													</Button>
												:
													<Formik
														initialValues={ {
															amount: ''
														} }
														validationSchema={ Yup.object().shape({
															amount: Yup.string()
																.required('Please enter Amount')
														}) }
														onSubmit={ (values, { setSubmitting, resetForm }) => {
															const amount = values.amount.toFixed(2);
									
															// Set submitting to true
															setSubmitting(true);
									
															// Call on refund button click function
															onRefundButtonClick({ amount }, resetForm);
														} }
													>
														{({
															values,
															errors,
															touched,
															isSubmitting,
															handleChange,
															handleSubmit,
															setFieldValue
														}) => (
															<Form onSubmit={ handleSubmit }>
																<InputGroup className="mb-3">
																	<Field 
																		step={ 0.01 }
																		min="0"
																		type="number"
																		name="amount"
																		onChange={ handleChange }
																		className="form-control" 
																	/>
																	<InputGroup.Append>
																		<Button 
																			type="submit"
																			variant="success"
																			disabled={ isSubmitting }
																		>
																			Refund Now
																		</Button>
																	</InputGroup.Append>
																	{touched.amount && errors.amount ? (
																		<div className="fv-plugins-message-container">
																			<div className="fv-help-block">{ errors.amount }</div>
																		</div>
																	) : null}
																</InputGroup>
															</Form>
														)}
													</Formik>
													// <InputGroup className="mb-3">
													// 	<FormControl/>
														// <InputGroup.Append>
														// 	<Button variant="success">Refund Now</Button>
														// </InputGroup.Append>
													// </InputGroup>
											}
										</div>
								}
							</Modal.Body>

							{
								props.bookingDetail.propertyDetails && props.bookingDetail.propertyDetails.cancellationPolicy &&
									<Modal.Footer className="border-0 pho_CancellationPolicyBG justify-content-start">
										<PerfectScrollbar
											options={ perfectScrollbarOptions2 }
											style={{ maxHeight: "80px", position: "relative" }}                  
										>
											<div className="pho_14_400 pb-2">Cancellation Policy:</div>
											<div className="pho_12_400">
												{ props.bookingDetail.propertyDetails.cancellationPolicy.en.data && props.bookingDetail.propertyDetails.cancellationPolicy.en.data }												
											</div>
											<div className="pho_12_400">
												{ props.bookingDetail.propertyDetails.cancellationPolicy.en.doc_url && 
													<a 
														target="_blank"
														href={ props.bookingDetail.propertyDetails.cancellationPolicy.en.doc_url }
														rel="noopener noreferrer"
													>
														click to view policy
													</a>
												}
											</div>
										</PerfectScrollbar>
									</Modal.Footer>
							}
						</>
					: 
						props.detailLoading ?
							<div id="compnent-splash-screen" className="compnent-splash-screen h-450px">
								<svg className="compnent-splash-spinner" viewBox="0 0 50 50">
									<circle 
										className="path"
										cx="25"
										cy="25"
										r="20"
										fill="none"
										strokeWidth="5"
									></circle>
								</svg>
							</div>							
						:
							<>
								<div className="text-center py-3 mt-3 mb-1">Error while fetching data</div>					
							</>
				}		
			</Modal>

			<Modal
				show={ props.refunded }
				onHide={ handleRefundNowClose }
				centered
				size="sm"
			>
				<Modal.Body className="text-center">
					<SVG
						alt="success"
						className="h-70px w-70px mb-6"
						src={toAbsoluteUrl("/media/custom/pho_success_icon.svg")}
					/>
					<div className="pho_18_600">Refund Done</div>
					<p className="pho_opacity_5 my-4">
						Payment refunded successfully.
					</p>
					<Button
						variant="success"
						onClick={ handleRefundNowClose }
						className="px-5 py-3"
					>
						OKAY
					</Button>
				</Modal.Body>
			</Modal>

			<div className="userDetailsPage phoBookingManagementPage">
				<Formik
					enableReinitialize
					initialValues={ modifiedInitialTabObj }
					// onSubmit={(values, { setSubmitting, resetForm }) => {
					//     try {
					//         setSubmitting(true);

					//         // Show delete modal
					//         showDeleteModal(undefined, values, true);

					//         if (deleteModal) {
					//             setTimeout(() => {                                
					//                 // Reset form to initial value
					//                 resetForm(modifiedInitialTabObj);

					//                 // Set submitting form to false
					//                 setSubmitting(false);  
					//             }, 500);
					//         }
							
					//     } catch (err) {
					//         console.log(err);

					//         // Notification
					//         notify('error', 'Something went wrong');
					//     }
					// }}
				>
					{({
						values,
						setFieldValue
					}) => (
						<form>
							<Tabs 
								defaultActiveKey={ activeTabKey } 
								id="uncontrolled-tab-example"
								onSelect={ (key) => {
									// Skip inti function call
									props.skipInit();

									// Update find clicked state in store to false
									props.findClicked(false);

									// Set active tab key to state
									setActiveTabKey(key);
								} }
							>
								<Tab eventKey="All" title="All">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Completed" title="Completed">
									<PerfectScrollbar component="div"
										options={ perfectScrollbarOptions }
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Pending" title="Pending">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Cancelled" title="Cancelled">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Rejected" title="Rejected">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Approved" title="Approved">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Payment Refunded" title="Refunded">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Payment Released" title="Payment Released">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
								<Tab eventKey="Request Expired" title="Expired">
									<PerfectScrollbar component="div"
										options={perfectScrollbarOptions}
										className="scroll"
										style={{ maxWidth: "100%", position: "relative" }}
									>
										{ commonTabs(values, setFieldValue) }										
									</PerfectScrollbar>
								</Tab>
							</Tabs>
						</form>
					)}
				</Formik>
				
			</div>
			{/* Pagination */}
			{ 
				!props.loading && props.bookings && props.bookings.recordsTotal > 0 && 
					<div className="d-flex flex-wrap justify-content-between align-items-center mt-5">
						<div className="pho_14_400 pho_opacity_5">
							Displaying { props.skip > 1 ? ((props.skip - 1) * props.limit) + 1 : 1 } - { props.bookings.recordsTotal > (props.limit * props.skip) ? props.limit * props.skip : props.bookings.recordsTotal } of { props.bookings.recordsTotal } records
						</div>
						<Pagination>
							{/* Go to first page */}
							<Pagination.First
								disabled={ props.skip === 1 } 
								onClick={ goToFirstPage }
							>
								<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
							</Pagination.First>

							{/* Go to previous page */}
							<Pagination.Prev
								disabled={ props.skip === 1 } 
								onClick={ prevPageHandler }
							>
								<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-left.svg")}/>
							</Pagination.Prev>

							{/* All pages */}
							{
								pageNumbers.map((number, i) => {
									if (
										number >= parseInt(props.skip) - 3 &&
										number <= parseInt(props.skip) + 3 
									) {
										return (
										<Pagination.Item
											key={ i }
											id={ number }
											active={ number === props.skip }
											onClick={ pageChangeHandler }
										>
											{ number }
										</Pagination.Item>
										);
									} else {
										return null;
									}
								})
							}

							{/* Go to next page */}
							<Pagination.Next
								disabled={ props.skip === totalPages } 
								onClick={ nextPageHandler }
							>
								<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-right.svg")}/>
							</Pagination.Next>

							{/* Go to last page */}
							<Pagination.Last
								disabled={ props.skip === totalPages } 
								onClick={ goToLastPage }
							>
								<SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
							</Pagination.Last>
						</Pagination>
					</div>
			}
		</>
	);
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    skip: state.booking.skip,
    limit: state.booking.limit,
	find: state.subHeader.find,
	error: state.booking.error,
	type: state.subHeader.type,
    toDate: state.subHeader.toDate,
    search: state.subHeader.search,
    loading: state.booking.loading,
	refunded: state.payment.refunded,
	bookings: state.booking.bookings,
	fromDate: state.subHeader.fromDate,
	bookingDetail: state.booking.bookingDetail,
	detailLoading: state.booking.detailLoading,
	findClickedValue: state.subHeader.findClicked
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bookingInit: () => dispatch(bookingActions.bookingInit()),
    skipInit: () => dispatch(bookingActions.bookingSkipInit()),
	subheaderInit: () => dispatch(subheaderActions.initState()),
	updateFind: (value) => dispatch(subheaderActions.updateFind(value)),
	findClicked: (value) => dispatch(subheaderActions.findClicked(value)),
	updateSkip: (skip) => dispatch(bookingActions.bookingSkipUpdate(skip)),
	removeBookingDetailData: () => dispatch(bookingActions.removeBookingDetail()),
	refundPaymentDone: (value) => dispatch(paymentActions.refundPaymentDone(value)),
	getBookingDetail: (adminId, bookingId) => dispatch(bookingActions.fetchBookingDetail(adminId, bookingId)),
	refundPayment: (
		adminId, 
		userId, 
		bookingId, 
		values
	) => dispatch(paymentActions.refundPayment(adminId, userId, bookingId, values)),
    getAllBookings: (
      adminId,
      fromDate, 
      toDate, 
      status, 
      typeValue, 
      skip, 
      limit,
      search
    ) => dispatch(bookingActions.fetchAllBookings(adminId, fromDate, toDate, status, typeValue, skip, limit, search))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingManagement);

// {/* <tr>
//   <td width="80px">
//     <div className="pho_checkbox iskl_border">
//       <input type="checkbox" className={`mr-2 checkbox`} />
//       <label></label>
//     </div>
//   </td>
//   <td>06/03/2020</td>
//   <td className="pho_16_500">012345678900</td>
//   <td>Workplace</td>
//   <td>
//     <div className="pho_ellips w-170px">
//       Neque porro quisquam
//     </div>
//   </td>
//   <td>
//     <div className="pho_ellips w-90px">New York, NY</div>
//   </td>
//   <td>LandlordName</td>
//   <td>UserName</td>
//   <td>
//     <label className="m-0 pho_orengeLbl pho_14_500 w-100">
//       PENDING
//     </label>
//   </td>
//   <td></td>
//   <td className="text-right">
//     <ItemDropdown />
//   </td>
// </tr>
// <tr>
//   <td width="80px">
//     <div className="pho_checkbox iskl_border">
//       <input type="checkbox" className={`mr-2 checkbox`} />
//       <label></label>
//     </div>
//   </td>
//   <td>06/03/2020</td>
//   <td className="pho_16_500">012345678900</td>
//   <td>Home Office</td>
//   <td>
//     <div className="pho_ellips w-170px">
//       Neque porro quisquam
//     </div>
//   </td>
//   <td>
//     <div className="pho_ellips w-90px">New York, NY</div>
//   </td>
//   <td>LandlordName</td>
//   <td>UserName</td>
//   <td>
//     <label className="m-0 pho_greenLbl pho_14_500 w-100">
//       COMPLETED
//     </label>
//   </td>
//   <td></td>
//   <td className="text-right">
//     <ItemDropdown />
//   </td>
// </tr>
// <tr>
//   <td width="80px">
//     <div className="pho_checkbox iskl_border">
//       <input type="checkbox" className={`mr-2 checkbox`} />
//       <label></label>
//     </div>
//   </td>
//   <td>06/03/2020</td>
//   <td className="pho_16_500">012345678900</td>
//   <td>Home Office</td>
//   <td>
//     <div className="pho_ellips w-170px">
//       Neque porro quisquam
//     </div>
//   </td>
//   <td>
//     <div className="pho_ellips w-90px">New York, NY</div>
//   </td>
//   <td>LandlordName</td>
//   <td>UserName</td>
//   <td>
//     <label className="m-0 pho_blackLbl pho_14_500 w-100">
//       CANCELLED
//     </label>
//   </td>
//   <td>
//     <Button
//       variant="outline-success"
//       className="py-1"
//       onClick={onClickRefund}
//     >
//       Refund
//     </Button>
//   </td>
//   <td className="text-right">
//     <ItemDropdown />
//   </td>
// </tr> */}

