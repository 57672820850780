/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="pho-dashboard icon"></span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item 
              ${getMenuItemActive("/user-management", false) ||
                getMenuItemActive("/user-details", false) ||
                getMenuItemActive("/add-user", false) ||
                getMenuItemActive("/user-accommodations", false)}
            `}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-management">
            <span className="pho-user icon"></span>
            <span className="menu-text">User Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item 
              ${getMenuItemActive("/landlord-management", false) ||
                getMenuItemActive("/landlord-details", false) ||
                getMenuItemActive("/add-landlord", false) ||
                getMenuItemActive("/landlord-accommodations", false)}
            `}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/landlord-management">
            <span className="pho-landlord1 icon"></span>
            <span className="menu-text">Landlord Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/accommodation-management",
            false
          ) || getMenuItemActive("/accommodation-details", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/accommodation-management">
            <span className="pho-accommodation icon"></span>
            <span className="menu-text">Accommodation Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/accommodation-request",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/accommodation-request">
            <span className="pho-accommodationRequest icon"></span>
            <span className="menu-text">Accommodation Request</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/master-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/master-management">
            <span className="pho-master icon"></span>
            <span className="menu-text">Master Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/support", false) ||
            getMenuItemActive("/support-details", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/support">
            <span className="pho-transaction icon"></span>
            <span className="menu-text">Support</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/cms-pages", false) ||
            getMenuItemActive("/about-us", false) ||
            getMenuItemActive("/cancellation-policy", false) ||
            getMenuItemActive("/how-we-works", false) ||
            getMenuItemActive("/privacy-policy", false) ||
            getMenuItemActive("/terms-conditions", false) ||
            getMenuItemActive("/trust-safety", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/cms-pages">
            <span className="pho-cms icon"></span>
            <span className="menu-text">CMS Pages</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/template-management",
            false
          ) ||
            getMenuItemActive("/add-template", false) ||
            getMenuItemActive("/edit-template", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/template-management">
            <span className="pho-template icon"></span>
            <span className="menu-text">Template Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/language-settings",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/language-settings">
            <span className="pho-languages icon"></span>
            <span className="menu-text">Language Settings</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/reports", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/reports">
            <span className="pho-reports icon"></span>
            <span className="menu-text">Reports</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/booking-management",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/booking-management">
            <span className="pho-booking icon"></span>
            <span className="menu-text">Booking Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/landlord-payment",
            false
          ) || getMenuItemActive("/landlord-payment-details", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/landlord-payment">
            <span className="pho-landlord-payment icon"></span>
            <span className="menu-text">Landlord Payment</span>
            {/* <span className="menu-text">Landlord Payment</span> */}
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/payment-received-by-users",
            false
          ) || getMenuItemActive("/user-payment-details", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/payment-received-by-users">
            <span className="pho-landlord-payment icon"></span>
            <span className="menu-text">Payment Received By Users</span>
            {/* <span className="menu-text">Landlord Payment</span> */}
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/payout", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/payout">
            <span className="pho-payout icon"></span>
            <span className="menu-text">Payout</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/tax-management", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/tax-management">
            <span className="pho-payout icon"></span>
            <span className="menu-text">Tax Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/invitation-link",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/invitation-link">
            <span className="pho-payout icon"></span>
            <span className="menu-text">Invitation Link</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
