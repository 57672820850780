import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';

import * as userAction from '../../../../../app/modules/User';
import { useSubheader } from "../../../_core/MetronicSubheader";
import * as bookingAction from '../../../../../app/modules/Booking';
import * as landlordAction from '../../../../../app/modules/Landlord';
import * as subheaderAction from '../../../../../app/modules/Subheader';

const sortingOptions = [
  // { value: '', label: 'Sort By' },
  { value: 'asc', label: 'A-Z' },
  { value: 'dsc', label: 'Z-A' },
];

const typeOptions = [
  // { value: '', label: 'Type' },
  { value: 'HOME_OFFICE', label: 'Home Office' },
  { value: 'WORKPLACE', label: 'Workplace' },
];

const statusOptions = [
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'REQUESTED', label: 'Pending' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'PAYMENT_RELEASED', label: 'Payment Released' },
  { value: 'PAYMENT_REFUNDED', label: 'Payment Refunded' },
  { value: 'REQUEST_EXPIRED', label: 'Request Expired' }
];

function FilterActions2(props) {
  let landlordOption = [];
  let userOption = [];
  const subheader = useSubheader();
  const location = useLocation();
  
  // State
  const [typeOption, setTypeOption] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [showBooking, setShowBooking] = useState(false);
  const [statusOption, setStatusOption] = useState(null);
  const [selectOption, setSelectOption] = useState(null);
  const [hideStatus, setHideStatus] = useState(false);
  const [find, setFind] = useState(false);
  const [showType, setShowType] = useState(true);

  // On sort change handler
  const onSortChangeHandler = (e) => {

    // Set state
    setSortOption(e);

    // Store sorting value in store
    props.addSorting('createdAt', e.value);
  };

  // On status change handler
  const onStatusChangeHandler = (e) => {

    // Set state
    setStatusOption(e);

    // Store sorting value in store
    props.addStatus(e.value);
  };

  // On search select handler
  const onSearchSelectHandler = (e) => {

    // Set state
    setSelectOption(e);

    // Store sorting value in store
    props.addSearch(e.value);
  };

  // On type change handler
  const onTypeChangeHandler = (e) => {

    // Set state
    setTypeOption(e);

    // Store sorting value in store
    props.addType(e.value);
  };

  // On change function
  const onChange = dates => {
    const [start, end] = dates;

    // Update from date in store
    props.addFromDate(start);

    // Update to date in store
    props.addToDate(end);
  };

  useEffect(() => {
    if (selectOption) {

      // Set select option state to null
      setSelectOption(null);
    }

    if (statusOption) {
      
      // Set statusOption state to null
      setStatusOption(null);
    }

    if (typeOption) {
      
      // Set type option state to null
      setTypeOption(null);
    }

    // if (sortOption) {
      
    //   // Set sort option state to null
    //   setSortOption(null);
    // }
    
    //booking-management page
    if (location.pathname === '/booking-management'){
      // Fetch landlord list
      props.getLandlordList(props.user._id);

      // Set show Booking value
      setShowBooking(true);

      // Set hide status state to true
      setHideStatus(true);

    } else {
      // Set show Booking value
      setShowBooking(false);  

      // Set hide status state to false
      setHideStatus(false);
    }

    if (location.pathname === '/payment-received-by-users') {
      // Fetch user list
      props.getUserList(props.user._id);

      // Show type set to false
      setShowType(false);

    } else { 
      // Show type set to true
      setShowType(true);
    }
  }, [subheader, location.pathname]);

  if (props.landlordList && props.landlordList.length > 0) {
    props.landlordList.forEach(landlord => {
      landlordOption.push({ value: landlord._id, label: `${landlord.firstName} ${landlord.lastName}` });
    });
  }

  if (props.userList && props.userList.length > 0) {
    props.userList.forEach(user => {
      userOption.push({ value: user._id, label: `${user.firstName} ${user.lastName}` });
    });
  }
  
  return (
    <>
      <Form 
        className="pho_subheader_filter" 
      >
        <Form.Row className="justify-content-md-end align-items-start">
          <Form.Group>
            {/* <Form.Control 
              type="text" 
              name="Search"
              // placeholder="Search by landlord, user, location" 
              placeholder="Search by landlord" 
              onChange={ (e) => {

                props.addSearch(e.target.value);
              } }
            /> */}
            <Select 
              value={ selectOption }
              onChange={ onSearchSelectHandler }
              placeholder={ location.pathname === '/payment-received-by-users' ? 'User' : "Landlord" }
              className="form-control p-0 border-0"
              classNamePrefix="phoSelect"
              options={ location.pathname === '/payment-received-by-users' ? userOption : landlordOption }
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#DCF4E4',
                  primary75: '#DCF4E4',
                  primary50: '#DCF4E4',
                  primary:"#50C878",
                },
              })}
            />
          </Form.Group> 
          {
            !hideStatus &&
              <Form.Group>
                <Select
                  value={ statusOption }
                  onChange={ onStatusChangeHandler }
                  placeholder="Status"
                  className="form-control p-0 border-0"
                  classNamePrefix="phoSelect"
                  options={ statusOptions }
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#DCF4E4',
                      primary75: '#DCF4E4',
                      primary50: '#DCF4E4',
                      primary:"#50C878",
                    },
                  })}
                />
              </Form.Group>
          }

          {
            showType &&
              <Form.Group controlId="exampleForm.ControlSelect2">
                <Select
                  value={ typeOption }
                  onChange={ onTypeChangeHandler }
                  placeholder="Type"
                  className="form-control p-0 border-0"
                  classNamePrefix="phoSelect"
                  options={ typeOptions }
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#DCF4E4',
                      primary75: '#DCF4E4',
                      primary50: '#DCF4E4',
                      primary:"#50C878",
                    },
                  })}
                />
              </Form.Group>
          }
          {/* <Form.Group>
            <Select
              value={ sortOption }
              onChange={ onSortChangeHandler }
              placeholder="Sort By"
              className="form-control p-0 border-0"
              classNamePrefix="phoSelect"
              options={ sortingOptions }
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#DCF4E4',
                  primary75: '#DCF4E4',
                  primary50: '#DCF4E4',
                  primary:"#50C878",
                },
              })}
            />
          </Form.Group> */}
          {
            showBooking && 
              <Form.Group>
                <DatePicker 
                  selected={ props.fromDate } 
                  onChange={ onChange } 
                  startDate={ props.fromDate }
                  endDate={ props.toDate }
                  className="form-control icondate"
                  selectsRange
                />
              </Form.Group>
          }

          <Button 
            variant="success" 
            className="mb-2"
            disabled={ find }
            onClick={ (e) => {

              if (
                location.pathname === '/booking-management' && 
                !props.fromDate && !props.toDate && !props.search && !props.type
              ) {
                return;
              }

              if (
                location.pathname === '/payment-received-by-users' && 
                !props.search && !props.status
              ) {
                return;
              }

              // Set find state to true
              setFind(true);

              // if (props.findClickedValue) {
              //   // Update find clicked state in store to false
              //   props.findClicked(false);  
              // }

              if (
                location.pathname === '/booking-management' ||
                location.pathname === '/payment-received-by-users'
              ) {

                // Booking Skip init function
                props.skipInit();
              }

              // Store find state in store to true
              props.updateFind(true);

              // Update find clicked state in store to true
              props.findClicked(true);

              setTimeout(() => {
                // set find state to false
                setFind(false);

                // Store find state in store to true
                props.updateFind(false);
              }, 3000);
            } }
          >
            Find
          </Button>
        </Form.Row>  
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    type: state.subHeader.type,
    userList: state.user.userList,
    toDate: state.subHeader.toDate,
    search: state.subHeader.search,
    status: state.subHeader.status,
    fromDate: state.subHeader.fromDate,
    landlordList: state.landlord.landlordList,
    findClickedValue: state.subHeader.findClicked
  };
};

// Dispatch actions
const mapDispatchToProps = dispatch => {
  return {
    skipInit: () => dispatch(bookingAction.bookingSkipInit()),
    addType: (value) => dispatch(subheaderAction.addType(value)),
    addStatus: (value) => dispatch(subheaderAction.addStatus(value)),
    addSearch: (value) => dispatch(subheaderAction.addSearch(value)),
    addToDate: (value) => dispatch(subheaderAction.addToDate(value)),
    updateFind: (value) => dispatch(subheaderAction.updateFind(value)),
    getUserList: (adminId) => dispatch(userAction.getUserList(adminId)),
    findClicked: (value) => dispatch(subheaderAction.findClicked(value)),
    addFromDate: (value) => dispatch(subheaderAction.addFromDate(value)),
    getLandlordList: (adminId) => dispatch(landlordAction.getLandlordList(adminId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterActions2);
