import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { IconButton, InputAdornment } from '@material-ui/core';
// import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import { notify } from '../toaster';
import { toAbsoluteUrl } from "../../_helpers";
import { changeUserPassword } from '../../../app/modules/Auth/_redux/authCrud';
import { changePasswordInitialValues, changePasswordSchema } from './ProfileUtils';

import 'react-toastify/dist/ReactToastify.min.css';

const addBodyClass = className => document.body.classList.add('ChangePassword');
const removeBodyClass = className => document.body.classList.remove("ChangePassword");

const ChangePassword = (props) => {
    const history = useHistory();

    const [passValues, setValues] = useState({
        showPassword: false,
        showPassword2: false,
        showPassword3: false,
    });

     // Show password function
     const handleClickShowPassword = () => {
        setValues({ ...passValues, showPassword: !passValues.showPassword });
    };

    const handleClickShowPassword2 = () => {
        setValues({ ...passValues, showPassword2: !passValues.showPassword2 });
    };

    const handleClickShowPassword3 = () => {
        setValues({ ...passValues, showPassword3: !passValues.showPassword3 });
    };

    const changePasswordFunction = async (values, resetForm, setSubmitting) => {
        try {
            const oldPassword = values.oldPassword;
            const newPassword = values.newPassword;

            const { data } = await changeUserPassword(props.user._id, oldPassword, newPassword);

            console.log('data', data);

            if (data.responseCode !== 200) {
                throw new Error(data.responseMessage);
            }

            // if (data.response && data.response.responseCode === 400) {
            //     // // Notification
            //     // notify('error', data.response.responseMessage);

            //     // Throw error
            //     throw Error(data.response.responseMessage);
            // }
            
            // // If validation error found throw error
            // if (data.error && data.error.errors.lenth > 0) {
            //     // // Notification
            //     // notify('error', data.error.errors[0].message);

            //     // Throw error
            //     throw Error(data.error.errors[0].message);
            // }
            
            // Notification
            notify('success', 'Password updated successfully');

            setTimeout(() => {      
                history.push('/profile');

                // Reset form to initial value
                resetForm(changePasswordInitialValues);

                // Set submitting form to false
                setSubmitting(false);
            }, 3000);

        } catch (err) {
            // console.log(err);

            // Set submitting to true
            setSubmitting(false);

            // Notification
            notify('error', err.message);
        }
    };    
    
    useEffect(() => {
        // Set up
        props.className instanceof Array ? props.className.map(addBodyClass) : addBodyClass(props.className);

        // // Clean up
        // return () => {
        //     props.className instanceof Array
        //         ? props.className.map(removeBodyClass)
        //         : removeBodyClass(props.className);
        // };
    },[props]);
    
    return (
        <div className="">
            <div className="container-fluid">
                <div className="row pb-10 justify-content-center">
                    <div className="col-5">
                        <Formik
                            enableReinitialize
                            initialValues={ changePasswordInitialValues }
                            validationSchema={ changePasswordSchema }
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                // Set submitting state to true
                                setSubmitting(true);

                                // Call change password function
                                changePasswordFunction(values, resetForm, setSubmitting);

                                // // Password change action function
                                // props.changePassword(values);
                            }}
                        >
                            {({
                                values, 
                                errors, 
                                touched, 
                                dirty,
                                isValid, 
                                isSubmitting,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched
                            }) => (
                                <Form onSubmit={ handleSubmit }>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="pho_22_600 pb-2">Change Password</div>
                                            <div className="pho_18_400 pb-10 text-muted">Please never share with anyone for safe use.</div>
                                        </div>
                                        <div className=" col-12">
                                            {/* begin: Password */}
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Old Password<sup className="text-danger">*</sup></label>
                                                <Field
                                                    name="oldPassword"
                                                    type={passValues.showPassword ? 'text' : 'password'}
                                                    className={`form-control h-auto py-5 px-6 `}
                                                />
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={ handleClickShowPassword }
                                                    >
                                                        {passValues.showPassword ? <img height="15px" alt="visibility off" src={toAbsoluteUrl("/media/custom/pho_visibilityoff.svg")}/> : <span className="pho-visibility"></span>}
                                                    </IconButton>
                                                </InputAdornment>

                                                {touched.oldPassword && errors.oldPassword ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.oldPassword}</div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {/* end: Password */}
                                            {/* begin: Password */}
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">New Password<sup className="text-danger">*</sup></label>
                                                <Field
                                                    name="newPassword"
                                                    type={passValues.showPassword2 ? 'text' : 'password'}
                                                    className={`form-control h-auto py-5 px-6 `}
                                                />
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={ handleClickShowPassword2 }
                                                    >
                                                        {passValues.showPassword2 ? <img height="15px" alt="visibility off" src={toAbsoluteUrl("/media/custom/pho_visibilityoff.svg")}/> : <span className="pho-visibility"></span>}
                                                    </IconButton>
                                                </InputAdornment>

                                                {touched.newPassword && errors.newPassword ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.newPassword}</div>
                                                    </div>
                                                ) : null}                                                
                                            </div>
                                            {/* end: Password */}
                                            {/* begin: Password */}
                                            <div className="form-group fv-plugins-icon-container">
                                                <label className="pho_14_500">Confirm Password<sup className="text-danger">*</sup></label>
                                                <Field
                                                    name="confirmPassword"
                                                    type={passValues.showPassword3 ? 'text' : 'password'}
                                                    className={`form-control h-auto py-5 px-6`}
                                                />
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Toggle password visibility"
                                                        onClick={ handleClickShowPassword3 }
                                                    >
                                                        {passValues.showPassword3 ? <img height="15px" alt="visibility off" src={toAbsoluteUrl("/media/custom/pho_visibilityoff.svg")}/> : <span className="pho-visibility"></span>}
                                                    </IconButton>
                                                </InputAdornment>      

                                                {touched.confirmPassword && errors.confirmPassword ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.confirmPassword}</div>
                                                    </div>
                                                ) : null}                                          
                                            </div>
                                            {/* end: Password */}

                                        </div>
                                        <div className="col-12 mt-5 d-flex flex-wrap align-items-center justify-content-between">
                                            <NavLink className="btn text-dark btn-link my-3" to="/profile">
                                                Back
                                            </NavLink>
                                            <button
                                                type="submit"
                                                disabled={ isSubmitting }
                                                className={`btn btn-success px-8 py-4 my-3`}
                                            >
                                                <span>Update</span>
                                            </button>
                                        </div>
                                    </div> 
                                </Form>
                            )}
                        </Formik>
                    </div>    
                </div>    
            </div> 
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user
    };
};

// const mapDispatchToProps = dispatch => {
//     return {

//     };
// };

export default connect(mapStateToProps)(ChangePassword);