/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { Link, NavLink, useHistory } from "react-router-dom";
import { connect } from 'react-redux';

import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import * as notificationActions from '../../../../../app/modules/Notification';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

function UserNotificationsDropdown(props) {
  let notifications;
  const notificationIds = [];
  const history = useHistory();
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  // On notification button click handler
  const onNotificationButtonClick = (notificationId) => {

    // Push history
    history.push(`/accommodation-details/${notificationId}`);
  };

  // Handle dropdown toggle function
  const handleToggle = async (isOpen, event, metadata) => {
    if (isOpen && notificationIds.length > 0) {
      const skip = (props.skip - 1) * props.limit;

      // Call update notification viewed action function
      await props.updateNotifications(
        props.user._id, 
        notificationIds,
        skip,
        props.limit
      );
    }
  };

  useEffect(() => {
    // Notification count function
    props.getNotificationCount(props.user._id);

    const skip = (props.skip - 1) * props.limit;

    // Get all notification
    props.getAllNotifications(props.user._id, skip, props.limit);

    // Remove notification data when component destroy
    return () => {

      // Notification init function
      props.notificationInit();
    };
  }, []);

  if (props.allNotifications && props.allNotifications.records && props.allNotifications.records.length > 0) {
    notifications = props.allNotifications.records.map((notification, index) => {
      // Push notification id into notification ids array
      notificationIds.push(notification._id);

      return (
        <div className="pho_new d-flex align-items-center mb-6" key={ index }>
          {
            !notification.isRead &&
            <span className="pho_new_notification"></span>
          }
          {/* <div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
            <span className="symbol-label">
              <img alt="user" src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
            </span>
          </div> */}
          <div className="pho_notification_text d-flex flex-column">
            <span
              onClick={ (e) => onNotificationButtonClick(notification.refId) }
              className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
            >
              { notification.message.en }
            </span>
            {/* <span className="pho_ellips text-muted">
              Duis non eros consequat elementum.
            </span> */}
          </div>
        </div>
      );
    });
  }

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-secondary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-secondary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown 
          alignRight
          drop="down" 
          onToggle={ handleToggle }
        >
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            >
              <div
                className="btn pho_noti_btn btn-md mr-4"
                id="kt_quick_notifications_toggle"
              >
                <span className="pho-notification"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                {
                  !props.loading && props.count && props.count > 0 ?
                    <span className="pho_base"></span>
                  : null
                }                
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-5 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div className="d-flex flex-column rounded-top">
                <div className="d-flex justify-content-between pt-3 pb-7">
                <div className="pho_18_600">Notifications</div>
                  <Link 
                    to="notifications" 
                    className="pho_14_600 text-success text-uppercase"
                  >
                    View All
                  </Link>
                </div>
                
                {
                  !props.loading && props.allNotifications && props.allNotifications.records && props.allNotifications.records.length > 0 ?
                    <PerfectScrollbar
                      className="scroll px-3"
                      options={ perfectScrollbarOptions }
                      style={{ maxHeight: "300px", position: "relative" }}                  
                    >
                      {
                        notifications
                      }
                    </PerfectScrollbar>
                  :
                    props.loading ?
                      <div id="compnent-splash-screen" className="compnent-splash-screen">
                          <svg className="compnent-splash-spinner" viewBox="0 0 50 50">
                              <circle 
                                  className="path"
                                  cx="25"
                                  cy="25"
                                  r="20"
                                  fill="none"
                                  strokeWidth="5"
                              ></circle>
                          </svg>
                      </div>
                : 
                  <div>
                    No Data Available
                  </div>
                }
                
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    skip: state.notification.skip,
    limit: state.notification.limit,
    count: state.notification.count,
    loading: state.notification.loading,
    allNotifications: state.notification.allNotifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notificationInit: () => dispatch(notificationActions.notificationInit()),
    getNotificationCount: (adminId) => dispatch(notificationActions.fetchNotificationCount(adminId)),
    updateNotifications: (
      adminId, 
      notificationIds,
      skip, 
      limit
    ) => dispatch(notificationActions.notificationUpdate(adminId, notificationIds, skip, limit)),
    getAllNotifications: (
      adminId, 
      skip, limit
    ) => dispatch(notificationActions.fetchAllNotification(adminId, skip, limit))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsDropdown);

{/* <div className="pho_new d-flex align-items-center mb-6">
  <span className="pho_new_notification"></span>
  <div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
      <img alt="user" src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
  </div>
  <div className="pl-5 pho_notification_text d-flex flex-column">
    <a
      href="#"
      className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
      Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
      Duis non eros consequat elementum.
    </span>
  </div>
</div>
<div className="d-flex align-items-center mb-6">
  <span className="pho_new_notification"></span>
  <div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
      <img alt="user" src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
  </div>
  <div className="pl-5 pho_notification_text d-flex flex-column">
    <a
      href="#"
      className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
      Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
      Duis non eros consequat elementum.
    </span>
  </div>
</div>
<div className="d-flex align-items-center mb-6">
  <span className="pho_new_notification"></span>
  <div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
      <img alt="user" src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
  </div>
  <div className="pl-5 pho_notification_text d-flex flex-column">
    <a
      href="#"
      className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
      Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
      Duis non eros consequat elementum.
    </span>
  </div>
</div>
<div className="d-flex align-items-center mb-6">
  <span className="pho_new_notification"></span>
  <div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
      <img alt="user" src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
  </div>
  <div className="pl-5 pho_notification_text d-flex flex-column">
    <a
      href="#"
      className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
      Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
      Duis non eros consequat elementum.
    </span>
  </div>
</div>
<div className="d-flex align-items-center mb-6">
  <span className="pho_new_notification"></span>
  <div className="symbol symbol-50 symbol-light-primary pho_rounded_12">
    <span className="symbol-label">
      <img alt="user" src={toAbsoluteUrl("/media/users/100_1.jpg")} className="w-100 h-100"/>
    </span>
  </div>
  <div className="pl-5 pho_notification_text d-flex flex-column">
    <a
      href="#"
      className="pho_ellips text-dark text-hover-success mb-1 pho_16_500"
    >
      Neque porro quisquam dolorem
    </a>
    <span className="pho_ellips text-muted">
      Duis non eros consequat elementum.
    </span>
  </div>
</div> */}