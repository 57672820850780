import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { notify } from '../../../../_metronic/_partials/toaster';
import { 
    getAllReport,
    downloadReport
} from '../reportCrud';

import 'react-toastify/dist/ReactToastify.min.css';

// Get all report function
export function* fetchingAllReport(action) {  
    try {
        // Call fetch start to displaying loading spinner
        yield put(actions.fetchStart());

        // Call function to fetch all notification
        const result = yield getAllReport(
            action.adminId, 
            action.column, 
            action.dir, 
            action.fromDate, 
            action.toDate, 
            action.skip, 
            action.limit,
            action.typeValue,
            action.searchColumn,
            action.searchVal
        );

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }
        
        // Store dashboard data into store
        yield put(actions.allReportFetched(result.data.responseData));

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};

// Downloading report function
export function* downloadingReport(action) {    
    try {
        // // Call fetch start to displaying loading spinner
        // yield put(actions.fetchStart());

        // Call function to change CMS status
        const result = yield downloadReport(
            action.adminId, 
            action.column, 
            action.dir, 
            action.fromDate, 
            action.toDate, 
            action.skip, 
            action.limit,
            action.typeValue,
            action.searchColumn,
            action.searchVal
        );

        console.log('result', result);

        // If response error found throw error
        if (result.data.responseCode !== 200) {
            throw new Error(result.data.responseMessage);
        }

        // // Store dashboard data into store
        // yield put(actions.allReportFetched(result.data.responseData));  

        // Notification
        notify('success', 'Report downloaded successfully');

        // Call fetch success to set loading false
        yield put(actions.fetchSuccess());

    } catch (err) {
        console.log(err);

        // Call fetch faild to store error message in state
        yield put(actions.fetchFaild(err.message));
    }
};