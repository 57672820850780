import axios from "axios";

// Language related URL
export const NOTIFICATION_ALL_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAdminNotifications`;
export const NOTIFICATION_COUNT_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/getAdminNotificationsCount`;
export const NOTIFICATION_UPDATE_URL = `${process.env.REACT_APP_BACKEND_LIVE_API}/updateNotificationStatus`;

// All Language get function
export async function getAllNotification(adminId, skip, limit) {
  return await axios.get(`${NOTIFICATION_ALL_URL}/${adminId}?skip=${skip}&limit=${limit}`);
};

// Get notification count function
export async function getNotificationCount(adminId) {
  return await axios.get(`${NOTIFICATION_COUNT_URL}/${adminId}`);
};

// Language status change function
export async function notificationUpdate(adminId, notificationIds) {
  return await axios.put(`${NOTIFICATION_UPDATE_URL}/${adminId}`, { notificationIds });
};

// // Language verification function
// export async function updateLanguage(adminId, languageId, values) {
//   return await axios.put(`${NOTIFICATION_UPDATE_URL}/${adminId}/${languageId}`, { ...values });
// };